import { DocumentPriority } from "../enums/documentPriority";
import { DocumentStatus } from "../enums/documentStatus";

export const getDocumentStatusColor = (status: DocumentStatus): string => {
    switch (status) {
        case DocumentStatus.Draft:
            return 'text-gray';
        case DocumentStatus.New:
            return 'bg-gradient-purple';
        case DocumentStatus.Process:
            return 'bg-gradient-orange';
        case DocumentStatus.Finish:
            return 'bg-gradient-cyan';
        case DocumentStatus.Promulgate:
            return 'bg-gradient-lime';
        case DocumentStatus.Cancel:
            return 'bg-gradient-red';
        case DocumentStatus.Retract:
            return 'bg-gradient-stone';
        default:
            return '';
    }
};

export const getDocumentPriorityColor = (dp: DocumentPriority) => {
    switch (dp) {
        case DocumentPriority.High:
            return 'bg-gradient-orange'
        case DocumentPriority.Urgent:
            return 'bg-gradient-red'
        default:
            return 'bg-gradient-blue';
    }
}

