import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectEmployeeState } from "src/store/slices/commonSlice"

export interface IUserAvatar {
    id: string
    fullName?: string
    departmentName?: string
    departmentId?:string
}

const UserAvatar = (props: IUserAvatar) => {
    const employeeState = useSelector(selectEmployeeState)
    const [state, setState] = useState({
        fullName: props.fullName,
        departmentName: props.departmentName 
    })
    useEffect(() => {
        if (!props.fullName) {
            const user = employeeState.find((x: any) => x.employeeId === props.id)
            if(user)
            setState({
                fullName: user?.employeeName,
                departmentName: user?.departmentName || user?.departmentId
            })
        }
    }, [props.id])
    return (
        <div className="flex px-2 py-1">
            <div>
                <img src={`${process.env.REACT_APP_FILE_URL}/profile/${props.id}/avatar.png?v=1`} 
                onError={(e) => { e.currentTarget.src = "/assets/img/team-2.jpg" }}
                    className="inline-flex items-center justify-center md:mr-4 text-sm text-white transition-all duration-200 ease-soft-in-out h-9 w-9 rounded-xl" />
            </div>
            <div className="flex-col justify-center hidden md:block">
                <h6 className="mb-0 text-sm leading-normal">{state?.fullName || "No name"}</h6>
                <p className="mb-0 text-xs leading-tight text-slate-400">{state?.departmentName || props.departmentId}</p>
            </div>
        </div>
    )
}
export default UserAvatar