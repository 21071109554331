//Chart Chỉ tiêu và Thực tế theo từng nhóm
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js';
import { formatNumber } from 'src/commons/helpers/common';
import "chartjs-plugin-datalabels";
import { IBarConfig, IActualTargetBarDataSet } from 'src/interfaces/IChart';
import { t } from 'i18next';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);



interface ActualTargetBarChartProps {
    actualConfig: IBarConfig;
    targetConfig: IBarConfig;
    data: IActualTargetBarDataSet
    title: string;
    width?: number;
    barHeight?: number;
    isAutoShowData?: boolean
}

const CustomActualTargetBarChart: React.FC<ActualTargetBarChartProps> = ({
    actualConfig, targetConfig,
    data,
    title,
    width,
    barHeight,
    isAutoShowData = true
}) => {

    const chartData: any = {
        labels: Object.entries(data).map(([key, value]) => {
            return key;
        }),
        datasets: [
            {
                label: t(actualConfig.label),
                data: Object.entries(data).map(([key, value]) => {
                    return value.actual;
                }),
                backgroundColor: actualConfig.backgroundColor,
                borderColor: actualConfig.backgroundColor,
                borderWidth: actualConfig.borderWidth,
                type: actualConfig.type,
                showLine: actualConfig.showLine,
                pointRadius: actualConfig.pointRadius,
                order: actualConfig.order,
            },
            {
                label: t(targetConfig.label),
                data: Object.entries(data).map(([key, value]) => {
                    return value.tatget;
                }),
                backgroundColor: targetConfig.backgroundColor,
                borderColor: targetConfig.backgroundColor,
                borderWidth: targetConfig.borderWidth,
                type: targetConfig.type,
                showLine: targetConfig.showLine,
                pointRadius: targetConfig.pointRadius,
                order: targetConfig.order,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: title,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem: any) => {
                        const value = tooltipItem.raw as number;
                        return `${tooltipItem.dataset.label}: ${`${formatNumber(value || 0)}`}`;
                    },
                },
            },
            datalabels: {
                formatter: (value: number, context: any) => `${formatNumber(value || 0)}`,
                color: 'black',
                display: isAutoShowData
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: (value: any) => {
                        return `${formatNumber(value || 0)}`;
                    },
                },
            },
        },

    };

    return (
        <div style={{ width: `${width}%` }}>
            <Bar data={chartData} options={options} height={barHeight} />
            {/* <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', fontWeight: 'bold', backgroundColor: '#f2f2f2', padding: '10px' }}>
                    <div style={{ flex: 1 }}>Nhóm Khách</div>
                    <div style={{ flex: 1, textAlign: 'right' }}>Doanh Số Thực Hiện</div>
                    <div style={{ flex: 1, textAlign: 'right' }}>CT Tháng</div>
                </div>
                {data.labels.map((label: string, index: number) => (
                    <div key={index} style={{ display: 'flex', borderBottom: '1px solid #ddd', padding: '10px' }}>
                        <div style={{ flex: 1 }}>{label}</div>
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            {data.datasets[0].data[index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            {data.datasets[1].data[index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                    </div>
                ))}
            </div> */}
        </div>
    );
};

export default CustomActualTargetBarChart;
