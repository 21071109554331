import { Close } from "@mui/icons-material";
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom";
import Button from "src/components/buttons/Button"
import InputText from "src/components/inputs/InputText";
import UploadFile, { IFileUploadModel } from "../upload-file/UploadFile";
import UserPermission from "./UserPermission";
import { assignDocument, deleteDocument, processDocument, promulgateDocument, retractDocument, returnDocument, shareDocument } from "src/apis/apiDocument";
import { toast } from "react-toastify";
import { IDocumentActionModel } from "src/interfaces/IDocumentActionModel";
import { ButtonColor } from "src/commons/enums/buttonColor";
import { IFileResponse } from "src/interfaces/IFile";
import { IDocumentFile } from "src/interfaces/IDocumentFile";
import { IDocument } from "src/interfaces/IDocument";
import { IActiveId } from "src/commons/enums/activeId";
import Swal from "sweetalert2";
import { DocumentStatus } from "src/commons/enums/documentStatus";
import OrgSelect from "src/components/org/OrgSelect";
import { IOrgSelectedItem } from "src/interfaces/IOrg";
import { IDocumentAssign } from "src/interfaces/IDocumentAssign";
import { DocumentAssignType } from "src/commons/enums/documentAssignType";

interface IDocumentAction {
    actions: number[],
    id: string,
    onReload: (data: any) => void,
    documentInfo?: IDocument,
}
const DocumentAction = (props: IDocumentAction) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [state, setState] = useState({
        openCancel: false,
        isCancel: false,
        note: '',
        openProcess: false,
        isProcess: false,
        openShare: false,
        reload: 0
    })

    const fileRef = useRef<any>({})
    const userDeptRef = useRef<any>()
    const [defaultValue, setDefaultValue] = useState<IOrgSelectedItem[]>([]);

    useEffect(() => {
        if (state.openProcess && state.isProcess && props?.documentInfo && props.documentInfo?.documentFiles && state.reload) {
            const fileMap = loadInitFiles(props.documentInfo.documentFiles);
            // setInitFiles(fileMap);
            setTimeout(() => {
                fileRef.current && fileRef.current.setData(fileMap);
            }, 1000);
        }
    }, [state.reload])


    const loadInitFiles = (docFiles: IDocumentFile[]) => {
        const fileMap: IFileUploadModel[] = docFiles.filter((f: IDocumentFile) => f.active === IActiveId.Active).map((f: IDocumentFile) => {

            const item: IFileUploadModel = {
                name: f.name,
                size: f.size,
                type: f.type,
                description: f.description,
                physicalAddress: f.physicalAddressId?.toString() || null,
                metaData: f?.id || '0',
                isReadonly: true
            }
            return item
        });

        return fileMap;
    }

    const handleOnSaveProcess = async () => {
        if (!state.note || state.note === '') {
            toast.warning(t('message.required'))
            return
        }
        const model: IDocumentActionModel = {
            documentId: props.id,
            note: state.note,
        }
        if (!state.isProcess) {
            const assigns = userDeptRef?.current.getData() || []
            model.documentAssigns = assigns
            assignDocument(model).then(rs => {
                if (rs.success) {
                    toast.success(t('message.actionSuccess'))
                    setState({ ...state, openProcess: false })
                    props.onReload && props.onReload({ id: props.id, status: rs.data.status })
                }
                else toast.warning(t('message.actionError'))
            })
        }
        else {
            const defaultFolder: string | undefined = props?.documentInfo?.serial;

            if (!defaultFolder) {
                toast.error(t('serialNotNull'));
                return;
            }
            const files = await fileRef?.current.getData('document', defaultFolder.replace('/', '-')) || []
            const fileMap = files.map((f: IFileResponse) => {
                const rs: IDocumentFile = {
                    id: f.metaData,
                    url: f.url,
                    name: f.fileName,
                    size: f.size,
                    type: f.contentType,
                    description: f.description,
                    physicalAddress: f.physicalAddress,
                    physicalAddressId: f.physicalAddress?.includes('T') ? null : f.physicalAddress,
                    version: 1,
                    documentId: undefined,
                }
                return rs
            })
            model.documentFiles = fileMap
            processDocument(model).then(rs => {
                if (rs.success) {
                    toast.success(t('message.actionSuccess'))
                    setState({ ...state, openProcess: false })
                    props.onReload && props.onReload({ id: props.id, status: rs.data.status })
                }
                else {
                    toast.warning(t(rs?.message || 'message.actionError'))
                }
            })
        }
    }

    const handleCloseProcess = () => {
        setState({ ...state, openProcess: false })
    }

    const handleCancel = () => {
        Swal.fire({
            input: "textarea",
            inputLabel: t('documentAction6'),
            inputPlaceholder: t('pleaseEnter'),
            showCancelButton: true,
            cancelButtonText: t('cancel'),
            confirmButtonText: t('documentAction6'),
        }).then((rs) => {
            if (rs.isConfirmed) {
                const model: IDocumentActionModel = {
                    documentId: props.id,
                    note: rs.value
                }
                returnDocument(model).then(rs => {
                    if (rs.success) {
                        toast.success(t('message.actionSuccess'))
                        setState({ ...state, openCancel: false })
                        props.onReload && props.onReload({ id: props.id, status: DocumentStatus.Cancel })
                    }
                    else toast.warning(t('message.actionError'))
                })
            }
        });
    }
    const handleRetract = () => {
        Swal.fire({
            input: "textarea",
            inputLabel: t('documentAction7'),
            inputPlaceholder: t('pleaseEnter'),
            showCancelButton: true,
            cancelButtonText: t('cancel'),
            confirmButtonText: t('documentAction7'),
        }).then((rs) => {
            if (rs.isConfirmed) {
                const model: IDocumentActionModel = {
                    documentId: props.id,
                    note: rs.value
                }
                retractDocument(model).then(rs => {
                    if (rs.success) {
                        toast.success(t('message.actionSuccess'))
                        setState({ ...state, openCancel: false })
                        props.onReload && props.onReload({ id: props.id, status: DocumentStatus.Cancel })
                    }
                    else toast.warning(t('message.actionError'))
                })
            }
        });
    }
    const handlePromulgate = () => {
        Swal.fire({
            title: t('message.areYouSureYouWantToIssueIt'),
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: t('documentAction8'),
            cancelButtonText: t('cancel'),
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                const model: IDocumentActionModel = {
                    documentId: props.id,
                }
                promulgateDocument(model).then((rs: any) => {
                    if (rs.success) {
                        toast.success(t('message.actionSuccess'))
                        props.onReload && props.onReload({ id: props.id, status: DocumentStatus.Promulgate })
                    }
                    else {
                        toast.warning(t(rs?.message || 'message.actionError'))
                    }
                })
            }
        })
    }
    const handleDeleted = () => {
        Swal.fire({
            title: t('message.areYouSureYouWantToDelete'),
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: t('documentAction12'),
            cancelButtonText: t('cancel'),
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                deleteDocument(props.id).then((rs: any) => {
                    if (rs.success) {
                        toast.success(t('message.actionSuccess'))
                        props.onReload && props.onReload({id:props.id, active : 0})
                    }
                    else {
                        toast.warning(t(rs?.message || 'message.actionError'))
                    }
                })
            }
        })
    }
    const handleOnchangeUser = (e: any) => {
        setDefaultValue(e)
    }

    const handleOnSaveShare = () => {
        const mapDocAssgns: IDocumentAssign[] = defaultValue?.map(x => {
            return {
                documentId: props.id,
                type: DocumentAssignType.Read,
                departmentId: x.type === 'department' && x.department ? x.department.code : undefined,
                userId: x.type === 'employee' && x.employee ? x.employee.employeeId : undefined,
            };
        });

        const model: IDocumentActionModel = {
            documentId: props.id,
            documentAssigns: mapDocAssgns || []
        };

        shareDocument(model).then((rs: any) => {
            if (rs.success) {
                toast.success(t('message.actionSuccess'))
                props.onReload && props.onReload({ id: props.id })
            }
            else {
                toast.warning(t(rs?.message || 'message.actionError'))
            }
        })
    };


    const handleCloseShare = () => {
        setDefaultValue([])
        setState({ ...state, openShare: false })
    }
    const handleClickAction = (action: number) => {
        switch (action) {
            case 1: // chỉnh sửa
                navigate('/document/edit/' + props.id)
                break
            case 3:
            case 2:// báo cáo, cập nhật file
                setState({ ...state, openProcess: true, isProcess: true, reload: state.reload + 1 })
                break
            case 4:// giao
                setState({ ...state, openProcess: true, isProcess: false })
                break
            case 6:// trả lại
                handleCancel()
                break
            case 7: // thu hồi
                handleRetract()
                break
            case 8:
                handlePromulgate()
                break
            case 9:// chia sẻ
                setState({ ...state, openShare: true, reload: state.reload + 1 })
                break
            case 12:
                handleDeleted()
                break
        }
    }

    const getColorAction = (action: number): ButtonColor => {
        switch (action) {
            case 2:
            case 3:
                return "primary"
            case 3:
                return "success"
            case 6:
                return "warning"
            case 7:
            case 12:
                return "error"
            case 8:
                return "info"
            default:
                return "blue"
        }
    }

    return (
        <div className="flex flex-wrap">
            {props.actions.filter(x => x !== 5).map((a: number) =>
                <Button onClick={() => handleClickAction(a)} key={a} label={t(`documentAction${a}`)} color={getColorAction(a)} />
            )}

            {/* Giao việc */}
            <Dialog
                disableEscapeKeyDown
                open={state.openProcess}
                className="border-0 rounded-lg"
                fullWidth
                maxWidth='md'
            >
                <AppBar sx={{ position: 'relative' }} className="bg-gradient-to-tl from-purple-700 to-pink-500">
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {state.isProcess ? t('dialogProcessTitle') : t('dialogAssignTitle')}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseProcess}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent >
                    <div className="flex flex-wrap">
                        <div className="px-3 w-full flex flex-wrap">
                            <div className="w-full max-w-full">
                                <InputText
                                    rows={3}
                                    label={t('note')}
                                    name="note"
                                    required
                                    onChange={(e) => setState({ ...state, note: e.target.value })}
                                />
                            </div>
                            <div className={`w-full max-w-full mt-3 ${!state.isProcess && 'hidden'}`}>
                                <UploadFile ref={fileRef} isCheckDuplicateFileName />
                            </div>
                            <div className={`w-full max-w-full mt-3 ${state.isProcess && 'hidden'}`}>
                                <UserPermission ref={userDeptRef} />
                            </div>
                        </div>
                        <div className="mt-2 w-full">
                            <div className="float-right">
                                <Button label={t('save')} onClick={handleOnSaveProcess} color="primary" />
                                <Button label={t('close')} onClick={handleCloseProcess} color="default" />
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            {/* Chia sẻ */}
            <Dialog
                disableEscapeKeyDown
                open={state.openShare}
                className="border-0 rounded-lg"
                fullWidth
                maxWidth='md'
            >
                <AppBar sx={{ position: 'relative' }} className="bg-gradient-to-tl from-purple-700 to-pink-500">
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {t('documentAction9')}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseShare}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent >
                    <div className="flex flex-wrap">
                        <div className="px-3 w-full flex flex-wrap">
                            <div className="w-full max-w-full">
                                <OrgSelect
                                    name="employeeIds"
                                    showEmployees
                                    multiple
                                    defaultValue={defaultValue}
                                    exclude={props.documentInfo?.documentAssigns?.map(a=>(a.userId || a.departmentId)+'') || []}
                                    onChange={handleOnchangeUser} />
                            </div>
                        </div>
                        <div className="mt-2 w-full">
                            <div className="float-right">
                                <Button label={t('save')} onClick={handleOnSaveShare} color="primary" />
                                <Button label={t('close')} onClick={handleCloseShare} color="default" />
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default DocumentAction