import { t } from "i18next"
import { DocumentAction } from "src/commons/enums/documentAction"
import { getKeyByValueEnum } from "src/commons/helpers/common"
import { getDateTimeString } from "src/commons/helpers/formatDate"
import { IDocumentHistory } from "src/interfaces/IDocumentHistory"

interface IProps {
  histories?: IDocumentHistory[]
}



const Hitory = (props: IProps) => {

  const getDocumentActionIcon = (da: DocumentAction) => {
    switch (da) {
      case DocumentAction.CreatedDocument:
        return <i className="far fa-file-alt" style={{ color: 'green' }}></i>;
      case DocumentAction.UpdateDocument:
        return <i className="fas fa-edit" style={{ color: 'blue' }}></i>;
      case DocumentAction.UploadFile:
        return <i className="fas fa-upload" style={{ color: 'orange' }}></i>;
      case DocumentAction.UpdateFile:
        return <i className="fas fa-file-upload" style={{ color: 'purple' }}></i>;
      case DocumentAction.Assign:
        return <i className="fas fa-share" style={{ color: 'red' }}></i>;
      case DocumentAction.Download:
        return <i className="fas fa-download" style={{ color: 'black' }}></i>;
      case DocumentAction.Return:
        return <i className="fas fa-undo" style={{ color: 'gray' }}></i>;
      case DocumentAction.Retract:
        return <i className="fas fa-times" style={{ color: 'brown' }}></i>;
        case DocumentAction.Promulgate:
          return <i className="fa fa-globe" style={{ color: 'purple' }}></i>
      case DocumentAction.Share:
        return <i className="fa fa-share-alt" style={{ color: 'blue' }}></i>;
      case DocumentAction.PrivateAccess:
        return <i className="fa fa-eye" style={{ color: 'black' }}></i>;
      default:
        return <></>;
    }
  }




  return props && props.histories ? <div className="before:border-r-solid relative before:absolute before:top-0 before:left-4 before:h-full before:border-r-2 before:border-r-slate-100 before:content-[''] before:lg:-ml-px">
    {props.histories.map(h => <div className="relative mb-4 mt-0 after:clear-both after:table after:content-['']" >
      <span className="w-6.5 h-6.5 text-base absolute left-4 z-10 inline-flex -translate-x-1/2 items-center justify-center rounded-full bg-white text-center font-semibold">
        {/* <i className="relative z-10 text-transparent ni leading-none ni-bell-55 leading-pro bg-gradient-to-tl from-green-600 to-lime-400 bg-clip-text fill-transparent"></i> */}
        {
          getDocumentActionIcon(h.action)
        }
      </span>
      <div className="ml-11.252 pt-1.4 lg:max-w-120 relative -top-1.5 w-auto">
        <h6 className="mb-0 font-semibold leading-normal text-sm text-slate-700">{t('documentAction' + h.action)}</h6>
        <p className="mt-1 mb-0 font-semibold leading-tight text-xs text-slate-400">{getDateTimeString(new Date(h.createdDate))}</p>
        <p className="mt-1 mb-0 font-semibold leading-tight text-xs text-slate-400">{`${h?.createFullName} ${!h?.createJobtitle ? '' : ('(' + h.createJobtitle + ')')}`}</p>
        <p className="mt-1 mb-0 font-semibold leading-tight text-xs text-slate-350">{h?.note}</p>
      </div>
    </div >)
    }
  </div > : <></>

}
export default Hitory