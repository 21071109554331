import { Outlet, useNavigate } from "react-router-dom";
import { selectAuthState } from "src/store/slices/authSlice";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import SideNav from "src/components/themes/SideNav/SideNav";
import TopNav from "src/components/themes/TopNav/TopNav";
import Foolter from "src/components/themes/Foolter/Foolter";
import { setAuthToken } from "src/commons/helpers/set-auth-token";
import { useUserProfile } from "src/store/hook";

const MobileLayout = () => {
  const auth = useAuth();
  const authState = useSelector(selectAuthState)
  const navigate = useNavigate()
  useUserProfile(true);

  if (process.env.REACT_APP_LOGIN_API === 'true') {
    if (!authState.isLogin) {
      navigate('/login')
    }
  }
  else if (!auth || !auth.isLoading && !auth.isAuthenticated) {// && !authState.isLogin
    auth.signinRedirect()
  }
  else if (auth.isAuthenticated) {
    const token = auth?.user?.access_token || ''
    setAuthToken(token)
  }
  return (
    <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen overflow-hidden rounded-xl transition-all duration-200">
      <SideNav />
      <TopNav />
      <div className="w-full md:px-6 py-2 mx-auto">
        <Outlet />
      </div>
      <Foolter />
    </div>
  );
};
export default MobileLayout;
