import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from './auth-config';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './components/error-boundary/ErrorBoundary';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <AuthProvider {...oidcConfig}>
      <Provider store={store} >
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </AuthProvider>
  </ErrorBoundary>
  // </React.StrictMode>
);
// reportWebVitals(console.log);
serviceWorker.unregister();

