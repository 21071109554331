import { AppBar, Dialog, DialogActions, DialogContent, FormControl, IconButton, MenuItem, Select, Toolbar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, selectAuthState } from "src/store/slices/authSlice";
import { AppDispatch, useAppDispatch } from "src/store/store";
import moment from "moment";
import { useUserAppNoti, useUserProfile } from "src/store/hook";
import { IUserProfile } from "src/interfaces/IUserProfile";
import { fetchUserProfile, selectUserProfileState } from "src/store/slices/userProfileSlice";
import { IEmployee } from "src/interfaces/IOrg";
import { selectEmployeeState } from "src/store/slices/commonSlice";
import { Close } from "@mui/icons-material";
import { Suspense, useEffect, useState } from "react";
import Button from "src/components/buttons/Button";
import { updateUserProfile } from "src/apis/apiCommon";
import { toast } from "react-toastify";
import UploadFile, { IFileUploadModel } from "src/views/upload-file/UploadFile";
import { IFileResponse } from "src/interfaces/IFile";
import { EFileCategoryId } from "src/commons/enums/fileCategory";
import { convertToFileFormDataWithModifiyFileName } from "src/commons/helpers/file";
import { HidenLoading, ShowLoading } from "src/components/loading-page/LoadingPage";
import { upload } from "src/apis/apiFile";
import { IUserAppNoti } from "src/interfaces/IUserAppNoti";
import { selectUserAppNotiState, setUserAppNoti } from "src/store/slices/userAppNotiSlice";
import Swal from "sweetalert2";
import { updateUserAppNoti } from "src/apis/apiNoti";

let isLogout = true
const fileURL = `${process.env.REACT_APP_FILE_URL}/profile`

const otherLocales = [
    { name: "Việt Nam", value: 'vi' },
    { name: "English", value: 'en' },
    { name: "Deutsch", value: 'de' },
    { name: "中国人", value: 'zh' }
]

const ProfilePage = () => {
    useUserProfile(true);
    const userProfile = useSelector<IUserProfile, IUserProfile>(selectUserProfileState);
    const employeesState = useSelector<IEmployee[], IEmployee[]>(selectEmployeeState)

    const { t, i18n } = useTranslation()
    const auth = useAuth()
    const authState = useSelector(selectAuthState);

    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate()

    const handleLogOut = () => {
        dispatch(logout())
        if (process.env.REACT_APP_LOGIN_API === 'true') {
            navigate('/login')
            return
        }
        auth.signoutRedirect()
            .then(rs => {
                auth.removeUser()
                handleLogin()
                isLogout = true
            })
    }
    const handleLogin = () => {
        if (process.env.REACT_APP_LOGIN_API === 'true') {
            navigate('/login')
            return
        }
        auth.signinRedirect()
        isLogout = false
    }

    const handleOnChangeLang = (value: string) => {
        i18n.changeLanguage(value as string);
        localStorage.setItem('language', value as string);
        updateUserProfile({ language: value });
        switch (value) {
            case 'vi':
                moment.locale('vi');
                break;
            case 'en':
                moment.locale('en-gb');
                break;
            case 'zh':
                moment.locale('zh-cn');
                break;
            case 'de':
                moment.locale('de-DE');
                break;
            default:
                break;
        }
    }

    //----------------EditImageDialog-----------------------
    const [openEditImageDialog, setOpenEditImageDialog] = useState<{ open: boolean, type: 'avatar' | 'background' }>({ open: false, type: 'avatar' });
    const [currImage, setCurrImage] = useState<IFileUploadModel | null>();

    const handleOpenEditImageDialog = (type: 'avatar' | 'background') => {
        setOpenEditImageDialog({ type: type, open: true });
    }

    const handleCloseEditImageDialog = () => {
        setOpenEditImageDialog({ ...openEditImageDialog, open: false });
    }

    const handleChangeEditImageDialog = (files: IFileUploadModel[]) => {
        if (files.length > 0) {
            setCurrImage(files[0])
        }
    };

    const handleSaveEditImageDialog = async () => {
        if (!currImage) {
            return;
        }

        handleUpload([currImage], `${openEditImageDialog.type}.png`, authState.id)
        setOpenEditImageDialog({ ...openEditImageDialog, open: false });

    }

    const getDefaultCategoryByFileType = (fileType?: string) => {
        if (fileType?.includes('image')) {
            return EFileCategoryId.Thumbnail
        }

        if (fileType?.includes('video')) {
            return EFileCategoryId.VideoReview
        }

        return EFileCategoryId.Document
    }

    const handleUpload = async (files: IFileUploadModel[], fileName: string, folder: string) => {
        const fileFilters = files.filter(x => !x.isFailed).map(x => {
            return { ...x, category: getDefaultCategoryByFileType(x.type) }
        });

        if (fileFilters && fileFilters?.length > 0) {
            //Form data Convert
            const formData = convertToFileFormDataWithModifiyFileName({
                files: fileFilters.map(x => x.file as File),
                fileNames: fileFilters.map(x => {
                    return fileName;
                }),
                bucketName: 'profile',
                folders: fileFilters.reduce((accumulator, currentValue, index) => {
                    accumulator[`${index}`] = `${folder}` as string;

                    return accumulator;
                }, {} as { [key: string]: string }),
                categories: fileFilters.reduce((accumulator, currentValue, index) => {
                    accumulator[`${index}`] = currentValue.category;

                    return accumulator;
                }, {} as { [key: string]: number }),
            });


            //Start upload
            ShowLoading();
            formData && upload(formData, true).then(res => {
                if (!res || !res.success) {
                    toast.error(t('message.ImportedFailed'))
                    return;
                }

                const data: IFileResponse[] = res.data;

                if (data.length > 0) {

                    const imageURL = `${data[0].absoluteURL}?v=${data[0].version}`;
                    const dataURL: IUserProfile = fileName.includes('avatar')
                        ? {
                            avatar: imageURL
                        }
                        : {
                            background: imageURL
                        };

                    updateUserProfile(dataURL).then(res2 => {
                        if (!res2.success) {
                            toast.warning(t('message.actionError'));
                            return;
                        }

                        toast.success(t('message.actionSuccess'))
                        dispatch(fetchUserProfile())
                    })
                }
            }).catch(error => {
                toast.error(t('message.errorExcution'));
                console.error(error);
            }).finally(() => {
                HidenLoading();
                handleCloseEditImageDialog();
            });
        }
    }

    //----------------EditProfileDialog-----------------------
    const [openEditProfileDialog, setOpenEditProfileDialog] = useState(false);
    const [currUserProfile, setCurrUserProfile] = useState<IUserProfile>({
        name: userProfile?.name || '',
        phone: userProfile?.phone || '',
        email: userProfile?.email || '',
    });

    useEffect(() => {
        setCurrUserProfile({
            name: userProfile?.name || '',
            phone: userProfile?.phone || '',
            email: userProfile?.email || '',
        })
    }, [userProfile])


    const handleOpenEditProfileDialog = () => {
        setOpenEditProfileDialog(true);
    }

    const handleCloseEditProfileDialog = () => {
        setOpenEditProfileDialog(false);
        setCurrUserProfile({
            name: userProfile?.name || '',
            phone: userProfile?.phone || '',
            email: userProfile?.email || '',
        })
    }

    const handleSaveEditProfileDialog = () => {
        setOpenEditProfileDialog(false);
        updateUserProfile({ ...currUserProfile }).then(res => {
            if (!res.success) {
                toast.warning(`${t('message.actionError')}: ${res?.message}`);
                return;
            }

            toast.success(t('message.actionSuccess'))
            dispatch(fetchUserProfile())
        })
    }

    const handleChangeEditProfileDialog = (e: any) => {
        const { name, value } = e.target;
        setCurrUserProfile((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };




    const handleUpdateYubikey = () => {
        Swal.fire({
            title: `${t('edit')} Yubikey`,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off',
                id: "inputSwal",
                required: "true"
            },
            showCancelButton: true,
            confirmButtonText: `${t('confirm')}`,
            showLoaderOnConfirm: true,
            cancelButtonText: `${t('cancel')}`,
            preConfirm: (otp) => {
                return updateUserProfile({ yubikey: otp }).then(res => {
                    if (!res.success) {
                        toast.warning(res.message || `${t('message.actionError')}`)
                    }

                    toast.success(t('message.actionSuccess'))
                }).catch(error => {
                    Swal.showValidationMessage(
                        `${t('message.errorExcution')}: ${error}`
                    )
                    const inputSwal = document.getElementById('inputSwal') as HTMLInputElement;
                    inputSwal.value = "";
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(fetchUserProfile())
            }
        })
    }

    //----------------UserAppNoti-----------------------
    const [notis, setNotis] = useState<IUserAppNoti>()
    useUserAppNoti(true);
    const userAppNoti = useSelector<IUserAppNoti, IUserAppNoti>(selectUserAppNotiState);

    useEffect(() => {
        setNotis(userAppNoti)
    }, [userAppNoti])

    useEffect(() => {
        if (notis) {
            dispatch(setUserAppNoti(notis))
        }
    }, [notis])

    const handleChangeNoti = (e: any) => {
        const { name, checked } = e.target;
        const updatedData = {
            ...notis,
            [name]: checked
        }
        setNotis(updatedData);
        updateUserAppNoti(updatedData)
    };

    return (
        <div className="w-full px-6 mx-auto overflow-auto h-[87svh]">
            <div
                className="relative group flex items-center p-0 mt-6 overflow-hidden bg-center bg-cover min-h-75 rounded-2xl bg-gray-400"
                style={{
                    backgroundImage: `url('${userProfile?.background || `/assets/img/curved-images/curved0.jpg`}')`,
                    backgroundPositionY: '50%',
                }}
            >
                <div className="p-5 absolute z-30 top-2">
                    <button
                        type="button"
                        onClick={() => navigate('/')}
                        className="text-white bg-gradient-to-tl from-purple-700 to-pink-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2 ease-soft-in hover:scale-102 hover:active:scale-102 active:opacity-85 text-fuchsia-500 hover:shadow-none active:scale-100"
                    >
                        <i className="fa fa-home mr-2"></i>
                        {t('home')}
                    </button>
                </div>
                <div className="p-5 float-right absolute z-30 top-3 right-0">
                    <button
                        type="button"
                        onClick={handleLogOut}
                        className="float-right text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2 ease-soft-in hover:scale-102 hover:active:scale-102 active:opacity-85 text-fuchsia-500 hover:shadow-none active:scale-100"
                    >
                        <i className="fa fa-sign-out mr-2"></i>
                        {t('logout')}
                    </button>
                </div>

                <div
                    className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-800 bg-opacity-50"
                >
                    <a
                        onClick={() => handleOpenEditImageDialog('background')}
                        className="text-white text-2xl transition-transform duration-300 ease-in-out transform hover:scale-110"
                    >
                        <i className="fas fa-camera" />
                    </a>
                </div>
            </div>

            <div className="relative flex flex-col flex-auto min-w-0 p-4 mx-6 -mt-16 overflow-hidden break-words border-0 shadow-blur rounded-2xl bg-white/80 bg-clip-border backdrop-blur-2xl backdrop-saturate-200">
                <div className="flex flex-wrap -mx-3">
                    <div className="group/item flex-none w-auto max-w-full px-3">
                        <div className="relative text-base ease-soft-in-out h-18.5 w-18.5 inline-flex items-center justify-center rounded-xl transition-all duration-200">
                            <img
                                src={`${userProfile?.avatar || '/assets/img/team-2.jpg'}`}
                                alt="avatar"
                                className="object-cover w-full h-full shadow-soft-sm rounded-xl bg-gray-400"
                            />
                            <div
                                className="absolute inset-0 flex items-center justify-center opacity-0 group-hover/item:opacity-100 transition-opacity duration-300 bg-gray-800 bg-opacity-60 rounded-xl"
                            >
                                <a
                                    onClick={() => handleOpenEditImageDialog('avatar')}
                                    className="text-white text-xl transition-transform duration-300 ease-in-out transform hover:scale-110"
                                >
                                    <i className="fas fa-camera" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <Suspense>
                        <Dialog
                            open={openEditImageDialog.open}
                            onClose={handleCloseEditImageDialog}
                            maxWidth={'sm'}
                            fullWidth
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1, textTransform: 'capitalize' }} variant="h6" component="div">
                                        {`${t('edit')} ${t(openEditImageDialog.type)} ${authState?.fullName}`}
                                    </Typography>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseEditImageDialog}
                                        aria-label="close"
                                    >
                                        <Close />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent className='relative'>
                                <UploadFile isSingleUpload isHideInputs onChange={handleChangeEditImageDialog} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleSaveEditImageDialog()} label={t('save')} classButton='absolute right-0 bottom-0 px-6' color='primary'></Button>
                            </DialogActions>
                        </Dialog >
                    </Suspense>

                    <div className="flex-none w-auto max-w-full px-3 my-auto">
                        <div className="h-full">
                            <h5 className="mb-1">{`${authState.fullName} ${userProfile?.name ? `(${userProfile.name})` : ''}`}</h5>
                            <p className="mb-0 font-semibold leading-normal text-sm">{userProfile?.email}</p>
                        </div>
                    </div>
                    <div className="w-full max-w-full px-3 mx-auto mt-4 sm:my-auto sm:mr-0 md:w-1/2 md:flex-none lg:w-4/12">
                        <FormControl variant="standard" className="float-end">
                            <Select
                                label="Language"
                                className="text-center"
                                value={i18n.language}
                            >
                                {otherLocales.map(l => (
                                    <MenuItem key={l.value} value={l.value} onClick={() => handleOnChangeLang(l.value)} >{l.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap -mx-3 mt-5">
                <div className="w-full max-w-full px-3 xl:w-4/12">
                    <div className="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                        <div className="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                            <h6 className="mb-0">{t('notiSettings')}</h6>
                        </div>
                        <div className="flex-auto p-4">
                            {/* <h6 className="font-bold leading-tight uppercase text-xs text-slate-500">Account</h6> */}
                            <ul className="flex flex-col pl-0 mb-0 rounded-lg">
                                <li className="relative block px-0 py-2 bg-white border-0 rounded-t-lg text-inherit">
                                    <div className="min-h-6 mb-0.5 block pl-0">
                                        <input checked={notis?.byApp} onChange={handleChangeNoti} id="byApp" name="byApp" className="mt-0.54 rounded-10 duration-250 ease-soft-in-out after:rounded-circle after:shadow-soft-2xl after:duration-250 checked:after:translate-x-5.25 h-5 relative float-left ml-auto w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-slate-800/95 checked:bg-slate-800/95 checked:bg-none checked:bg-right" type="checkbox" />
                                        <label className="w-4/5 mb-0 ml-4 overflow-hidden font-normal cursor-pointer select-none text-sm text-ellipsis whitespace-nowrap text-slate-500">
                                            {t('notiMeViaApp')}
                                        </label>
                                    </div>
                                </li>
                                <li className="relative block px-0 py-2 bg-white border-0 text-inherit">
                                    <div className="min-h-6 mb-0.5 block pl-0">
                                        <input checked={notis?.byEmail} onChange={handleChangeNoti} id="byEmail" name="byEmail" className="mt-0.54 rounded-10 duration-250 ease-soft-in-out after:rounded-circle after:shadow-soft-2xl after:duration-250 checked:after:translate-x-5.25 h-5 relative float-left ml-auto w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-slate-800/95 checked:bg-slate-800/95 checked:bg-none checked:bg-right" type="checkbox" />
                                        <label className="w-4/5 mb-0 ml-4 overflow-hidden font-normal cursor-pointer select-none text-sm text-ellipsis whitespace-nowrap text-slate-500">
                                            {t('notiMeViaEmail')}
                                        </label>
                                    </div>
                                </li>
                                <li className="relative block px-0 py-2 bg-white border-0 rounded-b-lg text-inherit">
                                    <div className="min-h-6 mb-0.5 block pl-0">
                                        <input checked={notis?.byPhone} onChange={handleChangeNoti} id="byPhone" name="byPhone" className="mt-0.54 rounded-10 duration-250 ease-soft-in-out after:rounded-circle after:shadow-soft-2xl after:duration-250 checked:after:translate-x-5.25 h-5 relative float-left ml-auto w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-slate-800/95 checked:bg-slate-800/95 checked:bg-none checked:bg-right" type="checkbox" />
                                        <label className="w-4/5 mb-0 ml-4 overflow-hidden font-normal cursor-pointer select-none text-sm text-ellipsis whitespace-nowrap text-slate-500">
                                            {t('notiMeViaPhone')}
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Edit Profile */}
                <div className="w-full max-w-full px-3 lg-max:mt-6 xl:w-4/12">
                    <div className="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                        <div className="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                            <div className="flex flex-wrap -mx-3">
                                <div className="flex items-center w-full max-w-full px-3 shrink-0 md:w-8/12 md:flex-none">
                                    <h6 className="mb-0">{`${t('profileInformation')}`}</h6>
                                </div>
                                <div className="w-full max-w-full px-3 text-right shrink-0 md:w-4/12 md:flex-none" >
                                    <a onClick={handleOpenEditProfileDialog}>
                                        <i className="leading-normal fas fa-user-edit text-sm text-slate-400 cursor-pointer" ></i>
                                    </a>
                                    <a onClick={handleUpdateYubikey}>
                                        <i className="leading-normal fas fa-key text-sm text-slate-400 ml-1 cursor-pointer" ></i>
                                    </a>
                                    <div data-target="tooltip"
                                        className="px-2 py-1 text-center text-white bg-black rounded-lg text-sm hidden" role="tooltip"
                                        style={{ position: 'absolute', inset: 'auto auto 0px 0px', margin: 0, transform: 'translate(940px, -556px)' }} data-popper-placement="top">
                                        Edit Profile
                                        <div className="invisible absolute h-2 w-2 bg-inherit before:visible before:absolute before:h-2 before:w-2 before:rotate-45 before:bg-inherit before:content-['']"
                                            style={{ position: 'absolute', left: 0, transform: 'translate(0px, 0px)' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-auto p-4">
                            {/* <p className="leading-normal text-sm">Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).</p>
                            <hr className="h-px my-6 bg-transparent bg-gradient-to-r from-transparent via-white to-transparent" /> */}
                            <ul className="flex flex-col pl-0 mb-0 rounded-lg">
                                <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal bg-white border-0 rounded-t-lg text-sm text-inherit">
                                    <strong className="text-slate-700">{t('employeeName')}:</strong> &nbsp; {authState.fullName}
                                </li>
                                <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal bg-white border-0 rounded-t-lg text-sm text-inherit">
                                    <strong className="text-slate-700">{t('department')}:</strong> &nbsp; {authState.departmentName}
                                </li>
                                <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal bg-white border-0 rounded-t-lg text-sm text-inherit">
                                    <strong className="text-slate-700">{t('nickName')}:</strong> &nbsp; {userProfile?.name}
                                </li>
                                <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal bg-white border-0 rounded-t-lg text-sm text-inherit">
                                    <strong className="text-slate-700">{t('phone')}:</strong> &nbsp; {userProfile?.phone}
                                </li>
                                <li className="relative block px-4 py-2 pt-0 pl-0 leading-normal bg-white border-0 rounded-t-lg text-sm text-inherit">
                                    <strong className="text-slate-700">{t('Email')}:</strong> &nbsp; {userProfile?.email}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Suspense>
                    <Dialog
                        open={openEditProfileDialog}
                        onClose={handleCloseEditProfileDialog}
                        maxWidth={'sm'}
                        fullWidth
                    >
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    {`${t('')} ${authState?.fullName}`}
                                </Typography>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseEditProfileDialog}
                                    aria-label="close"
                                >
                                    <Close />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <DialogContent className='relative'>
                            <form onSubmit={handleSaveEditProfileDialog} className="max-w-sm mx-auto">
                                <div className="relative z-0 w-full mb-5 group">
                                    <input
                                        value={currUserProfile.name}
                                        type="text"
                                        name="name"
                                        id="floating_name"
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        placeholder=" "
                                        required
                                        onChange={handleChangeEditProfileDialog}
                                    />
                                    <label
                                        htmlFor="floating_name"
                                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                        {t('nickName')}
                                    </label>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input
                                        value={currUserProfile.phone}
                                        type="text"
                                        name="phone"
                                        id="floating_phone"
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        placeholder=" "
                                        required
                                        onChange={handleChangeEditProfileDialog}
                                    />
                                    <label
                                        htmlFor="floating_phone"
                                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                        {t('phone')}
                                    </label>
                                </div>
                                <div className="relative z-0 w-full mb-5 group">
                                    <input
                                        value={currUserProfile.email}
                                        type="email"
                                        name="email"
                                        id="floating_email"
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        placeholder=" "
                                        required
                                        onChange={handleChangeEditProfileDialog}
                                    />
                                    <label
                                        htmlFor="floating_email"
                                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                        {t('Email')}
                                    </label>
                                </div>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleSaveEditProfileDialog()} label={t('save')} classButton='absolute right-0 bottom-0 px-6' color='primary'></Button>
                        </DialogActions>
                    </Dialog >

                </Suspense>
                {/* Friends */}
                <div className="w-full max-w-full px-3 lg-max:mt-6 xl:w-4/12 ">
                    <div className="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                        <div className="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                            <h6 className="mb-0">{t('friend')}</h6>
                        </div>
                        <div className="flex-auto p-4 overflow-y-auto max-h-[30vh]">
                            {
                                employeesState && employeesState.filter(x => x.departmentName === authState?.departmentName).map(x => (

                                    <ul className="flex flex-col pl-0 mb-0 rounded-lg">
                                        <li className="relative flex items-center px-0 py-2 mb-2 bg-white border-0 rounded-t-lg text-inherit">
                                            <div className="inline-flex items-center justify-center w-12 h-12 mr-4 text-white transition-all duration-200 text-base ease-soft-in-out rounded-xl">
                                                <img src={`${fileURL}/${x?.employeeId}/avatar.png`} onError={(e) => { e.currentTarget.src = "/logo192.png" }} alt="kal" className="w-full shadow-soft-2xl rounded-xl" />
                                            </div>
                                            <div className="flex flex-col items-start justify-center">
                                                <h6 className="mb-0 leading-normal text-sm">{x?.employeeName}</h6>
                                                <p className="mb-0 leading-tight text-xs">{x?.departmentName}</p>
                                            </div>
                                            {/* <a className="inline-block py-3 pl-0 pr-4 mb-0 ml-auto font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg shadow-none cursor-pointer leading-pro text-xs ease-soft-in hover:scale-102 hover:active:scale-102 active:opacity-85 text-fuchsia-500 hover:text-fuchsia-800 hover:shadow-none active:scale-100" href="javascript:;">Reply</a> */}
                                        </li>
                                    </ul>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProfilePage