import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/database";
import "firebase/compat/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyAXVnVgDQHmd9XgAgC_duPgboZ-J2UIZFU",
  authDomain: "mlportal.firebaseapp.com",
  projectId: "mlportal",
  storageBucket: "mlportal.appspot.com",
  messagingSenderId: "614914053053",
  appId: "1:614914053053:web:afc098d17e7954d8fef8d4",
  measurementId: "G-9NX7NXTDYQ"
};

firebase.initializeApp(firebaseConfig);

export default firebase;


