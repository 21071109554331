import axios from "axios";

const baseURL = `${process.env.REACT_APP_AUTHORITY}`;

export const LoginApi = async (username: string, password: string) => {
  // Gửi yêu cầu xác thực đến IdentityServer4
  const response = await axios.post(baseURL + "connect/token", {
    grant_type: "password",
    client_id: process.env.REACT_APP_CLIENT_ID,
    username: username,
    password: password,
  },
  {headers: {'content-type': 'application/x-www-form-urlencoded'}});
  return response.data;
};
