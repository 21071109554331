import { AuthProviderProps } from "react-oidc-context";

export const oidcConfig: AuthProviderProps = {
  client_id: process.env.REACT_APP_CLIENT_ID || "",
  redirect_uri: `${process.env.REACT_APP_URL}/callback` || "",
  response_type: "code",
  scope: process.env.REACT_APP_SCOPE,
  authority: process.env.REACT_APP_AUTHORITY || "",
  silent_redirect_uri: `${process.env.REACT_APP_URL}/silent_renew`,
  post_logout_redirect_uri: process.env.REACT_APP_URL + "/",
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
  client_secret: "MLStaff"
};
