import { EFileCategoryId } from "src/commons/enums/fileCategory";
import { IFileRemiderModel } from "src/views/upload-file/UploadFile";

export interface IUploadFile {
    files: File[];
    bucketName: string | null;
    fileNames?: string[] | [];
    folders: { [key: string]: string } | null;
    notes?: { [key: string]: string } | null;
    categories?: { [key: string]: number } | null;
    showApps?: { [key: string]: string } | null;
    parentIds?: { [key: string]: string } | null;
    sortOrders?: { [key: string]: string } | null;
}


export interface IFileInfo {
    id?: number;
    url: string;
    absoluteURL: string;
    bucketName: string;
    folder: string;
    fileName: string;
    contentType: string;
    size: number;
    isSucceed: boolean;
    createdDate?: Date;
    updatedDate?: Date;
    category?: number;
    showApp?: boolean;
    parentId?: number;
    sortOrder?: number;
    version?: number;
}

export interface IFileResponse extends IFileInfo {
    description?: string;
    physicalAddress?: string;
    metaData?: any;
    isSecurity?:boolean;
    documentReminders?: IFileRemiderModel[]
}

export const initFileResponse: IFileResponse = {
    id: 0,
    url: "",
    absoluteURL: "",
    bucketName: "",
    folder: "",
    fileName: "",
    contentType: "",
    size: 0,
    isSucceed: false,
    description: "",
    physicalAddress: ""
};


export interface ICopyFile {
    newPath: string;
    newBucketName: string;
    fileId: number;
    note?: string;
    newParentId?: number | null;
    newSortOrder?: number | null;
    newShowApp?: boolean | null;
    newCategory?: EFileCategoryId | null;
    isCreateSmallFile?: boolean
}

export interface IFileCountResult {
    folderPath: string;
    fileCountsByType: IFileTypeCount[];
}

export interface IFileTypeCount {
    fileType: string;
    fileCount: number;
}

export interface IUpdateFileInfo {
    id: number;
    sortOrder?: number | null;
    showApp?: boolean | null;
}

export interface IFileVersion {
    id: number;
    versionId: string;
    versionOrder: number;
    fileId: number;
    createdBy: string;
    createdDate: string;
    note: string;
}
