import { Grid, Chip, Box, Tooltip, IconButton, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Edit, Delete, Add } from "@mui/icons-material";
import { toast } from "react-toastify";
import { deleteRole, fetchRole, createRole, updateRole } from "src/apis/permission/apiRole";
import getLangReactTable from "src/commons/helpers/getLangReactTable";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { IApplication } from "src/interfaces/permission/IApplication";
import SaveRole from "src/views/permission/SaveRole";

let indexReload = 1
const RolePage = () => {

    const { t, i18n } = useTranslation();

    const [data, setData] = useState<any[]>([])
    const [state, setState] = useState({
        showEdit: false,
        reLoadEdit: 1,
        keyword: '',
        detail: {}
    })
    useEffect(() => {

    }, [])

    useEffect(() => {
        if (data.length === 0)
            loadData()
    }, [])


    const loadData = () => {
        fetchRole().then((rs: any) => {
            if (rs.success) {
                setData(rs.data)
                setState({ ...state, showEdit: false, reLoadEdit: state.reLoadEdit + 1 })
            }
        })
    }

    //handle
    const handleOnSave = (objData: any) => {
        // kiểm tra id đã tồn tại
        if ((!objData.id  || objData.id ==='') && data.some((rs: any) => rs.name === objData.name)) {
            toast.warning(t('message.nameIsExist'))
            return
        }
        const functionApi = objData.isCreated ? createRole : updateRole

        functionApi(objData).then(rs => {
            if (rs.success) {
                toast.success(t('message.actionSuccess'))
                if (objData.isCreated) {
                    setData([rs.data, ...data])
                }
                else {
                    const dataUpdate: any[] = [...data];
                    const index = data.findIndex((x: IApplication) => x.id === objData.id);
                    dataUpdate[index] = rs.data
                    setData([...dataUpdate]);
                }
            }
            else toast.error(rs)
        })
    }
    const handleOnDelete = (rowData: any) => {
        // show thông báo xác nhận
        if (window.confirm(t('common.actionDelete') + ` "${rowData.original.name}"?`) === true) {
            deleteRole(rowData.original.id || '')
                .then(rs => {
                    if (rs.success) {
                        const dataDelete = [...data];
                        const index = dataDelete.findIndex((obj: any) => obj.id === rowData.original.id);
                        if (index !== -1){
                            setData([...data.slice(0, index), ...data.slice(index + 1),])
                            indexReload = indexReload + 1
                            setState({
                                ...state,
                                showEdit: false,
                                reLoadEdit: indexReload,
                                detail: {}
                            })
                        }
                    }
                })
        }
    }
    const handleOnEdit = (e: any) => {
        const row = e.target.closest('.MuiTableRow-root');
        if (row) {
            const rowIndex = row.dataset.index;
            const cur = data[rowIndex]
            indexReload = indexReload + 1
            setState({
                ...state,
                showEdit: true,
                reLoadEdit: indexReload,
                detail: cur
            })
        }
    }
    const handleOnAdd = () => {
        indexReload = indexReload + 1
        setState({
            ...state,
            showEdit: true,
            reLoadEdit:indexReload,
            detail: {}
        })
    }
    const table = useMaterialReactTable({
        enableEditing: true,
        data: data,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableHiding: false,
        localization: getLangReactTable(i18n.language),
        positionToolbarAlertBanner: 'head-overlay',
        columns: [
            {
                header: t('stt'),
                accessorKey: 'index',
                maxSize: 40,
                Cell: (row) => {
                    return <div>{row.row.index + 1}</div>;
                },
            },
            { header: t('name'), accessorKey: 'name' },
        ],
        muiTableBodyProps: ({ table }: any) => ({
            onClick: handleOnEdit,
            sx: { cursor: 'pointer' }
        }),
        renderRowActions: ({ row, table }: any) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => handleOnDelete(row)}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>),
        renderTopToolbarCustomActions: ({ table }: any) => (
            <Button
                variant="contained"
                onClick={() => handleOnAdd()}
            >
                <Add /> {t('add')}
            </Button>
        )
    })

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} >
                <MaterialReactTable table={table} />
            </Grid>
            <Grid item xs={12} md={8}>
                <SaveRole
                    show={state.showEdit}
                    reload={state.reLoadEdit}
                    data={state.detail}
                    onSave={handleOnSave} />
            </Grid>
        </Grid>
    )
}
export default RolePage