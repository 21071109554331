import { DocumentAssignType } from "src/commons/enums/documentAssignType";
import { DocumentStatus } from "src/commons/enums/documentStatus";

export interface IDocumentFilePhyAddSearchModel {
    status?: DocumentStatus;
    assignType?: DocumentAssignType;
    creationDepartmentId?: string;
    keyword?: string;
    physicalAddressId?: number;
    page?: number;
    size?: number;
}

export const initDocumentFilePhyAddSearchModel: IDocumentFilePhyAddSearchModel = {
    page: 1,
    size: 10
}