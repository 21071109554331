import { Grid, Chip, Box, Tooltip, IconButton, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Edit, Delete, Add } from "@mui/icons-material";
import { toast } from "react-toastify";
import { deleteGroup, fetchGroup, createGroup, updateGroup } from "src/apis/permission/apiGroup";
import getLangReactTable from "src/commons/helpers/getLangReactTable";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { IGroup } from "src/interfaces/permission/IGroup";
import SaveGroup from "src/views/permission/SaveGroup";

let indexReload = 1

interface IState {
    showEdit: boolean,
    reLoadEdit: number,
    keyword?: string,
    idExtend?: string,
    detail: IGroup
}
const Group = () => {
    const { t, i18n } = useTranslation();

    const [data, setData] = useState<any[]>([])
    const [state, setState] = useState<IState>({
        showEdit: false,
        reLoadEdit: 1,
        detail: { name: '' }
    })

    useEffect(() => {
        if (data.length === 0)
            loadData()
    }, [])


    const loadData = () => {
        fetchGroup().then((rs: any) => {
            if (rs.success) {
                setData(rs.data)
                setState({ ...state, showEdit: false, reLoadEdit: state.reLoadEdit + 1 })
            }
        })
    }

    //handle
    const handleOnSave = (objData: any) => {

        const functionApi = !objData.id ? createGroup : updateGroup

        functionApi(objData).then(rs => {
            if (rs.success) {

                if (!objData.id) {
                    setData([rs.data, ...data])
                }
                else {
                    const dataUpdate: any[] = [...data];
                    const index = data.findIndex((x: IGroup) => x.id === objData.id);
                    dataUpdate[index] = rs.data
                    setData([...dataUpdate]);
                }
                indexReload = indexReload + 1
                setState({
                    ...state,
                    reLoadEdit: indexReload,
                    showEdit: false
                })
            }
            else toast.error(rs.message)
        })
    }
    const handleOnDelete = (rowData: any) => {
        // show thông báo xác nhận
        if (window.confirm(t('common.actionDelete') + ` "${rowData.original.name}"?`) === true) {
            deleteGroup(rowData.original.id || '')
                .then(rs => {
                    if (rs.success) {
                        const dataDelete = [...data];
                        const index = dataDelete.findIndex((obj: any) => obj.id === rowData.original.id);
                        if (index !== -1)
                            setData([...data.slice(0, index), ...data.slice(index + 1),])
                    }
                })
        }
    }
    const handleOnEdit = (e: any) => {
        const row = e.target.closest('.MuiTableRow-root');
        if (row) {
            const rowIndex = row.dataset.index;
            const cur = data[rowIndex]
            indexReload = indexReload + 1
            setState({
                ...state,
                showEdit: true,
                reLoadEdit: indexReload,
                detail: cur
            })
        }
    }
    const handleOnAdd = () => {
        indexReload = indexReload + 1
        setState({
            ...state,
            showEdit: true,
            reLoadEdit: indexReload
        })
    }
    const table = useMaterialReactTable({
        enableEditing: true,
        data: data,
        localization: getLangReactTable(i18n.language),
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableHiding: false,
        columns: [
            {
                header: t('stt'),
                accessorKey: 'index',
                maxSize: 40,
                Cell: (row) => {
                    return <div>{row.row.index + 1}</div>;
                },
            },
            { header: t('name'), accessorKey: 'name' },
            {
                header: t('active'), accessorKey: 'active',
                Cell: (props: any) => (props.row.original.active === 1
                    ? (<Chip label={t('active1')} color="success" />)
                    : (<Chip label={t('active0')} color="default" />))
            }
        ],
        muiTableBodyProps: ({ table }: any) => ({
            onClick: handleOnEdit,
            sx: { cursor: 'pointer' }
        }),
        renderRowActions: ({ row, table }: any) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title={t('delete')}>
                    <IconButton onClick={() => handleOnDelete(row)}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>),
        renderTopToolbarCustomActions: ({ table }: any) => (
            <Button
                variant="contained"
                onClick={() => handleOnAdd()}
            >
                <Add /> {t('add')}
            </Button>
        )
    })

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <MaterialReactTable table={table} />
            </Grid>
            <Grid item xs={12} md={8}>
                <SaveGroup
                    show={state.showEdit}
                    reload={state.reLoadEdit}
                    data={state.detail || {}}
                    onSave={handleOnSave} />
            </Grid>
        </Grid>
    )
}
export default Group