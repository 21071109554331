import { createSlice } from "@reduxjs/toolkit";

export interface RootState {
  transparentSidenav: boolean;
  sidenavColor: string;
  transparentNavbar: boolean;
  keyword:string
}
const initialState: RootState = {
  sidenavColor: "",
  transparentSidenav: false,
  transparentNavbar: false,
  keyword:''
};
export const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setTransparentSidenav(state, action) {
      state.transparentSidenav = action.payload;
    },
    setSidenavColor(state, action) {
      state.sidenavColor = action.payload;
    },
    setTransparentNavbar(state, action) {
      state.transparentNavbar = action.payload;
    },
    setKeyword(state, action) {
      state.keyword = action.payload;
    },
  },
});

export const {
  setTransparentNavbar,
  setTransparentSidenav,
  setSidenavColor,
  setKeyword
} = rootSlice.actions;

export const selectRootState = (state: any) => state.root || initialState;

export default rootSlice.reducer;
