import { IRole } from "src/interfaces/permission/IRole";
import api, { ResultObject } from "../base/base";

const baseUrl = `${process.env.REACT_APP_API_PERMISSION}/api/role`;

export const fetchRole = async (): Promise<ResultObject> => {
  const result = await api
    .get(baseUrl)
    .then((response: any) => response?.data);
  return result;
};

export const fetchDetail = async (id:string =''): Promise<ResultObject> => {
  const result = await api
    .get<ResultObject>(baseUrl+'/detail/'+id)
    .then((response: any) => response.data);
  return result;
};

export const createRole = async (
  obj: IRole
): Promise<any> => {
  const result = await api
    .post(`${baseUrl}`, obj)
    .then((response: any) => response?.data);
  return result;
};

export const updateRole = async (
    obj: IRole
): Promise<any> => {
  const result = await api
    .put(`${baseUrl}`, obj)
    .then((response: any) => response?.data);
  return result;
};

export const deleteRole = async (
    id:string
): Promise<any> => {
  const result = await api
    .delete(`${baseUrl}/${id}`)
    .then((response: any) => response?.data);
  return result;
};
