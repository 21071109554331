import api, { ResultObject } from "./base/base";

const baseURL = `${process.env.REACT_APP_API_FILE}/api/private`;

export const GetFolderByUser = async (
  bucketName?: string
): Promise<ResultObject> => {
  const result = await api
    .get<ResultObject>(`${baseURL}/GetFolderByUser?bucketName=${bucketName}`)
    .then((response: any) => response.data);
  return result;
};

export const GetFileByFolder = async (
    folderId: string,
    otp: string = "",
    bucketName?:string
  ): Promise<ResultObject> => {
    const result = await api
      .get<ResultObject>(
        `${baseURL}/GetFileByFolder?folderId=${folderId}&isGetPresignURL=false&otp=${otp}&bucketName=${bucketName}`
      )
      .then((response: any) => response.data);
    return result;
  };
///////////////////// bucket private
export const GetPrivateFileByURL = async (
  url: string
): Promise<ResultObject> => {
  const result = await api
    .get<ResultObject>(baseURL + "/GetPrivateFileByURL?url=" + url)
    .then((response: any) => response.data);
  return result;
};
export const SetLockFolder = async (
    folderId: number,
    setLock: boolean,
    otp: string
  ): Promise<ResultObject> => {
    const result = await api
      .post<ResultObject>(baseURL + `/SetLockFolder`, {
        folderId: folderId,
        setLock: setLock,
        otp: otp,
      })
      .then((response: any) => response.data);
    return result;
  };
///////////////////// end bucket private

export const CreateFolder = async (path: string,bucketName?: string): Promise<ResultObject> => {
  const result = await api
    .post<ResultObject>(`${baseURL}/CreateFolder?path=${path}&bucketName=${bucketName}`)
    .then((response: any) => response.data);
  return result;
};

export const DeleteFolder = async (
  ids: string[],
  otp: string = "",
  bucketName?:string
): Promise<ResultObject> => {
  const result = await api
    .delete<ResultObject>(`${baseURL}/DeleteFolder?otp=${otp}&bucketName=${bucketName}`, {
      data: ids,
    })
    .then((response: any) => response?.data);
  return result;
};

export const Upload = async (
  form: FormData,
  folderId: string,
  bucketName?: string
): Promise<ResultObject> => {
  const result = await api
    .postForm<ResultObject>(
      `${baseURL}/upload/${folderId}?bucketName=${bucketName}`,
      form
    )
    .then((response: any) => response.data);
  return result;
};

export const DeleteFile = async (ids: string[],bucketName?:string): Promise<ResultObject> => {
  const result = await api
    .delete<ResultObject>(`${baseURL}/DeleteFile?bucketName=${bucketName}`, {
      data: ids,
    })
    .then((response: any) => response.data);
  return result;
};
