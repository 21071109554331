import { ICopyFile, IUpdateFileInfo } from 'src/interfaces/IFile';
import api, { ResultObject } from './base/base'
import { EFileCategoryId } from 'src/commons/enums/fileCategory';

const baseURL = `${process.env.REACT_APP_API_FILE}/api`

export const uploadFile = async (data: FormData): Promise<ResultObject> => {
    const result = await api
        .postForm(`${baseURL}/file`, data)
        .then((response: any) => response?.data);
    return result;
};

export const upload = async (form: FormData, isCreateSmallFile: boolean = false): Promise<ResultObject> => {
    form.append('isCreateSmallFile', `${isCreateSmallFile}`);

    const result = await api
        .postForm<ResultObject>(baseURL + '/file', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response: any) => response.data);
    return result;
};

export const copyFile = async (data: ICopyFile): Promise<ResultObject> => {
    const result = await api
        .post<ResultObject>(baseURL + '/file/copy', data)
        .then((response: any) => response.data);
    return result;
};


export const getFolderByCatalogue = async (): Promise<ResultObject> => {
    const result = await api
        .get<ResultObject>(baseURL + '/GetFolderByCatalogue')
        .then((response: any) => response.data);
    return result;
};

export const getPrivateFileByURL = async (bucket: string, url: string, expTime: number = 100): Promise<ResultObject> => {
    const result = await api
        .get<ResultObject>(`${baseURL + '/file?url=' + url + '&bucketName=' + bucket}&expTime=${expTime}`)
        .then((response: any) => response.data);
    return result;
};

export const getFilesByFolder = async (bucket: string, prefixFolderPath: string, prefixFileName: string, isGetPresignURL: boolean = false, searchType: number = 0, categories: EFileCategoryId[] = []): Promise<ResultObject> => {
    const result = await api
        .get<ResultObject>(`${baseURL}/file/list?bucketName=${bucket}&prefixFolderPath=${prefixFolderPath}&prefixFileName=${prefixFileName}&isGetPresignURL=${isGetPresignURL}&searchType=${searchType}&categories=${categories.join(',')}`)
        .then((response: any) => response.data);
    return result;
};

export const countFilesByFolder = async (bucketName: string, fileType: string, prefixFolderPaths: string, prefixFileName: string = '', searchFileNameType: number = 0): Promise<ResultObject> => {
    const result = await api
        .get<ResultObject>(`${baseURL}/file/count?bucketName=${bucketName}&fileType=${fileType}&prefixFolderPaths=${prefixFolderPaths}&prefixFileName=${prefixFileName}&searchFileNameType=${searchFileNameType}`)
        .then((response: any) => response.data);
    return result;
};

export const deleteFiles = async (ids: string[], bucketName: string, categoryFilters: EFileCategoryId[]): Promise<ResultObject> => {
    const result = await api
        .delete<ResultObject>(baseURL + `/file`, {
            data: { ids, bucketName, categoryFilters },
        })
        .then((response: any) => response.data);

    return result;
};

export const updateFileInfo = async (datas: IUpdateFileInfo[]): Promise<ResultObject> => {
    const result = await api
        .put<ResultObject>(baseURL + '/file', datas)
        .then((response: any) => response.data);
    return result;
};

export const getFileVersions = async (fileURL: string): Promise<ResultObject> => {
    const result = await api
        .get<ResultObject>(`${baseURL}/file/versions?fileURL=${fileURL}`)
        .then((response: any) => response.data);
    return result;
};

export const reverseFileVersion = async (fileId: number, versionId: string, note?: string): Promise<ResultObject> => {
    const result = await api
        .post<ResultObject>(`${baseURL}/file/reverse?fileId=${fileId}&minioVersionId=${versionId}&note=${note ? note : `reverse ${fileId} to ${versionId}`}`)
        .then((response: any) => response.data);
    return result;
};
