import { useEffect, useState } from "react"
import { number } from "yup"

interface IProps {
    label?: string,
    name?: string,
    rows?: number
    required?: boolean,
    inputClass?: string,
    inputField?: object,
    description?: string,
    error?: any,
    defaultValue?: string | number,
    value?: string | number,
    readonly?: boolean,
    type?: string,
    placeholder?:string,
    onChange?: (e: any) => void
}
const InputText = (props: IProps) => {
    //const [value, setValue] = useState(props.value)
    // useEffect(() => {
    //     setValue(props.value)
    // }, [props.value])
    return (
        <div className="w-full">
            {props.label &&<label htmlFor={props.name} className="block text-sm leading-5 font-bold text-gray-700">
                {props.label} {props.required === true && (<span className="font-bold text-red-600 font-bold">*</span>)}
            </label>}
        
            <div className="mt-2">
                {props.rows && props.rows > 1 ? (
                    <textarea
                        name={props.name}
                        rows={props.rows}
                        className={`px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 
                    shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                    sm:text-sm sm:leading-6 ${props.inputClass}`}
                        defaultValue={props.defaultValue}
                        readOnly={props.readonly}
                        value={props.value}
                        onChange={(e) => props.onChange && props.onChange(e)}
                        {...props.inputField}
                        placeholder={props.placeholder}
                    />
                ) : (
                    <input
                        type={props.type}
                        name={props.name}
                        autoComplete="address-level2"
                        className={`px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 
                    shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                    focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                    sm:text-sm sm:leading-6 ${props.inputClass}`}
                        defaultValue={props.defaultValue}
                        readOnly={props.readonly}
                        value={props.value}
                        onChange={(e) => props.onChange && props.onChange(e)}
                        {...props.inputField}
                        placeholder={props.placeholder}
                    />
                )}

            </div>
            {props.error && <p className="mt-1 mb-2 text-sm leading-6 text-red-500">{props.error}</p>}
            {props.description && <p className="mt-1 mb-2 text-sm leading-6 text-gray-500">{props.description}</p>}
        </div>
    )
}
InputText.defaultProps = {
    readonly: false,
    required: false,
    type: 'text'
}
export default InputText