import { Directory, Filesystem, ReadFileResult } from "@capacitor/filesystem"
import { useEffect, useState } from "react"
// import { getFile } from "src/store/usePhotoGallery"
import Logo from 'src/assets/images/logo1024.png'
interface IProps {
    src: string
    title: string
    width: number | string
    height: number | string
    objectFit?: any
}

export const getFile = async (url: string): Promise<ReadFileResult> => {
    try {
      const urlSplit = url.split("?")[0];
      const path = urlSplit.replace(/^https?:\/\/[^/]+/, "");
      const rs = await Filesystem.readFile({
        path: path,
        directory: Directory.Documents,
      });
  
      return rs;
    } catch (e) {
      const rs: ReadFileResult = {
        data: url,
      };
      return Promise.resolve(rs);
    }
  };

const ImageCustom = (props: IProps) => {
    const [src, setSrc] = useState<string>('')
    const [rendered, setRendered] = useState(false)

    useEffect(() => {
        const urlSplit = props.src.split("?")[0];
        const path = urlSplit.replace(/^https?:\/\/[^/]+/, '')

        getFile(path).then((rs: ReadFileResult) => {
            if (rs.data && typeof rs.data !== 'string') {
                const blob = URL.createObjectURL(rs.data as Blob)
                setSrc(blob);
            }
            else setSrc(props.src)
        })
    }, [])

    const onError = async () => {
        const avatarDefault = Logo
        setSrc(avatarDefault)
    }
    return (
        <img style={{ objectFit: props.objectFit ? props.objectFit : 'fit-content', backgroundPosition: 'center', borderRadius: '5px' }}
            className={!rendered ? 'mine-opacity-fade-out' : 'mine-opacity-fade-in'}
            src={src} width={props.width} height={props.height}
            onError={onError}
            onLoad={(e) => {
                setRendered(true)
            }}
        />

    )

}
ImageCustom.defaultProps = {
    src: '/logo1024.png',
    title: 'logo',
    width: 200,
    height: 200
}

export default ImageCustom