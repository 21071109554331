import { useEffect, useRef, useState } from "react"
import CreateQuestions from "./CreateQuestion"
import InputQuestions from "./InputQuestions"
import { IHtmlInput } from "src/interfaces/IHtmlInput"

interface IProps {
    data?: IHtmlInput[],
    onChange?: (data: IHtmlInput[]) => void
}
const Questions = (props: IProps) => {
    const viewRef = useRef<any>()
    const [state, setState] = useState<{ data: IHtmlInput[], reload: number }>({ data: props.data || [], reload: 0 })

    useEffect(() => {
        setState({ data: props.data || [], reload: state.reload + 1 })
    }, [props.data])

    const handleOnChange = (inData: IHtmlInput[]) => {
        setState({ data: inData, reload: state.reload + 1 })
        props.onChange && props.onChange(inData)
    }

    return (
        <div className="flex flex-wrap ">
            <div className="md:w-8/12 px-2">
                <CreateQuestions onChange={handleOnChange} data={state.data} />
            </div>
            <div className="md:w-4/12 px-2">
                <InputQuestions ref={viewRef} data={state.data} reload={state.reload} />
            </div>
        </div>
    )
}

export default Questions
