import { FolderOpenOutlined, LockOpenOutlined, Security } from "@mui/icons-material";
import { Collapse, Tooltip } from "@mui/material";
import moment from "moment";
import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { upload } from "src/apis/apiFile";
import { EFileCategoryId } from "src/commons/enums/fileCategory";
import { autoCreateFolder, convertToFileFormData } from "src/commons/helpers/file";
import CustomAutoComplete from "src/components/auto-complete/CustomAutoComplete";
import InputText from "src/components/inputs/InputText";
import { IFileInfo, IFileResponse, initFileResponse } from "src/interfaces/IFile";
import { usePhysicalAddress } from "src/store/hook";
import { IPhysicalAddressState, selectPhysicalAddressState } from "src/store/slices/physicalAddressSlice";

export interface IFileUploadModel {
    file?: File
    name?: string
    size?: number
    type?: string
    description?: string
    physicalAddress?: string
    isReadonly?: boolean
    isSecurity?: boolean
    isFailed?: boolean
    category?: EFileCategoryId
    isCreateSmallFile?: boolean
    metaData?: any,
    documentReminders?: IFileRemiderModel[]
}

export interface IFileRemiderModel {
    note?: string
    dateTime?: Date,
    createdBy?:string
}
interface IProps {
    onChange?: (files: IFileUploadModel[]) => void
    isHideInputs?: boolean
    isCheckDuplicateFileName?: boolean;
    isSingleUpload?: boolean,
    departmentId?: string
}

const UploadFile = forwardRef((props: IProps, ref) => {

    useImperativeHandle(ref, () => ({
        async getData(bucketName: string, defaultFolder?: string) {
            let responses: IFileResponse[] = [];

            const displayFiles = files.filter(x => x.file === undefined);
            if (displayFiles && displayFiles?.length > 0) {
                const res = displayFiles.map(x => {
                    return {
                        ...initFileResponse,
                        fileName: x.name,
                        description: x.description,
                        physicalAddress: x.physicalAddress,
                        metaData: x.metaData,
                        documentReminders:x.documentReminders
                    };
                }) as IFileResponse[];
                responses = responses.concat(res)
            }

            const createFiles = files.filter(x => x.file !== undefined);
            if (createFiles && createFiles?.length > 0) {
                const autoCreatePhysicalAddress = autoCreateFolder();
                const formData = convertToFileFormData({
                    files: createFiles.map(x => x.file as File),
                    bucketName: bucketName,
                    folders: createFiles.reduce((accumulator, currentValue, index) => {
                        accumulator[`${index}`] = defaultFolder || currentValue?.physicalAddress || autoCreatePhysicalAddress;
                        return accumulator;
                    }, {} as { [key: string]: string }),
                    notes: createFiles.reduce((accumulator, currentValue, index) => {
                        accumulator[`${index}`] = currentValue?.description || "";
                        return accumulator;
                    }, {} as { [key: string]: string })
                });

                const res = await upload(formData)
                if (!res || !res.success) {
                    return responses;
                }

                const data: IFileInfo[] = res.data;
                responses = responses.concat(data.map((x, i) => (
                    {
                        ...x,
                        description: createFiles[i].description,
                        isSecurity: createFiles[i].isSecurity,
                        physicalAddress: createFiles[i].physicalAddress || autoCreatePhysicalAddress,
                        metaData: '0',
                        documentReminders: createFiles[i].documentReminders
                    }
                )) as IFileResponse[]);
            }

            return responses;
        },
        setData(data: IFileUploadModel[]) {
            setFiles(data)
        }
    }));

    const { t } = useTranslation()

    const [files, setFiles] = useState<IFileUploadModel[]>([]);
    const [collapseUpload, setCollapseUpload] = useState<boolean>(true);
    usePhysicalAddress(true)
    const physicalAddressState = useSelector<IPhysicalAddressState, IPhysicalAddressState>(selectPhysicalAddressState)

    const selectPhy = useMemo<{ label: string, value: string }[]>(() => {
        const temp = physicalAddressState?.data || []
        const filter = props.departmentId && props.departmentId !== '' ? temp.filter(x => x.departmentId == props.departmentId) : temp
        return filter.map(x => ({ label: `${x.name} (${x.departmentId})`, value: x.id.toString() }))
    }, [props.departmentId])

    useEffect(() => {
        props.onChange && props.onChange(files)
        setCollapseUpload(files.length === 0)
    }, [files.length])


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {

            const newFiles: IFileUploadModel[] = [];
            Array.from(event.target.files).forEach(file => {
                const mapFile: IFileUploadModel = {
                    file: file,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    description: '',
                    isSecurity: false,
                    physicalAddress: ''
                };

                if (props.isCheckDuplicateFileName) {
                    if (files.findIndex(x => x.name === mapFile.name) === -1) {
                        newFiles.push(mapFile);
                    } else {
                        toast.warning(`${t('error.DupliacateFile')}: ${file?.name}`)
                    }
                } else {
                    newFiles.push(mapFile);
                }
            });


            setFiles(prevFiles => [...prevFiles, ...newFiles]);
        }
    };

    const handleRemoveFile = (index: number) => {
        setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    const handleRemoveAllFile = () => {
        setFiles(prevFiles => prevFiles.filter((f, i) => f?.isReadonly === true));
    };

    const handleExpandedCollapse = () => {
        setCollapseUpload(!collapseUpload);
    };

    const handleNoteChange = (index: number, description: string) => {
        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].description = description;
            return updatedFiles;
        });
    };

    const handlePhysicalAddressChange = (index: number, physicalAddress: string) => {
        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].physicalAddress = physicalAddress;
            return updatedFiles;
        });
    };
    const handleOnClickSecurity = (index: number, value: boolean) => {
        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].isSecurity = value;
            return updatedFiles;
        });
    };
    const handleAddRemider = (e: any, index: number) => {
        e.preventDefault();
        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].documentReminders = [...updatedFiles[index].documentReminders || [], { note: '', dateTime: new Date() }];
            return updatedFiles;
        });
    }
    const handleChangeReminder = (index: number, remiderIndex: number, e: any, name: string) => {
        e.preventDefault();
        const value = e.target.value
        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].documentReminders = [...updatedFiles[index].documentReminders || []].map((r, i) => {
                if (i === remiderIndex) {
                    return { ...r, [name]: value }
                }
                return r;
            });
            return updatedFiles;
        });
    }
    const handleRemoveReminder = (e: any, index: number, remiderIndex: number) => {
        e.preventDefault();
        setFiles(prevFiles => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].documentReminders = [...updatedFiles[index].documentReminders || []].filter((_, i) => i !== remiderIndex);
            return updatedFiles;
        });
    }
    return (
        <div className="w-full">
            <div className="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                <div className="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                    <h6 className="mb-0">{t("fileUpload")}</h6>
                    <div>
                        {files && files.length > 0 &&
                            <Tooltip title={t('delete')} placement="top" arrow  >
                                <button type="button" onClick={handleRemoveAllFile} className="mr-2  px-3 py-2 bg-gradient-to-tl from-purple-700 to-pink-500 font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg  shadow-soft-md  leading-pro text-size-xs hover:shadow-soft-2xl hover:scale-102">
                                    <i className="fas fa-trash"></i>
                                </button>
                            </Tooltip>
                        }
                        {!props.isSingleUpload && <Tooltip title={collapseUpload ? t('close') : t('open')} placement="top" arrow  >
                            <button type="button" onClick={handleExpandedCollapse} className="mr-2  px-3 py-2 bg-gradient-to-tl from-purple-700 to-pink-500 font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg  shadow-soft-md  leading-pro text-size-xs hover:shadow-soft-2xl hover:scale-102">
                                {collapseUpload ? <i className="fas fa-chevron-up"></i>
                                    : <i className="fas fa-chevron-down"></i>}
                            </button>
                        </Tooltip>}
                    </div>
                </div>
                <Collapse in={collapseUpload} timeout="auto" unmountOnExit>
                    <div className="flex items-center justify-center w-full p-2"
                        style={{ position: 'relative' }}>
                        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-white-300 border-dashed rounded-lg cursor-pointer bg-white-50 light:hover:bg-bray-800 light:bg-white-700 hover:bg-white-100 light:border-white-600 light:hover:border-white-500 light:hover:bg-white-600">
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg className="w-8 h-8 mb-4 text-gray-500 light:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                    <path stroke="currentColor" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 light:text-gray-400"><span className="font-semibold">{t('clickToUpload')}</span>  {t('orDragAndDrop')} </p>
                                {/* <p className="text-xs text-gray-500 light:text-gray-400">SVG, PNG, JPG or GIF</p> */}
                            </div>
                            <input onChange={handleFileChange} multiple={!props.isSingleUpload ? true : false} id="dropzone-file" type="file"
                                style={{
                                    opacity: 0,
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute'
                                }} />
                        </label>
                    </div>
                </Collapse>
                <div className="flex-auto p-4">
                    <ul className="flex flex-col pl-0 mb-0 rounded-lg">

                        {files && files.length > 0 ? files?.map((f, index) => (
                            <li key={index} className={`group/item ${f.isFailed && 'border-2 border-solid border-red-600'} hover:bg-slate-50 relative shadow-sm flex items-center px-0 py-2 mb-2 bg-white  rounded-lg`}>
                                <div className="inline-flex items-center justify-center w-12 h-12 mr-4 transition-all duration-200 text-base ease-soft-in-out rounded-xl">
                                    <i className="fa-solid fa-file-image text-3xl"></i>
                                </div>
                                <div className="flex flex-col items-start justify-center w-12/12  text-ellipsis">
                                    <h6 className="font-bold  mb-0 leading-normal text-sm whitespace-nowrap w-full overflow-hidden  text-ellipsis">{f?.name}</h6>
                                    {!props.isHideInputs &&
                                        <>
                                            <hr className="w-full h-px my-2 bg-gray-400 border-0" />
                                            <div className="flex justify-between w-full">
                                                <InputText readonly={f.isReadonly}
                                                    label={t('description')}
                                                    name="description"
                                                    value={f.description}
                                                    onChange={(e) => handleNoteChange(index, e.target.value)}
                                                    placeholder={`${t('pleaseEnter')} ${t('description').toLocaleLowerCase()}`}
                                                />

                                                {selectPhy
                                                    && <CustomAutoComplete
                                                        label={t('physicalAddress')}
                                                        readOnly={f?.isReadonly}
                                                        defaultValue={selectPhy.find(x => x.value === f?.physicalAddress)?.value}
                                                        name="physicalAddress"
                                                        items={selectPhy}
                                                        onChange={(e) => handlePhysicalAddressChange(index, e.value)}
                                                        placeHolder={`${t('pleaseEnter')} ${t('physicalAddress').toLocaleLowerCase()}`}
                                                    />}
                                                <button type="button"
                                                    className="mt-[29px] ml-2 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none
                                         focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg
                                          dark:shadow-teal-800/80 font-medium rounded-lg text-sm px-2 py-1 text-center me-1 mb-1"
                                                    onClick={() => !f.isReadonly && handleOnClickSecurity(index, !f?.isSecurity)}>
                                                    {f?.isSecurity === true || f?.isSecurity ? <Security /> : <LockOpenOutlined />}
                                                </button>
                                            </div>
                                            <div className="">
                                                <button
                                                    className="mt-2 text-sm font-italic whitespace-nowrap text-sky-500 hover:text-sky-600"
                                                    onClick={(e) => handleAddRemider(e,index)}
                                                >
                                                    {t('addReminder')} <span aria-hidden="true">+</span>
                                                </button>
                                                {f.documentReminders && f.documentReminders.length > 0 && <div className="mt-2 w-full">
                                                    {f.documentReminders.map((r: IFileRemiderModel, i) => (
                                                        <div key={i} className="grid grid-cols-12 w-full border-b-2 border-dashed border-gray-300 py-1 ">
                                                            <input className="col-span-12 md:col-span-5" type="text" value={r.note} onChange={(e) => handleChangeReminder(index, i, e, 'note')} placeholder={t('pleaseEnter')} />
                                                            <input className="col-span-10 md:col-span-5" type="datetime-local" value={moment(r.dateTime).format('YYYY-MM-DD HH:mm')} onChange={(e) => handleChangeReminder(index, i, e, 'dateTime')} />
                                                            <div className="col-span-2 text-right pr-2">
                                                                <button className="text-red-400" onClick={(e) => handleRemoveReminder(e, index, i)}><i className="fas fa-trash p-2" ></i></button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>}
                                            </div>
                                        </>}
                                </div>
                                {!f.isReadonly && <div onClick={() => handleRemoveFile(index)} className="absolute right-1 top-1 group/edit invisible group-hover/item:visible" >
                                    <button style={{ fontSize: 20 }} className="mr-2 mt-2 font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg 
                            shadow-none cursor-pointer leading-pro text-xs ease-soft-in hover:scale-102 hover:active:scale-102 
                            active:opacity-85 text-fuchsia-500 hover:text-red-500 hover:shadow-none active:scale-100">
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </div>}
                            </li>
                        )) : null}
                    </ul>
                </div>
            </div>
        </div>
    )
})

export default UploadFile