// lấy all danh sách user
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Api from "src/apis/apiCommon";
import { initialState } from "src/apis/apiCommon";

// module
export const fetchSuite = createAsyncThunk("fetchSuite", async (search) => {
  const res = await Api.fetchSuite();
  return res;
});
const suiteSlice = createSlice({
  name: "suites", // tên này phải giống tên khai báo ./reducer.ts
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // module
      .addCase(fetchSuite.fulfilled, (state: any, action: any) => {
        state.datas = action.payload.data;
      })
  },
});

export const selectSuite = (state: any) => state.suites.datas;// state.<tên> có trong khai báo ./reducer.ts
export default suiteSlice.reducer;
