/* eslint-disable */
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from './store'
import { fetchDepartment, fetchGetEmployees, selectEmployeeState, selectUserDepartmentState } from './slices/commonSlice'
import { fetchDocumentType, selectDocumentTypeState } from './slices/documentTypeSlice'
import { fetchProducts, selectProductsState } from './slices/productSlice'
import { fetchSpaceUse, selectSpaceUse } from './slices/spaceUseSlice'
import { fetchKind, selectKind } from './slices/kindSlice'
import { fetchPattern, selectPattern } from './slices/patternSlice'
import { fetchBrand, selectBrand } from './slices/brandSlice'
import { fetchSuite, selectSuite } from './slices/suiteSlice'
import { fetchPhysicalAddress, selectPhysicalAddressState } from './slices/physicalAddressSlice'
import { fetchUserProfile, selectUserProfileState } from './slices/userProfileSlice'
import { fetchUserAppNoti, selectUserAppNotiState } from './slices/userAppNotiSlice'


export const useUserDepartment = (isClear: boolean = false) => {
  const [loaded, setLoaded] = useState(false)
  const state = useSelector(selectUserDepartmentState)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!loaded && (state?.length === 0 || isClear)) {
      dispatch(fetchDepartment())
      setLoaded(true)
    }
  }, [state, dispatch, loaded])
}

export const useEmployee = (isClear: boolean = false) => {
  const [loaded, setLoaded] = useState(false)
  const state = useSelector(selectEmployeeState)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!loaded && (state?.length === 0 || isClear)) {
      dispatch(fetchGetEmployees())
      setLoaded(true)
    }
  }, [state, dispatch, loaded])
}

export const useDocumentType = (isClear: boolean = false) => {
  const [loaded, setLoaded] = useState(false)
  const state = useSelector(selectDocumentTypeState)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!loaded && (state?.data?.length === 0 || isClear)) {
      dispatch(fetchDocumentType())
      setLoaded(true)
    }
  }, [state, dispatch, loaded])
}

export const usePhysicalAddress = (isClear: boolean = false) => {
  const [loaded, setLoaded] = useState(false)
  const state = useSelector(selectPhysicalAddressState)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!loaded && (state?.data?.length === 0 || isClear)) {
      dispatch(fetchPhysicalAddress())
      setLoaded(true)
    }
  }, [state, dispatch, loaded])
}

export const useProduct = (isClear: boolean = false) => {
  const [loaded, setLoaded] = useState(false)
  const state = useSelector(selectProductsState)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!loaded && (state?.length === 0 || isClear)) {
      dispatch(fetchProducts())
      setLoaded(true)
    }
  }, [state, dispatch, loaded])
}

export const useSpaceUse = (isClear: boolean = false) => {
  const [loaded, setLoaded] = useState(false)
  const state = useSelector(selectSpaceUse)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!loaded && (state?.length === 0 || isClear)) {
      dispatch(fetchSpaceUse())
      setLoaded(true)
    }
  }, [state, dispatch, loaded])
}

export const useKind = (isClear: boolean = false) => {
  const [loaded, setLoaded] = useState(false)
  const state = useSelector(selectKind)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!loaded && (state?.length === 0 || isClear)) {
      dispatch(fetchKind())
      setLoaded(true)
    }
  }, [state, dispatch, loaded])
}

export const usePattern = (isClear: boolean = false) => {
  const [loaded, setLoaded] = useState(false)
  const state = useSelector(selectPattern)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!loaded && (state?.length === 0 || isClear)) {
      dispatch(fetchPattern())
      setLoaded(true)
    }
  }, [state, dispatch, loaded])
}

export const useBrand = (isClear: boolean = false) => {
  const [loaded, setLoaded] = useState(false)
  const state = useSelector(selectBrand)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!loaded && (state?.length === 0 || isClear)) {
      dispatch(fetchBrand())
      setLoaded(true)
    }
  }, [state, dispatch, loaded])
}

export const useSuite = (isClear: boolean = false) => {
  const [loaded, setLoaded] = useState(false)
  const state = useSelector(selectSuite)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!loaded && (state?.length === 0 || isClear)) {
      dispatch(fetchSuite())
      setLoaded(true)
    }
  }, [state, dispatch, loaded])
}

export const useUserProfile = (isClear: boolean = false) => {
  const [loaded, setLoaded] = useState(false)
  const state = useSelector(selectUserProfileState)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!loaded && (!state || isClear)) {
      dispatch(fetchUserProfile())
      setLoaded(true)
    }
  }, [state, dispatch, loaded])
}
export const useUserAppNoti = (isClear: boolean = false) => {
  const [loaded, setLoaded] = useState(false)
  const state = useSelector(selectUserAppNotiState)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!loaded && (!state || isClear)) {
      dispatch(fetchUserAppNoti())
      setLoaded(true)
    }
  }, [state, dispatch, loaded])
}