import { IActiveId } from "src/commons/enums/activeId";


export interface IUserDepartment {
    departmentId: string;
    userId: string;
    active?: IActiveId;
}

export interface IUserDepartmentSearchModel {
    departmentId?: string; // use one in two
    userId?: string; // use one in two
    activeId?: IActiveId;
    keyword?: string;
    page: number;
    size: number;
}

export const initUserDepartmentSearchModel: IUserDepartmentSearchModel = {
    page: 1,
    size: 10
}