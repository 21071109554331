import { MRT_Localization_EN } from "material-react-table/locales/en"
import { MRT_Localization_VI } from "material-react-table/locales/vi"
import { MRT_Localization_ZH_HANS } from "material-react-table/locales/zh-Hans"
import { MRT_Localization_DE } from "material-react-table/locales/de"

const getLangReactTable = (locale:string)=>{
    switch (locale) {
        case 'vi':
            return MRT_Localization_VI;
        case 'zh':
            return MRT_Localization_ZH_HANS;
        case 'de':
            return MRT_Localization_DE;
        default:
            return MRT_Localization_EN;
    }
}
export default getLangReactTable