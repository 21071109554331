import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, Checkbox, Grid, IconButton, Tooltip } from "@mui/material";
import { DropdownOption, MRT_ColumnDef, MRT_Row, MaterialReactTable, useMaterialReactTable } from "material-react-table"
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { fetchApplication } from "src/apis/permission/apiApplication";
import { createPermission, deletePermission, fetchPermission, updatePermission } from "src/apis/permission/apiPermission";
import getLangReactTable from "src/commons/helpers/getLangReactTable";
import { validateRequired } from "src/commons/helpers/stringHelper";
import { IApplication } from "src/interfaces/permission/IApplication";
import { IPermission } from "src/interfaces/permission/IPermission";
import SavePermission from "src/views/permission/SavePermission";

let indexReload = 1
interface IState {
    showEdit: boolean,
    reLoadEdit: number,
    keyword?: string,
    idExtend?: string,
    detail?: IPermission
}

const PermisisonPage = () => {
    const { t, i18n } = useTranslation()

    const [validationErrors, setValidationErrors] = useState<
        Record<string, string | undefined>
    >({});
    const [state, setState] = useState<IState>({
        showEdit: false,
        reLoadEdit: 1,
        keyword: '',
        detail:undefined
    })

    const [data, setData] = useState<IPermission[]>([])
    const [applications, setApplications] = useState<DropdownOption[]>([])

    useEffect(() => {
        loadPermission()
        loadApplication()
    }, [])

    const loadApplication = () => {
        fetchApplication().then(rs => {
            if (rs.success) {
                setApplications(rs.data)
            }
        })
    }

    const loadPermission = () => {
        fetchPermission().then(rs => {
            if (rs.success) {
                setData(rs.data)
            }
            else toast.warning(rs.message)
        })
    }
    const validate = (data: IPermission) => {
        return {
            code: !validateRequired(data.code) ? t('message.required') : '',
        };
    }
    const handleOnSave = (props: any) => {
        const newValidationErrors = validate(props);

        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        if ((!props.id  || props.id ==='') && data.some((rs: any) => rs.code === props.code)) {
            toast.warning(t('message.codeIsExist'))
            return
        }

        setValidationErrors({});
        const functionApi = !state.detail?.id ? createPermission : updatePermission
        functionApi(props)
            .then(rs => {
                if (rs.success) {
                    toast.success(t('message.actionSuccess'))
                    const index = data.findIndex((x: IPermission) => x.code === props.code)
                    if (index >= 0) {
                        const newData = [...data]
                        newData[index] = props
                        setData(newData)
                        
                    }
                    else {
                        setData([props, ...data])
                    }
                    indexReload = indexReload + 1
                    setState({
                        showEdit: false,
                        reLoadEdit: indexReload,
                        keyword: '',
                        detail: undefined
                    })
                }
                else toast.warning(rs.message)
            })
    }

    const handleOnEdit = (e: any) => {
        const row = e.target.closest('.MuiTableRow-root');
        if (row) {
            const rowIndex = row.dataset.index;
            const cur = data[rowIndex]
            indexReload = indexReload + 1
            setState({
                ...state,
                showEdit: true,
                reLoadEdit: indexReload,
                detail: cur
            })
        }
    }
    const handleOnDelete = (e: any, row: MRT_Row<IPermission>) => {
        e.stopPropagation()
        const name = row.original.name
        const index = data.findIndex((x: IPermission) => x.name === name)

        deletePermission(row.original.id || '').then((rs) => {
            if (rs.success) {
                if (index !== -1) {
                    const tempData = [...data];
                    tempData.splice(index, 1);
                    setData(tempData)
                }

            } else toast.warning(rs.message)
        })
    }

    const handleOnAdd = () => {
        indexReload = indexReload + 1
        setState({
            ...state,
            showEdit: true,
            reLoadEdit: indexReload,
            detail: undefined
        })
    }

    const columns = useMemo<MRT_ColumnDef<IPermission>[]>(
        () => [
            {
                header: t('stt'),
                accessorKey: 'index',
                maxSize: 40,
                Cell: (row) => {
                    return <div>{row.row.index + 1}</div>;
                },
            },
            {
                accessorKey: 'code',
                header: t('code'),
                width: 100,
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.label,
                    helperText: validationErrors?.label,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            label: undefined,
                        }),
                },
            },
            {
                accessorKey: 'name',
                header: t('name'),
                width: 100,
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.label,
                    helperText: validationErrors?.label,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            label: undefined,
                        }),
                },
            },
            {
                accessorKey: 'applicationId',
                header: t('application'),
                width: 100,
                Cell: (props: any) => {
                    return <span>{props.row.original.applicationName}</span>
                },
            },
        ],
        [validationErrors]
    );

    const table = useMaterialReactTable({
        localization: getLangReactTable(i18n.language),
        enablePagination: false,
        enableEditing: true,
        enableHiding: false,
        columns,
        data: data,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        onCreatingRowCancel: () => setValidationErrors({}),
        onEditingRowCancel: () => setValidationErrors({}),
        renderRowActions: ({ row, table }: any) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title={t('delete')}>
                    <IconButton onClick={(e) => handleOnDelete(e, row)}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={() => handleOnAdd()}
            >
                <Add /> {t('add')}
            </Button>
        ),
        muiTablePaperProps: ({ table }) => ({
            onClick: handleOnEdit,
        })
    });

    return (
        <Grid container spacing={2}>
            <Grid item md={4}>
                <MaterialReactTable table={table} />
            </Grid>
            <Grid item md={8}>
                <SavePermission
                    show={state.showEdit}
                    reload={state.reLoadEdit}
                    applications={applications}
                    data={state.detail}
                    onSave={handleOnSave} />
            </Grid>
        </Grid>
    )
}
export default PermisisonPage