export interface IProduct {
    id: string
    name: string | ''
    suiteName: string | ''
    suiteId: string | ''
    patternName: string | ''
    patternId: string | ''
    brandName: string | ''
    brandId: string | ''
}

export const initProduct: IProduct = {
    id: '0',
    name: '',
    suiteName: '',
    suiteId: '',
    patternName: '',
    patternId: '',
    brandName: '',
    brandId: ''
};