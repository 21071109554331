import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import rootReducer from './reducer'
import createIdbStorage from '@piotr-cz/redux-persist-idb-storage'
import { useDispatch } from 'react-redux'
import defaultStorage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'mlstaff',
  storage: globalThis.indexedDB ? createIdbStorage({ name: 'mlstaff', storeName: 'rootState' }) : defaultStorage
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store =
  configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).prepend()
  })

export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
