// lấy all danh sách user
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Api from "src/apis/apiCommon";
import { initialState } from "src/apis/apiCommon";
// module
export const fetchSpaceUse = createAsyncThunk("fetchSpaceUse", async (search) => {
  const res = await Api.fetchSpaceUse();
  return res;
});
const spaceUseSlice = createSlice({
  name: "spaceUses", // tên này phải giống tên khai báo ./reducer.ts
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // module
      .addCase(fetchSpaceUse.fulfilled, (state: any, action: any) => {
        state.datas = action.payload.data;
      })
  },
});

export const selectSpaceUse = (state: any) => state.spaceUses.datas;// state.<tên> có trong khai báo ./reducer.ts
export default spaceUseSlice.reducer;
