/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createUserProfile, getUserProfile } from "src/apis/apiCommon";
import { IUserProfile } from "src/interfaces/IUserProfile";

export interface IUserProfileState {
    userProfile: IUserProfile;
}

const initialState: IUserProfileState = {
    userProfile: {},
};


export const fetchUserProfile = createAsyncThunk("fetchUserProfile",
    async () => {
        const res = await getUserProfile();
        if (!res?.data) {
            const res2 = await createUserProfile({
                language: 'vi'
            });

            if (res2.success) {
                return res2;
            }
        }

        return res;
    });

export const userProfileSlice = createSlice({
    name: "userProfile",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchUserProfile.fulfilled, (state: any, action: any) => {
                state.userProfile = action.payload.data;
            })
    },
});

export const selectUserProfileState = (state: any) =>
    state.userProfile.userProfile || {};

export default userProfileSlice.reducer;
