// src/components/WeeklySalesByGroup.tsx
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { formatNumber } from 'src/commons/helpers/common';
import { IGroupBarDataSet, initBackgroundColors } from 'src/interfaces/IChart';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface IGroupBarProps {
  data: IGroupBarDataSet[]
  title: string;
  width?: number;
  barHeight?: number;
  groupBy?: 'key1' | 'key2',
  backgroundColors?: string[],
  isAutoShowData?: boolean
}

const CustomGroupBarChart: React.FC<IGroupBarProps> = ({
  data,
  title,
  width,
  barHeight,
  groupBy = 'key1',
  backgroundColors = initBackgroundColors,
  isAutoShowData = true
}) => {

  const mapDatas = (groupByKey: 'key1' | 'key2') => {
    if (groupByKey != 'key2') {

      const newObject = Object.values(data.reduce((acc: any, { key1, key2, actual }) => {
        if (!acc[key1]) {
          acc[key1] = { label: key1, data: [] };
        }
        acc[key1].data.push(actual);
        return acc;
      }, {}))

      console.log(newObject);

      return newObject;
    }

    const newObject = Object.values(data.reduce((acc: any, { key1, key2, actual }) => {
      if (!acc[key2]) {
        acc[key2] = { label: key2, data: [] };
      }
      acc[key2].data.push(actual);
      return acc;
    }, {}))

    return newObject;
  }

  const chartData: any = {
    labels: mapDatas(groupBy === 'key2' ? 'key1' : 'key2').map((x: any) => x.label),
    datasets: mapDatas(groupBy).map((x: any, i) => ({ ...x, backgroundColor: backgroundColors[i] }))
  };

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: title,
      },
      datalabels: {
        formatter: (value: number, context: any) => `${formatNumber(value || 0)}`,
        color: 'black',
        display: isAutoShowData
      }
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <div style={{ width: `${width}%` }}>
    <Bar data={chartData} options={options} height={barHeight} />
  </div>
    ;
};

export default CustomGroupBarChart;
