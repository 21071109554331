export enum ENotiType {
    Email = 1,
    CloudMessage,
    Phone,

    //Always send. Skip the UserAppNoti config
    RequiredEmail = 101,
    RequiredCloudMessage,
    RequiredSMS,
}

export enum ENotiStatus {
    New,
    Seen,
}