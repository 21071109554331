import { useEffect, useState } from "react"

export interface ISelect {
    label?: string,
    name: string,
    selectClass?: string,
    data?: IDataSelect[]
    value?: string | number,
    required?: boolean,
    defaultValue?: string,
    readonly?: boolean
    description?: string,
    error?: any,
    onChange?: (e: any) => void
}
export interface IDataSelect {
    value: string | number,
    label: string
}
const CustomSelect = (props: ISelect) => {

    // const [value, setValue] = useState(props.value)
    // useEffect(() => {
    //     setValue(props.value)
    // }, [props.value])

    return (
        <div className="w-full">
            {props.label && (<label htmlFor={props.name} className="block text-sm font-medium leading-5 text-gray-900">
                {props.label} {props.required === true && (<span className="font-bold text-red-600">*</span>)}
            </label>)}
            <div className="mt-2">
                <select
                    disabled={props.readonly}
                    name={props.name}
                    className={` border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block 
                    w-full p-2 ${props.selectClass} ${props.readonly && 'bg-gray-50'}`}
                    onChange={(e) => props.onChange && props.onChange(e)}
                    defaultValue={props.defaultValue}
                    value={props.value}
                >
                    {props.data?.map(item =>
                        <option key={item.value}
                            selected={props.value === item.value}
                            className="my-2 text-sm text-gray-900"
                            value={item.value}>
                            {item.label}
                        </option>
                    )}
                </select>
                {props.error && <p className="mt-3 text-sm leading-6 text-red-500">{props.error}</p>}
                {props.description && <p className="mt-3 text-sm leading-6 text-gray-500">{props.description}</p>}
            </div>
        </div>
    )
}
CustomSelect.defaultProps = {
    data: [{ value: 1, label: "1" }, { value: 2, label: "2" }]
}
export default CustomSelect