import { Close, CloudUpload, Delete, HideImage, MobileFriendly, MobileOff, RemoveRedEye, Save, Star } from "@mui/icons-material"
import { AppBar, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Toolbar, Tooltip, Typography } from "@mui/material"
import { Suspense, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { copyFile, countFilesByFolder, deleteFiles, getFilesByFolder, updateFileInfo, upload } from "src/apis/apiFile"
import { IModel, initCategoryModel } from "src/apis/apiCommon"
import { EFileCategoryId } from "src/commons/enums/fileCategory"
import { convertToFileFormDataWithModifiyFileName, formatFileName, toSlug } from "src/commons/helpers/file"
import { getDateTimeString } from "src/commons/helpers/formatDate"
import { fuzzySearch_VI } from "src/commons/helpers/search"
import ImageCustom from "src/components/img/ImageCustom"
import { HidenLoading, ShowLoading } from "src/components/loading-page/LoadingPage"
import { IFileCountResult, IFileInfo, IUpdateFileInfo } from "src/interfaces/IFile"
import { useBrand, useKind, usePattern, useSpaceUse, useSuite } from "src/store/hook"
import { selectBrand } from "src/store/slices/brandSlice"
import { selectKind } from "src/store/slices/kindSlice"
import { selectPattern } from "src/store/slices/patternSlice"
import { selectSpaceUse } from "src/store/slices/spaceUseSlice"
import { selectSuite } from "src/store/slices/suiteSlice"
import MediaViewer from "src/views/media-viewer/MediaViewer"
import UploadFile, { IFileUploadModel } from "src/views/upload-file/UploadFile"
import Swal from "sweetalert2"
import { MaterialReactTable, MRT_Row } from "material-react-table"
import { quickSort } from "src/commons/helpers/sort"
import { generateGUID } from "src/commons/helpers/common"
import { useParams } from "react-router-dom"
import getLangReactTable from "src/commons/helpers/getLangReactTable"
import { useTranslation } from "react-i18next"

const fileURL = `${process.env.REACT_APP_FILE_URL}`

export interface ISearchState {
  reload?: number
  keyword: string | null
  brandIds: string[]
  suiteIds: string[]
  patternIds: string[]
  sortBy: string
  pageSize: number
  pageIndex: number
  sortAscending: boolean

}
const CategoryFilePage = () => {
  const { t, i18n } = useTranslation()
  const containerRef = useRef<HTMLDivElement>(null);

  useSpaceUse();
  useKind();
  usePattern();
  useBrand();
  useSuite();

  const { type } = useParams<{ type?: string }>();
  const [typeQuery, setTypeQuery] = useState('');
  const [categoryState, setCategoryState] = useState<IModel[]>([]);

  const kinds = useSelector<IModel[], IModel[]>(selectKind)
  const brands = useSelector<IModel[], IModel[]>(selectBrand)
  const suites = useSelector<IModel[], IModel[]>(selectSuite)
  const patterns = useSelector<IModel[], IModel[]>(selectPattern)
  const spaceUses = useSelector<IModel[], IModel[]>(selectSpaceUse)

  const [version, setVersion] = useState('0');

  const [categories, setCategories] = useState<IModel[]>([]);
  const [fileCounts, setFileCounts] = useState<IFileCountResult[]>([]);

  const [isBottom, setIsBottom] = useState(false);

  const [searchState, setSearchState] = useState({
    keyword: "",
    pageSize: 20,
    pageIndex: 1,
    startDate: "2023-06-13T04:06:07.492Z",
    endDate: "2023-06-13T04:06:07.492Z",
    suiteId: "",
    patternId: "",
    brandId: "",
    categoryIDs: "",
    isSmall: false
  });

  useEffect(() => {
    if (type) {
      setTypeQuery(type || '');
    }
  }, [type])

  useEffect(() => {
    setSearchState({ ...searchState, pageSize: 20, pageIndex: 1 })
    callCountFiles()
  }, [categories])

  useEffect(() => {
    callCountFiles()
  }, [searchState.pageSize])

  useEffect(() => {
    isBottom && setSearchState({ ...searchState, pageSize: searchState.pageSize + 20, pageIndex: searchState.pageIndex + 1 })
  }, [isBottom])

  useEffect(() => {
    if (!typeQuery) {
      return;
    }

    switch (typeQuery) {
      case 'brand':
        setCategoryState(brands)
        break;
      case 'suite':
        setCategoryState(suites)
        break;
      case 'pattern':
        setCategoryState(patterns)
        break;
      case 'kind':
        setCategoryState(kinds)
        break;
      case 'space-use':
        setCategoryState(spaceUses)
        break;
      default:
        setCategoryState([])
    }
  }, [typeQuery])

  useEffect(() => {
    categoryState && setCategories(categoryState)
    const handleScroll = () => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const isBottom = container.scrollTop + container.clientHeight >= container.scrollHeight;

      setIsBottom(isBottom);
    };


    if (!containerRef.current) return;

    // Bắt sự kiện scroll của phần tử container
    containerRef.current.addEventListener('scroll', handleScroll);

    // Xóa sự kiện scroll khi component bị unmount
    return () => {
      if (!containerRef.current) return;
      containerRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [categoryState])

  const callCountFiles = () => {
    const prefixMapping = categories?.filter((x, i) => i < searchState.pageSize && (i >= (searchState.pageIndex - 1) * 20)).map(x => x.id).join('-');
    prefixMapping && categories && categories.length > 0
      && countFilesByFolder(typeQuery, '',
        prefixMapping || '').then((res) => {
          if (!res || !res.success) {
            toast.error('message.Failed');
            return;
          }

          const data: IFileCountResult[] = res.data;
          setFileCounts(prev => [...prev, ...data]);
        }).catch((e) => {
          toast.error('message.Failed');
        }).finally(() => HidenLoading());

  }

  const filterCategorys = (): IModel[] => {
    // const queries = searchState.keyword.trim().toLowerCase();

    const filteredCategorys =
      categoryState?.filter(category =>
      (fuzzySearch_VI([category], 'name', searchState.keyword).length > 0 ||
        fuzzySearch_VI([category], 'id', searchState.keyword).length > 0)
      );

    return filteredCategorys;
  }

  const handleClickButtonSearch = () => {
    const filtered = filterCategorys()
    setCategories(filtered);
  }


  // ----------------------------Modal Update Files----------------------------//
  const [modalUpdateFiles, setModalUpdateFiles] = useState({
    reload: 0,
    show: false,
  })
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number>(-1);
  const [currentCategory, setCurrentCategory] = useState<IModel>(initCategoryModel);
  const [selectedFiles, setSelectedFiles] = useState<IFileInfo[]>([]);

  const sortFilesBySortOrderField = (files: any[]) => {
    return quickSort(files, 'sortOrder', "asc");
  }

  useEffect(() => {
    modalUpdateFiles.reload > 0 && handleOpenModalUpdateFiles()
  }, [currentCategory.id])

  const handleOpenModalUpdateFiles = () => {
    setModalUpdateFiles({
      show: true,
      reload: modalUpdateFiles.reload + 1,
    })
  }

  const handleOnSaveUpdateFiles = () => {
    // handleViewFile(currentFilesViewFiles);
  }

  const handleChangeAvatar = (file: IFileInfo) => {
    // handleViewFile(currentFilesViewFiles);
    ShowLoading()
    copyFile({
      newPath: `${file.folder}/avatar.png`,
      newBucketName: typeQuery,
      fileId: file.id || 0,
      newCategory: EFileCategoryId.Avatar,
      newParentId: file.id,
      newShowApp: file.showApp,
      newSortOrder: 0,
      isCreateSmallFile: true
    }).then(res => {
      if (!res.success) {
        toast.error('message.Failed');
        return;
      }

      handleOnClickCategory(currentCategory)
    }).finally(() => HidenLoading());
  }

  const handleDeleteAvatar = () => {
    // handleViewFile(currentFilesViewFiles);

    getFilesByFolder(typeQuery, currentCategory.id, 'avatar.png', false, 1, [EFileCategoryId.Avatar]).then(res => {
      if (!res.success) {
        toast.error('message.Failed');
        return;
      }

      var data: IFileInfo[] = res.data || [];

      const avatarFile = data.find(x => x.fileName === 'avatar.png');
      if (avatarFile) {
        handleDeleteFiles([avatarFile], [EFileCategoryId.Avatar, EFileCategoryId.AvatarSM])
      }
    }).catch(() => {
      Swal.fire(
        `${t('message.errorExcution')}`,
        `${t('fileNotDeleted')}`,
        'error'
      )
    }).finally(() => HidenLoading());

  }

  const handleOnClickDeleteFiles = (files: IFileInfo[]) => {
    handleDeleteFiles(files, [EFileCategoryId.Thumbnail, EFileCategoryId.ThumbnailSM, EFileCategoryId.VideoReview, EFileCategoryId.File3D])
  }

  const handleDeleteFiles = (files: IFileInfo[], categoryFilters: EFileCategoryId[]) => {
    const ids = files.map(item => item?.id?.toString()) as string[] || [];

    Swal.fire({
      title: `${t('message.DeleteConfirms')}`,
      text: `${t('message.DeleteWarning')}`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: `${t('cancel')}`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${t('confirm')}`
    }).then((result) => {
      if (result.isConfirmed) {
        ShowLoading()
        deleteFiles(ids, typeQuery, categoryFilters).then((r) => {
          if (r.success) {
            // const updatedData = selectedFiles.filter(item => !ids.includes(item?.id?.toString() || '-1'));
            // setSelectedFiles(updatedData);

            handleOnClickCategory(currentCategory)

            Swal.fire(
              `${t('deleted')}`,
              `${t('fileDeleted')}`,
              'success'
            )
          } else {
            Swal.fire(
              `${t('message.errorExcution')}`,
              `${t('fileNotDeleted')}`,
              'error'
            )
          }
        }).catch(() => {
          Swal.fire(
            `${t('message.errorExcution')}`,
            `${t('fileNotDeleted')}`,
            'error'
          )
        }).finally(() => HidenLoading());
      }
    });
  }

  const handleCloseModalUpdateFiles = () => {
    setCurrentCategoryIndex(categories.findIndex(x => x.id === currentCategory.id))
    setCurrentCategory(initCategoryModel);
    setModalUpdateFiles({ reload: 0, show: false })
  }

  const handleOnClickCategory = (category: IModel) => {
    setModalUpdateFiles({ ...modalUpdateFiles, reload: modalUpdateFiles.reload + 1 })
    setCurrentCategory(category)
    setCurrentCategoryIndex(-1)
    setVersion(generateGUID())

    getFilesByFolder(typeQuery, category.id, '', false, 1, [EFileCategoryId.Thumbnail, EFileCategoryId.VideoReview, EFileCategoryId.Document, EFileCategoryId.File3D]).then(res => {
      if (!res.success) {
        toast.error('message.Failed');
        return;
      }

      var data: IFileInfo[] = res.data;
      setSelectedFiles(sortFilesBySortOrderField(data));
    })
  }

  const getAvatarURL = (files: IFileInfo[]) => {
    if (files && files.length > 0 && (currentCategory?.id && currentCategory?.id != '0')) {
      // const avatar = files.find(x => x.category === EFileCategoryId.Avatar);

      // if (avatar) {
      //   return `${fileURL}/${currentCategory?.id}/avatar.png?v=${avatar.version}`;
      // }
      return `${fileURL}/${typeQuery}/${currentCategory?.id}/avatar.png?v=${version}`;
    }

    return '/logo1024.png';


  }

  const handleCheckShowApp = (file: IFileInfo) => {

    const req: IUpdateFileInfo = {
      id: file.id || -1,
      showApp: !file.showApp
    }

    updateFileInfo([req]).then((res) => {
      if (!res.success) {
        toast.error('message.Failed');
        return;
      }

      let updatedData = [...selectedFiles];
      const index = updatedData.findIndex(x => x.id === file.id);
      updatedData[index].showApp = !file.showApp;
      setSelectedFiles(updatedData);
    }).catch((e) => {
      toast.error('message.Failed');
    }).finally(() => HidenLoading());
  }

  const handleOnDragEnd = (data: IFileInfo[]) => {
    const lstUpdate = data.map((x, i) => {
      return {
        id: x.id,
        sortOrder: i + 1
      } as IUpdateFileInfo
    });

    updateFileInfo(lstUpdate).then((res) => {
      if (!res.success) {
        toast.error('message.Failed');
        return;
      }

      setSelectedFiles(data);
    }).catch((e) => {
      toast.error('message.Failed');
    }).finally(() => HidenLoading());
  }

  const handleOnDrag = (draggingFile: IFileInfo, draggingIndex: number, hoveredFile: IFileInfo, hoveredIndex: number) => {

    const reqDraggingFile: IUpdateFileInfo = {
      id: draggingFile.id || -1,
      sortOrder: hoveredIndex
    }

    const reqHoveredFile: IUpdateFileInfo = {
      id: hoveredFile.id || -1,
      sortOrder: draggingIndex
    }

    updateFileInfo([reqDraggingFile, reqHoveredFile]).then((res) => {
      if (!res.success) {
        toast.error('message.Failed');
        return;
      }

      let updatedData = [...selectedFiles];
      const indexDragging = updatedData.findIndex(x => x.id === draggingFile.id);
      const indexHovered = updatedData.findIndex(x => x.id === hoveredFile.id);

      updatedData[indexDragging].sortOrder = reqDraggingFile.sortOrder || 100;
      updatedData[indexHovered].sortOrder = reqHoveredFile.sortOrder || 100;

      setSelectedFiles(updatedData);
    }).catch((e) => {
      toast.error('message.Failed');
    }).finally(() => HidenLoading());
  }

  // ---------------------------- Modal View File----------------------------//
  const [modalViewFileOpen, setModalViewFileOpen] = useState(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState(-1);

  const handleOpenModalViewFile = () => {
    setModalViewFileOpen(true);
  };

  const handleCloseModalViewFile = () => {
    setModalViewFileOpen(false);
    setSelectedFileIndex(- 1);

  };

  const handleViewFile = (rowData: any) => {
    setSelectedFileIndex(rowData.index - 1);
    handleOpenModalViewFile();
  }


  // ---------------------------- Modal Upload----------------------------//
  const [modalUpload, setModalUpload] = useState({
    reload: 1,
    show: false,
  })
  const [currentFilesUploads, setCurrentFilesUploads] = useState<IFileUploadModel[]>([]);
  const fileRef = useRef<any>()

  const handleOpenModalUpload = () => {
    setModalUpload({
      show: true,
      reload: modalUpload.reload + 1,
    })
  }

  const handleOnSaveUpload = () => {
    handleUpload(currentFilesUploads);
  }

  const handleCloseModalUpload = () => {
    setModalUpload({ ...modalUpload, show: false })
  }

  const handleChangeFileUpload = (files: IFileUploadModel[]) => {
    const fileMaps = modalUpdateFiles.show ? files : files.map((x, i) => {
      if (x.file) {
        const id = extractCategoryIdByFileName(x.file?.name)
        if (categoryState.findIndex(x => x.id === id) != -1) {
          return x
        }
      }


      return { ...x, isFailed: true }
    });

    setCurrentFilesUploads(fileMaps);
    fileRef.current.setData(fileMaps);

  }

  const findCategory = (id: string) => {
    return categoryState.find(x => x.id === id) as IModel;
  }

  const extractCategoryIdByFileName = (fileName: string | undefined) => {
    return fileName?.split('-')[0] || '-1'
  }

  const generateFileNameTemplate = (categoryInfo: IModel, fileName: string) => {
    const format = `${toSlug(`${categoryInfo?.name}`)}-${formatFileName(fileName)}`;
    return format;
  }

  const getDefaultCategoryByFileType = (fileType?: string) => {
    if (fileType?.includes('image')) {
      return EFileCategoryId.Thumbnail
    }

    if (fileType?.includes('video')) {
      return EFileCategoryId.VideoReview
    }

    return EFileCategoryId.Document
  }

  const handleUpload = async (files: IFileUploadModel[]) => {
    const fileFilters = files.filter(x => !x.isFailed).map(x => {
      return { ...x, category: getDefaultCategoryByFileType(x.type) }
    });

    if (fileFilters && fileFilters?.length > 0) {

      const count = fileFilters.length;
      const lstCheckedAvatarExist: string[] = await countFilesByFolder(typeQuery, '', fileFilters.map(x => {
        const folderPath = currentCategory.id != initCategoryModel.id ? currentCategory.id : extractCategoryIdByFileName(x.file?.name);
        return folderPath
      }).join('-') || '', 'avatar.png', 1).then((res) => {
        if (!res || !res.success) {
          toast.error('message.Failed');
          return [];
        }

        const data: IFileCountResult[] = res.data;
        return data.map(x => x.folderPath);
      }).catch((e) => {
        toast.error('message.Failed');
        return []
      }).finally(() => HidenLoading());
      for (let index = 0; index < count; index++) {
        const element = fileFilters[index];
        const folderPath = currentCategory.id != initCategoryModel.id ? currentCategory.id : extractCategoryIdByFileName(element.file?.name);
        if (!lstCheckedAvatarExist.includes(folderPath)) {

          //Add avatar if not exists
          if (element.file) {
            fileFilters.push({ ...element, name: 'avatar.png', category: EFileCategoryId.Avatar });
          }

          lstCheckedAvatarExist.push(folderPath);
        }
      }
      //Form data Convert
      const formData = convertToFileFormDataWithModifiyFileName({
        files: fileFilters.map(x => x.file as File),
        fileNames: fileFilters.map(x => {
          const currCategoryInfo = currentCategory.id != initCategoryModel.id
            ? currentCategory
            : findCategory(extractCategoryIdByFileName(x.file?.name));

          return x.name === 'avatar.png' ? x.name : generateFileNameTemplate(currCategoryInfo, x.name || x.file?.name as string)
        }),
        bucketName: typeQuery,
        folders: fileFilters.reduce((accumulator, currentValue, index) => {
          accumulator[`${index}`] = currentCategory.id != initCategoryModel.id ? currentCategory.id : extractCategoryIdByFileName(currentValue.file?.name);

          return accumulator;
        }, {} as { [key: string]: string }),
        categories: fileFilters.reduce((accumulator, currentValue, index) => {
          accumulator[`${index}`] = currentValue.category;

          return accumulator;
        }, {} as { [key: string]: number }),
      });


      //Start upload
      ShowLoading();
      formData && upload(formData, true).then(res => {
        if (!res || !res.success) {
          // return null;
          toast.error(t('message.ImportedFailed'))
          return;
        }

        toast.success('message.successSendData')

        //handle if upload while opening the Details Modal => add the res to the table
        if (currentCategory) {
          // const data: IFileInfo[] = res.data;

          // setSelectedFiles([...selectedFiles, ...data.map(x => ({ ...x, createdDate: new Date(), updatedDate: new Date() }))])
          handleOnClickCategory(currentCategory)
        }

      }).catch(error => {
        toast.error(`${t('message.errorExcution')}`);
        console.error(error);
      }).finally(() => {
        HidenLoading();
        handleCloseModalUpload();
      });
    }
  }

  //--------------------------RENDER --------------------------//
  const renderBadge = (category: IModel) => {
    const fileTypes = fileCounts.find(x => x.folderPath === category.id)?.fileCountsByType || [];
    const countImage = fileTypes?.find(x => x.fileType.includes('image'))?.fileCount || 0;
    const countVideo = fileTypes?.find(x => x.fileType.includes('video'))?.fileCount || 0;
    const countOther = fileTypes?.map(x => x.fileCount).reduce((prev, curr) => prev += curr, 0) - countImage - countVideo;

    return (
      <div className="inline-flex bg-red w-2/3 opacity-85">
        {countImage > 0 && <button type="button" className="mr-2 relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 ">
          <i className="fas fa-images"></i>
          <span className="sr-only">image</span>
          <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 ">
            {`${Math.floor(countImage / 2)}`}
          </div>
        </button>}
        {countVideo > 0 && <button type="button" className="mr-2 relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 ">
          <i className="fas fa-video"></i>
          <span className="sr-only">countVideo</span>
          <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 ">
            {`${countVideo}`}
          </div>
        </button>}
        {countOther > 0 && <button type="button" className="mr-2 relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 ">
          <i className="far fa-file"></i>
          <span className="sr-only">countOther</span>
          <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 ">
            {`${countOther}`}
          </div>
        </button>}
      </div>
    )
  }

  return (
    <div className="bg-white " style={{ width: '100%' }}>
      <div className="p-4 sm:px-6 ">
        <h2 className="sr-only">Categorys</h2>
        <div className="grid grid-cols-1 gap-1 my-2 sm:grid-cols-2 xl:grid-cols-10">
          <div className="relative w-full col-span-7">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <i className="fas fa-file-image"></i>
            </div>
            {/* <label htmlFor="simple-search" className="block text-sm font-bold leading-5 text-gray-700">Search</label> */}
            <input type="text" id="simple-search" className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 "
              placeholder={t('searchByNameCodeURL') + '...'}
              onChange={(e) => setSearchState({ ...searchState, keyword: e.currentTarget.value })}
            />
          </div>
          <Tooltip title={t('clickToUpload')}>
            <button onClick={handleOpenModalUpload} type="button" className=" py-2.5 px-3 ms-2 text-sm font-medium text-white bg-green-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 ">
              <i className="fas fa-file-upload "></i>
            </button>
          </Tooltip>
          <button onClick={() => handleClickButtonSearch()} type="button" className=" col-span-2 py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 ">
            <i className="fas fa-search"></i>    {t('search')?.toLocaleUpperCase()}
          </button>
        </div>

        <div ref={containerRef} className="overflow-auto h-[78svh] grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 xl:gap-x-8">
          {categories?.map((category, index) => {
            return index < searchState.pageSize && (
              <div key={category.id} onClick={() => handleOnClickCategory(category)} className="group cursor-pointer relative">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  {/* <img
                    src={`${fileURL}/${category.id}/avatar.png`}
                    alt={`${fileURL}/${category.id}/avatar.png`}
                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                  /> */}
                  {category.id
                    ? <ImageCustom key={`${category.id}-${currentCategoryIndex === index}`}
                      src={`${fileURL}/${typeQuery}/${category.id}/avatar.png?r=${currentCategoryIndex === index}`} width={'100%'} height={'100%'} />
                    : <ImageCustom src={`/logo1024.png`} width={'100%'} height={'100%'} />}
                </div>
                <h3 className="mt-4 text-sm text-gray-700">{`${category.name} (${category.id})`}</h3>
                <div className="absolute right-0 top-0 p-2 w-full">
                  {
                    renderBadge(category)
                  }
                </div>
              </div>
            )
          })}
        </div>

        {/* MODAL UPLOAD */}
        <Suspense>
          <Dialog
            open={modalUpload.show}
            onClose={handleCloseModalUpload}
            maxWidth={'md'}
            fullWidth
          >
            <AppBar sx={{ position: 'relative' }} className="bg-gradient-to-tl from-purple-700 to-pink-500">
              <Toolbar>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  {t('fileUpload')}
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseModalUpload}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <Grid container spacing={0.5}>
                <UploadFile ref={fileRef} isHideInputs={true} onChange={handleChangeFileUpload} />
              </Grid>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse' }}>
              <Button onClick={() => handleOnSaveUpload()} ><Save />&nbsp;{t('save')}</Button>
            </DialogActions>
          </Dialog >
        </Suspense>
        <Suspense>
          {/* MODAL VIEW FILES */}
          <Dialog
            open={modalUpdateFiles.show}
            onClose={handleCloseModalUpdateFiles}
            maxWidth={'lg'}
            fullWidth
          >
            <AppBar sx={{ position: 'relative' }} className="bg-gradient-to-tl from-purple-700 to-pink-500">
              <Toolbar>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  {currentCategory?.name}
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseModalUpdateFiles}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <Grid container spacing={0.5}>
                {/* <UploadFile ref={fileRef} isHideInputs={true} onChange={handleChangeFileUploadInModal} /> */}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                  <div style={{ width: '50%' }} key={getAvatarURL(selectedFiles)} >
                    <ImageCustom src={getAvatarURL(selectedFiles)} width={'100%'} height={'100%'} />
                  </div>
                  <Tooltip title={t('deleteAvatar')}>
                    <IconButton sx={{ position: 'absolute', left: 0, bottom: 0 }} onClick={handleDeleteAvatar}>
                      <HideImage />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('uploadFile')} onClick={handleOpenModalUpload}>
                    <IconButton sx={{ position: 'absolute', right: 0, bottom: 0 }}>
                      <CloudUpload />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <MaterialReactTable
                    enableBottomToolbar={false}
                    enableTopToolbar={false}
                    localization={getLangReactTable(i18n.language)}
                    getRowId={(row) => `${row.index.toString()}-${currentCategory?.id}` || '-1'}
                    columns={[
                      {
                        header: t('action'), Cell: (data) =>
                          <>
                            <IconButton onClick={() => handleViewFile(data.cell.row.original)}>
                              <RemoveRedEye />
                            </IconButton>
                            <IconButton onClick={() => handleOnClickDeleteFiles([data.row.original])}>
                              <Delete />
                            </IconButton>
                            <IconButton sx={{ display: data.row.original.category != EFileCategoryId.Thumbnail ? 'none' : '' }} onClick={() => handleChangeAvatar(data.row.original)} color={selectedFiles.find(x => x.category === EFileCategoryId.Avatar)?.parentId === data.row.original.id ? 'warning' : 'default'}>
                              <Star />
                            </IconButton>
                          </>
                      },
                      {
                        header: t('image'), accessorKey: 'url',
                        Cell: (rowData) => {
                          return <ImageCustom key={`${fileURL}/${typeQuery}/${rowData.row.original.folder}/${rowData.row.original.fileName}`} src={`${fileURL}/${typeQuery}/${rowData.row.original.folder}/${rowData.row.original.fileName}`} />
                        }
                      },
                      // { header: t('id'), accessorKey: 'id' },
                      { header: t('name'), accessorKey: 'fileName' },
                      // { header: t('sort'), accessorKey: 'sortOrder' },
                      {
                        header: t('size'),
                        accessorKey: 'size',
                        Cell: (rowData) => {
                          return (rowData.row.original.size ? (rowData.row.original.size / (1024 * 1024)).toFixed(2) : 0) + ' MB';
                        }
                      },
                      { header: t('contentType'), accessorKey: 'contentType' },
                      { header: t('updatedDate'), accessorKey: 'updatedDate', Cell: (data) => <>{getDateTimeString(new Date(data.row.original.updatedDate || new Date()))}</> },
                      {
                        header: t('showApp'), Cell: (data) =>
                          <>
                            <IconButton onClick={() => handleCheckShowApp(data.cell.row.original)}>
                              {data.cell.row.original.showApp ? <MobileFriendly color="success" /> : <MobileOff />}
                            </IconButton>
                          </>
                      },

                    ]}
                    data={(
                      selectedFiles
                        ? (selectedFiles.map((item: IFileInfo, index: number) => ({
                          ...item, index: index + 1, sortOrder: item.sortOrder === 100 ? index + 1 : item.sortOrder
                        })))
                        : [])}
                    muiTableBodyCellProps={({ cell }) => ({
                      onDoubleClick: (event) => {
                        handleViewFile(cell.row.original);
                      },
                    })}
                    initialState={{
                      pagination: {
                        pageIndex: 0,
                        pageSize: 10
                      },
                      showGlobalFilter: true,
                      sorting: [
                        {
                          id: 'sortOrder',
                          desc: false
                        }
                      ]
                    }}

                    enableSorting={false}
                    enableRowOrdering
                    // onDraggingRowChange={setDraggingRow}
                    muiRowDragHandleProps={({ table }) => ({
                      onDragEnd: (props) => {
                        const { draggingRow, hoveredRow } = table.getState();

                        if (hoveredRow && draggingRow) {
                          const data = [...selectedFiles]
                          data.splice((hoveredRow as MRT_Row<any>).index, 0, data.splice(draggingRow.index, 1)[0],);
                          handleOnDragEnd([...data])
                        }
                      }
                    })}
                  />
                </Grid>

              </Grid>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse' }}>
            </DialogActions>
          </Dialog >
        </Suspense>
        {/* MODAL VIEW FILE */}
        <Suspense>
          <div>
            <MediaViewer
              currentIndex={selectedFileIndex}
              files={selectedFiles ? selectedFiles.map((item: IFileInfo, index: number) => ({
                ...item, index: index + 1,
              })) : []}
              open={modalViewFileOpen}
              onClose={handleCloseModalViewFile}
            />
          </div>
        </Suspense>
      </div>
    </div>
  )
}
export default CategoryFilePage