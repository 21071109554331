const TestCode = () => {
    return (
        <div className="mt-10">
            <div id="loader" >
                <img src="/assets/js/loader.svg" alt=""/>
            </div>
            <script src="/assets/js/customer.js" />
        </div>
    )
}
export default TestCode