import { CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICustomAutoComplete } from './CustomAutoComplete';

let debounceTimer:any
interface AutocompleteAsyncProps {
    search: (value: string) => Promise<ICustomAutoComplete[]>
    placeHolder: string;
    className?: string
    label?: string,
    name?: string
    required?: boolean
    onChange?: (value: ICustomAutoComplete) => void
    defaultValue?: string
    readOnly?: boolean
}

const CustomAutoCompleteAsync: React.FC<AutocompleteAsyncProps> = ({ readOnly, defaultValue, onChange, name, label, required, search, placeHolder, className }) => {
    const { t } = useTranslation()
    const [query, setQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState<ICustomAutoComplete[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        defaultValue && setQuery(defaultValue)
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(debounceTimer);
        const inputQuery = event.target.value;
        setQuery(inputQuery);
        debounceTimer = setTimeout(() => {
            setLoading(true)
            searchApi(inputQuery);
        },1000); // 1 giây
    };
    const searchApi =async(value:string)=>{
        const data =await search(value)
        setLoading(false)
        setFilteredItems(data);
        if (!value) {
            onChange && onChange({ label: 'all', value: '' })
        }
        setShowDropdown(true);
    }

    const handleItemClick = (item: ICustomAutoComplete) => {
        setQuery(item.label);
        setShowDropdown(false);
        setLoading(false)
        onChange && onChange(item)
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowDropdown(false);
            setLoading(false)
        }
    };
    const handleOnClick = (e: any) => {
        setShowDropdown(true)
    }
    return (
        <div className="autocomplete w-full" ref={dropdownRef}>
            {label && (<label htmlFor={name} className="block text-sm leading-5 text-gray-700">
                {label} {required === true && (<span className="font-bold text-red-600">*</span>)}
            </label>)}
            <input
                readOnly={readOnly}
                className={`px-2 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 
                shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                sm:text-sm sm:leading-6 undefined ${className}`}
                type="text"
                name={name}
                placeholder={placeHolder}
                value={query}
                onChange={handleInputChange}
                onClick={handleOnClick}
            />
            {loading && !readOnly && (
                <ul className="autocomplete-list text-sm text-gray-900">
                    <li className="autocomplete-list-item">
                        <CircularProgress color="inherit" size={20} />
                        <span className='ml-2'>  {t('loading')}</span>
                    </li>
                </ul>
            )}

            {showDropdown && !readOnly && (
                <ul className="autocomplete-list text-sm text-gray-900">
                    {filteredItems?.length === 0 ?<li className="autocomplete-list-item">
                       {t('message.notData')}
                    </li>
                    : filteredItems.map((item, index) => (
                        <li
                            key={index}
                            className="autocomplete-list-item "
                            onClick={() => handleItemClick(item)}
                            onBlur={() => setShowDropdown(false)}
                        >
                            {item.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomAutoCompleteAsync;
