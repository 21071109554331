export const getInitials = (inputString: string) => {
  // Chia chuỗi thành từng từ bằng khoảng trắng
  const words = inputString ? inputString.split(" ") : "";

  // Tạo biến để lưu các chữ cái đầu
  let initials = "";

  // Lặp qua từng từ và lấy chữ cái đầu của mỗi từ
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (word.length > 0) {
      initials += word[0].toUpperCase();
    }
  }
  return initials;
};

export const getAvatarText = (fullName: string) => {
  if (fullName === "" || fullName === null || fullName === undefined)
    return "??";
  if (fullName.length === 1) return (fullName + fullName).toUpperCase();

  const text = getInitials(fullName) || "";
  return text.length > 1 ? text.slice(-2) : fullName.slice(0, 2).toUpperCase();
};
export const getRandomIntInclusive = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
