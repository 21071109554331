import { AppBar, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Toolbar, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { Add, Close, DeleteForever, Save } from "@mui/icons-material";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { IFileInfo } from "src/interfaces/IFile";
import { FolderModel } from "src/interfaces/IFolder";
import TreeViewFolder from "src/views/folder/TreeViewFolder";
import MediaViewer from "src/views/media-viewer/MediaViewer";
import MaterialTable from "@material-table/core";
import * as Api from '../../apis/apiPrivate';
import { getDateTimeString } from "src/commons/helpers/formatDate";
import { t } from "i18next";
import UploadFile, { IFileUploadModel } from "src/views/upload-file/UploadFile";
import { fileListToArray } from "src/commons/helpers/common";
import { HidenLoading, ShowLoading } from "src/components/loading-page/LoadingPage";


const PrivateFilesPage = () => {

    const [isNotExistAnyFolders, setIsNotExistAnyFolders] = useState(false);
    const [isLoadData, setIsLoadData] = useState(false);
    const [lstFolder, setLstFolder] = useState<FolderModel[]>([]);
    const [selectedFolder, setSelectedFolder] = useState<FolderModel>();

    const [selectedFiles, setSelectedFiles] = useState<IFileInfo[]>([]);
    const [selectedFileIndex, setSelectedFileIndex] = useState(-1);

    const [isValidOTP, setIsValidOTP] = useState(false);

    useEffect(() => {
        loadDataFolder(0);
    }, [])

    useEffect(() => {
        isNotExistAnyFolders && Api.CreateFolder("").then((r) => {
            if (r.success) {
                setIsNotExistAnyFolders(false)
                // loadDataFolder(0);
            }
        });
    }, [isNotExistAnyFolders])

    //---------------------------------FOLDER----------------------------------------------//

    //Load list folder (indexFolder: set default folder to load)
    const loadDataFolder = (indexFolder: number) => {
        setIsLoadData(true);
        Api.GetFolderByUser().then((r) => {
            if (r.success) {
                if (r.data.length === 0) {
                    setIsNotExistAnyFolders(true)
                    return;
                }

                setLstFolder(r.data);
                setIsLoadData(false);

                if (r.data && r.data.length > 0) {
                    handleClickFolderTreeItem(r.data[indexFolder], true);
                }
            }
        });
    }

    //Delete folder
    const handleClickDeleteFolderTreeItem = (folder: FolderModel) => {

        if (folder.isHasToken) {
            Swal.fire({
                title: `${t('useYubikeyToAccess')}`,
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: `${t('confirm')}`,
                showLoaderOnConfirm: true,
                cancelButtonText: `${t('cancel')}`,
                preConfirm: (otpText) => otpText,
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    let otp = result.value;
                    confirmDeleteFolder(folder, otp);
                }
            })
            return;
        }

        confirmDeleteFolder(folder);
    }

    function confirmDeleteFolder(folder: FolderModel, otp: string = ""): void {
        Swal.fire({
            title: `${t('message.DeleteConfirms')}`,
            text: `${t('message.DeleteWarning')}`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: `${t('cancel')}`,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `${t('confirm')}`
        }).then((result) => {
            if (result.isConfirmed) {

                if (folder.parentId === 0) {
                    toast.warning(`${t('cannotdeleteOriginalFolder')}!`);
                    return;
                }

                setSelectedFolder(folder)
                Api.DeleteFolder([folder.id.toString()], otp).then((r) => {
                    // setSelectedFiles(r.data);
                    if (r === undefined) {
                        toast.error(t('message.errorConnect'));
                        return;
                    }

                    if (r.success) {
                        if (r.data.length > 0) {
                            for (let i = 0; i < r.data.length; i++) {
                                const element = r.data[i];
                                toast.warning(`${t('cannotDelete')}: ` + element);
                            }
                            lstFolder.forEach((x, i) => {
                                if (x.id === folder.id) {
                                    loadDataFolder(i);
                                }
                            })
                            return;
                        }

                        toast.success(t('deleted'));

                        lstFolder.forEach((x, i) => {
                            if (x.id === folder.parentId) {
                                loadDataFolder(i);
                            }
                        })
                        return;
                    }

                    toast.error(`${t('failed')}` + r.message);
                }).catch(e => {
                    toast.error(`${t('failed')}`);
                });
            }
        });
    }




    //Add folder
    function formatPath(path: string): string {
        const parts = path.split('/');
        return parts.length > 1 ? parts.slice(1).join('/') : '';
    }

    const handleClickAddFolderTreeItem = (folder: FolderModel) => {
        Swal.fire({
            title: t('enterFolderName'),
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: `${t('confirm')}`,
            showLoaderOnConfirm: true,
            cancelButtonText: `${t('cancel')}`,
            preConfirm: (folderName) => {
                var newPath = formatPath(folder.path) + "/" + folderName.trim() ?? '';
                return Api.CreateFolder(newPath)
                    .then(response => {
                        if (!response.success) {
                            throw new Error(response.message ? response.message : `${t('message.actionError')}`)
                        }
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `${t('message.errorExcution')}: ${error}`
                        )
                    })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                toast.success(`${t('message.actionSuccess')}`)

                lstFolder.forEach((x, i) => {
                    if (x.id === folder.id) {
                        loadDataFolder(i);
                    }
                })
            }
        })
    }

    //Yubikey
    const handleClickClockFolderTreeItem = (folder: FolderModel) => {
        var title = folder.isHasToken ? `${t('yubikeyUnlockFolder')}` : `${t('yubikeyLockFolder')}`;
        Swal.fire({
            title: title,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off',
                id: "inputSwal"
            },
            showCancelButton: true,
            confirmButtonText: `${t('confirm')}`,
            showLoaderOnConfirm: true,
            cancelButtonText: `${t('cancel')}`,
            preConfirm: (otp) => {
                return Api.SetLockFolder(folder.id, !folder.isHasToken, otp)
                    .then(response => {
                        if (!response.success) {
                            throw new Error(response.message ? response.message : `${t('message.actionError')}`)
                        }
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `${t('message.errorExcution')}: ${error}`
                        )
                        const inputSwal = document.getElementById('inputSwal') as HTMLInputElement;
                        inputSwal.value = "";
                    })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                toast.success(`${t('message.actionSuccess')}`)
                lstFolder.forEach((x, i) => {
                    if (x.id === folder.id) {
                        loadDataFolder(i);
                    }
                })
            }
        })
    }


    //-------------------------------FILE----------------------------------------------//

    //Load list file
    const handleClickFolderTreeItem = (folder: FolderModel, isShowFile: boolean = true) => {
        setIsValidOTP(false);
        setIsLoadData(true);
        setSelectedFileIndex(-1);

        if (isShowFile === false) {
            setIsLoadData(false);
            return;
        }

        if (folder.isHasToken) {
            Swal.fire({
                title: `${t('thisIsTheLockedFolder')} ${t('useYubikeyToAccess')}`,
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off',
                    id: "inputSwal"
                },
                showCancelButton: true,
                confirmButtonText: `${t('confirm')}`,
                showLoaderOnConfirm: true,
                cancelButtonText: `${t('cancel')}`,
                preConfirm: (otp) => {
                    return Api.GetFileByFolder(folder.id.toString(), otp).then((response) => {
                        if (!response.success) {
                            throw new Error(response.message ? response.message : `${t('message.actionError')}`)
                        }
                        setSelectedFiles(response.data);
                        setIsLoadData(false);
                        setIsValidOTP(true);
                        return response;
                    })
                        .catch(error => {
                            Swal.showValidationMessage(
                                `${t('message.errorExcution')}: ${error}`
                            )
                            const inputSwal = document.getElementById('inputSwal') as HTMLInputElement;
                            inputSwal.value = "";
                            setIsLoadData(false);
                        })
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    // toast.success(`${t('message.actionSuccess')}`)
                    setSelectedFolder(folder)
                } else {
                    setSelectedFolder(folder)
                    setSelectedFiles([]);
                }
                setIsLoadData(false);
            })
        } else {
            setSelectedFolder(folder)
            Api.GetFileByFolder(folder.id.toString(), "").then((r) => {

                if (!r.success) {
                    toast.error(`${t('message.actionError')}`);
                }

                setSelectedFiles(r.data);
                setIsValidOTP(true);
                setIsLoadData(false);

            }).catch(error => {
                toast.error(`${t('message.errorExcution')}`);
                console.error(error);
            });
        }
    }

    //Upload file
    const [modalUpload, setModalUpload] = useState({
        reload: 1,
        show: false,
    })
    const [currentFilesUploads, setCurrentFilesUploads] = useState<IFileUploadModel[]>([]);

    const handleOpenModalUpload = () => {
        if (selectedFolder) {
            setModalUpload({
                show: true,
                reload: modalUpload.reload + 1,
            })
        }
    }

    function updateFileLists(sourceList: IFileInfo[], newList: IFileInfo[]): IFileInfo[] {
        return newList.reduce((mergedList: IFileInfo[], newItem: IFileInfo) => {
            const existingItemIndex = mergedList.findIndex(item => item.id === newItem.id);

            if (existingItemIndex !== -1) {
                mergedList[existingItemIndex] = newItem; // Cập nhật đối tượng đã tồn tại
            } else {
                mergedList.push(newItem); // Thêm đối tượng mới
            }

            return mergedList;
        }, [...sourceList]);
    }

    const handleOnSaveUpload = () => {
        upload(currentFilesUploads.map(f => f.file as File));
    }

    const handleCloseModalUpload = () => {
        setModalUpload({ ...modalUpload, show: false })
    }

    const handleChangeFileUploadInModal = (files: IFileUploadModel[]) => {
        setCurrentFilesUploads(files);
    }

    const upload = (files: File[]) => {
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('formFiles', files[i])
        }

        ShowLoading();

        Api.Upload(formData, selectedFolder?.id.toString() || '-1').then(r => {
            if (!r.success) {
                toast.error(`${t('message.actionError')}`);
            }

            setSelectedFiles(updateFileLists(selectedFiles, r.data.listSucceedResult))
            // setIsValidOTP(true);
            // setIsLoadData(false);

        }).catch(error => {
            toast.error(`${t('message.errorExcution')}`);
            console.error(error);
        }).finally(() => {
            HidenLoading();
            handleCloseModalUpload();
        });
    }

    //Drag file
    const [isOnDrag, setIsOnDrag] = useState(false);

    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        setIsOnDrag(true);
    }

    const handleDragEnd = () => {
        setIsOnDrag(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        upload(fileListToArray(e.dataTransfer.files) || []);
        setIsOnDrag(false);
    };


    //Delete file
    const handleDeleteFiles = (files: IFileInfo[]) => {
        const ids = files.map(item => item?.id?.toString()) as string[] || [];

        Swal.fire({
            title: `${t('message.DeleteConfirms')}`,
            text: `${t('message.DeleteWarning')}`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: `${t('cancel')}`,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `${t('confirm')}`
        }).then((result) => {
            if (result.isConfirmed) {
                Api.DeleteFile(ids).then((r) => {
                    if (r.success) {
                        if (selectedFolder) {
                            const updatedData = selectedFiles.filter(item => !ids.includes(item?.id?.toString() || '-1'));
                            setSelectedFiles(updatedData);
                        }
                        Swal.fire(
                            `${t('deleted')}`,
                            `${t('fileDeleted')}`,
                            'success'
                        )
                    } else {
                        Swal.fire(
                            `${t('message.errorExcution')}`,
                            `${t('fileNotDeleted')}`,
                            'error'
                        )
                    }
                }).catch(() => {
                    Swal.fire(
                        `${t('message.errorExcution')}`,
                        `${t('fileNotDeleted')}`,
                        'error'
                    )
                });
            }
        });
    }


    //View file
    const [dialogViewFileOpen, setDialogViewFileOpen] = useState(false);

    const handleOpenDialogViewFile = () => {
        setDialogViewFileOpen(true);
    };

    const handleCloseDialogViewFile = () => {
        setDialogViewFileOpen(false);
        setSelectedFileIndex(- 1);

    };

    const handleViewFile = (rowData: any) => {
        setSelectedFileIndex(rowData.index - 1);
        handleOpenDialogViewFile();
    }



    //---------------------------------RENDER----------------------------------------------//
    return (
        <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12 }}>
            {/* FOLDER LIST */}
            <Grid item xs={12} sm={5} md={2}>
                <TreeViewFolder data={lstFolder} handleClickFolderTreeItem={handleClickFolderTreeItem} handleClickDeleteFolderTreeItem={handleClickDeleteFolderTreeItem} handleClickAddFolderTreeItem={handleClickAddFolderTreeItem} handleClickClockFolderTreeItem={handleClickClockFolderTreeItem} />
            </Grid>

            {/* FILE LIST */}
            <Grid item xs={12} sm={7} md={10} className="unSelectable"
                onDrop={handleDrop}
                onDragOver={handleDrag}
                onDragLeave={handleDragEnd}
                sx={{ position: 'relative' }}
            >
                {
                    isOnDrag
                    &&
                    <div style={{
                        width: '100%', height: '100%', backgroundColor: 'gray', opacity: 0.6,
                        position: 'absolute', zIndex: 999999, alignItems: 'center', display: 'flex'
                    }}>
                        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-white-300 border-none rounded-lg cursor-pointer bg-white-50 light:hover:bg-bray-800 light:bg-white-700 hover:bg-white-100 light:border-white-600 light:hover:border-white-500 light:hover:bg-white-600">
                            <div className="animate-bounce flex flex-col items-center justify-center pt-5 pb-6">
                                <svg className="w-25 h-25 mb-4 text-white light:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                    <path stroke="currentColor" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                </svg>
                                <p className="mb-2 text-lg text-white light:text-white"><span className="font-semibold">
                                    {t('dropFilesHere')}</span>
                                </p>
                                {/* <p className="text-xs text-gray-500 light:text-gray-400">SVG, PNG, JPG or GIF</p> */}
                            </div>
                        </label>
                    </div>
                }
                <MaterialTable
                    title={selectedFolder?.path}
                    isLoading={isLoadData}
                    columns={[
                        { title: '#', field: 'index' },
                        { title: t('name'), field: 'fileName' },
                        {
                            title: t('size'),
                            field: 'size',
                            render: (rowData: any) => {
                                return (rowData.size ? (rowData.size / (1024 * 1024)).toFixed(2) : 0) + ' MB';
                            }
                        },
                        { title: t('contentType'), field: 'contentType' },
                        { title: t('updatedDate'), field: 'updatedDate', render: (data) => <>{getDateTimeString(new Date(data?.updatedDate))}</> },
                    ]}
                    data={selectedFiles ? selectedFiles.map((item: any, index: number) => ({
                        ...item, index: index + 1,
                    })) : []}
                    actions={[
                        {
                            hidden: !selectedFolder?.isDelete,
                            icon: () => <DeleteForever />,
                            tooltip: t('delete'),
                            onClick: (event: any, data: any) => { handleDeleteFiles(data) },
                        },
                        {
                            hidden: ((!selectedFolder?.isUpload) || !isValidOTP),
                            icon: () => <Add />,
                            tooltip: t('add'),
                            isFreeAction: true,
                            onClick: (event: any, data: any) => { handleOpenModalUpload() }
                        }
                    ]}
                    onRowDoubleClick={(event, rowData, togglePanel) => {
                        handleViewFile(rowData);
                    }}
                    options={{
                        paging: true,
                        pageSize: 10,
                        actionsColumnIndex: -1,
                        search: true,
                        grouping: true,
                        selection: true
                    }}
                />

            </Grid>

            {/* MODAL UPLOAD */}
            <Dialog
                open={modalUpload.show}
                onClose={handleCloseModalUpload}
                maxWidth={'md'}
                fullWidth
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {t('fileUpload')}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseModalUpload}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container spacing={0.5}>
                        <UploadFile isHideInputs={true} onChange={handleChangeFileUploadInModal} />
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse' }}>
                    <Button onClick={() => handleOnSaveUpload()} ><Save />&nbsp;{t('save')}</Button>
                </DialogActions>
            </Dialog >

            {/* MODAL VIEW FILE */}
            <div>
                <MediaViewer
                    currentIndex={selectedFileIndex}
                    files={(selectedFiles && selectedFiles.length > 0) ? selectedFiles : []}
                    open={dialogViewFileOpen}
                    onClose={handleCloseDialogViewFile}
                    isLoadPresignedURL={true}
                />
            </div>
        </Grid>
    )
}

export default PrivateFilesPage