import MaterialTable, { Column } from "@material-table/core";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, TextField, Autocomplete, FormControlLabel } from "@mui/material"
import { t } from "i18next";
import { useEffect } from "react";
import { useState } from "react"

import * as ApplicationApi from 'src/apis/permission/apiApplication'
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectEmployeeState } from "src/store/slices/commonSlice";
import { Transition } from "src/commons/handle/transitionModal";
import InputText from "src/components/inputs/InputText";
import { IApplicationUser } from "src/interfaces/permission/IApplicationUser";
import _ from "lodash";
import { HidenLoading, ShowLoading } from "src/components/loading-page/LoadingPage";

const SaveApplication = (props: any) => {
    
    const userState = useSelector(selectEmployeeState)
    const [state, setState] = useState({
        show: false,
        id: '',
        name: '',
        url: '',
        description: '',
        active: true,
        isCachePermission:true,
        isCreated: true
    })
    const [users, setUsers] = useState<IApplicationUser[]>([])
    const [selectUser, setSelectUser] = useState<any[]>([])

    useEffect(() => {
        if (userState && userState.length > 0) {
            setSelectUser(userState)
        }
    }, [userState])

    useEffect(() => {
        if (props.data.id !== state.id)
            loadUserByApplication(props.data.id)
        if (!props.data.id || props.data.id === '') {
            setState({
                show: props.show || false,
                id: '',
                name: '',
                url: '',
                description: '',
                active: true,
                isCachePermission:true,
                isCreated: true
            })
            setUsers([])
        }
        else setState({ ...state, ...props.data, show: props.show || false, })

    }, [props.reload])

    const loadUserByApplication = (appId: string) => {
        if (appId && appId !== '') {
            ShowLoading()
            ApplicationApi
                .fetchDetail(appId)
                .then((rs: any) => {
                    if (rs.success) {
                        const userIds = rs.data.users.map((u: any) => u.userId)
                        const users = selectUser.filter((user: any) => userIds.indexOf(user.employeeId) !== -1)
                        const selectFiltter = _.difference(selectUser, users)
                        const mapUsers = rs.data.users.map((x:IApplicationUser)=>{
                            const cur = users.find((u:any)=>u.employeeId === x.userId)
                            return {
                                ...x,
                                fullName:cur?.employeeName||''
                            }
                        })
                        setUsers(mapUsers)
                        setSelectUser(selectFiltter)

                        setState({
                            show: true,
                            id: props.data.id,
                            name: props.data.name,
                            url: props.data.url,
                            description: props.data.description,
                            active: props.data.active,
                            isCachePermission: props.data.isCachePermission,
                            isCreated: false
                        })
                    }
                    else {
                        toast.error(rs.message)
                    }
                }).finally(()=>HidenLoading())
        }
    }
    //handle
    const handleOnchange = (value: any, name: string, state: any, setState: any) => {
        setState({ ...state, [name]: value });
    }

    const handleOnClickCheckbox = (e: any, state: any, setState: any) => {
        const name = e.target.id || e.target.name;
        const value = e.target.checked ;
        setState({ ...state, [name]: value });
    }

    const handleClose = () => {
        setState({ ...state, show: false })
    }

    const handleSave = () => {
        const element = document.querySelector('[data-testid="check"]');
        if (element instanceof HTMLElement) {
            element.click()
            toast.warning('Đã lưu lại cấu hình user. Vui lòng bấm lưu lại "Ứng dụng"')
            return
        }
        // validate dữ liệu
        if (state.id === '' || state.id === '') {
            toast.warning(t('common.required'))
            return
        }
        // kiểm tra dữ liệu có bị trung user không?
        let userArr: any[] = []
        let isStop = false
        users.forEach((obj: any) => {
            if (userArr.indexOf(obj.userId) === -1) {
                userArr.push(obj.userId)
            }
            else {
                isStop = true
                toast.warning(`${t('common.duplicate')} : ${obj.userId} - ${obj.fullName}`)
            }
            delete obj["user"]
        })
        if (isStop)
            return false;
       
        const modelSave = {
            ...state,
            users: users,
            active: state.active ? 1:0
        }
        props.onSave(modelSave)
    }

    const handleOnAddUser = async (newData: any) => {
        if (Array.isArray(newData.user)) {
            const userAdds = newData.user.map((obj: any) => {
                return {
                    userId: obj.employeeId,
                    fullName: obj.employeeName || '',
                    isEditPermission: newData.isEditPermission || newData.isAdmin,
                    isEditGroup: newData.isEditGroup || newData.isAdmin ,
                    isEditUser: newData.isEditUser || newData.isAdmin,
                    isAdmin: newData.isAdmin ,
                }
            })
            setUsers([...userAdds, ...users])
            //filter select
            const userIds = newData.user.map((u: any) => u.userId)
            const selectFiltter = selectUser.filter((user: any) => userIds.indexOf(user.userId) === -1)
            setSelectUser(selectFiltter)
        }
    }
    const handleOnUpdateUser = async (newData: any, oldData: any) => {
        const dataUpdate = [...users];
        const index = oldData.tableData.id;
        debugger
        dataUpdate[index] = {
            ...newData,
            userId: newData.user.employeeId,
            fullName: newData.user.employeeName || '',
            isEditPermission: newData.isEditPermission || newData.isAdmin ,
            isEditGroup: newData.isEditGroup || newData.isAdmin ,
            isEditUser: newData.isEditUser || newData.isAdmin ,
            isAdmin: newData.isAdmin ,
        };
        setUsers([...dataUpdate]);
    }
    const handleOnDeleteUser = async (oldData: any) => {
        const dataDelete = [...users];
        const index = dataDelete.findIndex((obj: any) => obj.userId === oldData.userId);
        if (index !== -1)
            setUsers([...users.slice(0, index), ...users.slice(index + 1),])

        setSelectUser([{ userId: oldData.userId, fullName: oldData.fullName }, ...selectUser])
    }

    const handleOnChangeCheckbox = (e: any, userId: string) => {
        const name = e.target.id || e.target.name;
        const value = e.target.checked;
        const dataUpdate = [...users];
        const index = dataUpdate.findIndex((option: any) => option.userId === userId)

        dataUpdate[index] = {
            ...dataUpdate[index],
            [name]: value
        };
        setUsers([...dataUpdate]);
    }

    const columns: Column<object>[] =
        [
            {
                title: t('user'),
                field: 'user',
                width: 300,
                render: (rowData: any) => (<span>{`${rowData.userId} - ${rowData.fullName}`}</span>),
                editComponent: (props: any) => {
                    return (props.rowData.userId ?
                        (<Autocomplete
                            options={selectUser}
                            onChange={(e, item) => props.onChange(item)}
                            getOptionLabel={(obj: any) => `${obj.employeeId} - ${obj.employeeName}`}
                            value={props.rowData.user ? props.rowData.user : userState?.data.find((option: any) => option.employeeId === props.rowData.userId)}
                            renderInput={params => <TextField {...params} label={t('account')} />
                            } />)
                        : (<Autocomplete
                            options={selectUser}
                            multiple
                            disableCloseOnSelect
                            onChange={(e, item) => props.onChange(item)}
                            getOptionLabel={(obj: any) => `${obj.employeeId} - ${obj.employeeName}`}
                            renderInput={params => <TextField {...params} label={t('account')} />
                            } />
                        )
                    );
                }
            },
            {
                title: t('application.isAdmin'),
                type: 'boolean',
                field: 'isAdmin',
                width: 5,
                render: (rowdata: any) => (
                    <Checkbox name="isAdmin"
                        checked={rowdata.isAdmin}
                        onChange={(e) => handleOnChangeCheckbox(e, rowdata.userId)} />
                )
            },
            {
                title: t('application.isEditUser'),
                field: 'isEditUser',
                type: 'boolean',
                width: 5,
                render: (rowdata: any) => (
                    <Checkbox name="isEditUser"
                        checked={rowdata.isEditUser}
                        onChange={(e) => handleOnChangeCheckbox(e, rowdata.userId)} />
                )
            },
            {
                title: t('application.isEditGroup'),
                type: 'boolean',
                field: 'isEditGroup',
                width: 5,
                render: (rowdata: any) => (
                    <Checkbox name="isEditGroup"
                        checked={rowdata.isEditGroup}
                        onChange={(e) => handleOnChangeCheckbox(e, rowdata.userId)} />
                )
            },
            {
                title: t('application.isEditPermission'),
                type: 'boolean',
                field: 'isEditPermission',
                width: 5,
                render: (rowdata: any) => (
                    <Checkbox name="isEditPermission"
                        checked={rowdata.isEditPermission}
                        onChange={(e) => handleOnChangeCheckbox(e, rowdata.userId)} />
                )
            },]


    return (
        <Dialog
            open={state.show}
            TransitionComponent={Transition}

            fullWidth={true}
            scroll='paper'
            maxWidth='lg'
            onClose={handleClose}
        >
            <DialogTitle>{t('application.editModel')}</DialogTitle>
            <DialogContent className="mt-2">
                <Grid container spacing={2} >
                    <Grid item container spacing={2} >
                        <Grid item xs={6}>
                            <InputText
                                required
                                label={t('id')}
                                onChange={(e: any) => handleOnchange(e.target.value, 'id', state, setState)}
                                value={state.id}
                                placeholder={t('pleaseEnter') || ''}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputText
                                required
                                label={t('name')}
                                onChange={(e: any) => handleOnchange(e.target.value, 'name', state, setState)}
                                value={state.name}
                                placeholder={t('pleaseEnter') || ''}
                            />
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <InputText
                                label={t('url')}
                                onChange={(e: any) => handleOnchange(e.target.value, 'url', state, setState)}
                                value={state.url}
                                placeholder={t('pleaseEnter') || ''}
                            />
                        </Grid>
                        <Grid item md={2} xs={6} className="content-end">
                            <FormControlLabel control={<Checkbox
                                checked={state.active}
                                name="active"
                                onChange={(e) => handleOnClickCheckbox(e, state, setState)} />}
                                label={t('active')} />
                        </Grid>
                        <Grid item md={2} xs={6}  className="content-end">
                            <FormControlLabel control={<Checkbox
                                checked={state.isCachePermission}
                                name="isCachePermission"
                                onChange={(e) => handleOnClickCheckbox(e, state, setState)} />}
                                label={t('isCachePermission')} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputText
                                rows={2}
                                onChange={(e: any) => handleOnchange(e.target.value, 'description', state, setState)}
                                value={state.description}
                                label={t('description')}
                                placeholder={t('pleaseEnter') || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item md={12}>
                            <MaterialTable
                                title={t('application.listUser')}
                                columns={columns}
                                data={users}
                                editable={{
                                    onRowAdd: handleOnAddUser,
                                    onRowUpdate: handleOnUpdateUser,
                                    onRowDelete: handleOnDeleteUser,
                                }}
                                options={{
                                    paging: true,
                                    pageSize: 10,
                                    actionsColumnIndex: 0
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSave}>{t('save')}</Button>
                <Button onClick={handleClose}>{t('close')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SaveApplication