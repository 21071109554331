/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDepartment, getEmployee } from "src/apis/apiCommon";
import { IDepartment, IEmployee } from "src/interfaces/IOrg";

export interface ICommonState {
  userDepartment: IDepartment[];
  employees: IEmployee[];
}

const initialState: ICommonState = {
  userDepartment: [],
  employees: [],
};

export const fetchDepartment = createAsyncThunk("fetchDepartment", async () => {
  const res = await getDepartment(true);
  return res;
});

export const fetchGetEmployees = createAsyncThunk(
  "fetchGetEmployees",
  async () => {
    const res = await getEmployee();
    return res;
  }
);

export const commonsSlice = createSlice({
  name: "commons",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchDepartment.fulfilled, (state: any, action: any) => {
        state.userDepartment = action.payload.data;
      })
      .addCase(fetchGetEmployees.fulfilled, (state: any, action: any) => {
        state.employees = action.payload.data;
      });
  },
});

export const selectUserDepartmentState = (state: any) =>
  state.commons.userDepartment || [];

export const selectEmployeeState = (state: any) =>
  state.commons.employees || [];

export default commonsSlice.reducer;
