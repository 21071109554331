import { IMenu } from "src/interfaces/IMenu";
import api, { ResultObject } from "../base/base";

const baseUrl = `${process.env.REACT_APP_API_COMMON}/api/menu`;

export const fetchMenu = async (): Promise<ResultObject> => {
  const result = await api
    .get(baseUrl)
    .then((response: any) => response?.data);
  return result;
};

export const fetchDetail = async (id:string =''): Promise<ResultObject> => {
  const result = await api
    .get<ResultObject>(baseUrl+'/detail/'+id)
    .then((response: any) => response.data);
  return result;
};

export const createMenu = async (
  obj: IMenu
): Promise<any> => {
  const result = await api
    .post(`${baseUrl}`, obj)
    .then((response: any) => response?.data);
  return result;
};

export const updateMenu = async (
    obj: IMenu
): Promise<any> => {
  const result = await api
    .put(`${baseUrl}`, obj)
    .then((response: any) => response?.data);
  return result;
};

export const deleteMenu = async (
    id:string
): Promise<any> => {
  const result = await api
    .delete(`${baseUrl}/${id}`)
    .then((response: any) => response?.data);
  return result;
};
