import { Close } from "@mui/icons-material"
import { AppBar, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Typography } from "@mui/material"
import { useFormik, Form } from "formik"
import { min, set } from "lodash"
import { Suspense, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { createDocumentType, deleteDocumentType, updateDocumentType } from "src/apis/apiDocument"
import SelectDocumentTypeResetNumber from "src/commons/enums/resetNumber"
import Button from "src/components/buttons/Button"
import InputRadio from "src/components/inputs/InputRadio"
import InputText from "src/components/inputs/InputText"
import OrgSelect from "src/components/org/OrgSelect"
import CustomSelect from "src/components/selects/select"
import { IDocumentType } from "src/interfaces/IDocumentType"
import { IHtmlInput } from "src/interfaces/IHtmlInput"
import { IDepartment } from "src/interfaces/IOrg"
import { useDocumentType, useUserDepartment } from "src/store/hook"
import { selectUserDepartmentState } from "src/store/slices/commonSlice"
import { addDocumentType, editDocumentType, removeDocumentType, selectDocumentTypeState } from "src/store/slices/documentTypeSlice"
import { useAppDispatch } from "src/store/store"
import Questions from "src/views/questions"
import * as yup from 'yup'

const DocumentType = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    useUserDepartment()
    useDocumentType(true)
    const documentTypeState = useSelector(selectDocumentTypeState)
    const userDepartmentState = useSelector(selectUserDepartmentState)
    const [openModal, setOpenModal] = useState(false)
    const [data, setData] = useState<IDocumentType[]>([])

    useEffect(() => {
        setData(documentTypeState.data || [])
    }, [documentTypeState.reload])

    const handleClose = () => {
        formik.setTouched({}, false);
        formik.setValues({
            id: 0,
            name: '',
            description: '',
            format: '',
            departmentId: '',
            resetNumber: 0,
            currentNumber: 0,
            active: true,
            reminderTime: 0,
            isDate: true,
            isAutoSerial: true,
            extendField: ''
        })
        setOpenModal(false)
    }
    const handleOpenNew = () => {
        setOpenModal(true)
    }

    const handleDelete = (id: number) => {
        if (window.confirm(t('message.actionDelete'))) {
            deleteDocumentType(id).then(rs => {
                if (rs.success) {
                    const mapId: any = id
                    dispatch(removeDocumentType(mapId))
                    toast.success(t('message.actionSuccess'))
                }
                else toast.warning(t('message.actionError'))
            })
        }
    }
    const handleOnChangeQuestion = (data: IHtmlInput[]) => {
        const strData = JSON.stringify(data)
        formik.setValues({
            ...formik.values,
            extendField: strData
        })
    }
    const handleEdit = (docType: IDocumentType) => {
        formik.setValues({
            id: docType.id,
            name: docType.name,
            description: docType.description,
            format: docType.format,
            departmentId: docType.departmentId,
            resetNumber: docType.resetNumber,
            currentNumber: docType.currentNumber,
            active: docType.active === 1,
            reminderTime: docType.reminderTime || 0,
            isDate: docType.isDate === true,
            isAutoSerial: docType.isAutoSerial === true,
            extendField: docType.extendField || ''
        })
        setOpenModal(true)
    }
    const validationSchema = yup.object({
        name: yup.string().required(t('isRequired')),
        format: yup.string().required(t('isRequired')).matches(/^[a-zA-Z0-9/\-{}]*$/, t('message.IncorrectFormatDoesNotContain')),
        currentNumber: yup.string().matches(/^(([0-9]|[0-9].[1-9])\d*)$/, t('message.valueGreaterThanZero')),
        reminderTime: yup.string().matches(/^(([0-9]|[0-9].[1-9])\d*)$/, t('message.valueGreaterThanZero'))
    });

    const formik = useFormik({
        initialValues: {
            id: 0,
            name: '',
            description: '',
            format: '',
            departmentId: '',
            resetNumber: 0,
            currentNumber: 0,
            active: true,
            reminderTime: 0,
            isDate: true,
            isAutoSerial: true,
            extendField: ''
        },
        validationSchema,
        onSubmit: (values: any) => {
            values.resetNumber = parseInt(values.resetNumber) || 0

            if (values.id > 0) {//edit
                updateDocumentType(values).then(rs => {
                    if (rs.success) {
                        values.active = values.active === true ? 1 : 0
                        dispatch(editDocumentType(values))
                        handleClose()
                    }
                    else toast.warning(t('message.actionError'))
                })
            }
            else { // add
                createDocumentType(values).then(rs => {
                    if (rs.success) {
                        dispatch(addDocumentType(rs.data))
                        handleClose()
                    }
                    else toast.warning(t('message.actionError'))
                })
            }
        },
    });

    return (
        <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            <div className="p-6 pb-0 mb-0 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                <h6 className="font-bold leading-5 text-gray-900">{t('documentType')}</h6>
                <div className="float-right">
                    <button onClick={handleOpenNew} className="mr-2  px-3 py-3 bg-gradient-to-tl from-purple-700 to-pink-500 font-bold 
                    text-center text-white from-purple-700 to-pink-500 uppercase align-middle 
                    transition-all ease-in border-0 rounded-lg  shadow-soft-md  leading-pro text-size-xs hover:shadow-soft-2xl hover:scale-102 ">
                        <i className="fa fa-plus mr-2"></i>
                        {t('add')}
                    </button>
                </div>
            </div>
            <div className="flex-auto px-0 pt-0 pb-2 overflow-auto h-[76svh]">
                <div className="p-0 overflow-x-visible ps">
                    <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                        <thead className="align-bottom">
                            <tr>
                                <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('serialFormat')}</th>
                                <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('name')}</th>
                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70 max-w-[300px]">{t('description')}</th>
                                <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('department')}</th>
                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('serialResetNumber')}</th>
                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('documentIsAutoSerial')}</th>
                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('documentIsDate')}</th>
                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('active')}</th>
                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.length > 0 ? data.map((d: IDocumentType) => (
                                <tr key={d.id}>
                                    <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                        {d.format}
                                    </td>
                                    <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent max-w-[300px]">
                                        {d.name}
                                    </td>
                                    <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent truncate max-w-[300px]">
                                        {d.description}
                                    </td>
                                    <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent max-w-[300px]">
                                        {userDepartmentState?.find((x: IDepartment) => x.code === d.departmentId)?.name}
                                    </td>
                                    <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                        {t("documentTypeResetNumber." + d.resetNumber)}
                                    </td>
                                    <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                        {t(d.isAutoSerial ? 'yes' : 'no')}
                                    </td>
                                    <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                        {t(d.isDate ? 'yes' : 'no')}
                                    </td>
                                    <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                        {t('active' + d.active)}
                                    </td>
                                    <td className="p-2 text-sm leading-normal text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                        <Button label={t('edit')} onClick={() => handleEdit(d)} color="primary" />
                                        <Button label={t('delete')} onClick={() => handleDelete(d.id)} color="error" />
                                    </td>
                                </tr>
                            ))
                                : (
                                    <tr><td colSpan={6} className="text-center py-3 italic">{t('message.noData')}</td></tr>
                                )}
                        </tbody>
                    </table>
                </div>
            </div>
            <Suspense>
                <Dialog
                    disableEscapeKeyDown
                    open={openModal}
                    className="border-0 rounded-xl"
                    onClose={(event, reason) => (reason !== 'backdropClick' && reason !== 'escapeKeyDown') && handleClose}
                    fullWidth
                    maxWidth='xl'
                >
                    <AppBar sx={{ position: 'relative' }} className="bg-gradient-to-tl from-purple-700 to-pink-500">
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {t('addEditDocumentType')}
                            </Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <Close />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent >
                        <form onSubmit={formik.handleSubmit} className="flex flex-wrap ">
                            <div className="px-3 md:w-6/12">
                                <div className="w-full">
                                    <InputText
                                        label={t('name')}
                                        name="name"
                                        required
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.name) && formik?.errors?.name}
                                    />
                                </div>
                                <div className="w-full mt-2">
                                    <CustomSelect label={t('serialResetNumber')}
                                        name="resetNumber"
                                        data={SelectDocumentTypeResetNumber.map((s) => { return { value: s.value, label: t("documentTypeResetNumber." + s.label) } })}
                                        value={formik.values.resetNumber}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="px-3 md:w-6/12">
                                <InputText label={t('serialFormat')}
                                    required
                                    name="format"
                                    value={formik.values.format}
                                    onChange={formik.handleChange}
                                    description={t('serialDescription')}
                                    error={Boolean(formik.touched.format) && formik?.errors?.format}
                                />
                            </div>
                            <div className="md:w-6/12 px-2 mt-2">
                                <OrgSelect label={t('department')}
                                    name="departmentId"
                                    onChange={formik.handleChange} />
                            </div>
                            <div className="md:w-3/12 mt-2 pl-2">
                                {formik.values.isAutoSerial &&
                                    <InputText label={t('documentTypeCurrentNumber')}
                                        name="currentNumber"
                                        type="number"
                                        inputField={{
                                            min: '0',
                                            step: '1'
                                        }}
                                        value={formik.values.currentNumber}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.touched.currentNumber) && formik?.errors?.currentNumber}
                                    />}
                            </div>
                            <div className="md:w-3/12 pl-2 mt-2">
                                {formik.values.isDate && <InputText label={t('reminderTime')}
                                    type="number"
                                    name="reminderTime"
                                    inputField={{
                                        min: '0',
                                        step: '0.5'
                                    }}
                                    value={formik.values.reminderTime}
                                    error={Boolean(formik.touched.reminderTime) && formik?.errors?.reminderTime}
                                    onChange={formik.handleChange} />
                                }
                            </div>

                            <div className="flex flex-wrap w-full">
                                <div className="px-3 mt-2 md:w-4/12" style={{ paddingTop: '27px' }}>
                                    <InputRadio label={t('active')}
                                        name="active"
                                        value={formik.values.active}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="px-3 mt-2 md:w-4/12" style={{ paddingTop: '27px' }}>
                                    <InputRadio label={t('documentIsAutoSerial')}
                                        name="isAutoSerial"
                                        value={formik.values.isAutoSerial}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="px-3 mt-2 md:w-4/12" style={{ paddingTop: '27px' }}>
                                    <InputRadio label={t('documentIsDate')}
                                        name="isDate"

                                        value={formik.values.isDate}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mt-2 w-full">
                                <Questions data={formik.values?.extendField ? JSON.parse(formik.values.extendField + '') : []} onChange={handleOnChangeQuestion} />
                            </div>
                            <div className="px-3 mt-2 w-full">
                                <InputText label={t('description')}
                                    name="description"
                                    rows={3}
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                />
                            </div>

                            <div className="mt-2 w-full">
                                <div className="float-right">
                                    <Button label={t('save')} type="submit" color="primary" />
                                    <Button label={t('close')} onClick={handleClose} color="default" />
                                </div>
                            </div>
                        </form>
                    </DialogContent>

                </Dialog>
            </Suspense>
        </div>
    )
}

export default DocumentType