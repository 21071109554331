import { IUserProfile } from "src/interfaces/IUserProfile";
import api, { ApiResponse, ResultObject } from "./base/base";
import { IRequestCache, IResponseCache } from "src/interfaces/ICache";

const baseURL = `${process.env.REACT_APP_API_COMMON}/api`;

// Menu ///////////////////////////
export const getMenu = async (objMenu: any): Promise<any> => {
  const result = await api
    .get(`${baseURL}/menu?${new URLSearchParams(objMenu).toString()}`)
    .then((response: any) => response?.data);
  return result;
};
export const createdMenu = async (data: any): Promise<ResultObject> => {
  const result = await api
    .post<ResultObject>(`${baseURL}/menu`, data)
    .then((response: any) => response.data);
  return result;
};

export const updatedMenu = async (data: any): Promise<ResultObject> => {
  const result = await api
    .put<ResultObject>(`${baseURL}/menu/${data.id}`, data)
    .then((response: any) => response.data);
  return result;
};
export const deletedMenu = async (id: any): Promise<ResultObject> => {
  const result = await api
    .delete<ResultObject>(`${baseURL}/menu/${id}`)
    .then((response: any) => response.data);
  return result;
};
// end Menu ////////////////////////////////////////
export const getDepartment = async (
  isGetEmployees: boolean
): Promise<ResultObject> => {
  const result = await api
    .get(baseURL + "/Org/GetDepartments?isGetEmployees=" + isGetEmployees)
    .then((response: any) => response?.data);
  return result;
};

export const getEmployee = async (): Promise<ResultObject> => {
  const result = await api
    .get(baseURL + "/Org/GetEmployees")
    .then((response: any) => response?.data);
  return result;
};

//UserProfile
export const searchUserProfile = async (
  userIds: string[]
): Promise<ResultObject> => {
  const result = await api
    .post(`${baseURL}/UserProfile/Search`, userIds)
    .then((response: any) => response?.data);
  return result;
};

export const getUserProfile = async (): Promise<ResultObject> => {
  const result = await api
    .get(`${baseURL}/UserProfile`)
    .then((response: any) => response?.data);
  return result;
};

export const createUserProfile = async (
  model: IUserProfile
): Promise<ResultObject> => {
  const result = await api
    .post(`${baseURL}/UserProfile`, model)
    .then((response: any) => response?.data);
  return result;
};

export const updateUserProfile = async (
  model: IUserProfile
): Promise<ResultObject> => {
  const result = await api
    .put(`${baseURL}/UserProfile`, model)
    .then((response: any) => response?.data);
  return result;
};

export const deleteUserProfile = async (): Promise<ResultObject> => {
  const result = await api
    .delete(`${baseURL}/UserProfile`)
    .then((response: any) => response?.data);
  return result;
};

//Yubikey
export const verifyOTP = async (otp: string): Promise<ResultObject> => {
  const result = await api
    .post(`${baseURL}/Yubikey/VerifyOTP`, otp)
    .then((response: any) => response?.data);
  return result;
};

//Cache
export const getOrSetCache = async (
  model: IRequestCache
): Promise<ApiResponse<IResponseCache>> => {
  const result = await api
    .post(`${baseURL}/Cache`, model)
    .then((response: any) => response?.data);
  return result;
};

export const clearCache = async (): Promise<ApiResponse<IResponseCache>> => {
  const result = await api
    .delete(`${baseURL}/Cache`)
    .then((response: any) => response?.data);
  return result;
};
////////////////////////////////////////////////Category////////////////////////////////////////////

export interface IModel {
  id: string | "";
  name: string | "";
}

export interface IDefaultState {
  datas: IModel[];
}

export const initialState: IDefaultState = {
  datas: [],
};

export const initCategoryModel: IModel = {
  id: "0",
  name: "",
};

export const fetchProduct = async (): Promise<ResultObject> => {
  const result = await api
    .get(baseURL + "/category/get-list/product")
    .then((response: any) => response?.data);
  return {
    ...result,
    data: JSON.parse(result.data || ""),
  };
};

export const fetchSpaceUse = async (): Promise<ResultObject> => {
  const result = await api
    .get<ResultObject>(baseURL + "/category/get-list/spaceuse")
    .then((response: any) => response.data);
  return {
    ...result,
    data: JSON.parse(result.data || ""),
  };
};

export const fetchSuite = async (): Promise<ResultObject> => {
  const result = await api
    .get<ResultObject>(baseURL + "/category/get-list/suite")
    .then((response: any) => response.data);
  return {
    ...result,
    data: JSON.parse(result.data || ""),
  };
};

export const fetchPattern = async (): Promise<ResultObject> => {
  const result = await api
    .get<ResultObject>(baseURL + "/category/get-list/pattern")
    .then((response: any) => response.data);
  return {
    ...result,
    data: JSON.parse(result.data || ""),
  };
};

export const fetchKind = async (): Promise<ResultObject> => {
  const result = await api
    .get<ResultObject>(baseURL + "/category/get-list/kind")
    .then((response: any) => response.data);
  return {
    ...result,
    data: JSON.parse(result.data || ""),
  };
};

export const fetchBrand = async (): Promise<ResultObject> => {
  const result = await api
    .get<ResultObject>(baseURL + "/category/get-list/brand")
    .then((response: any) => response.data);

  return {
    ...result,
    data: JSON.parse(result.data || ""),
  };
};
