/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

export interface INotificationState {
    reload: number;
    newNotiCount: number;
}

const initialState: INotificationState = {
    reload: 0,
    newNotiCount: 0
};


export const notificationSlice = createSlice({
    name: "notificationSlice",
    initialState,
    reducers: {
        reloadNotification: (state) => {
            state.reload = state.reload + 1 || 1;
        },
        setNotiCount: (state, action) => {
            state.newNotiCount = action.payload;
        },
    }
});
export const { reloadNotification, setNotiCount } = notificationSlice.actions;

export const selectNotificationState = (state: any) =>
    state.notification || initialState;

export default notificationSlice.reducer;
