import { IUploadFile } from "src/interfaces/IFile";
import removeDiacritics from "./removeDiacritics";
import { removeSpecialCharacters } from "./stringHelper";

export const autoCreateFolder = () => {
    const date = new Date();
    const year: number = date.getFullYear();
    const month: number = date.getMonth() + 1;
    const day: number = date.getDate();
    const time: number = date.getTime();

    return `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}T${time}`;
}

export const convertToFileFormData = (fileModel: IUploadFile): FormData => {
    const formData = new FormData();

    // Append Files
    if (fileModel.files) {
        fileModel.files.forEach((file, index) => {
            formData.append(`files`, file);
        });
    }

    // Append BucketName
    if (fileModel.bucketName) {
        formData.append('bucketName', fileModel.bucketName);
    }

    // Append Folder
    if (fileModel.folders) {
        Object.entries(fileModel.folders).forEach(([key, value]) => {
            formData.append(`folders[${key}]`, value);
        });
    }

    // Append Notes
    if (fileModel.notes) {
        Object.entries(fileModel.notes).forEach(([key, value]) => {
            formData.append(`notes[${key}]`, value);
        });
    }

    // Append categories
    if (fileModel.categories) {
        Object.entries(fileModel.categories).forEach(([key, value]) => {
            formData.append(`categories[${key}]`, value.toString());
        });
    }

    // Append showApps
    if (fileModel.showApps) {
        Object.entries(fileModel.showApps).forEach(([key, value]) => {
            formData.append(`showApps[${key}]`, value.toString());
        });
    }

    // Append parentIds
    if (fileModel.parentIds) {
        Object.entries(fileModel.parentIds).forEach(([key, value]) => {
            formData.append(`parentIds[${key}]`, value.toString());
        });
    }

    // Append sortOrders
    if (fileModel.sortOrders) {
        Object.entries(fileModel.sortOrders).forEach(([key, value]) => {
            formData.append(`sortOrders[${key}]`, value.toString());
        });
    }

    return formData;
}

export const convertToFileFormDataWithModifiyFileName = (fileModel: IUploadFile): FormData | null => {
    if (!fileModel.fileNames || fileModel.fileNames.length === 0 || (fileModel.files.length != fileModel.fileNames?.length)) {
        return null;
    }

    const formData = new FormData();

    // Append Files
    if (fileModel.files) {
        fileModel.files.forEach((file, index) => {
            formData.append(`files`, file,
                (fileModel.fileNames && fileModel.fileNames[index]) || file.name);
        });
    }

    // Append BucketName
    if (fileModel.bucketName) {
        formData.append('bucketName', fileModel.bucketName);
    }

    // Append Folder
    if (fileModel.folders) {
        Object.entries(fileModel.folders).forEach(([key, value]) => {
            formData.append(`folders[${key}]`, value);
        });
    }

    // Append Notes
    if (fileModel.notes) {
        Object.entries(fileModel.notes).forEach(([key, value]) => {
            formData.append(`notes[${key}]`, value);
        });
    }

    // Append categories
    if (fileModel.categories) {
        Object.entries(fileModel.categories).forEach(([key, value]) => {
            formData.append(`categories[${key}]`, value.toString());
        });
    }

    // Append showApps
    if (fileModel.showApps) {
        Object.entries(fileModel.showApps).forEach(([key, value]) => {
            formData.append(`showApps[${key}]`, value.toString());
        });
    }

    // Append parentIds
    if (fileModel.parentIds) {
        Object.entries(fileModel.parentIds).forEach(([key, value]) => {
            formData.append(`parentIds[${key}]`, value.toString());
        });
    }

    // Append sortOrders
    if (fileModel.sortOrders) {
        Object.entries(fileModel.sortOrders).forEach(([key, value]) => {
            formData.append(`sortOrders[${key}]`, value.toString());
        });
    }

    return formData;
}

export const formatFileName = (fileName: string) => {
    fileName = removeSpecialCharacters(fileName.replace(' ', '-'), ['-', '.']);
    return removeDiacritics(fileName).toLowerCase();
}



export const toSlug = (str: string) => {
    // Chuyển hết sang chữ thường
    str = str.toLowerCase();

    // xóa dấu
    str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
    str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
    str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
    str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
    str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
    str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
    str = str.replace(/(đ)/g, 'd');

    // Xóa ký tự đặc biệt
    str = str.replace(/([^0-9a-z-\s])/g, '');

    // Xóa khoảng trắng thay bằng ký tự -
    str = str.replace(/(\s+)/g, '-');

    // Xóa ký tự - liên tiếp
    str = str.replace(/-+/g, '-');

    // xóa phần dự - ở đầu
    str = str.replace(/^-+/g, '');

    // xóa phần dư - ở cuối
    str = str.replace(/-+$/g, '');

    // return
    return str;
}

