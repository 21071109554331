import { useAuth } from 'react-oidc-context';
import { Grid } from '@mui/material';
import { HidenLoading, ShowLoading } from 'src/components/loading-page/LoadingPage';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from 'src/commons/helpers/set-auth-token';
import { AppDispatch, useAppDispatch } from 'src/store/store';
import { setShowChangingCurrencyWarning } from 'src/store/slices/settingSlice';
import { setAuth } from 'src/store/slices/authSlice';
import { useEmployee } from 'src/store/hook';
import { useSelector } from 'react-redux';
import { selectEmployeeState } from 'src/store/slices/commonSlice';

const CallbackPage = () => {
    useEmployee()
    const auth = useAuth();
    const navigate = useNavigate()
    const dispatch: AppDispatch = useAppDispatch();
    const employeeState = useSelector(selectEmployeeState)
    if (auth.isLoading) {
        ShowLoading()
    }

    if (auth.isAuthenticated) {
        HidenLoading()
        const token = auth?.user?.access_token || ''
        setAuthToken(token)
        const user: any = auth?.user?.profile
        if (user) {
            const curUser = employeeState.find((x: any) => x.employeeId === user.name || x.email === user.name)
            console.log('curUser', curUser);
            dispatch(setAuth({ ...user, id: curUser?.employeeId || '', departmentName: curUser?.departmentName || '' }))
            dispatch(setShowChangingCurrencyWarning(true))
            navigate('/')
        }
    }

    const ShowMessage = () => {
        ShowLoading()
        if (auth.error) {
            HidenLoading()
            return <div>Oops... {auth.error.message}</div>;
        }
        switch (auth.activeNavigator) {
            case "signinSilent":
                return <div>Signing you in...</div>;
            case "signoutRedirect":
                return <div>Signing you out...</div>;
            default:
                <div>Đang chờ đăng nhập. Waiting for login</div>
        }
        HidenLoading()
    }

    return <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
    >
        <Grid item xs={3}>
            {ShowMessage()}
        </Grid>
    </Grid>
}


export default CallbackPage