import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete, Select, MenuItem } from "@mui/material"
import { t } from "i18next";
import { Suspense, useEffect } from "react";
import { useState } from "react"

import { toast } from "react-toastify";
import { Transition } from "src/commons/handle/transitionModal";
import InputText from "src/components/inputs/InputText";
import _ from "lodash";
import { HidenLoading, ShowLoading } from "src/components/loading-page/LoadingPage";
import { IMenu } from "src/interfaces/IMenu";
import { fetchApplication } from "src/apis/permission/apiApplication";
import { useEmployee, useUserDepartment } from "src/store/hook";
import { useSelector } from "react-redux";
import { selectEmployeeState, selectUserDepartmentState } from "src/store/slices/commonSlice";
import { IApplication } from "src/interfaces/permission/IApplication";
import { IDepartment, IEmployee } from "src/interfaces/IOrg";


interface IProps {
    show: boolean,
    reload: number
    onSave: (data: any) => void
    data: any,
}

interface IState extends IMenu {
    show: boolean,
    selectApplication: any,
    selectUser: any,
    selectDepartment: any
}

const initalState: IState = {
    show: false,
    id: undefined,
    moduleId: '',
    parentId: 0,
    name: '',
    iconClass: '',
    url: '',
    position: 'left',
    order: 0,
    userId: '',
    departmentId: '',
    selectApplication: {},
    selectDepartment: {},
    selectUser: {}
}
const SaveMenu = (props: IProps) => {
    useEmployee()
    useUserDepartment()

    const userState = useSelector(selectEmployeeState)
    const userDepartmentState = useSelector(selectUserDepartmentState)

    const [applications, setApplications] = useState([])

    const [state, setState] = useState<IState>(initalState)

    useEffect(() => {
        loadCategory()
    }, [])

    useEffect(() => {
        if (!props.data.id || props.data.id === '') {
            setState(initalState)
        }
        else if (props.data.id !== state.id) {
            debugger
            const application = applications.find((x: IApplication) => x.id === props.data.moduleId)
            const user = props.data.userId && props.data.userId !== '' ? userState.find((x: IEmployee) => x.employeeId === props.data.userId) : null
            const department = props.data.departmentId && props.data.departmentId !== '' ? userDepartmentState.find((x: IDepartment) => x.code === props.data.departmentId) : null

            setState({
                show: props.show,
                id: props.data.id,
                name: props.data.name,
                moduleId: props.data.moduleId,
                iconClass: props.data.iconClass,
                url: props.data.url,
                position: props.data.position,
                order: props.data.order,
                userId: props.data.userId,
                departmentId: props.data.departmentId,
                selectApplication: application,
                selectUser: user,
                selectDepartment: department
            })
        }
        else setState({ ...state, ...props.data, show: props.show || false, })

    }, [props.reload])

    const loadCategory = () => {
        fetchApplication().then(rs => {
            if (rs.success) {
                setApplications(rs.data)
            }
        })
    }
    //handle
    const handleOnchange = (value: any, name: string, state: any, setState: any) => {
        setState({ ...state, [name]: value });
    }

    const handleClose = () => {
        setState({ ...state, show: false })
    }

    const handleSave = () => {
        debugger
        // validate dữ liệu
        if (state.name === '' || state.name === '') {
            toast.warning(t('common.required'))
            return
        }

        const modelSave = {
            ...state
        }
        props.onSave(modelSave)
    }

    return (
        <Suspense>
            {state.show ? <div className="grid grid-rows grid-cols lg:grid-cols-3 md:grid-cols-2 gap-4">
                <div className="">
                    <InputText
                        required
                        label={t('name')}
                        onChange={(e: any) => handleOnchange(e.target.value, 'name', state, setState)}
                        value={state.name}
                        placeholder={t('pleaseEnter') || ''}
                    />
                </div>
                <div>
                    <InputText
                        label={t('icon')}
                        onChange={(e: any) => handleOnchange(e.target.value, 'iconClass', state, setState)}
                        value={state.iconClass}
                        placeholder={t('pleaseEnter') || ''}
                    />
                </div>
                <div>
                    <Autocomplete
                        options={applications}
                        onChange={(e, item) => setState({ ...state, selectApplication: item, moduleId: item.id })}
                        getOptionLabel={(obj: any) => `${obj.id} - ${obj.name}`}
                        value={state.selectApplication}
                        renderInput={params => <TextField {...params} label={t('application')} />}
                    />
                </div>
                <div >
                    <InputText
                        required
                        label={t('url')}
                        onChange={(e: any) => handleOnchange(e.target.value, 'url', state, setState)}
                        value={state.url}
                        placeholder={t('pleaseEnter') || ''}
                    />
                </div>
                <div>
                    <Select
                        fullWidth
                        value={state.position}
                        label={t('position')}
                        onChange={(e: any) => handleOnchange(e.target.value, 'position', state, setState)}
                    >
                        <MenuItem value="left">left</MenuItem>
                        <MenuItem value="top">top</MenuItem>
                    </Select>
                </div>
                <div>
                    <Autocomplete
                        options={userState}
                        onChange={(e, item) => setState({ ...state, selectUser: item, userId: item.employeeId })}
                        getOptionLabel={(obj: any) => `${obj.employeeId} - ${obj.employeeName}`}
                        value={state.selectUser}
                        renderInput={params => <TextField {...params} label={t('account')} />}
                    />
                </div>
                <div>
                    <Autocomplete
                        options={userDepartmentState}
                        onChange={(e, item) => setState({ ...state, selectUser: item, departmentId: item.code })}
                        getOptionLabel={(obj: any) => `${obj.code} - ${obj.name}`}
                        value={state.selectDepartment}
                        renderInput={params => <TextField {...params} label={t('department')} />}
                    />
                </div>
                <div>
                    <Button variant="contained" onClick={handleSave}>{t('save')}</Button>
                </div>
            </div>
                : <div className="text-xl mt-10 text-center"> <h4>{t('pleaseSelectToDisplay')}</h4></div>
            }
        </Suspense >
    )
}

export default SaveMenu