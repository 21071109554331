import api from "src/apis/base/base";

export const setAuthToken = (token: string) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = "Bearer " + token;
  }else {
    delete api.defaults.headers.common["Authorization"];
  }
};

export const getUserInfoFromToken = (token: string) => {
  try {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};
