import { ArrowDropDown, ArrowRight, Folder } from "@mui/icons-material"
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { IDepartment, IEmployee, IOrgSelectedItem } from "src/interfaces/IOrg"
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { fuzzySearch_VI } from "src/commons/helpers/search"
import { useTranslation } from "react-i18next"
import Button from "../buttons/Button"

interface IProps {
    dataDepartment: IDepartment[];
    dataSelectedOrgs?: IOrgSelectedItem[];
    onChangeSelectedOrgs?: (orgs: IOrgSelectedItem[]) => void;
    dataExpandedNodes?: string[];
    onChangeExpandedNodes?: (nodes: string[]) => void;
    showEmployees?: boolean
    multiple?: boolean
    selectEmployeesOnly?: boolean
}

const OrgTreeView: React.FC<IProps> = (
    { dataDepartment, onChangeSelectedOrgs, dataSelectedOrgs, dataExpandedNodes, onChangeExpandedNodes, showEmployees, multiple, selectEmployeesOnly }
) => {
    const { t } = useTranslation()
    const [orgs, setOrgs] = useState<IOrgSelectedItem[]>([]);
    const [selectedOrgs, setSelectedOrgs] = useState<IOrgSelectedItem[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const [reload, setReload] = useState(0);
    const [searchType, setSearchType] = useState<'department' | 'employee'>('department');
    const [keyword, setKeyword] = useState('');
    const [expanded, setExpanded] = useState<string[]>([]);

    useEffect(() => {
        dataSelectedOrgs && setSelectedOrgs(dataSelectedOrgs);
        setOrgs(dataDepartment.map(x => departmentToOrg(x)));
        dataExpandedNodes && setExpanded(dataExpandedNodes)
    }, [])

    useEffect(() => {
        onChangeSelectedOrgs && onChangeSelectedOrgs(selectedOrgs)
    }, [selectedOrgs, reload])

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClickDeleteFolderItem = () => {
        if (selectedOrgs) {
            handleCloseMenu();
        }
    }

    const onKeyUpKeyWord = (e: any) => {
        setKeyword(e.target.value)
        if ([13].includes(e.keyCode)) {
            e.preventDefault();
            handleClickSearchButton()
        }
    }

    const handleChangeSearchType = (value: any) => {
        setSearchType(value)
    }

    const findParentNodes = (orgsFullMap: IOrgSelectedItem[], results: IOrgSelectedItem[], parentId: string) => {
        const parentNode = orgsFullMap.find(x => x.id === parentId);
        if (parentNode && parentNode.parentId) {
            results.push(parentNode)
            findParentNodes(orgsFullMap, results, parentNode.parentId);
        }
    }

    const handleClickSearchButton = () => {
        const orgsFullMap = dataDepartment.map(x => departmentToOrg(x));
        const filters = searchType === 'department'
            ? fuzzySearch_VI(orgsFullMap, 'label', keyword)
            : orgsFullMap.filter(x => {
                return fuzzySearch_VI(x.department?.employees || [], 'employeeName', keyword)?.length != 0;
            });

        let results: IOrgSelectedItem[] = [];
        for (let index = 0; index < filters.length; index++) {
            const element = filters[index];
            results.push(element)
            if (element.parentId) {
                findParentNodes(orgsFullMap, results, element.parentId)
            }
        }
        const expandedMaps = results.map(x => x.id.toString());
        handleNodeToggle(expandedMaps)
    }


    const handleNodeToggle = (nodes: string[]) => {
        setExpanded(nodes);
        onChangeExpandedNodes && onChangeExpandedNodes(nodes)
    };

    const fillColorSearchDepartmentItem = (node: IOrgSelectedItem, expectedColor: string = 'yellow') => {
        if (!keyword || searchType != 'department') {
            return '';
        }

        const checkInclude = expanded.findIndex(x => x === node.id.toString()) != -1
        const checkSearchName = fuzzySearch_VI([node], 'label', keyword)?.length > 0;

        return checkInclude && checkSearchName ? expectedColor : ''
    }

    const fillColorSearchEmployeeeItem = (emp: IEmployee | undefined, expectedColor: string = 'yellow') => {
        if (!keyword || searchType != 'employee' || !emp) {
            return '';
        }

        const checkInclude = expanded.findIndex(x => x === emp?.deptId?.toString()) != -1;
        const checkSearchName = fuzzySearch_VI([emp], 'employeeName', keyword)?.length > 0;
        return checkInclude && checkSearchName ? expectedColor : ''
    }

    //--------------------------------Select-----------------------------------
    const toggleOrg = (selectedOrgItems: IOrgSelectedItem[], item: IOrgSelectedItem, isExist: boolean) => {
        const isDepartment = item.type === 'department';
        if (isDepartment) {
            const childrenEmployees = item.department?.employees;
            if (childrenEmployees && childrenEmployees?.length > 0) {
                for (let index = 0; index < childrenEmployees.length; index++) {
                    const element = childrenEmployees[index];
                    const indexEmp = selectedOrgItems.findIndex(x => x.id === element.id);
                    indexEmp != -1 && selectedOrgItems.splice(indexEmp, 1);
                }
            }
        } else {
            const indexDept = selectedOrgItems.findIndex(x => x.id === item?.employee?.deptId);
            if (indexDept != -1) {
                return;
            }
        }

        const orgIndex = selectedOrgItems.findIndex(x => x.id === item.id);
        const children = orgs.filter(d => d?.department?.parentId === item.id);
        if (isExist) {
            if (orgIndex != -1) {
                selectedOrgItems.splice(orgIndex, 1);
            }
        } else {
            if (orgIndex === -1) {
                selectedOrgItems.push(item);
            }
        }

        children.forEach(child => toggleOrg(selectedOrgItems, child, isExist));
    }

    const handleSelectedOrgs = (selectedOrgItems: IOrgSelectedItem[], selectingOrgs: IOrgSelectedItem[], isExist: boolean): IOrgSelectedItem[] => {

        for (let index = 0; index < selectingOrgs.length; index++) {
            const orgItem = selectingOrgs[index];
            toggleOrg(selectedOrgItems, orgItem, isExist);
        }

        return selectedOrgItems;
    }

    const departmentToOrg = (item: IDepartment) => {
        const selectedOrg: IOrgSelectedItem = {
            id: item.id,
            label: item.name || ('noName'),
            type: 'department',
            department: item,
            parentId: item?.parentId
        };

        return selectedOrg;
    }

    const employeeToOrg = (item: IEmployee) => {
        const selectedOrg: IOrgSelectedItem = {
            id: item.id,
            label: item.employeeName || ('noName'),
            type: 'employee',
            employee: item
        };

        return selectedOrg;
    }


    const handleOnClickSelectDepartment = (event: any, item: IOrgSelectedItem) => {
        event.stopPropagation()
        if (selectEmployeesOnly)  // nếu biến này bật chỉ cho phép chọn nhân viên
        {
            event.currentTarget.parentNode.parentNode.parentNode.querySelector('.MuiTreeItem-iconContainer')?.click()
            return
        }

        if (multiple) {
            const updatedList = handleSelectedOrgs(selectedOrgs, [item], selectedOrgs.findIndex(x => x.id === item.id) != -1);
            setReload(reload + 1);
            setSelectedOrgs(updatedList);
        }
        else {
            setSelectedOrgs([item])
        }
    }

    const handleOnClickSelectEmployee = (event: any, item: IEmployee) => {
        event.stopPropagation()
        if (multiple) {
            const updatedList = handleSelectedOrgs(selectedOrgs, [employeeToOrg(item)], selectedOrgs.findIndex(x => x.id === item.id) != -1);
            setReload(reload + 1);
            setSelectedOrgs(updatedList);
        }
        else {
            setSelectedOrgs([employeeToOrg(item)])
        }
    }

    const buildTree = (items: IOrgSelectedItem[], parentId: number | string | null = null): React.ReactNode => {
        const filteredItems = items.filter((item) => item.parentId === parentId);

        return filteredItems.map((item) => {
            let isExist = selectedOrgs.findIndex(x => x.id === item?.id) != -1;
            return (
                <div key={item.id} >
                    <TreeItem key={item.id} nodeId={(item.id).toString()}
                        className={isExist ? "Tree-Item Tree-Item-Selected" : "Tree-Item"}
                        label=
                        {
                            <div>
                                <div
                                    key={item.id + 'label'}
                                    onClick={e => handleOnClickSelectDepartment(e, item)}
                                    className='Tree-Item-Label'
                                >
                                    {
                                        // item.isHasToken ?
                                        //     <RuleFolder /> :
                                        <Folder />
                                    }
                                    <label style={{ marginLeft: '1vh', cursor: 'pointer', backgroundColor: fillColorSearchDepartmentItem(item, 'yellow') }}>
                                        {item?.label}
                                    </label>


                                </div>
                                {/* <IconButton aria-label="more"
                                sx={{ width: '1vh', height: '1vh', top: '50%', bottom: '50%', transform: 'translate(0%, -50%)', position: 'absolute', right: 0 }}
                                id="long-button"
                                aria-controls={openMenu ? 'long-menu' : undefined}
                                aria-expanded={openMenu ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(e) => handleClickMenu(e, item)}
                            >
                                <MoreVert />
                            </IconButton> */}
                            </div>
                        }
                    >
                        {buildTree(items, item.id)}
                        {(showEmployees || selectEmployeesOnly) &&
                            item?.department?.employees && item.department.employees?.length > 0 &&

                            item.department.employees.map((emp) => {
                                let isExist2 = selectedOrgs.findIndex(x => x.id === emp?.id) != -1;

                                return (
                                    <TreeItem key={emp.id} nodeId={`employee-${emp.id}`}
                                        label={
                                            <div>
                                                <label style={{ marginLeft: '1vh', cursor: 'pointer', backgroundColor: fillColorSearchEmployeeeItem(emp, 'yellow') }}>
                                                    {emp?.employeeName}
                                                </label>
                                            </div>
                                        }
                                        className={isExist2 || isExist ? "Tree-Item Tree-Item-Selected" : "Tree-Item"}
                                        onClick={e => handleOnClickSelectEmployee(e, emp)}
                                    >
                                    </TreeItem>
                                );
                            })
                        }
                    </TreeItem >
                </div>
            )
        });
    };

    return (
        <Grid container spacing={1}>

            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel >{t('searchType')}</InputLabel>
                    <Select
                        size="small"
                        value={searchType}
                        label={t('searchType')}
                        onChange={(e) => handleChangeSearchType(e.target.value as string)}
                        defaultValue={showEmployees ? 'employee':'department'}
                    >
                        <MenuItem value={'department'}>{t('department')}</MenuItem>
                        <MenuItem value={'employee'}>{t('employee')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    defaultValue={keyword}
                    onKeyUp={onKeyUpKeyWord}
                    fullWidth
                    size="small"
                    type="search"
                />
            </Grid>
            <Grid item xs={2}>
                <Button onClick={handleClickSearchButton} color="primary" classButton="!mt-0" label={t('search')}></Button>
            </Grid>
            <Grid item xs={12}>
                {`${t('choosed')}: ${selectedOrgs.filter(x => x.type === 'department').length || 0} ${t('department')} & ${selectedOrgs.filter(x => x.type === 'employee').length || 0} ${t('employee')}`}
            </Grid>
            <Grid item xs={12}>
                {orgs && orgs.length > 0 ?
                    <TreeView
                        aria-label="folder"
                        // defaultExpanded={selectedOrgs.filter(x => !(x?.department?.employees?.length || 0 > 0)).map(x => x.id.toString())}
                        expanded={expanded}
                        onNodeToggle={(e, n) => handleNodeToggle(n)}
                        defaultCollapseIcon={
                            <div style={{ display: 'flex' }}>
                                <ArrowDropDown />
                                {/* <Folder /> */}
                            </div>
                        }
                        defaultExpandIcon={
                            <div style={{ display: 'flex' }}>
                                <ArrowRight />
                                {/* <Folder /> */}
                            </div>}
                        defaultEndIcon={<div style={{ display: 'flex', "marginRight": "-2vh" }}>
                            {/* <Folder /> */}
                        </div>}
                        sx={{ height: 'auto', maxHeight: '70vh', flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}
                        selected="0"

                    >
                        {buildTree(orgs, 1)}
                    </TreeView> : <div>{t('noInfo')}</div>}
            </Grid>
        </Grid>
    )
}
OrgTreeView.defaultProps = {
    showEmployees: false,
    multiple: false,
    selectEmployeesOnly: false
}
export default OrgTreeView