import { Close, Info, RemoveRedEye } from "@mui/icons-material"
import { AppBar, Dialog, DialogContent, Grid, IconButton, Toolbar, Tooltip, Typography } from "@mui/material"
import { useFormik } from "formik"
import { MRT_PaginationState, MaterialReactTable } from "material-react-table"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { createPhysicalAddress, deletePhysicalAddress, getPhysicalAddressByDepartment, getPhysicalAddressFiles, updatePhysicalAddress } from "src/apis/apiDocument"
import { IActiveId } from "src/commons/enums/activeId"
import getLangReactTable from "src/commons/helpers/getLangReactTable"
import Button from "src/components/buttons/Button"
import InputText from "src/components/inputs/InputText"
import { HidenLoading, ShowLoading } from "src/components/loading-page/LoadingPage"
import OrgSelect from "src/components/org/OrgSelect"
import CustomSelect from "src/components/selects/select"
import { IPaginationResponse } from "src/interfaces/ICommon"
import { IDocumentFile } from "src/interfaces/IDocumentFile"
import { IDocumentFilePhyAddSearchModel, initDocumentFilePhyAddSearchModel } from "src/interfaces/IDocumentPhyAddSearchModel"
import { IDocumentTreeItem } from "src/interfaces/IDocumentTreeItem"
import { IFileInfo } from "src/interfaces/IFile"
import { IDepartment } from "src/interfaces/IOrg"
import { IDepartmentPhysicalAddress, IPhysicalAddressTreeItem } from "src/interfaces/IOrgPhysicalAddress"
import { IPhysicalAddress } from "src/interfaces/IPhysicalAddress"
import { usePhysicalAddress, useUserDepartment } from "src/store/hook"
import { selectUserDepartmentState } from "src/store/slices/commonSlice"
import { addPhysicalAddress, editPhysicalAddress, removePhysicalAddress, selectPhysicalAddressState } from "src/store/slices/physicalAddressSlice"
import { useAppDispatch } from "src/store/store"
import PhysicalAddressTreeView from "src/views/document/PhysicalAddressTreeView"
import MediaViewer from "src/views/media-viewer/MediaViewer"
import Swal from "sweetalert2"
import * as yup from 'yup'

const initialValues: IPhysicalAddress = {
    id: 0,
    name: '',
    parentId: 0,
    path: '',
    description: '',
    departmentId: ''
}

const fileURL = `${process.env.REACT_APP_FILE_URL}/api`
const PhysicalAddress = () => {

    const { t, i18n } = useTranslation()
    // const dispatch = useAppDispatch()
    usePhysicalAddress(true)
    useUserDepartment(true)
    const physicalAddressState = useSelector(selectPhysicalAddressState)
    const userDepartmentState = useSelector<IDepartment[], IDepartment[]>(selectUserDepartmentState)
    const [data, setData] = useState<IDepartmentPhysicalAddress[]>([])


    //filter and pagination
    const [selectedTreeItem, setSelectedTreeItem] = useState<IPhysicalAddressTreeItem>();
    const [selectedDocFiles, setSelectedDocFiles] = useState<IDocumentFile[]>([]);

    const [fileSearchModel, setFileSearchModel] = useState<IDocumentFilePhyAddSearchModel>(initDocumentFilePhyAddSearchModel);
    const [isLoad, setIsLoad] = useState(false);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [globalFilter, setGlobalFilter] = useState('');
    const [rowCount, setRowCount] = useState(0);

    useEffect(() => {
        const phyAddDatas: IPhysicalAddress[] = physicalAddressState.data || [];

        const dataDeptMaps = userDepartmentState.map(x => {
            return {
                ...x,
                id: 'dept_' + x.id,
                parentId: 'dept_' + x.parentId,
                code: x.code,
                name: x.name,
                type: 'department'
            };
        }) as IDepartmentPhysicalAddress[];
        const dataPhyAddMaps = phyAddDatas?.map(x => {
            const deptData = dataDeptMaps?.find(y => y.code === x.departmentId);
            return {
                ...x,
                id: 'phy_' + x.id,
                parentId: x.parentId === 0 ? deptData?.id : 'phy_' + x.parentId,
                name: x.name,
                type: 'physicalAddress',
                code: deptData?.code,
                count: x.documentFilesCount
            };
        }) as IDepartmentPhysicalAddress[];

        setData([...dataPhyAddMaps, ...dataDeptMaps])

    }, [userDepartmentState, physicalAddressState])

    const onClickAddFolderTreeItem = (selectedTreeItem: IPhysicalAddressTreeItem) => {
        Swal.fire({
            title: t('enterthecabinetname'),
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: t('confirm'),
            showLoaderOnConfirm: true,
            cancelButtonText: t('cancel'),
            preConfirm: (folderName) => {
                if (selectedTreeItem.departmentId) {

                    var phyAdd: IPhysicalAddress = {
                        departmentId: selectedTreeItem.departmentId,
                        name: folderName,
                        parentId: selectedTreeItem.type === 'department' ? 0 : parseInt(selectedTreeItem.id?.replace('phy_', '') || '0'),
                        id: 0
                    };

                    return createPhysicalAddress(phyAdd)
                        .then(response => {
                            if (!response.success) {
                                throw new Error(response.message ? response.message : `${t('message.actionError')}`)
                            }

                            const resData: IPhysicalAddress = response.data;

                            const dataMap: IDepartmentPhysicalAddress = {
                                id: 'phy_' + resData.id,
                                parentId: resData.parentId === 0 ? 'dept_' + userDepartmentState.find(x => x.code === resData.departmentId)?.id : 'phy_' + resData.parentId,
                                name: resData.name,
                                type: 'physicalAddress',
                                code: resData.departmentId
                            };

                            setData([dataMap, ...data])
                            return response;
                        })
                        .catch(error => {
                            Swal.showValidationMessage(
                                `${t('message.errorExcution')}: ${error}`
                            )
                        })
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                toast.success(t('message.actionSuccess'))
            }
        })
    }

    const onClickEditFolderTreeItem = (selectedTreeItem: IPhysicalAddressTreeItem) => {
        Swal.fire({
            title: t('enterthecabinetname'),
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: t('confirm'),
            showLoaderOnConfirm: true,
            cancelButtonText: t('cancel'),
            preConfirm: (folderName) => {
                if (selectedTreeItem.departmentId) {
                    const id = parseInt(selectedTreeItem.id.replace('phy_', '') || '0');

                    var phyAdd: IPhysicalAddress = {
                        departmentId: selectedTreeItem.departmentId,
                        name: folderName,
                        parentId: selectedTreeItem.parentId?.includes('dept') ? 0 : parseInt(selectedTreeItem.parentId?.replace('phy_', '') || '0'),
                        id: id
                    };


                    return updatePhysicalAddress(phyAdd)
                        .then(response => {
                            if (!response.success) {
                                throw new Error(response.message ? response.message : `${t('message.actionError')}`)
                            }


                            const findIndex = data.findIndex(x => x.id === selectedTreeItem.id);
                            if (findIndex != -1) {
                                const updatedData = [...data];
                                updatedData[findIndex].name = folderName;
                                setData(updatedData);
                            }

                            return response;
                        })
                        .catch(error => {
                            Swal.showValidationMessage(
                                `${t('message.errorExcution')}: ${error}`
                            )
                        })
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                toast.success(t('message.actionSuccess'))
            }
        })
    }

    const onClickDeleteFolderTreeItem = (selectedTreeItem: IPhysicalAddressTreeItem) => {
        Swal.fire({
            title: `${t('message.DeleteConfirms')}`,
            text: `${t('message.DeleteWarning')}`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: `${t('cancel')}`,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `${t('confirm')}`
        }).then((result) => {
            if (result.isConfirmed) {

                if (selectedTreeItem.type === 'department') {
                    toast.warning(t('cannotdeletedepartment'));
                    return;
                }
                const deleteId = parseInt(selectedTreeItem.id?.replace('phy_', '') || '0');
                deletePhysicalAddress(deleteId)
                    .then(response => {
                        if (!response.success) {
                            throw new Error(response.message ? response.message : `${t('message.actionError')}`)
                        }

                        setData(data.filter(x => x.id != selectedTreeItem.id));
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `${t('message.errorExcution')}: ${error}`
                        )
                    })
            }
        });
    }

    const onSelectTreeItem = (selectedTreeItem: IPhysicalAddressTreeItem) => {
        if (selectedTreeItem.type === 'physicalAddress') {
            const searchModel: IDocumentFilePhyAddSearchModel = {
                ...fileSearchModel,
                physicalAddressId: parseInt(selectedTreeItem.id?.replace('phy_', '') || '0'),
            }

            setFileSearchModel(searchModel)
            setSelectedTreeItem(selectedTreeItem)
        }
    }

    const fetchPhyAddFiles = () => {
        setIsLoad(true)
        getPhysicalAddressFiles({
            ...fileSearchModel,
            keyword: globalFilter,
            page: pagination.pageIndex + 1,
            size: pagination.pageSize
        }).then(res => {
            if (!res.success) {
                toast.warning(t('message.actionError'));
                return;
            }

            const resDatas: IPaginationResponse<IDocumentFile> = res.data;
            setSelectedDocFiles(resDatas.items);
            setRowCount(resDatas.count);

        }).catch(err => {
            console.error(err);
            toast.error(t('message.actionError'));
        }).finally(() => { setIsLoad(false) });
    }

    useEffect(() => {
        fileSearchModel.physicalAddressId && fetchPhyAddFiles();
    }, [fileSearchModel])


    useEffect(() => {
        //console.log(pagination);
        //console.log(globalFilter);

        setFileSearchModel({
            ...fileSearchModel,
            keyword: globalFilter,
            page: pagination.pageIndex + 1,
            size: pagination.pageSize
        })
    }, [pagination, globalFilter])



    const [dialogViewFileOpen, setDialogViewFileOpen] = useState(false);
    const [selectedFileIndex, setSelectedFileIndex] = useState(-1);

    const handleCloseDialogViewFile = () => {
        setDialogViewFileOpen(false);
        setSelectedFileIndex(-1);
    };

    const handleOpenDialogViewFile = () => {
        setDialogViewFileOpen(true);
    };

    const handleViewFileClick = (index: number) => {
        setSelectedFileIndex(index);
        handleOpenDialogViewFile();
    }


    //---------------------------RENDER---------------------------------//
    return (
        <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
            {
                <Grid container spacing={1} p={2}>
                    <Grid item xs={12} md={4} className="overflow-auto h-[80svh]">
                        {data && data.length > 0
                            && <PhysicalAddressTreeView dataDepartment={data}
                                onClickAddFolderTreeItem={onClickAddFolderTreeItem}
                                onClickDeleteFolderTreeItem={onClickDeleteFolderTreeItem}
                                onClickEditFolderTreeItem={onClickEditFolderTreeItem}
                                onSelectTreeItem={onSelectTreeItem}
                            />}
                    </Grid>
                    <Grid item xs={12} md={8} className="overflow-auto h-[80svh]">
                        <MaterialReactTable
                            localization={getLangReactTable(i18n.language)}
                            columns={[
                                {
                                    accessorKey: 'name',
                                    header: t('name'),
                                },
                                {
                                    accessorKey: 'documentSerial',
                                    header: t('serial'),
                                },
                                {
                                    accessorKey: 'size',
                                    header: t('size'),
                                    Cell: (data) =>
                                        <span>
                                            {(data.row.original.size ? (data.row.original.size / (1024 * 1024)).toFixed(2) : 0) + ' MB'}
                                        </span>
                                },
                                {
                                    header: t('action'),
                                    Cell: (data) =>
                                        <>
                                            <Tooltip title={t('view')}>
                                                <IconButton onClick={() => handleViewFileClick(data.row.index)}>
                                                    <RemoveRedEye />
                                                </IconButton>
                                            </Tooltip>
                                            <Link to={`/document?s=${data.row.original.documentSerial}`}>
                                                <Tooltip title={t('documentDetail')}>
                                                    <IconButton >
                                                        <Info />
                                                    </IconButton>
                                                </Tooltip>
                                            </Link>
                                        </>
                                },
                            ]}
                            data={selectedDocFiles || []}
                            renderTopToolbarCustomActions={() => (
                                <Typography variant="subtitle1">
                                    {selectedTreeItem?.label}
                                </Typography>
                            )}
                            enableDensityToggle={false}
                            initialState={{
                                density: 'compact',
                                pagination: { pageIndex: pagination.pageIndex, pageSize: pagination.pageSize },

                            }}
                            manualPagination={true}
                            onPaginationChange={setPagination}
                            onGlobalFilterChange={setGlobalFilter}
                            state={{
                                isLoading: isLoad,
                                pagination,
                                globalFilter,
                            }}
                            rowCount={rowCount}
                            enableSorting={false}
                        // enableFilters={false}
                        />
                    </Grid>
                </Grid>
            }
            <MediaViewer
                isFullscreen
                currentIndex={selectedFileIndex}
                files={(selectedDocFiles && selectedDocFiles.length > 0) ? selectedDocFiles.filter(x => x.active === IActiveId.Active).map(x => ({
                    absoluteURL: `${fileURL}/${x.url}`,
                    url: x.url,
                    bucketName: 'document',
                    contentType: x.type,
                    fileName: x.name,
                    folder: x.physicalAddress,
                    isSucceed: true,
                    size: x.size,
                } as IFileInfo)) : []}
                open={dialogViewFileOpen}
                onClose={handleCloseDialogViewFile}
                isLoadPresignedURL={true}
            />

        </div>
    )
}

export default PhysicalAddress