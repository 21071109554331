import { Close, Save } from '@mui/icons-material'
import { AppBar, Dialog, DialogActions, DialogContent, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Table, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material'
import { Suspense, useEffect, useState } from 'react'
import OrgTreeView from './OrgTreeView'
import { IDepartment, IOrgSelectedItem } from 'src/interfaces/IOrg'
import { getDepartment } from 'src/apis/apiCommon'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import Button from '../buttons/Button'
import { useSelector } from 'react-redux'
import { selectUserDepartmentState } from 'src/store/slices/commonSlice'
import { useUserDepartment } from 'src/store/hook'
import React from 'react'

interface IProps {
    open: boolean,
    reload?: number,
    data?: IOrgSelectedItem[]
    onSave?: (orgs: IOrgSelectedItem[]) => void,
    onClose?: () => void | null,
    showEmployees?: boolean,
    multiple?: boolean
    selectEmployeesOnly?: boolean,
    exclude?: string[]
}

const OrgDialog = React.memo((props: IProps) => {
    const {exclude,showEmployees=false,multiple=false,selectEmployeesOnly=false,data,onSave,onClose,reload,open } = props
    useUserDepartment()
    const { t } = useTranslation()
    const [selectedOrgs, setSelectedOrgs] = useState<IOrgSelectedItem[]>(data || []);
    const [expanded, setExpanded] = useState<string[]>([]);
    const userDepartmentState = useSelector(selectUserDepartmentState)
    const [viewTree, setViewTree] = useState<IDepartment[]>(userDepartmentState || [])

    useEffect(() => {
        if (exclude && exclude.length > 0){
            const filter = filterDataDepartment(userDepartmentState, exclude)
            setViewTree(filter)
        }
    }, [exclude])

    useEffect(() => {
        if (data)
            setSelectedOrgs(data)
    }, [data])

    const onChangeSelectedOrgs = (items: IOrgSelectedItem[]) => {
        setSelectedOrgs(items)
    }

    const handleSave = () => {
        handleClose();
    }

    const handleClose = () => {
        onSave && onSave(selectedOrgs)
        onClose && onClose()
    }

    const handleChangeExpandedNodes = (nodes: string[]) => {
        setExpanded(nodes)
    }

    const filterDataDepartment = (data: IDepartment[], excludeValues: string[]): IDepartment[] => {
        if (!data) return [];
        if (!excludeValues || excludeValues.length === 0) return data;

        return data.filter((item) => {
            // Kiểm tra nếu `code` tồn tại và không nằm trong danh sách exclude
            if (item.code && excludeValues.includes(item.code)) return false;

            // Kiểm tra từng nhân viên, bỏ các nhân viên có `employeeId` nằm trong danh sách exclude
            if (item.employees) {
                item.employees = item.employees.filter(
                    (employee: any) => !excludeValues.includes(employee.employeeId)
                );
            }
            return true;
        });
    };

    return (
        <Suspense>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'md'}
                fullWidth
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {t('department')}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent className='relative'>
                    <Grid container spacing={0.5}>
                        <Grid item md={12} sx={{ justifyContent: 'center' }}>
                            {viewTree?.length > 0 &&
                                <OrgTreeView dataDepartment={viewTree} dataSelectedOrgs={selectedOrgs}
                                    onChangeSelectedOrgs={onChangeSelectedOrgs}
                                    dataExpandedNodes={expanded} onChangeExpandedNodes={handleChangeExpandedNodes}
                                    showEmployees={showEmployees}
                                    multiple={multiple}
                                    selectEmployeesOnly={selectEmployeesOnly}
                                />}
                        </Grid>
                        <Button onClick={() => handleSave()} label={t('save')} classButton='absolute right-0 bottom-0 px-6' color='primary'></Button>
                    </Grid>
                </DialogContent>
            </Dialog >
        </Suspense>
    )
})

export default OrgDialog