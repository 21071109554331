import { IUserAppNoti } from "src/interfaces/IUserAppNoti";
import api, { ResultObject } from "./base/base";
import { ENotiStatus } from "src/commons/enums/noiType";
import { INotificationSearchModel } from "src/interfaces/INoti";
import { removeUndefinedKeys } from "src/commons/helpers/common";

const baseURL = `${process.env.REACT_APP_API_NOTI}/api`
//UserAppNoti
export const getUserAppNoti = async (): Promise<ResultObject> => {
    const result = await api
        .get(`${baseURL}/UserAppNoti?app=Web_Documents`)
        .then((response: any) => response?.data);
    return result;
};

export const createUserAppNoti = async (model: IUserAppNoti): Promise<ResultObject> => {
    model.app = 'Web_Documents';

    const result = await api
        .post(`${baseURL}/UserAppNoti`, model)
        .then((response: any) => response?.data);
    return result;
};

export const updateUserAppNoti = async (model: IUserAppNoti): Promise<ResultObject> => {
    model.app = 'Web_Documents';

    const result = await api
        .put(`${baseURL}/UserAppNoti`, model)
        .then((response: any) => response?.data);
    return result;
};

export const deleteUserAppNoti = async (): Promise<ResultObject> => {
    const result = await api
        .delete(`${baseURL}/UserAppNoti?app=Web_Documents`)
        .then((response: any) => response?.data);
    return result;
};

//Firebase Cloud Message
export const createUserToken = async (token: string): Promise<ResultObject> => {
    const app = 'Web_Documents';

    const result = await api
        .post(`${baseURL}/Noti/create-usertoken?app=${app}`, token)
        .then((response: any) => response?.data)
    return result;
};

//Notification
export const getNotifications = async (model: INotificationSearchModel): Promise<ResultObject> => {
    const result = await api
        .get(`${baseURL}/Noti?${new URLSearchParams(removeUndefinedKeys(model)).toString()}`)
        .then((response: any) => response?.data);
    return result;
};

export const updateNotification = async (id: number, status: ENotiStatus): Promise<ResultObject> => {

    const result = await api
        .put(`${baseURL}/Noti`, { id, status })
        .then((response: any) => response?.data);
    return result;
};

export const deleteNotifications = async (ids: number[]): Promise<ResultObject> => {
    const result = await api
        .delete(`${baseURL}/Noti`, { data: ids })
        .then((response: any) => response?.data);
    return result;
};