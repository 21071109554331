import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import i18n from './locales'
import routes from './routers';
import LoadingPage from './components/loading-page/LoadingPage';
import { ToastContainer } from 'react-toastify';

import './styles/nucleo-icons.css'
import './styles/nucleo-svg.css'
import './styles/soft-ui-dashboard-tailwind.css'
import 'react-toastify/dist/ReactToastify.css'
import './styles/index.css'
import './styles/globals.css'
import { getMessagingToken } from './firebase-noti';
import { useAuth } from 'react-oidc-context';
import { createUserToken } from './apis/apiNoti';

const App = () => {


  const [reload, setReload] = useState(0);
  const auth = useAuth();

  useEffect(() => {
    // Yêu cầu quyền thông báo
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    } else if (Notification.permission === "granted") {
      // const notification = new Notification("Hi there!");
      // …
      handleCreateToken()
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          handleCreateToken()
        }
      });
    }

    // if (reload === 0) {
    //   const channel = new BroadcastChannel("bg-notifications");
    //   channel.addEventListener("message", (event) => {
    //     console.log("Receive background: ", event.data);
    //   });
    // }

  }, [reload]);

  // useEffect(() => {
  //   onMessageListener().then(data => {
  //     console.log("Receive foreground: ", data)
  //     setReload(reload + 1)
  //   })
  // });

  // useEffect(() => {
  //   if (auth.isAuthenticated) {
  //     console.log('welcome', auth);

  //     // createUserToken(currentToken)
  //   }
  // }, [auth.isAuthenticated]);

  const handleCreateToken = () => {
    getMessagingToken().then(currentToken => {
      if (currentToken) {
        // console.log('Token: ', currentToken);
        localStorage.setItem('noti-token', currentToken)
        // Gửi token này đến server để lưu lại
        if (auth.isAuthenticated) {
          createUserToken(currentToken)
        }
      } else {
        console.log('No registration token available. Request permission to generate one.');
        localStorage.setItem('noti-token', '')
        //toast.warning('Hệ thống thông báo đang bảo trì ...')
      }
    }).catch(err => {
      console.log('An error occurred while retrieving token. ', err);
      localStorage.setItem('noti-token', '')
      //toast.warning('Hệ thống thông báo đang bảo trì ...')
    });
  }

  return (
    <I18nextProvider i18n={i18n}>
      {/* <Suspense fallback={<LoadingPage showLoading={true} />} >  Dùng để import với lazy*/}
      <RouterProvider router={routes} />
      <LoadingPage />
      <ToastContainer />
      {/* </Suspense> */}
    </I18nextProvider>
  );
}

export default App;
