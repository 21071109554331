// src/components/GaugeChart.tsx
import React, { useState } from 'react';
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from '@mui/x-charts/Gauge';
import { formatNumber } from 'src/commons/helpers/common';

interface GaugeChartComponentProps {
  value: number;
  width?: number;
  height?: number;
  title?: string;
}

const GaugePointer: React.FC<{ value: number; showText: boolean }> = ({ value, showText }) => {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };

  return (
    <g>
      {!showText &&
        <circle cx={cx} cy={cy} r={5} fill="red" />
      }
      {!showText &&
        <path
          d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
          stroke="red"
          strokeWidth={3}
        />
      }
      {/* Hiển thị giá trị ở giữa Gauge khi hover */}
      <defs>
        <filter id="text-shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2" result="blur" />
          <feOffset in="blur" dx="2" dy="2" result="offsetBlur" />
          <feMerge>
            <feMergeNode in="offsetBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      {showText && (
        <text
          x={cx}
          y={cy}
          textAnchor="middle"
          dominantBaseline="middle"
          fill="black"
          fontSize={14}
          filter="url(#text-shadow)"
        >
          {`${value.toFixed(2)}%`}
        </text>
      )}
    </g>
  );
}

const GaugeChartComponent: React.FC<GaugeChartComponentProps> = ({ title, value, width = 100, height = 100 }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{ width: `${width}%`, height: `${height}px` }}
    >
      <GaugeContainer
        title={title}
        value={value}
        startAngle={-90}
        endAngle={90}
      >
        <GaugeReferenceArc />
        <GaugeValueArc />
        <GaugePointer value={value} showText={hovered} /> {/* Truyền trạng thái hover vào GaugePointer */}
      </GaugeContainer>
    </div >
  );
};

export default GaugeChartComponent;
