import { TFunction } from "i18next";
import { IDataSelect } from "src/components/selects/select";

export const getKeyByValueEnum = (
  enumObj: any,
  value: any
): string | undefined => {
  return Object.keys(enumObj).find((key) => enumObj[key] === value);
};

export const fileListToArray = (fileList: FileList): File[] => {
  const filesArray: File[] = [];

  // Sử dụng Array.prototype.forEach() để lặp qua các phần tử trong fileList
  Array.from(fileList).forEach((file: File) => {
    filesArray.push(file);
  });

  return filesArray;
};

export const isGUID = (str: string) => {
  const pattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return pattern.test(str);
};

export const isNumber = (str: string) => {
  return !isNaN(parseFloat(str)) && isFinite(str as any);
};

export const enumToListSelect = (
  inEnum: object,
  t: TFunction<"translation", undefined>,
  keyResource: string = "enum"
) => {
  return Object.values(inEnum)
    .filter((x) => typeof x === "string")
    .map((key, index) => {
      const item: IDataSelect = {
        label: t(`${keyResource}${index}`),
        value: index,
      };
      return item;
    });
};

export const generateGUID = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const removeUndefinedKeys = (obj: any): any => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (
      newObj[key] === undefined ||
      newObj[key] === null ||
      newObj[key] === ""
    ) {
      delete newObj[key];
    }
  });

  return newObj;
};

export function svgToCanvas(
  svgString: string,
  width: number,
  height: number
): Promise<HTMLCanvasElement> {
  return new Promise((resolve, reject) => {
    const svg = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
    const url = URL.createObjectURL(svg);

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.onload = () => {
      ctx?.drawImage(img, 0, 0, width, height);
      URL.revokeObjectURL(url);
      resolve(canvas);
    };
    img.onerror = (error) => {
      URL.revokeObjectURL(url);
      reject(error);
    };
    img.src = url;
  });
}

export async function svgToPng(
  svgString: string,
  width: number,
  height: number
): Promise<string> {
  const canvas = await svgToCanvas(svgString, width, height);
  return canvas.toDataURL("image/png");
}

export const formatNumber = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const convertToEncodedURL = (url: string): string => {
  // Tách phần query (nếu có)
  const [baseUrl, queryString] = url.split("?");
  const query = queryString ? "?" + queryString : "";

  // Tách URL cơ bản thành các phần dựa trên dấu '/'
  const parts = baseUrl.split("/");
  const lastPart = parts.pop() || ""; // Lấy phần cuối cùng

  // Giải mã phần cuối cùng

  // Mã hóa lại phần cuối cùng
  const encodedLastPart = encodeURIComponent(lastPart);

  // Ghép lại URL cơ bản với phần đã mã hóa và phần query
  const newBaseUrl = parts.join("/") + "/" + encodedLastPart;
  return newBaseUrl + query;
};

export const filterDataDepartment = (data: any[], excludeValues: string[]) => {
  if (!data) return [];
  if (!excludeValues || excludeValues.length === 0) return data;

  return data.filter((item) => {
    // Kiểm tra nếu `code` tồn tại và không nằm trong danh sách exclude
    if (item.code && excludeValues.includes(item.code)) return false;

    // Kiểm tra từng nhân viên, bỏ các nhân viên có `employeeId` nằm trong danh sách exclude
    if (item.employees) {
      item.employees = item.employees.filter(
        (employee: any) => !excludeValues.includes(employee.employeeId)
      );
    }
    return true;
  });
};
