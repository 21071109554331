import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Card,
    CardMedia,
    DialogTitle,
    Grid,
    useTheme,
    useMediaQuery,
    IconButton,
    Skeleton,
} from '@mui/material';
import { ArrowLeft, ArrowRight, Close, Download } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { getPrivateFileByURL } from 'src/apis/apiFile';
import { IFileInfo } from 'src/interfaces/IFile';
import { t } from 'i18next';
import { convertToEncodedURL } from 'src/commons/helpers/common';

interface ImageViewerProps {
    files: IFileInfo[];
    open: boolean;
    onClose: () => void;
    currentIndex: number;
    isLoadPresignedURL?: boolean;
    isDisableSlideImage?: boolean;
    isFullscreen?: boolean;
}

const baseURL = `${process.env.REACT_APP_FILE_URL}`

const MediaViewer: React.FC<ImageViewerProps> = ({
    files,
    open,
    onClose,
    currentIndex,
    isLoadPresignedURL,
    isDisableSlideImage,
    isFullscreen
}) => {
    const [currentFileIndexState, setCurrentFileIndexState] = useState(currentIndex);
    const [currentURL, setCurrentURL] = useState("");
    const [isLoadData, setIsLoadData] = useState(false);

    useEffect(() => {
        setCurrentFileIndexState(currentIndex);

        if (files && files.length > 0 && currentIndex > -1) {
            handleLoadPresignedURL(files[currentIndex].bucketName, files[currentIndex].url);
        }
    }, [currentIndex]);


    const handleLoadPresignedURL = (bucketName: string, url: string) => {
        setIsLoadData(true);
        isLoadPresignedURL
            ? getPrivateFileByURL(bucketName, baseURL + '/' + encodeURIComponent(url)).then((res) => {
                if (res.success) {
                    const urlRes: string = res.data.presignURL;
                    setCurrentURL(convertToEncodedURL(urlRes));
                } else {
                    toast.error(t('message.errorConnect'))
                }
                setIsLoadData(false);
            }).catch((err) => console.error(err))
            : setCurrentURL(convertToEncodedURL(baseURL + '/' + url + '?v=1')); setIsLoadData(false);
    }

    const handleNext = () => {
        if (!isLoadData && !isDisableSlideImage) {
            var index = currentFileIndexState === files.length - 1 ? 0 : currentFileIndexState + 1;
            handleLoadPresignedURL(files[index].bucketName, files[index].url);
            setCurrentFileIndexState(index);
        }
    };

    const handleBack = () => {
        if (!isLoadData && !isDisableSlideImage) {
            var index = currentFileIndexState === 0 ? files.length - 1 : currentFileIndexState - 1;
            handleLoadPresignedURL(files[index].bucketName, files[index].url);
            setCurrentFileIndexState(index);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
            handleBack();
        } else if (event.key === 'ArrowRight') {
            handleNext();
        }
    };

    const theme = useTheme();
    const fullScreenData = useMediaQuery(theme.breakpoints.down('md'));
    const currentFile = files[currentFileIndexState];



    const renderView = () => {
        if (currentURL && currentFile) {
            let renderOption: "microsoft" | "basic" | "ggdrive" | "muiImage" | "muiVideo" | "" = "";
            switch (true) {
                case ["wordprocessingml", "spreadsheetml", "msword"].some(type => currentFile.contentType.includes(type)):
                    renderOption = "microsoft";
                    break;
                case ["pdf", "text"].some(type => currentFile.contentType.includes(type)):
                    renderOption = "basic";
                    break;
                case currentFile.contentType.includes("image"):
                    renderOption = "muiImage";
                    break;
                case currentFile.contentType.includes("video"):
                    renderOption = "muiVideo";
                    break;
            }

            switch (renderOption) {
                case 'microsoft':
                    return <iframe
                        key={currentURL}
                        title={currentFile?.fileName || ''}
                        src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(currentURL)}`}
                        allowFullScreen={true}
                        height="100%" width={'100%'}
                    />
                case 'basic':
                    return <embed
                        title={currentFile?.fileName || ''}
                        src={`${currentURL}`}
                        height="100%" width={'100%'}
                    />
                case 'muiImage':
                    return <CardMedia height="80vh" width={'100%'}
                        className='media-image' sx={{ height: '80vh', objectFit: 'contain' }} component="img" image={currentURL} draggable={true} />
                case 'muiVideo':
                    return <CardMedia component="video" controls autoPlay>
                        <source src={currentURL} type="video/mp4" />
                    </CardMedia>
                default:
                    return <>{t('message.errorExcution')}</>
            }
        }
    };

    return (
        <Dialog open={open} onKeyDown={handleKeyDown} onClose={onClose} maxWidth="md" fullScreen={!isFullscreen ? fullScreenData : isFullscreen} >
            <DialogTitle >{currentFile?.fileName}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                ) : null}
            </DialogTitle>

            <DialogContent style={{
                // display: 'flex',
                // flexDirection: 'column',
                // alignItems: 'center',
                // justifyContent: 'center',
                // minHeight: '75vh',
            }}>
                {
                    isLoadData ?
                        <div style={{ display: 'flex', minWidth: '75vw', justifyContent: 'center', alignContent: 'space-between' }}>
                            <Skeleton animation="pulse" variant="circular" width={40} height={40} />
                            <Skeleton animation="pulse" variant="circular" width={40} height={40} />
                            <Skeleton animation="pulse" variant="circular" width={40} height={40} />
                        </div>
                        : (
                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                                <Grid item xs={12} md={12} lg={12}>
                                    {

                                        currentFile &&
                                        <Card sx={{ height: '80vh', padding: 1 }}>
                                            {renderView()}
                                        </Card>
                                    }
                                </Grid>
                                <Grid display={isDisableSlideImage ? 'none' : ''} item container xs={12} md={12} lg={12} justifyContent={"space-between"} position={"absolute"}>
                                    <Grid item>
                                        <IconButton onClick={handleBack} color="primary" >
                                            <ArrowLeft />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={handleNext} color="primary" >
                                            <ArrowRight />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                }

            </DialogContent>
            <DialogActions>
                <IconButton
                    // onClick={() => handleDownloadClick(currentURL)}
                    color='success' title={t('download')}
                    href={`${currentURL}`}
                    download
                    target="_blank"
                >
                    <Download />
                </IconButton>
            </DialogActions>
        </Dialog >
    );
};

export default MediaViewer;
