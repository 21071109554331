import {
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

import SideNav from "../../components/themes/SideNav/SideNav";
import TopNav from "../../components/themes/TopNav/TopNav";
import Foolter from "../../components/themes/Foolter/Foolter";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { selectAuthState } from "src/store/slices/authSlice";
import { setAuthToken } from "src/commons/helpers/set-auth-token";
import { useUserProfile } from "src/store/hook";
import { useEffect } from "react";
import {  setBackUrl } from "src/store/slices/settingSlice"
import { useAppDispatch } from "src/store/store"
const DashboardLayout = () => {
  const auth = useAuth();
  const authState = useSelector(selectAuthState)
  useUserProfile(true);
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  if (process.env.REACT_APP_LOGIN_API === 'true') {
    if (!authState.isLogin) {
      navigate('/login')
    }
  }
  else if (!auth || !auth.isLoading && !auth.isAuthenticated && !authState.isLogin) {//&& !authState.isLogin
    auth.signinRedirect()
  }
  else if (auth.isAuthenticated) {
    const token = auth?.user?.access_token || ''
    setAuthToken(token)
  }

  useEffect(() => {
    dispatch(setBackUrl(''))
  }, [])

  return (
    <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen overflow-hidden rounded-xl transition-all duration-200">
      <SideNav />
      <TopNav />
      <div className="w-full md:px-6 py-2 mx-auto">
        <Outlet />
      </div>
      <Foolter />
    </div>
  );
};
export default DashboardLayout;