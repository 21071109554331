import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { isGUID, isNumber } from "src/commons/helpers/common";

const Breadcrumb = () => {
    const { t } = useTranslation()
    const location = useLocation();
    const paths = location.pathname.split('/')
    const last = paths[paths.length - 1] || 'home';
    const isId = isGUID(last) || isNumber(last)
    return (
        <nav>
            <ol className="flex flex-wrap pt-1 mr-12 bg-transparent rounded-lg">
                <li className="leading-normal text-sm">
                    <Link className="opacity-50 text-slate-700" to="/">
                        <i className="fa fa-home" />
                    </Link>
                </li>

                {location.pathname != '' &&
                    <>
                        {paths?.slice(1, -1)?.map((item, index) => {
                            const temp = [...paths]
                            return (
                                <li key={item} className="pl-2  leading-normal text-sm before:float-left before:pr-2 before:text-gray-600 before:content-['/']">
                                    <Link className="opacity-50 text-slate-700 " to={"/" + temp.splice(1, index + 1).join('/')}>{t(`breadcrumb.${item}`)} </Link>
                                </li>
                            )
                        })}
                       {!isId  && <li className="text-sm pl-2 capitalize leading-normal text-slate-700 before:float-left before:pr-2 before:text-gray-600 before:content-['/']" >{t(`breadcrumb.${last}`)}</li>}
                    </>
                }

            </ol>
            {!isId  &&<h6 className="mb-0 font-bold capitalize hidden md:block">{t(`breadcrumb.${last}`)}</h6>}
        </nav>
    )
}

export default Breadcrumb