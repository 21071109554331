/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoginApi } from "src/apis/apiAuth";
import {
  getUserInfoFromToken,
  setAuthToken,
} from "src/commons/helpers/set-auth-token";

export interface IAuthState {
  fullName: string
  email: string
  departmentName:string
  departmentId:string
  id: string
  isLogin: boolean
  message: string
  token:string
  reload: number
}

const initialState: IAuthState = {
  fullName: "",
  email: "",
  departmentName:'',
  departmentId:'',
  id: '',
  isLogin: false,
  message: "",
  token:'',
  reload: 1,
};

export const fetchLogin = createAsyncThunk("fetchLogin", async (user: any) => {
  const res = await LoginApi(user.username, user.password);
  return res;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state: any) {
      setAuthToken("");
      state.fullName = "";
      state.email = "";
      state.token =""
      state.departmentId=""
      state.departmentName =""
      state.id = "";
      state.isLogin = false;
      state.message = "";
      state.reload++;
    },
    setAuth(state: any, action: any) {
      const data = action.payload
      state.token = data.token
      state.fullName = data.FullName;
      state.departmentName = data.departmentName
      state.departmentId = data.DepartmentCode
      state.email = data.email;
      state.id = data.id;
      state.isLogin = true;
      state.message = "";
      state.reload++;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchLogin.fulfilled, (state: any, action: any) => {
        const data = action.payload;
        setAuthToken(data.access_token);
        state.token = data.access_token;

        // giãi mã token để lấy dữ liệu
        const deCode = getUserInfoFromToken(data.access_token);
        state.fullName = deCode?.full_name || "";
        state.email = deCode?.email || "";
        state.phone = deCode?.phone || "";
        
        state.isLogin = true;
        state.message = "";
        state.reload++;
      })
      .addCase(fetchLogin.rejected, (state: any, action: any) => {
        state.message = action.error;
        state.isLogin = false;
        state.reload++;
      });
  },
});

export const { logout, setAuth } = authSlice.actions;

export const selectAuthState = (state: any) => state.auth || {};

export default authSlice.reducer;
