import { AppBar, Box, Tab, Tabs, useTheme } from "@mui/material";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getDocummentDetail, privateAccessDocument } from "src/apis/apiDocument";
import { getDateString, getDateString2, getDateTimeString } from "src/commons/helpers/formatDate";
import Hitory from "src/components/themes/History/History"
import { IDocument } from "src/interfaces/IDocument";
import { IDepartment, IEmployee } from "src/interfaces/IOrg";
import QRCode from "react-qr-code"
import MediaViewer from "../media-viewer/MediaViewer";
import { IDocumentFile } from "src/interfaces/IDocumentFile";
import DocumentAction from "./DocumentAction";
import { getDocumentPriorityColor, getDocumentStatusColor } from "src/commons/helpers/getClass";
import { IFileInfo } from "src/interfaces/IFile";
import TabPanel, { a11yProps } from "src/components/tab-panel/TabPanel";
import { useSelector } from "react-redux";
import { selectEmployeeState, selectUserDepartmentState } from "src/store/slices/commonSlice";
import FileReverseModal from "../file-reverse-modal/FileReverseModal";
import { IPhysicalAddressState, selectPhysicalAddressState } from "src/store/slices/physicalAddressSlice";
import { MaterialReactTable } from "material-react-table";
import { IActiveId } from "src/commons/enums/activeId";
import DetailDocumentSkeleton from "./DetailDocumentSkeleton";
import Swal from "sweetalert2";
import * as apiPrivate from '../../apis/apiPrivate';
import { selectAuthState } from "src/store/slices/authSlice";
import getLangReactTable from "src/commons/helpers/getLangReactTable";
import { useTranslation } from "react-i18next";
import { verifyOTP } from "src/apis/apiCommon";
import { IUserProfile } from "src/interfaces/IUserProfile";
import { selectUserProfileState } from "src/store/slices/userProfileSlice";
import { IDocumentActionModel } from "src/interfaces/IDocumentActionModel";
import { Link } from "react-router-dom";
import ViewQuestions from "../questions/ViewQuestions";
import { IDocumentLink } from "src/interfaces/IDocumentLink";
import { getRandomIntInclusive } from 'src/commons/get-initials'
import moment from "moment";
const baseURL = `${process.env.REACT_APP_URL}`;
const fileURL = `${process.env.REACT_APP_FILE_URL}/api`

interface IProps {
    id: string,
    onReload?: (doc: IDocument) => void
}

const DetailDocument = (props: IProps) => {
    const { t, i18n } = useTranslation()
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const authState = useSelector(selectAuthState)
    const [value, setValue] = useState(0);
    const userDepartmentState = useSelector(selectUserDepartmentState)
    const employeeState = useSelector<IEmployee[], IEmployee[]>(selectEmployeeState)
    const userProfile = useSelector<IUserProfile, IUserProfile>(selectUserProfileState);

    const [document, setDocument] = useState<IDocument | null>(null);

    const [selectedFiles, setSelectedFiles] = useState<IDocumentFile[]>([]);
    const [selectedFileIndex, setSelectedFileIndex] = useState(-1);
    const [dialogViewFileOpen, setDialogViewFileOpen] = useState(false);

    const [dialogReverseFileOpen, setDialogReverseFileOpen] = useState(false);
    const [selectedFileReverse, setSelectedFileReverse] = useState<IDocumentFile | null>(null);
    const physicalAddressState = useSelector<IPhysicalAddressState, IPhysicalAddressState>(selectPhysicalAddressState)

    useEffect(() => {
        if (props.id && props.id !== '') {
            getDetail()
        }
    }, [props.id])

    const getDetail = (isReloadList: boolean = false) => {
        setIsLoading(true)
        getDocummentDetail(props.id).then((rs) => {

            if (!rs || !rs.success) {
                return;
            }
            if (!rs.data) {
                toast.warning(t('message.notHaveAccess'))
                setDocument(null)
                return
            }
            const data: IDocument = rs.data;
            setDocument(data);
            setSelectedFiles(data.documentFiles);

            if (isReloadList)
                props.onReload && props.onReload(data)

        }).catch((err) => {
            toast.error(err?.message)
        }).finally(() => setIsLoading(false));
    }

    const handleCloseDialogViewFile = () => {
        setDialogViewFileOpen(false);
        setSelectedFileIndex(- 1);

    };

    const handleOpenDialogViewFile = () => {
        setDialogViewFileOpen(true);
    };

    const handleViewFileClick = (index: number) => {
        // kiểm tra file có yêu bảo mật 2 lớp usbToken
        const curFile = document?.documentFiles.filter(x => x.active === IActiveId.Active)[index]
        if (curFile && curFile?.isSecurity === true) { //
            // open usbtoken
            Swal.fire({
                title: t('message.ThisIsSecurityFile'),
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off',
                    id: "inputSwal",
                    required: 'true'
                },
                showCancelButton: false,
                confirmButtonText: `<i class="fa fa-arrow-right"></i> ${t('confirm')}`,
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    //1. Collect the reason
                    const noteReason = result.value;
                    Swal.fire({
                        title: `${t('pleaseEnter')} Yubikey`,
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off',
                            id: "inputSwal2",
                            required: "true"
                        },
                        showCancelButton: true,
                        confirmButtonText: `${t('confirm')}`,
                        showLoaderOnConfirm: true,
                        cancelButtonText: `${t('cancel')}`,
                        preConfirm: (otp: string) => {

                            //2. Verify Yubikey OTP
                            return verifyOTP(otp).then(res2 => {
                                if (!res2.success) {
                                    throw new Error(res2.message || `${t('message.actionError')}`)
                                }

                                //3. Check if user have Yubikey in UserProfile config
                                if (!userProfile?.yubikey) {
                                    throw new Error(res2.message || `${t('message.cannotFindYubikeyConfig')}`)
                                }

                                //4. Check OTP with Yubikey in UserProfile config
                                if (!otp.includes(userProfile.yubikey)) {
                                    throw new Error(res2.message || `${t('message.thisYubikeyDoesNotMatch')}`)
                                }

                                //5. If pass all, create history
                                const model: IDocumentActionModel = {
                                    documentId: props.id,
                                    note: noteReason
                                };

                                privateAccessDocument(model).then((res3: any) => {
                                    if (!res3.success) {
                                        throw new Error(res3.message || `${t('message.thisYubikeyDoesNotMatch')}`)
                                    }
                                    getDetail();
                                });

                            }).catch(error => {
                                Swal.showValidationMessage(
                                    `${t('message.errorExcution')}: ${error}`
                                )
                                const inputSwal2 = window.document.getElementById('inputSwal2') as HTMLInputElement;
                                inputSwal2.value = "";
                            })
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    }).then((result2) => {
                        if (result2.isConfirmed) {
                            setSelectedFileIndex(index);
                            handleOpenDialogViewFile();
                        }
                    })

                }
            })
            return
        } else {
            setSelectedFileIndex(index);
            handleOpenDialogViewFile();
        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const getUserNameById = (id: string) => {
        const employee = employeeState.find((x: IEmployee) => x.employeeId === id);
        return employee?.employeeName || id;
    }

    const getDepartmentNameById = (id: string) => {
        const dept = userDepartmentState.find((x: IDepartment) => x.code === id);

        return dept?.name || id;
    }

    const getDepartmentNameByUserId = (userId: string) => {
        const dept = userDepartmentState.find((x: IDepartment) => x.employees.findIndex(y => y.employeeId === userId) != -1);
        return dept?.name || userId;
    }




    //----------------Reverse----------------------//
    useEffect(() => {
        selectedFileReverse && handleOpenDialogReverseFile();
    }, [selectedFileReverse])


    const handleOpenDialogReverseFile = () => {
        setDialogReverseFileOpen(true);
    };

    const handleReverseFileClick = (curFile: IDocumentFile) => {
        // kiểm tra file có yêu bảo mật 2 lớp usbToken
        if (curFile && curFile?.isSecurity === true) { //
            // open usbtoken
            Swal.fire({
                title: t('message.ThisIsSecurityFile'),
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off',
                    id: "inputSwal",
                    required: 'true'
                },
                showCancelButton: false,
                confirmButtonText: `<i class="fa fa-arrow-right"></i> ${t('confirm')}`,
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    //1. Collect the reason
                    const noteReason = result.value;
                    Swal.fire({
                        title: `${t('pleaseEnter')} Yubikey`,
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off',
                            id: "inputSwal2",
                            required: "true"
                        },
                        showCancelButton: true,
                        confirmButtonText: `${t('confirm')}`,
                        showLoaderOnConfirm: true,
                        cancelButtonText: `${t('cancel')}`,
                        preConfirm: (otp: string) => {

                            //2. Verify Yubikey OTP
                            return verifyOTP(otp).then(res2 => {
                                if (!res2.success) {
                                    throw new Error(res2.message || `${t('message.actionError')}`)
                                }

                                //3. Check if user have Yubikey in UserProfile config
                                if (!userProfile?.yubikey) {
                                    throw new Error(res2.message || `${t('message.cannotFindYubikeyConfig')}`)
                                }

                                //4. Check OTP with Yubikey in UserProfile config
                                if (!otp.includes(userProfile.yubikey)) {
                                    throw new Error(res2.message || `${t('message.thisYubikeyDoesNotMatch')}`)
                                }

                                //5. If pass all, create history
                                const model: IDocumentActionModel = {
                                    documentId: props.id,
                                    note: noteReason
                                };

                                privateAccessDocument(model).then((res3: any) => {
                                    if (!res3.success) {
                                        throw new Error(res3.message || `${t('message.thisYubikeyDoesNotMatch')}`)
                                    }
                                    getDetail();
                                });

                            }).catch(error => {
                                Swal.showValidationMessage(
                                    `${t('message.errorExcution')}: ${error}`
                                )
                                const inputSwal2 = window.document.getElementById('inputSwal2') as HTMLInputElement;
                                inputSwal2.value = "";
                            })
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    }).then((result2) => {
                        if (result2.isConfirmed) {
                            setSelectedFileReverse(curFile);
                        }
                    })

                }
            })
            return
        } else {
            setSelectedFileReverse(curFile);
        }
    }

    const handleCloseDialogReverseFile = () => {
        setDialogReverseFileOpen(false);
        setSelectedFileReverse(null);
    };


    const handleOnSaveReverseClick = () => {
        handleCloseDialogReverseFile();
        getDetail();
    };

    const handleAction = (data: any) => {
        if (data.active === 0) { // xóa document
            props.onReload && props.onReload(data)
            return
        }

        getDetail(true);
    }
    //------------Render-------------------------//

    return isLoading ?
        <DetailDocumentSkeleton />
        : (document && userDepartmentState.length > 0
            ?
            <div className="relative flex flex-col h-full min-w-0 mb-6 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border" >
                <DocumentAction actions={document.actions} id={document.id} onReload={handleAction} documentInfo={document} />
                <hr className="h-px my-1 bg-gray-300 border-0"></hr>
                <div className="overflow-auto h-[78svh]">
                    <div className="flex flex-wrap px-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl ">
                        <div className="w-full px-3 md:w-9/12">
                            <h6 className="flex items-center w-full justify-between ">
                                <span className="whitespace-nowrap overflow-hidden text-ellipsis w-10/12 py-2 ">
                                    <span className={`font-bold text-center align-middle transition-all 
                                        bg-transparent border-0 rounded-lg px-3 py-1 mr-2  leading-pro
                                        text-xs ease-soft-in tracking-tight-soft shadow-soft-md ${getDocumentPriorityColor(document?.priority)}`}>
                                        {t('documentPriority' + document?.priority)}
                                    </span>
                                    {`${document?.name} ${document.isManualVersion ? `(${t('version')} ${document.documentManualVersions[0].versionOrder})` : ``}`}
                                </span>
                            </h6>

                            <div className="mb-0 flex items-center w-full">
                                <div className="flex items-center">
                                    <label className="mb-0 mr-4" style={{ fontSize: '14px' }}><i className="fas fa-clock"></i> {`${getDateTimeString(new Date(document.createdDate))}`}</label>
                                    <label className="mb-0  mr-4" style={{ fontSize: '14px' }}><i className="fas fa-user"></i> {`${getUserNameById(document?.createdBy)}`}</label>
                                    <label className="mb-0 " style={{ fontSize: '14px' }}><i className="fa-solid fa-book"></i> {document?.documentTypeName}</label>
                                </div>
                            </div>
                            {document.documentLinks && (
                                <div className="relative w-full mt-4">
                                    <h6 className="mb-2 font-bold leading-tight uppercase text-xs text-slate-400">{`${t('documentLink')}`}</h6>
                                    {document.documentLinks.map((s: IDocumentLink) => (
                                        <Link key={s.id} to={`/document?s=${s.serial}&v=${getRandomIntInclusive(1, 1000)}`} className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                                            {`${s.serial}-${s.name}`}
                                        </Link>
                                    ))}
                                </div>
                            )}
                            {document.extendField && <ViewQuestions data={document?.extendField ? JSON.parse(document.extendField + '') : []} />}
                            <div className="relative w-full mt-4">
                                <h6 className="mb-2 font-bold leading-tight uppercase text-xs text-slate-400">{` ${t('description')}`}</h6>
                                <ul className="flex flex-col pl-0 mb-0 rounded-lg">
                                    <div dangerouslySetInnerHTML={{ __html: document.description }} />
                                </ul>
                            </div>
                        </div>
                        <div className="block md:w-3/12 w-full">
                            <span className={`float-right text-center mb-2 font-bold  align-middle transition-all
                                        bg-transparent border-0 rounded-lg px-3 py-1 mr-2  leading-pro
                                        text-xs ease-soft-in tracking-tight-soft shadow-soft-md ${getDocumentStatusColor(document?.status)}`}>
                                {t('documentStatus' + document?.status)}
                            </span>
                            <div className="w-full text-center clear-right">
                                <label className="mb-0 mr-4" style={{ fontSize: '14px' }}> <i className="mr-2 far fa-calendar-alt" aria-hidden="true"></i>{`${getDateString(new Date(document?.fromDate))} - ${getDateString(new Date(document?.toDate))}`}</label>
                            </div>
                            <div className="flex items-center p-1 hover:shadow-black hover:shadow-sm duration-1000" style={{ flexFlow: 'column' }}>
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "75px", width: "75px", float: "right" }}
                                    value={`${baseURL}/document?s=${document?.serial}`}
                                    viewBox={`0 0 256 256`}
                                />
                                <small className="flex items-center justify-end">
                                    {document?.serial}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="flex-auto p-4 pt-6">
                        <Box sx={{ bgcolor: 'background.paper' }}>
                            <AppBar position="static">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="scrollable"
                                    aria-label="full width tabs example"
                                    scrollButtons="auto"
                                >
                                    <Tab label={t('file')} {...a11yProps(0)} />
                                    <Tab label={t('history')} {...a11yProps(1)} />
                                    <Tab label={t('assignList')} {...a11yProps(2)} />
                                    <Tab label={t('version')} {...a11yProps(3)} />
                                </Tabs>
                            </AppBar>

                            <TabPanel value={value} index={0} dir={theme.direction}>
                                {document.documentFiles?.filter(x => x.active === IActiveId.Active).map((f, index) =>
                                    <li key={index} className="group/item  relative overflow-hidden shadow-sm flex items-center px-0 py-2 mb-2 bg-white border-0 rounded-t-lg  hover:bg-slate-50 duration-500">
                                        <div className="inline-flex items-center justify-center w-12 h-12 mr-4 transition-all duration-200 text-base ease-soft-in-out rounded-xl">
                                            <i className="fa-solid fa-file-lines text-3xl"></i>
                                        </div>
                                        <div className="flex flex-col items-start justify-center w-10/12 overflow-hidden text-ellipsis">
                                            <h6 className="mb-0 leading-normal text-sm whitespace-nowrap w-full overflow-hidden text-ellipsis">{f?.name}</h6>
                                            <div className="flex justify-between w-10/12">
                                                <input
                                                    readOnly
                                                    name="description"
                                                    className="mb-0 p-1 leading-tight text-xs w-2/3 m-1 text-ellipsis"
                                                    type="text"
                                                    value={`${t('description')}: ${f?.description || t('noInfo')}`}
                                                // placeholder={`${t('pleaseEnter')} ${t('description').toLocaleLowerCase()}`}
                                                />
                                                <input
                                                    readOnly
                                                    name="physicalAddress"
                                                    className="mb-0 p-1 leading-tight text-xs w-2/3 m-1 text-ellipsis"
                                                    type="text"
                                                    value={`${t('physicalAddress')}: ${physicalAddressState?.data?.find(x => x.id?.toString() === f?.physicalAddressId)?.name || t('noInfo')}`}
                                                // placeholder={`${t('pleaseEnter')} ${t('physicalAddress').toLocaleLowerCase()}`}
                                                />
                                            </div>
                                            
                                            <div className="overflow-x-auto whitespace-nowrap py-4 relative">
                                                <div className="flex items-center space-x-6 w-max px-4 relative">

                                                {f.documentReminders && f.documentReminders.map((r, index) => {
                                                        const isPast =r.dateTime &&  r.dateTime < new Date();
                                                        return (
                                                            <div key={index} className="relative w-40 text-center flex-shrink-0">
                                                                <div
                                                                    className={`px-2 py-1 rounded-md shadow-md text-sm ${isPast ? "bg-gray-400 text-gray-200" : "bg-blue-500 text-white"}`}
                                                                >
                                                                    {moment(r.dateTime).format('YYYY-MM-DD HH:mm')}
                                                                </div>
                                                                <div className={`w-1 h-4 mx-auto ${isPast ? "bg-gray-400" : "bg-blue-500"}`}></div>
                                                                <div className={`border p-2 rounded-md bg-white shadow ${isPast ? "border-gray-300 text-gray-400" : "border-gray-300"}`}>
                                                                    <p className="text-xs text-gray-600">{r.note}</p>
                                                                </div>
                                                                {f.documentReminders && index < f.documentReminders.length - 1 && (
                                                                    <div className={`absolute top-4 left-full w-12 border-t-2 ${isPast ? "border-gray-400" : "border-blue-500"}`}></div>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute flex flex-row justify-around w-1/12 right-2 group/edit invisible group-hover/item:visible" >
                                            <a onClick={() => handleViewFileClick(index)} className="font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg 
                            shadow-none cursor-pointer leading-pro text-xs ease-soft-in hover:scale-102 hover:active:scale-102 
                            active:opacity-85 text-fuchsia-500 hover:text-red-500 hover:shadow-none  active:scale-100">
                                                <i className="fas fa-eye"></i>
                                            </a>
                                            {document.createdBy === authState.id && <a onClick={() => handleReverseFileClick(f)} className="font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg 
                            shadow-none cursor-pointer leading-pro text-xs ease-soft-in hover:scale-102 hover:active:scale-102 
                            active:opacity-85 text-fuchsia-500 hover:text-red-500 hover:shadow-none  active:scale-100">
                                                <i className="fas fa-history"></i>
                                            </a>
                                            }
                                        </div>
                                    </li>
                                )}
                                <MediaViewer
                                    isFullscreen
                                    currentIndex={selectedFileIndex}
                                    files={(selectedFiles && selectedFiles.length > 0) ? selectedFiles.filter(x => x.active === IActiveId.Active).map(x => ({
                                        absoluteURL: `${fileURL}/${x.url}`,
                                        url: x.url,
                                        bucketName: 'document',
                                        contentType: x.type,
                                        fileName: x.name,
                                        folder: x.physicalAddress,
                                        isSucceed: true,
                                        size: x.size,
                                    } as IFileInfo)) : []}
                                    open={dialogViewFileOpen}
                                    onClose={handleCloseDialogViewFile}
                                    isLoadPresignedURL={true}
                                    isDisableSlideImage={true}
                                />
                                {selectedFileReverse && <FileReverseModal file={selectedFileReverse} open={dialogReverseFileOpen} onClose={handleCloseDialogReverseFile} onSave={handleOnSaveReverseClick} />}
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <h6 className="my-4 font-bold leading-tight uppercase text-xs text-slate-500">{t('history')}</h6>
                                <Hitory histories={document?.documentHistories} />
                            </TabPanel>
                            <TabPanel value={value} index={2} dir={theme.direction}>
                                <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                                    <thead className="align-bottom">
                                        <tr>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{`${t('name')} (${t('department')}/${t('employee')})`}</th>
                                            <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('processingDeadline')}</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('role')}</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('status')}</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('note')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            document.documentAssigns.map((da) => {
                                                return (
                                                    <tr key={`${da.departmentId}-${da.userId}`}>
                                                        <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <div className="flex px-2 py-1">
                                                                <div className="m-2">
                                                                    {da?.userId
                                                                        ? <i className="fas fa-user "></i>
                                                                        : <i className="fas fa-building"></i>}
                                                                </div>
                                                                <div className="flex flex-col justify-center">
                                                                    <h6 className="mb-0 text-sm leading-normal">{da.userId ? `${getUserNameById(da?.userId)} (${getDepartmentNameByUserId(da?.userId || t('noInfo'))})` : getDepartmentNameById(da?.departmentId?.toString() || t('noInfo'))}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <p className="mb-0 text-xs font-semibold leading-tight">{`${da?.fromDate ? getDateString(new Date(da?.fromDate)) : ''} - ${da?.toDate ? getDateString(new Date(da?.toDate)) : ''}`}</p>
                                                        </td>

                                                        <td className="p-2 text-sm leading-normal text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <p className="mb-0 text-xs font-semibold leading-tight">{t('documentAssignType' + da.type)}</p>
                                                        </td>
                                                        <td className="p-2 text-sm leading-normal text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <p className="mb-0 text-xs font-semibold leading-tight">{t('documentAssignStatus' + da.status)}</p>
                                                        </td>
                                                        <td className="p-2 text-sm leading-normal text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <p className="mb-0 text-xs font-semibold leading-tight">{da.note}</p>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel value={value} index={3} dir={theme.direction}>
                                {document?.isManualVersion
                                    ? <MaterialReactTable
                                        localization={getLangReactTable(i18n.language)}
                                        enablePagination={false}
                                        columns={[
                                            {
                                                header: t('version'), accessorKey: 'versionOrder'
                                            },
                                            {
                                                header: t('note'), accessorKey: 'note', Cell: (rowData) => {
                                                    return rowData.row.original.note;
                                                }
                                            },
                                            {
                                                header: t('promulgateDate'), accessorKey: 'promulgateDate', Cell: (rowData) => {
                                                    return `${getDateString(new Date(rowData.row.original.promulgateDate))}`;
                                                }
                                            },
                                            {
                                                header: t('createdDate'), accessorKey: 'createdDate', Cell: (rowData) => {
                                                    return `${getDateTimeString(new Date(rowData.row.original.createdDate))}`;
                                                }
                                            },
                                            {
                                                header: t('modifiedBy'), accessorKey: 'createdBy', Cell: (rowData) => {
                                                    return `${employeeState.find(y => y.employeeId === rowData.row.original.createdBy)?.employeeName || t('noInfo')}`;
                                                }
                                            },
                                        ]}
                                        data={document?.documentManualVersions || []}
                                        initialState={{
                                            showGlobalFilter: true,
                                            grouping: ['versionOrder'],
                                            sorting: [{ id: 'versionOrder', desc: false }]
                                        }}
                                        enableGrouping={true}
                                    />
                                    :
                                    <MaterialReactTable
                                        localization={getLangReactTable(i18n.language)}
                                        enablePagination={false}
                                        columns={[
                                            {
                                                header: t('version'), accessorKey: 'versionOrder'
                                            },
                                            { header: t('name'), accessorKey: 'name' },
                                            {
                                                header: t('action'), accessorKey: 'action', Cell: (rowData) => {
                                                    return t('documentVersionAction' + rowData.row.original.action);
                                                }
                                            },
                                            {
                                                header: t('modifiedDate'), accessorKey: 'modifiedDate', Cell: (rowData) => {
                                                    return `${getDateTimeString(new Date(rowData.row.original.modifiedDate))}`;
                                                }
                                            },
                                            {
                                                header: t('modifiedBy'), accessorKey: 'modifiedBy', Cell: (rowData) => {
                                                    return `${employeeState.find(y => y.employeeId === rowData.row.original.modifiedBy)?.employeeName}`;
                                                }
                                            },
                                        ]}
                                        data={document?.documentHistories?.flatMap(history => (history.documentVersions) || []) || []}
                                        initialState={{
                                            showGlobalFilter: true,
                                            grouping: ['versionOrder'],
                                            sorting: [{ id: 'versionOrder', desc: false }]
                                        }}
                                        enableGrouping={true}
                                    />}
                            </TabPanel>
                        </Box>
                    </div>
                </div >
            </div >
            :
            <div className="p-6 px-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                <div className="flex flex-wrap w-full">
                    <div className="w-full px-5 text-center">
                        <h6 className="mb-0 w-full">{t('noInfo')}</h6>
                    </div>
                </div>
            </div>)
}
export default DetailDocument