
export interface IProgressBarDataSet {
    [key: string]: {
        value?: number,
        tooltipValue?: number,
    };
}

export interface IActualTargetBarDataSet {
    [key: string]: {
        actual?: number;
        tatget?: number;
    };
}

export interface IGroupBarDataSet {
    key1: string;
    key2: string;
    actual?: number;
    tatget?: number;
}

export interface IBarConfig {
    label: string;
    backgroundColor?: string;
    borderColor?: string;
    borderWidth?: number;
    type?: 'bar' | 'scatter';
    showLine?: boolean;
    pointRadius?: number;
    order?: number;
}

export const initBackgroundColors = [
    "rgba(255, 206, 86, 0.6)",  // Màu vàng nhạt
    "rgba(54, 162, 235, 0.6)",   // Màu xanh dương
    "rgba(255, 99, 132, 0.6)",   // Màu đỏ hồng
    "rgba(75, 192, 192, 0.6)",   // Màu xanh ngọc
    "rgba(153, 102, 255, 0.6)",  // Màu tím nhạt
    "rgba(255, 159, 64, 0.6)",   // Màu cam
    "rgba(255, 99, 71, 0.6)",    // Màu đỏ cà chua
    "rgba(100, 149, 237, 0.6)",   // Màu xanh ngô
    "rgba(255, 215, 0, 0.6)",     // Màu vàng
    "rgba(60, 179, 113, 0.6)",    // Màu xanh lá vừa
    "rgba(255, 105, 180, 0.6)",   // Màu hồng nóng
    "rgba(128, 0, 128, 0.6)",      // Màu tím
    "rgba(255, 0, 0, 0.6)",        // Màu đỏ
    "rgba(0, 255, 0, 0.6)",        // Màu xanh lá cây
    "rgba(0, 0, 255, 0.6)",        // Màu xanh dương đậm
    "rgba(255, 255, 0, 0.6)",      // Màu vàng tươi
    "rgba(255, 192, 203, 0.6)",    // Màu hồng nhạt
    "rgba(128, 128, 0, 0.6)",      // Màu vàng ô liu
    "rgba(0, 128, 128, 0.6)",      // Màu xanh ngọc đậm
    "rgba(75, 0, 130, 0.6)"         // Màu chàm
];
