import { Autocomplete, Chip, IconButton, InputAdornment, OutlinedInput, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { IDepartment, IEmployee, IOrgSelectedItem } from "src/interfaces/IOrg"
import OrgDialog from "./OrgDialog"
import { useSelector } from "react-redux"
import { selectEmployeeState, selectUserDepartmentState } from "src/store/slices/commonSlice"
import { t } from "i18next"
import { Clear, HighlightOff } from "@mui/icons-material"

interface IProps {
    label?: string,
    name?: string,
    required?: boolean,
    data?: IOrgSelectedItem[],
    onChange?: (e: any) => void,//if multiple =>  IOrgSelectedItem[], else => any
    defaultValue: IOrgSelectedItem | IOrgSelectedItem[]
    multiple?: boolean,
    selectEmployeesOnly?: boolean,
    showEmployees?: boolean,
    exclude?:string[]
}
const OrgSelect = (props: IProps) => {
    const [stateOrg, setStateOrg] = useState({
        open: false,
        reload: 0
    })
    const [selectedOrgs, setSelectedOrgs] = useState<IOrgSelectedItem[]>(props.data || []);
    const [val, setVal] = useState<IOrgSelectedItem | IOrgSelectedItem[]>(props.defaultValue)
    const userDepartmentState = useSelector<IDepartment[], IDepartment[]>(selectUserDepartmentState)
    const employeeState = useSelector<IEmployee[], IEmployee[]>(selectEmployeeState)

    // useEffect(() => {
    //   setVal(props.defaultValue)

    // }, [props.defaultValue])

    const departmentToOrg = (item: IDepartment) => {
        const selectedOrg: IOrgSelectedItem = {
            id: item.id,
            label: item.name || ('noName'),
            type: 'department',
            department: item,
            parentId: item?.parentId
        };

        return selectedOrg;
    }

    const employeeToOrg = (item: IEmployee) => {
        const selectedOrg: IOrgSelectedItem = {
            id: item.id,
            label: item.employeeName || ('noName'),
            type: 'employee',
            employee: item
        };

        return selectedOrg;
    }

    const onShowOrg = () => {
        setStateOrg({ open: true, reload: stateOrg.reload + 1 })
    }
    const onSaveSelectedOrgs = (orgs: IOrgSelectedItem[]) => {
        setStateOrg({ open: false, reload: stateOrg.reload + 1 })
        if (!orgs || orgs?.length === 0)
            return
        setSelectedOrgs(orgs)
        const value = props.multiple ? orgs : orgs[0] || props.defaultValue
        setVal(value)
        console.log(value);


        if (props.onChange) {
            if (!props.multiple) {
                const inputElement = document.createElement('input')
                inputElement.type = 'text';
                inputElement.name = props.name || '';
                document.body.appendChild(inputElement);
                inputElement.onchange = (e: any) => props.onChange && props.onChange(e)
                inputElement.value = (orgs[0].type === 'department' ? orgs[0].department?.code : orgs[0].employee?.employeeId) || ''
                inputElement.dispatchEvent(new Event('change'))
                inputElement.remove();

                return;
            }

            props.onChange && props.onChange(orgs)

        }
    }

    const onCloseSelectedOrgs = () => {

    }

    const handleRemoveSelectedOrg = (index: number) => {
        if (Array.isArray(val)) {
            let updatedVal = [...val];
            updatedVal.splice(index, 1)//dang9 alam2 removve
            setVal(updatedVal)
            console.log(updatedVal);

        }
    }

    return (
        <div className="w-full">
            {props.label && <label htmlFor={props.name} className="block text-sm font-medium leading-5 text-gray-800">
                {props.label} {props.required === true && (<span className="font-bold text-red-600">*</span>)}
            </label>}
            <div className="mt-2">
                <Autocomplete
                    readOnly
                    value={val}
                    multiple={props.multiple}
                    options={[...userDepartmentState.map(x => departmentToOrg(x)), ...employeeState.map(x => employeeToOrg(x))]}
                    getOptionLabel={(option) => option.label}
                    renderTags={(value: any, getTagProps) =>
                        value?.map((option: IOrgSelectedItem, index: number) => (
                            <div key={option.id} style={{ display: 'flex', position: 'relative', margin: 1.5 }}>
                                <Chip sx={{ paddingRight: 3 }} variant="outlined" label={option.label} color={option.type === 'department' ? 'primary' : 'warning'} />
                                <IconButton onClick={() => handleRemoveSelectedOrg(index)} size="small" sx={{ position: 'absolute', right: 0 }} color={option.type === 'department' ? 'primary' : 'warning'} >
                                    <HighlightOff />
                                </IconButton>
                            </div>
                        ))
                    }
                    // renderOption={(props, option, { selected }) => (
                    //     <li {...props} style={{ display: 'none' }}>
                    //     </li>
                    // )}
                    PopperComponent={() => <></>}
                    disableClearable
                    renderInput={(params) =>
                        props.multiple
                            ?
                            <div style={{ display: 'flex', position: 'relative' }}>
                                <TextField
                                    {...params}
                                    label={`${t('department')} / ${t('employee')}`}
                                    name={props.name}
                                    margin="normal"
                                    size="small"
                                    style={{
                                        padding: '0px 12px 0 5px',
                                        borderRadius: '0.5rem',
                                    }}
                                />
                                <IconButton
                                    onClick={onShowOrg}
                                    sx={{
                                        position: 'absolute',
                                        top: '51.5%',
                                        left: '96.5%',
                                        transform: 'translate(-50%, -50%)',
                                        borderRadius: '0%'
                                    }}
                                >
                                    <i className="fas fa-users mr-2" />
                                </IconButton>
                            </div>
                            :
                            <OutlinedInput
                                style={{
                                    padding: '0px 12px 0 5px',
                                    borderRadius: '0.5rem',

                                }}
                                name={props.name}
                                {...params}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={onShowOrg}
                                            edge="end"
                                        >
                                            <i className="fas fa-users mr-2" />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                    }
                />


                <OrgDialog
                    multiple={props.multiple}
                    open={stateOrg.open}
                    reload={stateOrg.reload}
                    onSave={onSaveSelectedOrgs}
                    onClose={onCloseSelectedOrgs}
                    showEmployees={props.showEmployees}
                    selectEmployeesOnly={props.selectEmployeesOnly}
                    exclude={props.exclude}
                    data={selectedOrgs} />
            </div>
        </div>
    )
}

OrgSelect.defaultProps = {
    multiple: false,
    defaultValue: { id: '-1', label: '', type: 'department' },
    selectEmployeesOnly: false,
    showEmployees: false

}
export default OrgSelect