import firebase, { firebaseConfig } from "src/firebase"
import "firebase/messaging";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { onBackgroundMessage } from "firebase/messaging/sw";

// if (!firebase.apps.length) {
//     firebase.initializeApp(firebaseConfig);
// } else {
//     firebase.app(); // if already initialized, use that one
// }

// let messaging: firebase.messaging.Messaging;

// if (typeof window !== "undefined") {
//     if (firebase.messaging.isSupported()) {
//         messaging = firebase.messaging();
//     }
// }

export interface NotificationPayload {
    data?: {
        [key: string]: string;
    };
    from?: string;
    collapseKey?: string;
    messageId?: string;
    notification?: {
        title?: string;
        body?: string;
        icon?: string;
    };
}

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);


export const getMessagingToken = async () => {
    let currentToken = "";
    if (!messaging) return;
    try {
        currentToken = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY,
        });
        // console.log("FCM registration token: ")
        // console.log(currentToken);
    } catch (error) {
        console.log("An error occurred while retrieving token. ", error);
    }
    return currentToken;
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

export const onBackgroundMessageListener = () =>
    new Promise((resolve) => {
        onBackgroundMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

export const deleteTokenListener = () =>
    new Promise((resolve) => {
        resolve(deleteToken(messaging));
    });


// messaging.onBackgroundMessage(function (payload) {
//     console.log('[firebase-messaging-sw.js] Received background message ', payload);
//     // Customize notification here
//     const notificationTitle = 'Background Message Title';
//     const notificationOptions = {
//         body: 'Background Message body.',
//         icon: '/firebase-logo.png'
//     };

//     self.registration.showNotification(notificationTitle,
//         notificationOptions);
// });
