// lấy all danh sách user
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Api from "src/apis/apiCommon";
import { initialState } from "src/apis/apiCommon";
// module
export const fetchBrand = createAsyncThunk("fetchBrand", async () => {
  const res = await Api.fetchBrand();
  return res;
});
const brandSlice = createSlice({
  name: "brands", // tên này phải giống tên khai báo ./reducer.ts
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // module
      .addCase(fetchBrand.fulfilled, (state: any, action: any) => {
        state.datas = action.payload.data;
      })
  },
});

export const selectBrand = (state: any) => state.brands.datas;// state.<tên> có trong khai báo ./reducer.ts
export default brandSlice.reducer;
