import { Grid, Chip, Box, Tooltip, IconButton, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Edit, Delete, Add } from "@mui/icons-material";
import { toast } from "react-toastify";
import { deleteMenu, fetchMenu, createMenu, updateMenu } from "src/apis/permission/apiMenu";
import getLangReactTable from "src/commons/helpers/getLangReactTable";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { IApplication } from "src/interfaces/permission/IApplication";
import SaveMenu from "src/views/permission/SaveMenu";

let indexReload = 1
const MenuPage = ()=>{
    const { t, i18n } = useTranslation();
    const [data, setData] = useState<any[]>([])
    const [state, setState] = useState({
        showEdit: false,
        reLoadEdit: 1,
        keyword: '',
        detail: {}
    })

    useEffect(() => {
        if (data.length === 0)
            loadData()
    }, [])

    const loadData = () => {
        fetchMenu().then((rs: any) => {
            if (rs.success) {
                setData(rs.data)
                setState({ ...state, showEdit: false, reLoadEdit: state.reLoadEdit + 1 })
            }
        })
    }

    const handleOnSave = (objData: any) => {
        // kiểm tra id đã tồn tại
        if (Object.keys(state.detail).length === 0 && data.some((rs: any) => rs.id === objData.id)) {
            toast.warning(t('common.roleIdIsExist'))
            return
        }
        const functionApi = objData.isCreated ? createMenu : updateMenu

        functionApi(objData).then(rs => {
            if (rs.success) {
                if (objData.isCreated) {
                    setData([rs.data, ...data])
                }
                else {
                    const dataUpdate: any[] = [...data];
                    const index = data.findIndex((x: IApplication) => x.id === objData.id);
                    dataUpdate[index] = rs.data
                    setData([...dataUpdate]);
                }
                indexReload = indexReload + 1
                setState({
                    ...state,
                    reLoadEdit: indexReload,
                    showEdit: false,
                    detail: {}
                })
            }
            else toast.error(rs)
        })
    }
    const handleOnDelete = (rowData: any) => {
        // show thông báo xác nhận
        if (window.confirm(t('common.actionDelete') + ` "${rowData.original.name}"?`) === true) {
            deleteMenu(rowData.original.id || '')
                .then(rs => {
                    if (rs.success) {
                        const dataDelete = [...data];
                        const index = dataDelete.findIndex((obj: any) => obj.id === rowData.original.id);
                        if (index !== -1)
                            setData([...data.slice(0, index), ...data.slice(index + 1),])
                    }
                })
        }
    }
    const handleOnEdit = (rowData: any) => {
        indexReload = indexReload + 1
        setState({
            ...state,
            showEdit: true,
            reLoadEdit: indexReload,
            detail: rowData.original
        })
    }
    const handleOnAdd = () => {
        setState({
            ...state,
            showEdit: true,
            reLoadEdit: state.reLoadEdit + 1,
            detail: {}
        })
    }

    const table = useMaterialReactTable({
        enableEditing: true,
        data: data,
        localization: getLangReactTable(i18n.language),
        columns: [
            { header: t('name'), accessorKey: 'name' },
            { header: t('module'), accessorKey: 'moduleId' },
            { header: t('parentId'), accessorKey: 'parentId' },
            { header: t('icon'), accessorKey: 'iconClass' },
            { header: t('url'), accessorKey: 'url' },
            { header: t('position'), accessorKey: 'position' },
            { header: t('user'), accessorKey: 'userId' },
            { header: t('department'), accessorKey: 'departmentId' }
        ],
        renderRowActions: ({ row, table }: any) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => handleOnEdit(row)}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                    <IconButton onClick={() => handleOnDelete(row)}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>),
        renderTopToolbarCustomActions: ({ table }: any) => (
            <Button
                variant="contained"
                onClick={() => handleOnAdd()}
            >
                <Add /> {t('add')}
            </Button>
        )
    })

    return(
        <Grid container spacing={3}>
        <Grid item xs={12}>
            <MaterialReactTable table={table} />
        </Grid>
        <Grid item xs={12}>
            <SaveMenu
                show={state.showEdit}
                reload={state.reLoadEdit}
                data={state.detail}
                onSave={handleOnSave} />
        </Grid>
    </Grid>
    )
}
export default MenuPage