import { IGroup } from "src/interfaces/permission/IGroup";
import api, { ResultObject } from "../base/base";

const baseUrl = `${process.env.REACT_APP_API_PERMISSION}/api/group`;

export const fetchGroup = async (): Promise<ResultObject> => {
  const result = await api
    .get(baseUrl )
    .then((response: any) => response?.data);
  return result;
};

export const createGroup = async (
  obj: IGroup
): Promise<any> => {
  const result = await api
    .post(`${baseUrl}`, obj)
    .then((response: any) => response?.data);
  return result;
};

export const updateGroup = async (
    obj: IGroup
): Promise<any> => {
  const result = await api
    .put(`${baseUrl}`, obj)
    .then((response: any) => response?.data);
  return result;
};

export const deleteGroup = async (
    id:string
): Promise<any> => {
  const result = await api
    .delete(`${baseUrl}/${id}`)
    .then((response: any) => response?.data);
  return result;
};


// user application
export const fetchDetail = async (id:string =''): Promise<ResultObject> => {
  const result = await api
    .get<ResultObject>(baseUrl+'/detail/'+id)
    .then((response: any) => response.data);
  return result;
};