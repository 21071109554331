import { useState } from "react"

const classTd = 'border border-gray-600 px-4 py-2'
const classTh = 'border bg-orange-300 border-gray-600 px-4 py-2'

const InventorySurveyReport = () => {
    const [search, setSearch] = useState({
        type:'year',
    })
    const handleOnChangeSelectTime = (e:any) => {
       
    }
    return (
        <div className="w-full grid grid-cols-12 text-blue-900 gap-2">
            <div className="col-span-12 grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-4">
                    <label className="block mb-2 text-sm font-medium text-gray-900">Chọn loại thời gian</label>
                    <select onChange={handleOnChangeSelectTime} className="bg-white-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        <option selected value="year">Năm</option>
                        <option value="quarter">Quý</option>
                        <option value="month">Tháng</option>
                        <option value="day">Ngày</option>
                    </select>
                </div>
                <div className="col-span-12 md:col-span-4">
                    <label className="block mb-2 text-sm font-medium text-gray-900">Từ năm</label>
                    <select onChange={handleOnChangeSelectTime} className="bg-white-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        {[...Array(new Date().getFullYear() - 2000)].map((v: any, index: number) => {
                            const value = new Date().getFullYear() - index
                            return (<option key={value} className="" value={value}>{value}</option>)
                        })}
                    </select>
                    <div className="w-full">
                        <label className="block mt-4 mb-2 text-sm font-medium text-gray-900">Từ quý</label>
                        <select onChange={handleOnChangeSelectTime} className="bg-white-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            {[...Array(4)].map((v: any, index: number) => {
                                const value = 4 - index
                                return (<option key={value} className="" value={value}>{value}</option>)
                            })}
                        </select>
                    </div>
                    <div className="w-full">
                        <label className="block mt-4 mb-2 text-sm font-medium text-gray-900">Từ tháng</label>
                        <select onChange={handleOnChangeSelectTime} className="bg-white-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            {[...Array(12)].map((v: any, index: number) => {
                                const value = 12 - index
                                return (<option key={value} className="" value={value}>{value}</option>)
                            })}
                        </select>
                    </div>
                    <div className="w-full">
                        <label className="block mt-4 mb-2 text-sm font-medium text-gray-900">Từ ngày</label>
                        <input type="date" className="bg-white-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                    </div>
                </div>
                <div className="col-span-12 md:col-span-4">
                    <label className="block mb-2 text-sm font-medium text-gray-900">Đến năm</label>
                    <select onChange={handleOnChangeSelectTime} className="bg-white-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        {[...Array(new Date().getFullYear() - 2000)].map((v: any, index: number) => {
                            const value = new Date().getFullYear() - index
                            return (<option key={value} className="" value={value}>{value}</option>)
                        })}
                    </select>
                </div>
            </div>


            <div className="col-span-12">
                <table className="min-w-full border-collapse">
                    <thead>
                        <tr>
                            <th className={classTh}>Kênh</th>
                            <th className={classTh}>Đơn vị</th>
                            <th className={classTh} colSpan={6}>Số lượng đơn hàng</th>
                            <th className={classTh}>Maqqette Logo</th>
                            <th className={classTh} colSpan={2}>Số lượng xử lý kiến nại</th>
                            <th className={classTh} colSpan={2}>Hỏi hàng</th>
                        </tr>
                        <tr>
                            <th className={classTd}></th>
                            <th className={classTd}></th>
                            <th className={classTd}>Logo</th>
                            <th className={classTd}>Cọc</th>
                            <th className={classTd}>Trữ</th>
                            <th className={classTd}>Ktra</th>
                            <th className={classTd}>Xuất/Mẫu/Tặng/Khác</th>
                            <th className="border border-gray-600 px-4 py-2 text-red">Tổng</th>
                            <th className={classTd}></th>
                            <th className={classTd}>Sản phẩm</th>
                            <th className={classTd}>Dịch vụ</th>
                            <th className={classTd}>Số lượng SKU</th>
                            <th className={classTd}>Số lượng sản phẩm</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={classTd} rowSpan={3}>Nhóm A</td>
                            <td className={classTd}>Dữ liệu A1</td>
                            <td className={classTd}>Dữ liệu A2</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-span-12">
                <table className="min-w-full border-collapse">
                    <thead>
                        <tr>
                            <th className={classTh}>Kênh</th>
                            <th className={classTh}>Tên nhân viên Admin</th>
                            <th className={classTh} colSpan={6}>Số lượng đơn hàng</th>
                            <th className={classTh}>Logo</th>
                            <th className={classTh} colSpan={2}>Số lượng xử lý kiến nại</th>
                            <th className={classTh} colSpan={2}>Hỏi hàng</th>
                        </tr>
                        <tr>
                            <th className={classTd}></th>
                            <th className={classTd}></th>
                            <th className={classTd}>Logo</th>
                            <th className={classTd}>Cọc</th>
                            <th className={classTd}>Trữ</th>
                            <th className={classTd}>Ktra</th>
                            <th className={classTd}>Xuất/Mẫu/Tặng/Khác</th>
                            <th className="border border-gray-600 px-4 py-2 text-red">Tổng</th>
                            <th className={classTd}>Maqqette Logo</th>
                            <th className={classTd}>Sản phẩm</th>
                            <th className={classTd}>Dịch vụ</th>
                            <th className={classTd}>Số lượng SKU</th>
                            <th className={classTd}>Số lượng sản phẩm</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={classTd} rowSpan={3}>Nhóm A</td>
                            <td className={classTd}>Dữ liệu A1</td>
                            <td className={classTd}>Dữ liệu A2</td>
                        </tr>
                        <tr>
                            <td className={classTd}>Dữ liệu A3</td>
                            <td className={classTd}>Dữ liệu A4</td>
                        </tr>
                        <tr>
                            <td className={classTd}>Dữ liệu A5</td>
                            <td className={classTd}>Dữ liệu A6</td>
                        </tr>
                        <tr>
                            <td className={classTd} rowSpan={2}>Nhóm B</td>
                            <td className={classTd}>Dữ liệu B1</td>
                            <td className={classTd}>Dữ liệu B2</td>
                        </tr>
                        <tr>
                            <td className={classTd}>Dữ liệu B3</td>
                            <td className={classTd}>Dữ liệu B4</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default InventorySurveyReport