import { useTranslation } from "react-i18next"
import { IHtmlInput } from "src/interfaces/IHtmlInput"

interface IProps {
    data: IHtmlInput[],
}
const ViewQuestions = (props: IProps) => {
    const { t } = useTranslation()
    return (
        <div className="flex flex-wrap">
            <div className="relative w-full mt-4">
                <h6 className="mb-2 font-bold leading-tight uppercase text-xs text-slate-400">{`${t('moreInformation')}`}</h6>
                {props.data?.map((x: IHtmlInput) => (
                    <div key={x.name}>
                        <b className="text-gray-500 text-xs">{x.label}</b>: {x.value}    
                    </div>
                ))}
            </div>
        </div>
    )
}
export default ViewQuestions