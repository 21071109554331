import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import Swal from "sweetalert2";
const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const isTokenExpired = (token: string): boolean => {
  if (!token) return true; // Token không tồn tại thì xem như đã hết hạn
  const payload = JSON.parse(atob(token.split(".")[1])); // Giả định token là JWT
  const expirationTime = payload.exp * 1000; // Chuyển đổi thành mili giây
  return Date.now() >= expirationTime;
};

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const authHeader = config.headers.Authorization as string;
    const language = localStorage.getItem("language");

    if (authHeader) {
      const headers = authHeader.split(" ");
      if (headers.length > 1) {
        const token = headers[1];
        if (isTokenExpired(token)) {
          localStorage.setItem("isTokenExpired", "1");

          if (
            localStorage.getItem("isHiddenTokenExpired") &&
            localStorage.getItem("isHiddenTokenExpired") === "1"
          ) {
            return config;
          }

          Swal.fire({
            title:
              language === "vi"
                ? "Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại"
                : "Your session has expired, please login again.",
            icon: "warning",
            input: "checkbox",
            inputValue: 0,
            inputPlaceholder: `
          ${language === "vi" ? "Không hiển thị lại" : "Do not show it again"}
          `,
            confirmButtonText: `
          ${
            language === "vi" ? "Đã hiểu" : "OK"
          }&nbsp;<i class="fa fa-arrow-right"></i>
          `,
          }).then(
            (fulfilled) => {
              if (fulfilled.isConfirmed) {
                Swal.fire({
                  title:
                    language === "vi"
                      ? "Bạn có muốn tới trang đăng nhập?"
                      : "Do you want to move to login page?",
                  showCancelButton: true,
                  confirmButtonText: language === "vi" ? "Đồng ý" : "OK",
                  cancelButtonText:
                    language === "vi" ? "Để sau" : "Still on this page",
                })
                  .then((result) => {
                    if (result.isConfirmed) {
                      window.location.href = "/login";
                    }
                  })
                  .finally(() => {
                    localStorage.setItem(
                      "isHiddenTokenExpired",
                      fulfilled.value
                    );
                  });
              }
            },
            (reject) => {
              console.log("reject", reject);
            }
          );
        }
      }
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res: any) => {
    return res;
  },
  (err: any) => {
    //HidenLoading()
    // nếu ở trạng thái off line thì
    if (err.code === "ERR_NETWORK") {
      return {
        data: {
          success: false,
          status: 503,
          message: err.message,
          url: err.config.url,
          method: err.config.method.toUpperCase(),
          body: err.config.data,
          data: "", // Dùm thêm data. nguyên nhân nếu gọi bị lỗi thì api res.data không tìm thấy nên lỗi
        },
      };
    }

    if (!err.response) return { data: { success: false } };

    if (
      err.response.status === 401 &&
      !!err.response.data &&
      err.response.data.Error !== null &&
      err.response.data.Error !== undefined &&
      err.response.data.Error === "NoPermission"
    ) {
      window.location.href = "/401";
    } else if (err.response.status === 401) {
      window.location.href = `/401?url=${window.location.pathname + window.location.search}`;
    } else if (err.response.status === 400) {
      return {
        data: {
          success: false,
          status: 400,
          message: JSON.stringify(err.response.data.errors),
          data: "",
        },
      };
    }
  }
);

export type ResultObject = {
  message: string | null;
  success: boolean;
  Success: boolean;
  data: any | null;
  isWarning?: boolean | false;
  status: number;
};

export type ApiResponse<T> = {
  message: string | null;
  success: boolean;
  Success: boolean;
  data: T | null;
  isWarning?: boolean | false;
  status: number;
};

export default api;
