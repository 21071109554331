import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';


import translationEN from './en.json';
import translationZH from './zh.json';
import translationVI from './vi.json';
import translationDe from './de.json';

const resources = {
  en: {
    translation: translationEN,
  },
  zh: {
    translation: translationZH,
  },
  vi: {
    translation: translationVI,
  },
  de: {
    translation: translationDe,
  },
};


i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: `${localStorage.getItem('language') ? localStorage.getItem('language') : 'vi'}`,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
