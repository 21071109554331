import { useTranslation } from "react-i18next"
import InputText from 'src/components/inputs/InputText'
import Select from "src/components/selects/select"
import UploadFile, { IFileUploadModel } from "src/views/upload-file/UploadFile"
import { useFormik } from "formik"
import * as yup from 'yup'
import UserPermission from "src/views/document/UserPermission"
import Button from "src/components/buttons/Button"
import { useEffect, useRef, useState } from "react"
import { createDocument, getDocumment, getDocummentDetail, getSerial, updateDocument } from "src/apis/apiDocument"
import { useDocumentType, useEmployee } from "src/store/hook"
import { useSelector } from "react-redux"
import { selectDocumentTypeState } from "src/store/slices/documentTypeSlice"
import { IDocumentType } from "src/interfaces/IDocumentType"
import { useLocation } from "react-router"
import { toast } from "react-toastify"
import { IDocument } from "src/interfaces/IDocument"
import InputRadio from "src/components/inputs/InputRadio"
import { IFileResponse } from "src/interfaces/IFile"
import { IDocumentFile } from "src/interfaces/IDocumentFile"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { enumToListSelect } from "src/commons/helpers/common"
import { DocumentPriority } from "src/commons/enums/documentPriority"
import { HidenLoading, ShowLoading } from "src/components/loading-page/LoadingPage"
import OrgSelect from "src/components/org/OrgSelect"
import { selectAuthState } from "src/store/slices/authSlice"
import { IActiveId } from "src/commons/enums/activeId"
import CustomAutoCompleteAsync from "src/components/auto-complete/CustomAutoCompleteAsync"
import { ICustomAutoComplete } from "src/components/auto-complete/CustomAutoComplete"
import { size } from "lodash"
import InputQuestions from "src/views/questions/InputQuestions"
import { IHtmlInput } from "src/interfaces/IHtmlInput"
import { IDocumentAssign } from "src/interfaces/IDocumentAssign"
import Swal from "sweetalert2"

let isDraft = false
const listAssign = [0, 1, 2]
const DocumentEdit = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    useDocumentType(true)
    useEmployee()
    const location = useLocation();
    const id = location.pathname.split('/').pop() || '0'
    const isEdit = id !== null && id !== '' && id !== '0'

    const documentTypeState = useSelector(selectDocumentTypeState)
    const authState = useSelector(selectAuthState)

    const [questions, setQuestions] = useState<{ data: IHtmlInput[], reload: number }>({ reload: 1, data: [] })
    const fileRef = useRef<any>()
    const userDeptRef = useRef<any>()
    const inputQuestionsRef = useRef<any>()
    useEffect(() => {
        if (id && id !== "0")
            getDetail(id)
        else
            getApiSerial(documentTypeState?.data[0]?.id || 0)
    }, [id])



    const getDetail = (curId: string) => {
        ShowLoading()
        getDocummentDetail(curId).then(rs => {
            if (rs && rs.success) {
                if (!rs.data) {
                    // không có quyền truy cập
                    toast.warning(t('message.notHaveAccess'))
                    navigate(`/document`)
                    return
                }

                const resData: IDocument = rs.data;
                let updatedFormikValue = {
                    ...rs.data
                };

                if (resData.isManualVersion && resData?.documentManualVersions && resData.documentManualVersions.length > 0) {
                    updatedFormikValue = {
                        ...updatedFormikValue,
                        promulgateDate: new Date(resData.documentManualVersions[0].promulgateDate),
                        versionOrder: resData.documentManualVersions[0].versionOrder,
                        note: resData.documentManualVersions[0].note
                    }
                }

                formik.setValues(updatedFormikValue)
                const fileMap: IFileUploadModel[] = rs.data.documentFiles.filter((f: IDocumentFile) => f.active === IActiveId.Active).map((f: IDocumentFile) => {

                    const item: IFileUploadModel = {
                        name: f.name,
                        size: f.size,
                        type: f.type,
                        description: f.description,
                        physicalAddress: f.physicalAddressId?.toString() || null,
                        metaData: f?.id || '0',
                        isSecurity: f.isSecurity || false,
                        documentReminders:f.documentReminders
                    }
                    return item
                })
                fileRef.current.setData(fileMap)

                userDeptRef.current.setData(rs.data.documentAssigns)

                // bộ câu hỏi
                const dataQuestion = resData.extendField !== '' && resData.extendField !== null ? JSON.parse(resData.extendField + '') : []
                setQuestions({
                    data: dataQuestion,
                    reload: questions.reload + 1
                })
            }
            else toast.warning(t('message.actionError'))
        }).finally(() => HidenLoading())
    }

    const getApiSerial = (value: string, deptId?: string) => {
        const dept = deptId || formik.values.departmentId || authState.departmentId || ''
        getSerial(value, dept).then(rs => {
            if (rs.success) {
                formik.setValues({
                    ...formik.values,
                    serial: rs.data?.serial,
                    documentTypeId: parseInt(value),
                    isAutoSerial: rs.data.isAutoSerial,
                    isDate: rs.data.isDate,
                    extendField: rs.data.extendField || '',
                    departmentId: deptId || ''
                })
                // bộ câu hỏi
                const dataQuestion = rs.data.extendField !== '' && rs.data.extendField !== null ? JSON.parse(rs.data.extendField + '') : []
                setQuestions({
                    data: dataQuestion,
                    reload: questions.reload + 1
                })
            }
        })
    }

    const handleClose = () => {
        navigate(-1)
    }

    const documentTypeOnChange = (e: any) => {
        getApiSerial(e.target.value)
    }

    const handleOnChangeDepartment = (e: any) => {
        getApiSerial(formik.values.documentTypeId + '', e.target.value)
    }
    const validationSchema = yup.object({
        name: yup.string().required(),
        //reminderTime:yup.string().matches(/^(0|[1-9]|[0-9].[1-9]\d*)$/,t('message.valueGreaterThanZero')),
    });

    const formik = useFormik({
        initialValues: {
            id: undefined,
            serial: '',
            name: '',
            description: '',
            fromDate: new Date(),
            toDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
            priority: 0,
            documentTypeId: 1,
            departmentId: '',
            isPublic: false,
            reminderTime: 0,
            isDate: true,
            isAutoSerial: true,
            documentLink: '',
            extendField: '',
            //manual version
            isManualVersion: false,
            promulgateDate: new Date(),
            versionOrder: 1,
            note: ''
        },
        validationSchema,
        onSubmit: async (values: any) => {
            const defaultFolder: string = formik.values?.serial;
            let status = 1//new
            let strQuestion = ''
            let assigns = [], fileMap = []
            if (!isDraft && !defaultFolder) {
                toast.error(t('serialNotNull'));
                return;
            }
            assigns = userDeptRef?.current.getData() || []
            const questions = await inputQuestionsRef?.current?.getData() || []
            strQuestion = JSON.stringify(questions)
            //validate
            // nêu không có người xử lý
            const asssignProcess = assigns.find((x: IDocumentAssign) => listAssign.indexOf(x.type) !== -1)
            if (!isDraft && (!asssignProcess || asssignProcess?.length === 0)) {
                const config = await Swal.fire({
                    title: t('message.areYouSureYouWantToCompleteIt'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    confirmButtonText: t('documentAction11'),
                    cancelButtonText: t('cancel'),
                    allowOutsideClick: () => !Swal.isLoading()
                })
                if (!config.isConfirmed)
                    return
                else
                    status = 3 // hoàn thành
            }

            // upload file
            ShowLoading()
            const files = await fileRef?.current.getData('document', defaultFolder.replace('/', '-')) || []
            debugger
            HidenLoading()
            fileMap = files.map((f: IFileResponse) => {
                const rs: IDocumentFile = {
                    id: f.metaData,//metaData is IDocumentFile Id, === 0 means create new
                    url: f.url,
                    name: f.fileName,
                    size: f.size,
                    type: f.contentType,
                    description: f.description,
                    isSecurity: f.isSecurity,
                    physicalAddressId: f.physicalAddress?.includes('T') ? null : f.physicalAddress,
                    version: 1,
                    documentId: undefined,
                    documentReminders: f.documentReminders
                }
                return rs
            })
            if (!isDraft && fileMap.length === 0) {
                toast.warning(t('fileNotNull'))
                HidenLoading()
                return
            }

            const model: IDocument = {
                ...values,
                documentFiles: fileMap,
                documentAssigns: assigns,
                priority: parseInt(values.priority || 0),
                status: isDraft ? 0 : status === 0 ? 1 : status,
                extendField: strQuestion
            }
            if (values.id) { //update
                ShowLoading()
                updateDocument(model).then(rs => {
                    if (rs.success) {
                        toast.success(t('message.actionSuccess'))
                        navigate(`/document?id=${isEdit ? id : rs.data.id}`)
                    }
                    else toast.warning(rs.message)
                }).finally(() => HidenLoading())
            }
            else { // add
                ShowLoading()
                createDocument(model).then(rs => {
                    if (rs.success) {
                        toast.success(t('message.actionSuccess'))
                        navigate(`/document?id=${rs.data.id}`)
                    }
                    else toast.warning(rs.message)
                }).finally(() => HidenLoading())
            }
            HidenLoading()
        },
    });
    const searchDocument = async (value: string): Promise<ICustomAutoComplete[]> => {
        const model = {
            keyword: value,
            page: 1,
            size: 20
        }
        const rs = await getDocumment(model)

        if (rs.success) {
            return rs.data.items.map((d: any) => {
                const item: ICustomAutoComplete = {
                    label: d.name,
                    value: d.id
                }
                return item
            })
        }
        else return []
    }

    return (
        <form onSubmit={formik.handleSubmit} className="overflow-auto h-[85svh] grid grid-cols-12 gap-2 ">
            <div className="col-span-12 md:col-span-8 grid grid-cols-12 gap-2 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border p-4">
                <div className="col-span-12 md:col-span-4">
                    <label htmlFor="serial" className="block text-sm font-bold leading-5 text-gray-900">
                        {t('serial')} <span className="font-bold text-red-600">*</span>
                    </label>
                    <div className="mt-2">
                        {formik.values.isAutoSerial === false
                            ? <InputText
                                inputClass="py-2"
                                name="serial"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.serial}
                            />
                            : <span className="italic text-2xl">{formik.values.serial}</span>
                        }
                    </div>
                </div>

                <div className="col-span-12 md:col-span-4">
                    <Select
                        label={t('documentType')}
                        required
                        readonly={isEdit}
                        name="documentTypeId"
                        data={Array.isArray(documentTypeState.data)
                            ? documentTypeState.data.map((d: IDocumentType) => { return { value: d.id, label: d.name } })
                            : []}
                        onChange={documentTypeOnChange}
                        value={formik.values.documentTypeId} />
                </div>

                <div className="col-span-12 md:col-span-4" style={{ paddingTop: '29px', paddingLeft: '15px' }}>
                    <InputRadio
                        label={t('isPublic')}
                        name="isPublic"
                        onChange={formik.handleChange}
                        value={formik.values.isPublic}
                    />
                </div>

                <div className="col-span-12 md:col-span-4">
                    <Select
                        label={t('priority')}
                        required
                        name="priority"
                        data={enumToListSelect(DocumentPriority, t, 'documentPriority')}
                        onChange={formik.handleChange}
                        value={formik.values.priority} />
                </div>

                <div className="col-span-12 md:col-span-8">
                    <OrgSelect label={t('department')}
                        required
                        name="departmentId"
                        defaultValue={{ id: authState.departmentCode, label: authState.departmentName, type: 'department' }}
                        onChange={(e) => handleOnChangeDepartment(e)} />
                </div>

                {(formik.values.isDate === null || formik.values.isDate === true) &&
                    (<>
                        <div className="col-span-12 md:col-span-4">
                            <InputText
                                name="fromDate"
                                label={t('fromDate')} type="date"
                                onChange={formik.handleChange}
                                value={moment(formik.values.fromDate || '').format('YYYY-MM-DD')}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-4">
                            <InputText label={t('toDate')}
                                name="toDate"
                                type="date"
                                inputField={{
                                    min: moment(formik.values.fromDate || '').format('YYYY-MM-DD')
                                }}
                                onChange={formik.handleChange}
                                value={moment(formik.values.toDate || '').format('YYYY-MM-DD')} />
                        </div>
                        <div className="col-span-12 md:col-span-4">
                            <InputText label={t('reminderTime')}
                                type="number"
                                name="reminderTime"
                                inputField={{
                                    min: '0',
                                    step: '0.5'
                                }}
                                value={formik.values.reminderTime}
                                error={Boolean(formik.touched.reminderTime) && formik?.errors?.reminderTime}
                                onChange={formik.handleChange} />
                        </div>
                    </>
                    )}
                <div className="col-span-12 md:col-span-4" style={{ paddingTop: '29px', paddingLeft: '15px' }}>
                    <InputRadio
                        label={t('isManualVersion')}
                        name="isManualVersion"
                        onChange={formik.handleChange}
                        value={formik.values?.isManualVersion}
                        readonly={isEdit}
                    />
                </div>
                {formik.values.isManualVersion === true
                    &&
                    <>
                        <div className="col-span-12 md:col-span-4">
                            <InputText
                                label={t('version')}
                                inputClass="py-2"
                                name="versionOrder"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.versionOrder}
                                inputField={{
                                    step: '1',
                                    min: 0
                                }}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-4">
                            <InputText
                                label={t('promulgateDate')}
                                inputClass="py-2"
                                name="promulgateDate"
                                type="date"
                                onChange={formik.handleChange}
                                value={moment(formik.values.promulgateDate || '').format('YYYY-MM-DD')}
                            />
                        </div>
                        <div className="col-span-12 ">
                            <InputText
                                label={`${t('note')} ${t('version')?.toLowerCase()}`}
                                inputClass="py-2"
                                name="note"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.note}
                                rows={3}
                            />
                        </div>
                    </>
                }

                {/* seperate */}
                <div className="col-span-12">
                    <InputText
                        name="name"
                        required
                        label={t('title')}
                        description={t('suggestDocumentTitle')}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        error={Boolean(formik.touched.name) && formik?.errors?.name}
                    />
                </div>

                <div className="col-span-12">
                    <CustomAutoCompleteAsync
                        search={searchDocument}
                        name="documentLink"
                        onChange={(e) => formik.setValues({ ...formik.values, documentLink: e.value })}
                        label={t('documentLink')}
                        placeHolder={t('enterDocumentLink')} />
                </div>

                <div className="col-span-12">
                    <InputText
                        name="description"
                        label={t('description')}
                        description={t('suggestDocumentDescription')}
                        rows={3}
                        onChange={formik.handleChange}
                        value={formik.values.description}
                    />
                </div>

                {formik.values.extendField && formik.values.extendField != '' && <div className="col-span-12">
                    <div className="py-3 flex items-center text-sm
                 text-gray-400 before:flex-1 before:border-t
                  before:border-gray-200 before:me-6 after:flex-1 after:border-t
                   after:border-gray-200 after:ms-6 dark:text-gray-600
                    dark:before:border-neutral-400 dark:after:border-neutral-400">
                        {t('moreInformation')}
                    </div>
                    <InputQuestions ref={inputQuestionsRef} reload={questions.reload} data={questions.data} />
                </div>
                }
            </div>
            <div className="col-span-12 md:col-span-4">
                <UploadFile ref={fileRef} isCheckDuplicateFileName={true} departmentId={formik.values.departmentId} />
            </div>
            <div className="col-span-12">
                <UserPermission ref={userDeptRef} />
            </div>
            <div className="col-span-12">
                <div className="float-right w-100">
                    <Button label={t('save')} type="submit" color="primary" />
                    <Button label={t('draft')} type="submit" onClick={() => isDraft = true} color="warning" />
                    <Button label={t('goBack')} onClick={handleClose} color="default" />
                </div>
            </div>
        </form >
    )
}
export default DocumentEdit