interface IProps {
    label: string,
    name: string,
    inputField?: object,
    defaultValue?: string,
    value?: boolean,
    readonly?: boolean,
    onChange?: (e: any) => void
}
const InputRadio = (props: IProps) => {
    return (
        <div className="w-full">
            <input className="mt-0.54 rounded-10 duration-250 ease-soft-in-out after:rounded-circle 
            after:shadow-soft-2xl after:duration-250 checked:after:translate-x-5.25 h-5 relative float-left 
            ml-auto w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none 
            bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 
            after:translate-x-px after:bg-white after:content-[''] 
            checked:border-slate-800/95 checked:bg-slate-800/95 checked:bg-none checked:bg-right" type="checkbox"
                name={props.name}
                defaultValue={props.defaultValue}
                disabled={props.readonly}
                checked={props.value}
                onChange={(e) => props.onChange && props.onChange(e)}
                {...props.inputField}
            />
            <label className="mb-0 ml-4 overflow-hidden font-normal cursor-pointer text-size-sm
                                        text-ellipsis whitespace-nowrap text-slate-500" >
                {props.label}
            </label>
        </div>
    )
}
InputRadio.defaultProps = {
    readonly: false,
}
export default InputRadio