import { Button, Grid, Checkbox, TextField, Autocomplete, FormControlLabel } from "@mui/material"
import { t } from "i18next";
import { Suspense, useEffect } from "react";
import { useState } from "react"

import { toast } from "react-toastify";
import InputText from "src/components/inputs/InputText";
import _ from "lodash";

const SavePermission = (props: any) => {
    const { applications, data, reload, show, onSave } = props
    const [state, setState] = useState<{ show: boolean, id?: string, name: string, code: string, active: boolean, applicationId: string, isCreated: boolean, selectApplication?: any }>({
        show: false,
        id: undefined,
        name: '',
        code: '',
        active: true,
        applicationId: '',
        isCreated: true,
    })


    useEffect(() => {
        debugger
        if (data && data.id !== state.id) {
            setState({
                show: true,
                id: data.id,
                name: data.name,
                code: data.code,
                applicationId: data.applicationId,
                active: data.active,
                isCreated: false,
            })
        }
        if (!data || !data?.id || data?.id=== '') {
            setState({
                show: show || false,
                id: undefined,
                name: '',
                code: '',
                active: true,
                applicationId: '',
                isCreated: true,
                selectApplication: {}
            })
        }
        else setState({ ...state, ...data, show: show || false, })

    }, [reload])

    //handle
    const handleOnchange = (value: any, name: string, state: any, setState: any) => {
        setState({ ...state, [name]: value });
    }

    const handleOnClickCheckbox = (e: any, state: any, setState: any) => {
        const name = e.target.id || e.target.name;
        const value = e.target.checked;
        setState({ ...state, [name]: value });
    }


    const handleSave = () => {
        // validate dữ liệu
        if (state.name === '' || state.code === '') {
            toast.warning(t('common.required'))
            return
        }
        let isStop = false

        if (isStop)
            return false;

        const modelSave = {
            id: state.id,
            name: state.name,
            code: state.code,
            active: state.active ? 1 : 0,
            applicationId: state.applicationId,
            isCreated: state.isCreated
        }
        onSave(modelSave)
    }
    return (
        <Suspense>
            {state.show ? <Grid container spacing={2} >
                <Grid item container spacing={2} >

                    <Grid item md={12}>
                        <InputText
                            required
                            label={t('code')}
                            onChange={(e: any) => handleOnchange(e.target.value, 'code', state, setState)}
                            value={state.code}
                            placeholder={t('pleaseEnter') || ''}
                        />
                    </Grid>
                    <Grid item md={10}>
                        <InputText
                            required
                            label={t('name')}
                            onChange={(e: any) => handleOnchange(e.target.value, 'name', state, setState)}
                            value={state.name}
                            placeholder={t('pleaseEnter') || ''}
                        />
                    </Grid>
                    <Grid item md={2} xs={6} style={{ paddingTop: '38px' }}>
                        <FormControlLabel control={<Checkbox
                            checked={state.active}
                            name="active"
                            onChange={(e) => handleOnClickCheckbox(e, state, setState)} />}
                            label={t('active')} />
                    </Grid>
                    <Grid item md={12}>
                        <Autocomplete
                            options={applications}
                            onChange={(e,value)=>handleOnchange(value.id, 'applicationId', state, setState)}
                            getOptionLabel={(obj: any) => `${obj.id} - ${obj.name}`}
                            value={applications.find((x:any) => x.id === state.applicationId)}
                            renderInput={params => <TextField {...params} label={t('application')} />
                            } />
                    </Grid>
                    <Grid item md={12}>
                        <Button variant="contained" onClick={handleSave}>{t('save')}</Button>
                    </Grid>
                </Grid>
            </Grid>
                : <div className="text-xl mt-10 text-center"> <h4>{t('pleaseSelectToDisplay')}</h4></div>}
        </Suspense>
    )
}

export default SavePermission