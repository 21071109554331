/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchProduct } from "src/apis/apiCommon";
import { IProduct } from "src/interfaces/IProduct";

export interface IProductState {
    products: IProduct[];
}

const initialState: IProductState = {
    products: [],
};


export const fetchProducts = createAsyncThunk("fetchProducts",
    async () => {
        const res = await fetchProduct();
        return res;
    });

export const productSlice = createSlice({
    name: "products",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchProducts.fulfilled, (state: any, action: any) => {
                state.products = action.payload.data;
            })
    },
});

export const selectProductsState = (state: any) =>
    state.products.products || [];

export default productSlice.reducer;
