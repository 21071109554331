import { useEffect, useState } from "react"
import { IMenu } from "./IMenu"
import { Link, useLocation } from "react-router-dom"
import { useAppDispatch } from "src/store/store"
import { useSelector } from "react-redux"
import { selectRootState, setKeyword, setTransparentNavbar } from "src/store/slices/rootSlice"
import { getMenu } from "src/apis/apiCommon"
import { selectAuthState } from "src/store/slices/authSlice"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { IconButton } from "@mui/material"
import { ArrowCircleRightOutlined, DoubleArrow, Menu, MenuOpen } from "@mui/icons-material"
const SideNav = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const location = useLocation();
    const moduleId = location.pathname.split('/')[1] || ''
    const authState = useSelector(selectAuthState);
    const [menu, setMenu] = useState<IMenu[]>()
    const { transparentNavbar } = useSelector(selectRootState)
    const [state, setState] = useState(transparentNavbar)

    useEffect(() => {
        setState(transparentNavbar)
    }, [transparentNavbar])

    useEffect(() => {
        loadMenu()
    }, [moduleId])

    const loadMenu = () => {
        if (moduleId === '')
            return
        const model = {
            moduleId: moduleId,
            userId: authState.id
        }
        getMenu(model).then(rs => {
            if (!rs) return
            if (rs.success) {
                setMenu(rs.data)
            }
            else {
                toast.error(t("message.actionError"))
            }
        })
    }
    const handleOnClickMenu = () => {
        dispatch(setTransparentNavbar(false))
        dispatch(setKeyword(''))
    }
    const handleShowMenu = (isShow: boolean) => {
        dispatch(setTransparentNavbar(isShow))
    }
    return (
        <aside className={`max-w-62.5 ease-nav-brand z-990 fixed inset-y-0 my-4 ml-4 block w-full -translate-x-full flex-wrap items-center 
        justify-between overflow-y-visible rounded-2xl border-0 bg-white p-0 antialiased shadow-none transition-transform duration-200 xl:left-0 
        xl:translate-x-0 xl:bg-transparent ${state ? 'translate-x-0 shadow-soft-xl' : ''}`}>
            <div className="h-[90px]" >
                <button onClick={() => handleShowMenu(false)} className={`absolute top-0 right-0  ${state ? '' : 'hidden'}`}>
                    <i className="p-4 opacity-50 cursor-pointer fa-solid fa-xmark text-slate-400" />
                </button>

                <button onClick={() => handleShowMenu(true)} className={`absolute top-0 -right-6 ${!state ? '' : 'hidden'} xl:hidden`}>
                    <i className="  p-4 opacity-50 cursor-poitner fa-solid fa-angles-right text-slate-400" />
                </button>
                <Link to="/" className="block m-0 text-center whitespace-nowrap text-slate-700 h-full" >
                    <img src="/logo192.png" className="inline h-full max-w-full transition-all duration-200 ease-nav-brand" alt="main_logo" />
                    {/* <span className="ml-1 font-semibold transition-all duration-200 ease-nav-brand">Minh Long I</span> */}
                </Link>
            </div>

            <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent" />
            <div className="items-center block w-auto overflow-auto grow basis-full">
                <ul className="flex flex-col pl-0 mb-0">
                    {menu?.map(item => {
                        const isActive = location.pathname + location.search === item.url + item.params
                        return (
                            <li key={item.name} className="mt-0.5 w-full">
                                <Link to={item.url + item.params} onClick={handleOnClickMenu} className={`py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 transition-colors ${isActive ? 'shadow-soft-xl rounded-lg bg-white  font-semibold text-slate-700 active' : ''}`} >
                                    <div className={`shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5 ${isActive ? 'bg-gradient-to-tl from-purple-700 to-pink-500 text-white' : ''}`}>
                                        <i className={item.iconClass}></i>
                                    </div>
                                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">{t(item.name)}</span>
                                    {/* <span className="absolute right-[21px] items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">10</span> */}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </aside>
    )
}
export default SideNav