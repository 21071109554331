// lấy all danh sách user
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Api from "src/apis/apiCommon";
import { initialState } from "src/apis/apiCommon";
// module
export const fetchPattern = createAsyncThunk("fetchPattern", async (search) => {
  const res = await Api.fetchPattern();
  return res;
});
const patternSlice = createSlice({
  name: "patterns", // tên này phải giống tên khai báo ./reducer.ts
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // module
      .addCase(fetchPattern.fulfilled, (state: any, action: any) => {
        state.datas = action.payload.data;
      })
  },
});

export const selectPattern = (state: any) => state.patterns.datas;// state.<tên> có trong khai báo ./reducer.ts
export default patternSlice.reducer;
