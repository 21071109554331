import { Close, Download, FileDownload, Info } from "@mui/icons-material"
import { AppBar, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Toolbar, Tooltip, Typography } from "@mui/material"
import { table } from "console"
import { t } from "i18next"
import { MRT_ColumnDef, MRT_PaginationState, MRT_Row, MRT_SortingState, MaterialReactTable, useMaterialReactTable } from "material-react-table"
import { Suspense, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import QRCode from "react-qr-code"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { getDocumment } from "src/apis/apiDocument"
import { svgToPng } from "src/commons/helpers/common"
import { fillExcelReportTemplate } from "src/commons/helpers/excelHelper"
import { getDateTimeString } from "src/commons/helpers/formatDate"
import { getDocumentStatusColor } from "src/commons/helpers/getClass"
import getLangReactTable from "src/commons/helpers/getLangReactTable"
import { IPaginationResponse } from "src/interfaces/ICommon"
import { IDocument, IDocumentSearchModel, initDocumentSearchModel } from "src/interfaces/IDocument"
import { IDocumentTreeItem } from "src/interfaces/IDocumentTreeItem"
import { IDepartment } from "src/interfaces/IOrg"
import { useUserDepartment } from "src/store/hook"
import { selectUserDepartmentState } from "src/store/slices/commonSlice"
import DetailDocument from "src/views/document/DetailDocument"
import DocumentTreeView from "src/views/document/DocumentTreeView"

const baseURL = `${process.env.REACT_APP_URL}`;
const DocumentReportDepartment = () => {
    useUserDepartment(true)
    const { i18n } = useTranslation();
    const userDepartmentState = useSelector<IDepartment[], IDepartment[]>(selectUserDepartmentState)
    const [dataDepartment, setDataDepartment] = useState<IDepartment[]>([]);

    const [selectedTreeItem, setSelectedTreeItem] = useState<IDocumentTreeItem | null>(null);
    const [currentStatus, setCurrentStatus] = useState<number>(999);

    const [currentTime, setCurrentTime] = useState<string>("");
    const [fileSearchModel, setFileSearchModel] = useState<IDocumentSearchModel>(initDocumentSearchModel);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'createdDate', desc: true }]);
    const [isLoad, setIsLoad] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [modalDetail, setModalDetail] = useState({
        id: '',
        show: false,
    })
    useEffect(() => {
        userDepartmentState && setDataDepartment(userDepartmentState)
    }, [])

    const handleChangeStatus = (statusValue: number) => {
        // console.log(statusValue);
        setCurrentStatus(statusValue)
    }

    const handleChangeTime = (timeValue: string) => {
        // console.log(timeValue);
        setCurrentTime(timeValue)
    }

    const onSelectTreeItem = (selectedTreeItem: IDocumentTreeItem) => {
        if (selectedTreeItem.type === 'department') {
            const searchModel: IDocumentSearchModel = {
                ...fileSearchModel,
                creationDepartmentId: selectedTreeItem.department?.code,
                status: currentStatus === 999 ? undefined : currentStatus,
            }

            setFileSearchModel(searchModel)
            setSelectedTreeItem(selectedTreeItem)
        }
    }

    useEffect(() => {
        fileSearchModel.creationDepartmentId && fetchDocs();
    }, [fileSearchModel])

    useEffect(() => {
        setFileSearchModel({
            ...fileSearchModel,
            keyword: globalFilter,
            page: pagination.pageIndex + 1,
            size: pagination.pageSize,
        })
    }, [pagination, globalFilter, sorting])

    const fetchDocs = () => {
        if (selectedTreeItem) {

            setIsLoad(true)
            const model = {
                page: pagination.pageIndex + 1,
                size: pagination.pageSize,
                creationDepartmentId: fileSearchModel.creationDepartmentId,
                status: currentStatus === 999 ? null : fileSearchModel.status,
                keyword: globalFilter,
                reminderTime: currentTime,
                sortBy: sorting && sorting?.length > 0 ? sorting[0].id : undefined,
                isDescSort: sorting && sorting?.length > 0 ? sorting[0].desc : undefined,
                includes: ["nothing"]
            }

            getDocumment(model).then(res => {
                if (!res.success) {
                    toast.warning(t('message.actionError'));
                    return;
                }

                const resDatas: IPaginationResponse<IDocument> = res.data;

                const updatedTreeItem = { ...selectedTreeItem };
                updatedTreeItem.documents = resDatas.items;

                setSelectedTreeItem(updatedTreeItem);
                setRowCount(resDatas.count);

            }).catch(err => {
                console.error(err);
                toast.error(t('message.actionError'));
            }).finally(() => { setIsLoad(false) });
        }
    }

    //--------------------------------Excel-------------------------------------//
    const downloadExcel = () => {
        if (selectedTreeItem) {
            setIsLoad(true)
            const model = {
                page: pagination.pageIndex + 1,
                size: 9999999,
                creationDepartmentId: fileSearchModel.creationDepartmentId,
                status: currentStatus === 999 ? null : fileSearchModel.status,
                keyword: globalFilter,
                reminderTime: currentTime,
                sortBy: sorting && sorting?.length > 0 ? sorting[0].id : undefined,
                isDescSort: sorting && sorting?.length > 0 ? sorting[0].desc : undefined,
                includes: ["DocumentHistories", "DocumentManualVersions"]
            }

            getDocumment(model).then(res => {
                if (!res.success) {
                    toast.warning(t('message.actionError'));
                    return;
                }

                const resDatas: IPaginationResponse<IDocument> = res.data;
                setDocumentQRs(resDatas.items);
            }).catch(err => {
                console.error(err);
                toast.error(t('message.actionError'));
            }).finally(() => { setIsLoad(false) });
        }
    }

    const [documentQRs, setDocumentQRs] = useState<IDocument[]>([]);
    const [qrCodes, setQrCodes] = useState<string[]>([]);

    const drawQRCode = async () => {
        let dataQRs: string[] = [];

        for (let index = 0; index < documentQRs.length; index++) {
            const element = documentQRs[index];

            const node = document.getElementById(`svg-${index}`)?.outerHTML;
            const svgString = node || '';

            const dataQR = await svgToPng(svgString, 100, 100).then((pngDataUrl) => {
                return pngDataUrl
            }).catch((error) => {
                console.error('Error converting SVG to PNG:', error);
                return "";
            });

            dataQRs.push(dataQR);
        }

        setQrCodes(dataQRs)
    }

    useEffect(() => {
        if (documentQRs && documentQRs.length > 0) {
            drawQRCode()
        }
    }, [documentQRs])

    useEffect(() => {
        if (qrCodes && qrCodes.length > 0) {
            const dataMaps = documentQRs.map((x, i) => {
                return {
                    document: x,
                    qrData: qrCodes[i]
                }
            });

            fillExcelReportTemplate(dataMaps || [], selectedTreeItem?.department?.name || '', baseURL, t);
        }
    }, [qrCodes])

    const handleCloseModalDetail = () => {
        setModalDetail({ show: false, id: '' })
    }

    const handleExportRows = (rows: MRT_Row<IDocument>[]) => {
        const rowData = rows.map((row) => row.original);
        setDocumentQRs(rowData)
    };

    const columns = useMemo<MRT_ColumnDef<IDocument>[]>(
        () => [
            {
                accessorKey: 'serial',
                header: t('serial'),
                Cell: (data) =>
                    <div>
                        {data.row.original?.serial}
                    </div>
            },
            {
                accessorKey: 'name',
                header: t('name'),
            },
            {
                accessorKey: 'documentTypeName',
                header: t('documentType'),
            },
            {
                accessorKey: 'status',
                header: t('documentStatus'),
                Cell: (data) =>
                    <span className={`font-bold text-center align-middle transition-all 
                                bg-transparent border-0 rounded-lg px-3 py-1 mr-2  leading-pro
                                text-xs ease-soft-in tracking-tight-soft shadow-soft-md ${getDocumentStatusColor(data.row.original?.status)}`}>
                        {t('documentStatus' + data.row.original?.status)}
                    </span>

            },
            {
                accessorKey: 'createdDate',
                header: t('createdDate'),
                Cell: (data) =>
                    <span>
                        {getDateTimeString(new Date(data.row.original?.createdDate))}
                    </span>

            },
            {
                header: t('action'),
                Cell: (data) =>
                    <Tooltip title={t('documentDetail')} onClick={() => setModalDetail({ show: true, id: data.row.original?.id })}>
                        <IconButton >
                            <Info />
                        </IconButton>
                    </Tooltip>
            },
        ], []
    )
    const table = useMaterialReactTable({
        localization: getLangReactTable(i18n.language),
        columns,
        data: selectedTreeItem?.documents || [],
        renderTopToolbarCustomActions: ({ table }) => (
            <div style={{ display: 'flex' }}>
              
                <Button
                    disabled={table.getRowModel().rows.length === 0}
                    onClick={downloadExcel}
                    color="primary"
                    startIcon={<FileDownload />}
                >
                    {selectedTreeItem?.label}
                </Button>
                <Button
                    disabled={table.getRowModel().rows.length === 0}
                    onClick={() => handleExportRows(table.getRowModel().rows)}
                    color="secondary" 
                    startIcon={<FileDownload />}
                >
                    {t('exportPageRows')}
                </Button>
            </div>
        ),
        enableDensityToggle: false,
        muiPaginationProps:{
            rowsPerPageOptions: [{label:'5',value:5}, {label:'10',value:10},{label:'50',value:50},{label:t('all'),value:100000000}],
        },
        initialState: {
            density: 'compact',
            pagination: { pageIndex: pagination.pageIndex, pageSize: pagination.pageSize },

        },
        onSortingChange: setSorting,
        manualSorting: true,
        manualPagination: true,
        onPaginationChange: setPagination,
        onGlobalFilterChange: setGlobalFilter,
        state: {
            isLoading: isLoad,
            pagination,
            globalFilter,
            sorting,

        },
        rowCount: rowCount,
        
    })
    return (
        <Grid container spacing={1} p={2}>
            <DocumentTreeView dataDepartment={dataDepartment}
                // setSelectedTreeItem={(data) => setSelectedTreeItem(data)}
                onSelectTreeItem={onSelectTreeItem}
                onChangeStatus={handleChangeStatus}
                onChangeTime={handleChangeTime}
            />
            <Grid item xs={12} md={8} className="overflow-auto h-[85svh]">
                <MaterialReactTable table={table}/>
            </Grid>
            <Grid item xs={12} sx={{ display: 'none' }}>
                {
                    documentQRs && documentQRs.length > 0 && documentQRs.map((item, index) =>
                        <QRCode
                            id={`svg-${index}`}
                            size={50}
                            style={{ height: "auto", maxWidth: "50px", width: "50px", float: "right" }}
                            value={`${baseURL}/document?s=${item.serial}`}
                            viewBox={`0 0 50 50`}
                        />
                    )
                }
            </Grid>
            <Suspense>
                <Dialog
                    open={modalDetail.show}
                    onClose={handleCloseModalDetail}
                    maxWidth={'md'}
                    fullWidth
                >
                    <AppBar sx={{ position: 'relative' }} className="bg-gradient-to-tl from-purple-700 to-pink-500">
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {t('documentDetail')}
                            </Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseModalDetail}
                                aria-label="close"
                            >
                                <Close />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <DetailDocument id={modalDetail.id} />
                    </DialogContent>
                </Dialog >
            </Suspense>
        </Grid>
    )
}
export default DocumentReportDepartment