import { Button, Grid, Checkbox, TextField, Autocomplete, FormControlLabel } from "@mui/material"
import { t } from "i18next";
import { Suspense, useEffect } from "react";
import { useState } from "react"

import { fetchDetail } from 'src/apis/permission/apiRole'
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectEmployeeState } from "src/store/slices/commonSlice";
import InputText from "src/components/inputs/InputText";
import _ from "lodash";
import { HidenLoading, ShowLoading } from "src/components/loading-page/LoadingPage";

const SaveRole = (props: any) => {
    const userState = useSelector(selectEmployeeState)
    const [state, setState] = useState<{ show: boolean, id?: string, name: string, active: boolean, isCreated: boolean, users: any[] }>({
        show: false,
        id: undefined,
        name: '',
        active: true,
        isCreated: true,
        users: []
    })


    useEffect(() => {
        if (props.data.id !== state.id)
            loadDetail(props.data.id)
        if (!props.data.id || props.data.id === '') {
            setState({
                show: props.show || false,
                id: undefined,
                name: '',
                active: true,
                isCreated: true,
                users: []
            })
        }
        else setState({ ...state, ...props.data, show: props.show || false, })

    }, [props.reload])

    const loadDetail = (appId: string) => {
        if (appId && appId !== '') {
            ShowLoading()
            fetchDetail(appId)
                .then((rs: any) => {
                    if (rs.success) {
                        const userIds = rs.data.users.map((x:any)=>x.itemId)
                        const mapUsers = userState.filter((x: any) => userIds.indexOf(x.employeeId) !== -1)
                        setState({
                            show: true,
                            id: props.data.id,
                            name: props.data.name,
                            active: props.data.active,
                            isCreated: false,
                            users: mapUsers
                        })
                    }
                    else {
                        toast.error(rs.message)
                    }
                }).finally(() => HidenLoading())
        }
    }
    //handle
    const handleOnchange = (value: any, name: string, state: any, setState: any) => {
        setState({ ...state, [name]: value });
    }

    const handleOnClickCheckbox = (e: any, state: any, setState: any) => {
        const name = e.target.id || e.target.name;
        const value = e.target.checked;
        setState({ ...state, [name]: value });
    }

    const handleClose = () => {
        setState({ ...state, show: false })
    }
    const handleOnChangeUser = (data: any) => {
        setState({ ...state, users: data })
    }
    const handleSave = () => {
        // validate dữ liệu
        if (state.name === '' || state.name === '') {
            toast.warning(t('common.required'))
            return
        }
        // kiểm tra dữ liệu có bị trung user không?
        let userArr: any[] = state.users.map(u => {
            return {
                itemId: u.employeeId,
                type: 0
            }
        })
        let isStop = false

        if (isStop)
            return false;

        const modelSave = {
            id: state.id,
            name: state.name,
            users: userArr,
            active: state.active ? 1 : 0,
            isCreated: state.isCreated
        }
        props.onSave(modelSave)
    }
    return (
        <Suspense>
            {state.show ? <Grid container spacing={2} >
                <Grid item container spacing={2} >

                    <Grid item md={10}>
                        <InputText
                            required
                            label={t('name')}
                            onChange={(e: any) => handleOnchange(e.target.value, 'name', state, setState)}
                            value={state.name}
                            placeholder={t('pleaseEnter') || ''}
                        />
                    </Grid>

                    <Grid item md={2} xs={6} style={{ paddingTop: '38px' }}>
                        <FormControlLabel control={<Checkbox
                            checked={state.active}
                            name="active"
                            onChange={(e) => handleOnClickCheckbox(e, state, setState)} />}
                            label={t('active')} />
                    </Grid>
                    <Grid item md={12}>
                        <Autocomplete
                            multiple
                            options={userState}
                            onChange={(e, item) => handleOnChangeUser(item)}
                            getOptionLabel={(obj: any) => `${obj.employeeId} - ${obj.employeeName}`}
                            value={state.users}
                            renderInput={params => <TextField {...params} label={t('account')} />
                            } />
                    </Grid>
                    <Grid item md={12}>
                        <Button variant="contained" onClick={handleSave}>{t('save')}</Button>
                    </Grid>
                </Grid>
            </Grid>
                : <div className="text-xl mt-10 text-center"> <h4>{t('pleaseSelectToDisplay')}</h4></div>}
        </Suspense>
    )
}

export default SaveRole