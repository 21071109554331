import { createSlice } from "@reduxjs/toolkit";

export interface SettingState {
  isSync: boolean;
  //syncDate: Date | null
  currency: string;
  isDarkMode: boolean;
  qualityCodes: string | null;
  quanlityName: string;
  isShowChangingCurrencyWarning?: boolean;
  backUrl: string;
}
const initialState: SettingState = {
  isSync: false,
  //syncDate: null,
  currency: "USD",
  isDarkMode: false,
  qualityCodes: null,
  quanlityName: "",
  isShowChangingCurrencyWarning: true,
  backUrl: "",
};
export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    setMode(state, action) {
      state.isDarkMode = action.payload;
    },
    setSync(state, action) {
      state.isSync = action.payload;
      //state.syncDate = new Date
    },
    setQualityCode(state, action) {
      state.qualityCodes = action.payload.value;
      state.quanlityName = action.payload.label;
    },
    setShowChangingCurrencyWarning(state, action) {
      state.isShowChangingCurrencyWarning = action.payload;
    },
    setBackUrl(state, action) {
      state.backUrl = action.payload;
    },
  },
});

export const {
  setCurrency,
  setMode,
  setSync,
  setQualityCode,
  setShowChangingCurrencyWarning,
  setBackUrl
} = settingSlice.actions;

export const selectSettingState = (state: any) => state.setting || initialState;

export default settingSlice.reducer;
