/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDocumentType } from "src/apis/apiDocument";
import { IDocumentType } from "src/interfaces/IDocumentType";

export interface IDocumentTypeState {
  data: IDocumentType[];
  reload: number;
}

const initialState: IDocumentTypeState = {
  data: [],
  reload: 0,
};

export const fetchDocumentType = createAsyncThunk(
  "fetchDocumentType",
  async () => {
    const res = await getDocumentType();
    return res.data;
  }
);

export const documentTypeSlice = createSlice({
  name: "documentTypeSlice",
  initialState,
  reducers: {
    addDocumentType: (state: any, action: any) => {
      state.data = [action.payload, ...state.data];
      state.reload = state.reload + 1 || 1;
    },
    editDocumentType: (state: any, action: any) => {
      const values = action.payload;
      const index = state.data.findIndex(
        (x: IDocumentType) => x.id === values.id
      );
      if (index > -1) {
        const tempData = [...state.data];
        tempData[index] = { ...values };
        state.data = tempData;
        state.reload = state.reload + 1 || 1;
      }
    },
    removeDocumentType: (state: any, action: any) => {
      const index = state.data.findIndex(
        (x: IDocumentType) => x.id === action.payload
      );
      if (index > -1) {
        const tempData = [...state.data];
        tempData.splice(index, 1);
        state.data = tempData;
        state.reload = state.reload + 1 || 1;
      }
    }
  },
  extraReducers: (builder: any) => {
    builder.addCase(fetchDocumentType.fulfilled, (state: any, action: any) => {
      state.data = action.payload;
      state.reload = state.reload + 1 || 1;
    });
  },
});
export const { addDocumentType, editDocumentType, removeDocumentType } =
  documentTypeSlice.actions;

export const selectDocumentTypeState = (state: any) =>
  state.documentType || initialState;

export default documentTypeSlice.reducer;
