import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
  } from "@mui/material";
  import { t } from "i18next";
  import { useEffect, useRef } from "react";
  import { useState } from "react";
  import { toast } from "react-toastify";
  import { Transition } from "src/commons/handle/transitionModal";
  import TablePermission, {
    TablePermissionHandle,
  } from "./TablePermission";
import { addUpdateUserApplication, fetchPermissionByUser } from "src/apis/permission/apiUser";
import { handleOnchange } from "src/commons/handle";
import { useEmployee, useUserDepartment } from "src/store/hook";
import { useSelector } from "react-redux";
import { selectUserDepartmentState } from "src/store/slices/commonSlice";
import { IPermission } from "src/interfaces/permission/IPermission";
import { ResultObject } from "src/apis/base/base";
import { useAppDispatch } from "src/store/store";
  interface IProps {
    show: boolean | false;
    data: any;
    reload: number;
    onSave: Function;
    applications:any[]
  }


  const SaveUser = (props: IProps) => {
    useUserDepartment();
  
    const defaulValue = {
      show: false,
      permissions: [] as any[],
      reloadPermission:1,
      userId: "",
      id:"",
      fullName: "",
      departmentId: "",
      inUse: true,
      applicationIds: [],
      selectDepartment: null
    };
    const dispatch = useAppDispatch()
    const departmentState = useSelector(selectUserDepartmentState)
    const [state, setState] = useState<any>(defaulValue);
    const tableApplicationRef = useRef<TablePermissionHandle>(null);
    const [permissions, setPermissions] = useState<IPermission[]>([])
    
    useEffect(() => {
      if (props.data && props.data.userId === 'new') { // thêm mới
        setState({
          ...defaulValue,
          show: props.show
        });
      }
      else if (state.userId !== props.data.userId) { // mở popup chỉnh sửa khác userId
        loadPermissionByUser();
      } else {// mở lại popup chỉnh sửa trùng userId 
        setState({
          ...state,
          show: props.show,
        });
      }
    }, [props.reload]);
    const loadPermissionByUser = () => {
      if (!props.data.userId || props.data.userId === "") return;
  
      fetchPermissionByUser(props.data.userId).then((rs) => {
        if (!rs.success) {
          toast.error(rs.message);
        } else {
          const applicationIds = rs.data.map((ap: any) => ap.applicationId);
          // lọc trùng
          const uniqueApplicationIds = [...new Set(applicationIds)];
          const selectDep = departmentState.data.find((rs: any) => rs.code === props.data.departmentId)
          setState({
            ...state,
            id:props.data.id,
            show: props.show,
            userId: props.data.userId,
            fullName: props.data.fullName,
            departmentId: props.data.departmentId,
            inUse: props.data.inUse,
            permissions: rs.data,
            applicationIds: uniqueApplicationIds,
            selectDepartment: selectDep
          });
        }
      });
    };
    //handle
    
    const handleClose = () => {
      setState({ ...state, show: false });
    };
    const handleSave = async () => {
      try {
        const model = {
          siteType: state.applicationIds.toString(),
          userID: state.userId,
          id: state.id,
          departmentID: state.departmentId,
          companyId: "CONGTY",
          fullName: state.fullName,
          inUse: state.inUse
        }
        const data: ResultObject = await addUpdateUserApplication(model);
  
        if (data.success) {
          //dispatch(addUser({ departmentId: state.departmentId, fullName: state.fullName, id:data.data.id, userId: state.userId }))
          // call save 
          const permissionRef = tableApplicationRef.current
            ? tableApplicationRef.current.get()
            : [];
          const model = {
            userId: state.userId,
            permissions: permissionRef,
          };
          props.onSave(model);
        } else {
          toast.error(data.message);
        }
      } catch (error: any) {
        alert(error.message);
      }
    };
    return (
      <Dialog
        open={state.show}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        scroll="paper"
        maxWidth="xl"
        onClose={handleClose}
      >
        <DialogTitle>
          {props.data.userId === 'new'
            ? (t("common.addUser"))
            : (`${t("user.editModel")}: ${state.userId} - ${state.fullName} (${state.departmentId})`)
          }
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item container spacing={2}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label={t("common.userId")}
                  placeholder={t("common.pleaseEnter") || ""}
                  size="small"
                  variant="outlined"
                  // disabled={props.data.userId !== 'new'}
                  onChange={(e) => handleOnchange(e.target.value, "userId", state, setState)}
                  value={state.userId}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  label={t("common.fullName")}
                  placeholder={t("common.pleaseEnter") || ""}
                  size="small"
                  variant="outlined"
                  onChange={(e) => handleOnchange(e.target.value, "fullName", state, setState)}
                  required
                  value={state.fullName}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  options={departmentState.data}
                  value={state.selectDepartment}
                  onChange={(e, item: any) => {
                    setState({
                      ...state,
                      departmentId: item.code,
                      selectDepartment: item
                    })
                  }}
                  getOptionLabel={(obj: any) => obj.name}
                  renderInput={params => <TextField {...params} label={t('common.departmentId')} />
                  } />
              </Grid>
              <Grid item md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={state.inUse}
                        onChange={(e: any) =>
                          handleOnchange(
                            e.target.value === 'on',
                            "inUse",
                            state,
                            setState
                          )
                        }
                      />
                    }
                    label={t("common.status")}
                  />
                </FormGroup>
              </Grid>
              <Grid item md={12}>
                <Autocomplete
                  options={props.applications}
                  multiple={true}
                  onChange={(e, item: any) => {
                    const ids = item ? item.map((rs: any) => rs.applicationID) : []
                    handleOnchange(
                      ids,
                      "applicationIds",
                      state,
                      setState
                    );
                  }}
                  getOptionLabel={(obj: any) => obj.applicationName}
                  value={props.applications.filter(
                    (rs: any) =>
                      state.applicationIds.indexOf(rs.applicationID) !== -1
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={t("common.application")} />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <TablePermission
            data={state.permissions}
            reload={state.reloadPermission}
            selects={permissions}
            ref={tableApplicationRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("common.close")}</Button>
          <Button onClick={handleSave}>{t("common.save")}</Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default SaveUser;
