import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, TextField, Autocomplete, FormControlLabel } from "@mui/material"
import { t } from "i18next";
import { Suspense, useEffect, useRef } from "react";
import { useState } from "react"

import * as GroupApi from 'src/apis/permission/apiGroup'
import { fetchRole } from 'src/apis/permission/apiRole'
import { fetchPermission } from 'src/apis/permission/apiPermission'
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useEmployee, useUserDepartment } from "src/store/hook";
import { selectEmployeeState, selectUserDepartmentState } from "src/store/slices/commonSlice";
import { Transition } from "src/commons/handle/transitionModal";
import _ from "lodash";
import { HidenLoading, ShowLoading } from "src/components/loading-page/LoadingPage";
import { IGroup } from "src/interfaces/permission/IGroup";
import { IRole } from "src/interfaces/permission/IRole";
import { IPermission } from "src/interfaces/permission/IPermission";
import TablePermission, { TablePermissionHandle } from "./TablePermission";

interface IProps {
    data: IGroup,
    show?: boolean,
    reload?: number,
    onSave: (data: IGroup) => void
}
interface IState extends IGroup {
    show: boolean,
    selectUsers: any[],
    selectDepartments: any[],
    selectRoles: IRole[],
    selectPermissions: IPermission[],
    reloadPermission: number
}
const SaveGroup = (props: IProps) => {
    useEmployee()
    useUserDepartment()
    const tablePermissionRef = useRef<TablePermissionHandle>(null);

    const userDepartmentState = useSelector(selectUserDepartmentState)
    const userState = useSelector(selectEmployeeState)
    const [roles, setRoles] = useState<IRole[]>([])
    const [permissions, setPermissions] = useState<IPermission[]>([])

    const [state, setState] = useState<IState>({
        show: false,
        selectUsers: [],
        selectDepartments: [],
        selectRoles: [],
        selectPermissions: [],
        reloadPermission: 1,
        ...props.data,
    })
    useEffect(() => {
        loadCategory()
    }, [])


    useEffect(() => {
        if (props.data.id !== state.id)
            loadUser(props.data.id || '')
        if (!props.data.id || props.data.id === '') {
            setState({
                show: props.show || false,
                id: '',
                name: '',
                selectUsers: [],
                selectDepartments: [],
                selectRoles: [],
                selectPermissions: [],
                reloadPermission: 1
            })
        }
        else setState({ ...state, ...props.data, show: props.show || false, })

    }, [props.reload])

    const loadCategory = () => {
        ShowLoading()
        let countLoad = 0
        fetchPermission().then((rs) => {
            if (rs.success)
                setPermissions(rs.data)

            countLoad++
            if (countLoad > 1)
                HidenLoading()
        })
        fetchRole().then((rs) => {
            if (rs.success)
                setRoles(rs.data)
            countLoad++
            if (countLoad > 1)
                HidenLoading()
        })
    }
    const loadUser = (appId: string) => {
        if (appId && appId !== '') {
            ShowLoading()
            GroupApi
                .fetchDetail(appId)
                .then((rs: any) => {
                    if (rs.success) {
                        let selectUsers: any[] = [], selectDepartments: any[] = []
                        const curUsers = rs.data.users || []
                        for (const index in curUsers) {
                            const u =curUsers[index]
                            if (u.type === 2) {
                                const curDept = userDepartmentState.find((x: any) => x.code === u.itemId)
                                selectDepartments.push(curDept)
                            }
                            else {
                                const curUser = userState.find((x: any) => x.employeeId === x.itemId)
                                selectUsers.push(curUser)
                            }
                        }
                        const curRoles = rs.data.roles?.length > 0 ? roles.filter((x: any) => rs.data.roles.container(x.id)) : []
                        
                        setState({
                            show: true,
                            id: rs.data.id,
                            name: rs.data.name,
                            selectUsers: selectUsers,
                            selectDepartments: selectDepartments,
                            selectRoles: curRoles,
                            selectPermissions: rs.data.permissions,
                            reloadPermission: state.reloadPermission + 1
                        })
                    }
                    else {
                        toast.error(rs.message)
                    }
                }).finally(() => HidenLoading())
        }
    }
    //handle
    const handleOnchange = (value: any, name: string, state: any, setState: any) => {
        setState({ ...state, [name]: value });
    }

    const handleOnClickCheckbox = (e: any, state: any, setState: any) => {
        const name = e.target.id || e.target.name;
        const value = e.target.checked;
        setState({ ...state, [name]: value });
    }

    const handleClose = () => {
        setState({ ...state, show: false })
    }

    const handleSave = () => {

        // validate dữ liệu
        if (state.name === '') {
            toast.warning(t('common.required'))
            return
        }
        // kiểm tra dữ liệu có bị trung user không?
        const dataPermission = tablePermissionRef.current?.get()
        const roles = state.selectRoles.map((x)=>x.id ||'')
        const users =  state.selectUsers.map((x)=>x.employeeId)
        const departments =  state.selectDepartments.map((x)=>x.code)

        const modelSave: IGroup = {

            id: state.id === '' ? undefined : state.id,
            active: state.active ? 1 : 0,
            name: state.name,
            departments: departments,
            users:users,
            roles:roles,
            permissions: dataPermission
        }
        props.onSave(modelSave)
    }


    const handleOnChangeSelect = (name: string, data?: any) => {
        setState({
            ...state,
            [name]: data
        })
    }

    return (
        <Suspense>
            {state.show ? <Grid container spacing={2} className="overflow-auto h-screen" >
                <Grid item md={12}>
                    <Button variant="contained" onClick={handleSave}>{t('save')}</Button>
                </Grid>
                <Grid item md={10} xs={12}>
                    <TextField
                        fullWidth
                        required
                        label={t('name')}
                        onChange={(e: any) => handleOnchange(e.target.value, 'name', state, setState)}
                        value={state.name}
                        placeholder={t('pleaseEnter') || ''} />
                </Grid>
                <Grid item md={2} xs={6} className="content-end">
                    <FormControlLabel control={<Checkbox
                        checked={state.active === true}
                        name="active"
                        onChange={(e) => handleOnClickCheckbox(e, state, setState)} />}
                        label={t('active')} />
                </Grid>
                <Grid item md={12} className="mt-4">
                    <Autocomplete
                        multiple
                        options={userState}
                        getOptionLabel={(obj: any) => `${obj?.employeeId} - ${obj?.employeeName} (${obj?.departmentName})`}

                        filterSelectedOptions
                        onChange={(e, item) => handleOnChangeSelect('selectUsers', item)}
                        value={state.selectUsers}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('user')}
                                placeholder={t('pleaseEnter')}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12} className="mt-4">
                    <Autocomplete
                        multiple
                        options={userDepartmentState}
                        getOptionLabel={(obj: any) => `${obj.code} - ${obj.name}`}
                        filterSelectedOptions
                        onChange={(e, item) => handleOnChangeSelect('selectDepartments', item)}
                        value={state.selectDepartments}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('department')}
                                placeholder={t('pleaseEnter')}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={12}>
                    <Autocomplete
                        multiple
                        options={roles}
                        getOptionLabel={(obj: any) => `${obj.name}`}
                        filterSelectedOptions
                        onChange={(e, item) => handleOnChangeSelect('selectRoles', item)}
                        value={state.selectRoles}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('role')}
                                placeholder={t('pleaseEnter')}
                            />
                        )}
                    />
                </Grid>
                {/* <Grid item md={12}>
                    <Autocomplete
                        multiple
                        options={permissions}
                        getOptionLabel={(obj: any) => `${obj.applicationId} - ${obj.name}`}
                        filterSelectedOptions
                        onChange={(e, item) => handleOnChangeSelect('permissions', item.map(r => r.id + ''))}
                        value={state.selectPermissions}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('permission')}
                                placeholder={t('pleaseEnter')}
                            />
                        )}
                    />
                </Grid> */}
                <Grid item md={12}>
                    <TablePermission
                        data={state.permissions || []}
                        selects={permissions}
                        ref={tablePermissionRef}
                        reload={state.reloadPermission}

                    />
                </Grid>
                <Grid item md={12}>
                    <Button variant="contained" onClick={handleSave}>{t('save')}</Button>
                </Grid>
            </Grid>
                : <div className="text-xl mt-10 text-center"> <h4>{t('pleaseSelectToDisplay')}</h4></div>
            }
        </Suspense>
    )
}

export default SaveGroup