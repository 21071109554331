import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    useTheme,
    useMediaQuery,
    IconButton,
    Tooltip,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { IDocumentFile } from 'src/interfaces/IDocumentFile';
import { getFileVersions, reverseFileVersion } from 'src/apis/apiFile';
import { IFileVersion } from 'src/interfaces/IFile';
import { t } from 'i18next';
import { HidenLoading, ShowLoading } from 'src/components/loading-page/LoadingPage';
import { quickSort } from 'src/commons/helpers/sort';
import Swal from 'sweetalert2';
import { updateDocumentFile } from 'src/apis/apiDocument';

interface ImageViewerProps {
    file: IDocumentFile;
    open: boolean;
    onClose: () => void;
    onSave?: () => void;
}


const FileReverseModal: React.FC<ImageViewerProps> = ({
    file,
    open,
    onClose,
    onSave
}) => {

    const [fileVersions, setFileVersions] = useState<IFileVersion[]>([]);
    const [reload, setReload] = useState(0);
    const [isOpenConfirmUpload, setIsOpenConfirmUpload] = useState(false);

    useEffect(() => {
        if (open) {
            ShowLoading();
            getFileVersions(file.url).then(res => {
                if (!res.success) {
                    toast.error(res?.message);
                    return;
                }

                const data: IFileVersion[] = res.data || [];
                setFileVersions(quickSort(data, 'versionOrder', 'desc'));
            }).catch(err => {
                toast.error(t('message.failedCallAPI'));
            }).finally(() => HidenLoading());
        }
    }, [open, reload]);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const handleReverseFileClick = (fileVersion: IFileVersion) => {
        setIsOpenConfirmUpload(true)
        Swal.fire({
            title: `${t('pleaseEnter')} ${t('description').toLowerCase()}`,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off',
                id: "inputSwal3",
            },
            showCancelButton: true,
            confirmButtonText: t('confirm'),
            showLoaderOnConfirm: true,
            cancelButtonText: t('cancel'),
            preConfirm: (note) => {
                const autoNote = note || `${t('reverse')} ${file.name} to version ${fileVersion.versionOrder}`;
                return reverseFileVersion(fileVersion.fileId, fileVersion.versionId, autoNote)
                    .then(res => {
                        if (!res.success) {
                            throw new Error(res.message ? res.message : t('message.actionError'))
                        }
                        // const data: any = res.data;

                        setReload(reload + 1);
                        updateDocumentFile({
                            documentId: file.documentId as string,
                            note: autoNote,
                            fileId: file.id
                        }).then(r => {
                            onSave && onSave();
                        })
                        return res;
                    }).catch(err => {
                        Swal.showValidationMessage(
                            `${t('message.errorExcution')}: ${err}`
                        )
                        const inputSwal3 = document.getElementById('inputSwal3') as HTMLInputElement;
                        inputSwal3.value = "";
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {

            if (result.isConfirmed) {
                toast.success(`${t('message.actionSuccess')}`)
            }
        }).finally(() => setIsOpenConfirmUpload(false))

    }

    return (
        <Dialog sx={{ display: isOpenConfirmUpload ? 'none' : '' }} open={open} onClose={onClose} maxWidth="md" fullScreen={fullScreen}>
            <DialogTitle >{file?.name}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                ) : null}
            </DialogTitle>

            <DialogContent>
                {fileVersions?.map((f, index) =>
                    <li key={index} className="group/item  relative overflow-hidden shadow-sm flex items-center px-0 py-2 mb-2 bg-white border-0 rounded-t-lg  hover:bg-slate-50 duration-500">
                        <div className="inline-flex items-center justify-center w-12 h-12 mr-4 transition-all duration-200 text-base ease-soft-in-out rounded-xl">
                            <i className="fa-solid fa-file-lines text-3xl"></i>
                        </div>
                        <div className="flex flex-col items-start justify-center w-10/12 overflow-hidden text-ellipsis">
                            <h6 className="mb-0 leading-normal text-sm whitespace-nowrap w-full overflow-hidden text-ellipsis">{file?.name}</h6>
                            <div className="flex justify-between w-10/12">
                                <input
                                    readOnly
                                    name="description"
                                    className="mb-0 leading-tight text-xs w-2/3 m-1 text-ellipsis p-1"
                                    type="text"
                                    value={`${t('description')}: ${f.note}`}
                                />
                                <input
                                    readOnly
                                    name="version"
                                    className="mb-0 leading-tight text-xs w-2/3 m-1 text-ellipsis p-1"
                                    type="text"
                                    value={`${t('version')}: ${f.versionOrder}`}
                                />
                            </div>
                        </div>
                        <div className="absolute flex flex-row justify-around w-1/12 right-2 group/edit invisible group-hover/item:visible" >
                            {/* <Tooltip title={`${t('reverseTo')} ${f.versionOrder}`}> */}
                                <a onClick={() => handleReverseFileClick(f)} className="font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg 
                            shadow-none cursor-pointer leading-pro text-xs ease-soft-in hover:scale-102 hover:active:scale-102 
                            active:opacity-85 text-fuchsia-500 hover:text-red-500 hover:shadow-none  active:scale-100">
                                    <i className="fas fa-history"></i>
                                </a>
                            {/* </Tooltip> */}

                        </div>
                    </li>
                )}
            </DialogContent>

            <DialogActions>
            </DialogActions>
        </Dialog >
    );
};

export default FileReverseModal;
