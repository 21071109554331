import { Pagination } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDocumment } from "src/apis/apiDocument";
import { getDocumentPriorityColor, getDocumentStatusColor } from "src/commons/helpers/getClass";
import UserAvatar from "src/components/avatars/UserAvatar";
import Button from "src/components/buttons/Button";
import { HidenLoading, ShowLoading } from "src/components/loading-page/LoadingPage";
import { IDocument } from "src/interfaces/IDocument";
import { useEmployee } from "src/store/hook";
import { selectRootState } from "src/store/slices/rootSlice";

interface IProps {
    onClick: (id: string) => void
}

const ListDocument = forwardRef((props: IProps, ref) => {

    useImperativeHandle(ref, () => ({
        reloadStatus(doc: any) {
            const index = data?.findIndex(x => x.id === doc.id)
            if (index != undefined && index > -1) {
                setData(prevDatas => {
                    const updatedDoc = prevDatas ? [...prevDatas] : [];
                    updatedDoc[index].status = doc.status;
                    return updatedDoc;
                });
            }
        },
        deletedDoc(id:string){
            const index = data?.findIndex(x => x.id === id)
            if(index != null && index >-1){
                const temp = [...(data|| [])]
                temp.splice(index, 1)
                setData(temp)
            }
        }
    }))

    const { t } = useTranslation()
    useEmployee()
    const rootState = useSelector(selectRootState)
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const status = searchParams.get('status')
    const type = searchParams.get('type')
    const id = searchParams.get('id')
    const serial = searchParams.get('s')
    const v = searchParams.get('v')
    const [idActive, setIdActive] = useState(id)
    const [data, setData] = useState<IDocument[]>()
    const [currentPage, setCurrentPage] = useState(1)
    const [page, setPage] = useState({
        total: 1,
        countItem: 0
    })

    useEffect(() => {
        getData()
        if (id || serial) {
            const tempId: string = id || serial || ''
            handleOnClickDetail(tempId)
        }
    }, [status, type, rootState.keyword, serial, v])

    const getData = (page: number = 1) => {
        ShowLoading()
        const model = {
            status: status,
            type: type || 0,
            page: page,
            keyword: rootState.keyword,
            size: 8
        }
        getDocumment(model).then(rs => {
            if (!rs) return
            if (rs.success) {
                setData(rs.data.items)
                setCurrentPage(page || 1)
                setPage({ countItem: rs.data.count, total: Math.ceil(rs.data.count / rs.data.size) || 1 })
            }
        }).finally(() => HidenLoading())
    }

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        getData(value)
    }

    const handleOnClickDetail = (id: string) => {
        props.onClick(id)
        setIdActive(id)
    }
    return (
        <div className="max-w-full px-3 w-3/12">
            <div className="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                <div className="absolute z-20 top-0 left-0 w-full px-4 py-2 mt-2  bg-white rounded-xl" style={{ backgroundColor: '#d3caca', padding: '10px 10px', left: '8px', width: '95%' }}>
                    <h6 className="mb-0 flex ">{page.countItem} {t('breadcrumb.document')}</h6>
                    <button type="button"
                        className='font-bold text-center  uppercase align-middle transition-all 
    bg-transparent border-0 rounded-lg cursor-pointer hover:scale-105 hover:shadow-soft-xs leading-pro
    text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 text-white bg-gradient-to-tl from-purple-700 to-pink-500
     absolute right-0 top-0 md:w-auto sm:w-full px-4 py-2 mt-2'
                        onClick={() => navigate('/document/edit/0')}>
                        {t('add')}
                    </button>
                </div>
                <div className="flex-auto p-2 py-10 md:pt-20 sm:pt-11 overflow-auto h-[87svh]">
                    <ul className="flex flex-col pl-0 mb-0 rounded-lg">
                        {data?.map((d: IDocument) => (
                            <li key={d.id} id={d.id} onClick={() => handleOnClickDetail(d.id)}
                                className={`relative flex p-3 mb-2 border-0 rounded-t-inherit rounded-xl  hover:bg-gray-10 ${idActive === d.id || idActive === d.serial ? 'bg-amber-100' : 'bg-gray-100'}`}>
                                <div className="flex flex-col">
                                    <UserAvatar id={d.createdBy} />
                                    <h6 className="mb-4 leading-normal text-sm">{d.name}</h6>
                                    <span className="mb-2 leading-tight text-xs">{t('documentType')}: <span className="font-semibold text-slate-700 sm:ml-2">{d.documentTypeName}</span></span>
                                    <span className="leading-tight text-xs">{t('serial')}: <span className="font-semibold text-slate-700 sm:ml-2">{d.serial}</span></span>
                                </div>
                                <span className={`absolute -top-1 -right-3 font-bold text-center align-middle transition-all 
                                        bg-transparent border-0 rounded-lg px-3 py-1 mr-2  leading-pro
                                        text-xs ease-soft-in tracking-tight-soft shadow-soft-md ${getDocumentStatusColor(d?.status)}`}>

                                    {t('documentStatus' + d?.status)}
                                </span>
                                {d?.priority > 1 &&
                                    <span className={`absolute top-6 -right-3 font-bold text-center align-middle transition-all 
                                        bg-transparent border-0 rounded-lg px-3 py-1 mr-2  leading-pro
                                        text-xs ease-soft-in tracking-tight-soft shadow-soft-md ${getDocumentPriorityColor(d?.priority)}`}>
                                        {t('documentPriority' + d?.priority)}
                                    </span>
                                }
                            </li>
                        ))}

                    </ul>
                </div>
                <div className="absolute m-auto w-full py-2 bottom-0 right-0">
                    <Pagination key={currentPage} page={currentPage} onChange={handleChangePage} count={page.total} siblingCount={0} color="primary" />
                </div>
            </div>
        </div>
    )
})
export default ListDocument