import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import InputText from "src/components/inputs/InputText"
import { IHtmlInput } from "src/interfaces/IHtmlInput"

interface IProps {
    data: IHtmlInput[],
    reload?: number
}
const InputQuestions = forwardRef((props: IProps, ref) => {
    useImperativeHandle(ref, () => ({
        getData: async () => {
            if (values.length === 0 || !values) return []
            const validate = await validateData()
            if (!validate)
                return false
            return values
        }
    }))
    const { t } = useTranslation()
    const [values, setValues] = useState<IHtmlInput[]>(props.data)

    useEffect(() => {
        setValues(props.data)
    }, [props.reload])

    const validateData = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            const fieldRequired = values.filter((x: IHtmlInput) => x.required === true && (x.value === undefined || x.value === null))
            if (fieldRequired.length > 0) {
                fieldRequired.forEach(x => toast.warning(`${t('isRequired')}: ${x.label}`))
                resolve(false)
            }
            else
                resolve(true)
        })
    }

    const handleOnChange = (e: any) => {
        const value = e.target.value
        const name = e.target.name
        const index = values.findIndex(x => x.name === name)
        values[index].value = value
        setValues(values)
    }
    return (
        <div className="flex flex-wrap">
            {values && values.map((i: IHtmlInput) => (
                <div className="w-full pt-2">
                    <InputText
                        required={i.required}
                        type={i.type}
                        label={i.label}
                        name={i.name}
                        defaultValue={i.value || i.defaultValue}
                        onChange={handleOnChange} />
                </div>
            ))}
        </div>
    )
})
export default InputQuestions
