import { Apartment, ArrowDropDown, ArrowRight, CreateNewFolder, DriveFileRenameOutline, FolderOff, MoreVert, Topic } from "@mui/icons-material"
import { Chip, Grid, IconButton, ListItemIcon, MenuItem, TextField, Typography } from "@mui/material"
import Menu from "@mui/material/Menu"
import { useEffect, useState } from "react"
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { fuzzySearch_VI } from "src/commons/helpers/search"
import { useTranslation } from "react-i18next"
import Button from "../../components/buttons/Button"
import { IDepartmentPhysicalAddress, IPhysicalAddressTreeItem } from "src/interfaces/IOrgPhysicalAddress"

interface IProps {
    dataDepartment: IDepartmentPhysicalAddress[];
    dataSelectedItems?: IPhysicalAddressTreeItem[];
    onChangeSelectedItems?: (orgs: IPhysicalAddressTreeItem[]) => void;
    dataExpandedNodes?: string[];
    onChangeExpandedNodes?: (nodes: string[]) => void;
    onSelectTreeItem?: (selectedTreeItem: IPhysicalAddressTreeItem) => void;
    onClickAddFolderTreeItem?: (selectedTreeItem: IPhysicalAddressTreeItem) => void;
    onClickDeleteFolderTreeItem?: (selectedTreeItem: IPhysicalAddressTreeItem) => void;
    onClickEditFolderTreeItem?: (selectedTreeItem: IPhysicalAddressTreeItem) => void;
}

const PhysicalAddressTreeView: React.FC<IProps> = (
    { dataDepartment, onChangeSelectedItems, dataSelectedItems, dataExpandedNodes,
        onChangeExpandedNodes, onSelectTreeItem, onClickAddFolderTreeItem, onClickDeleteFolderTreeItem, onClickEditFolderTreeItem }
) => {
    const { t } = useTranslation()
    const [treeItems, setTreeItems] = useState<IPhysicalAddressTreeItem[]>([]);
    const [selectedTreeItems, setSelectedTreeItems] = useState<IPhysicalAddressTreeItem[]>([]);
    const [selectedTreeItem, setSelectedTreeItem] = useState<IPhysicalAddressTreeItem | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const [reload, setReload] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [expanded, setExpanded] = useState<string[]>([]);

    useEffect(() => {
        setTreeItems(dataDepartment.map(x => departmentToTreeItem(x)))
        dataSelectedItems && setSelectedTreeItems(dataSelectedItems);
        dataExpandedNodes && setExpanded(dataExpandedNodes)
    }, [dataDepartment])

    useEffect(() => {
        onChangeSelectedItems && onChangeSelectedItems(selectedTreeItems)
    }, [selectedTreeItems, reload])


    const onKeyUpKeyWord = (e: any) => {
        setKeyword(e.target.value)
        if ([13].includes(e.keyCode)) {
            e.preventDefault();
            handleClickSearchButton()
        }
    }

    const findParentNodes = (orgsFullMap: IPhysicalAddressTreeItem[], results: IPhysicalAddressTreeItem[], parentId: string) => {
        const parentNode = orgsFullMap.find(x => x.id === parentId);
        if (parentNode && parentNode.parentId) {
            results.push(parentNode)
            findParentNodes(orgsFullMap, results, parentNode.parentId);
        }
    }

    const handleClickSearchButton = () => {
        const orgsFullMap = dataDepartment.map(x => departmentToTreeItem(x));
        const filters = fuzzySearch_VI(orgsFullMap, 'label', keyword)

        let results: IPhysicalAddressTreeItem[] = [];
        for (let index = 0; index < filters.length; index++) {
            const element = filters[index];
            results.push(element)
            if (element.parentId) {
                findParentNodes(orgsFullMap, results, element.parentId)
            }
        }
        const expandedMaps = results.map(x => x.id.toString());
        handleNodeToggle(expandedMaps)
    }


    const handleNodeToggle = (nodes: string[]) => {
        setExpanded(nodes);
        onChangeExpandedNodes && onChangeExpandedNodes(nodes)
    };

    const fillColorSearchDepartmentItem = (node: IPhysicalAddressTreeItem, expectedColor: string = 'yellow') => {
        if (!keyword) {
            return '';
        }

        const checkInclude = expanded.findIndex(x => x === node.id.toString()) != -1
        const checkSearchName = fuzzySearch_VI([node], 'label', keyword)?.length > 0;

        return checkInclude && checkSearchName ? expectedColor : ''
    }


    //--------------------------------Select-----------------------------------
    const toggleOrg = (selectedOrgItems: IPhysicalAddressTreeItem[], item: IPhysicalAddressTreeItem, isExist: boolean) => {


        const isDepartment = item.type === 'department';
        if (isDepartment) {
            const childrenEmployees = item.department?.physicalAddresses;
            if (childrenEmployees && childrenEmployees?.length > 0) {
                for (let index = 0; index < childrenEmployees.length; index++) {
                    const element = childrenEmployees[index];
                    const indexEmp = selectedOrgItems.findIndex(x => x.id === element.id.toString());
                    indexEmp != -1 && selectedOrgItems.splice(indexEmp, 1);
                }
            }
        }

        const orgIndex = selectedOrgItems.findIndex(x => x.id === item.id);
        const children = treeItems.filter(d => d?.department?.parentId === item.id);
        if (isExist) {
            if (orgIndex != -1) {
                selectedOrgItems.splice(orgIndex, 1);
            }
        } else {
            if (orgIndex === -1) {
                selectedOrgItems.push(item);
            }
        }

        children.forEach(child => toggleOrg(selectedOrgItems, child, isExist));
    }

    const departmentToTreeItem = (item: IDepartmentPhysicalAddress) => {
        const selectedOrg: IPhysicalAddressTreeItem = {
            id: item.id,
            label: item.name || ('noName'),
            type: item.type,
            departmentId: item.code,
            parentId: item?.parentId,
            count: item.count
        };

        return selectedOrg;
    }


    const handleOnClickSelectDepartment = (event: any, item: IPhysicalAddressTreeItem) => {
        event.stopPropagation()
        onSelectTreeItem && onSelectTreeItem(item)

    }

    const handleClickMenu = (event: React.MouseEvent<HTMLElement>, folder: IPhysicalAddressTreeItem) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
        setSelectedTreeItem(folder);

    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClickDeleteFolderItem = () => {
        if (selectedTreeItem) {
            onClickDeleteFolderTreeItem && onClickDeleteFolderTreeItem(selectedTreeItem)
            handleCloseMenu();
        }
    }

    const handleClickAddFolderItem = () => {
        if (selectedTreeItem) {
            onClickAddFolderTreeItem && onClickAddFolderTreeItem(selectedTreeItem)
            handleCloseMenu();
        }
    }

    const handleClickLockFolderItem = () => {
        if (selectedTreeItem) {
            onClickEditFolderTreeItem && onClickEditFolderTreeItem(selectedTreeItem)
            handleCloseMenu();
        }
    }


    const buildTree = (items: IPhysicalAddressTreeItem[], parentId: number | string | null = null): React.ReactNode => {
        const filteredItems = items.filter((item) => item.parentId === parentId);
        return filteredItems.map((item) => {
            let isExist = selectedTreeItems.findIndex(x => x.id === item?.id) != -1;

            return (
                <div key={item.id} >
                    <TreeItem key={item.id} nodeId={(item.id).toString()}
                        className={isExist ? "Tree-Item Tree-Item-Selected" : "Tree-Item"}
                        label=
                        {
                            <div className=' group/item'>
                                <div
                                    key={item.id + 'label'}
                                    onClick={e => handleOnClickSelectDepartment(e, item)}
                                    className='Tree-Item-Label'
                                >
                                    {
                                        item.type === 'department' ? <Apartment color="info" /> : <Topic color="warning" />
                                    }
                                    <label style={{ marginLeft: '1vh', cursor: 'pointer', backgroundColor: fillColorSearchDepartmentItem(item, 'yellow') }}>
                                        {item?.label}
                                    </label>


                                </div>
                                {item.count ?
                                    <Chip label={item.count}
                                        size="small"
                                        sx={{ padding: '2px', top: '50%', bottom: '50%', transform: 'translate(0%, -50%)', position: 'absolute', right: 10 }}
                                    /> : <></>}

                                <IconButton
                                    className='group/edit invisible group-hover/item:visible '
                                    aria-label="more"
                                    sx={{ width: '1vh', height: '1vh', top: '50%', bottom: '50%', transform: 'translate(0%, -50%)', position: 'absolute', right: 0 }}
                                    id="long-button"
                                    aria-controls={openMenu ? 'long-menu' : undefined}
                                    aria-expanded={openMenu ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(e) => handleClickMenu(e, item)}
                                >
                                    <MoreVert />
                                </IconButton>
                            </div>
                        }
                    >
                        {buildTree(items, item.id)}
                    </TreeItem >
                </div>
            )
        });
    };

    return (
        <Grid container spacing={1} p={2}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', whiteSpace: 'nowrap', backgroundColor: 'white' }}>
                <TextField
                    defaultValue={keyword}
                    onKeyUp={onKeyUpKeyWord}
                    fullWidth
                    size="small"
                    type="search"
                />
                <Button onClick={handleClickSearchButton} color="primary" classButton="!mt-0 mr-0" label={t('search')}></Button>
            </Grid>

            <Grid item xs={12} sx={{ backgroundColor: 'white', boxShadow: '4px 3px 5px 0px #ac9f9f', borderRadius: '1%' }}>
                {treeItems && treeItems.length > 0 ?
                    <TreeView
                        aria-label="folder"
                        // defaultExpanded={selectedOrgs.filter(x => !(x?.department?.employees?.length || 0 > 0)).map(x => x.id.toString())}
                        expanded={expanded}
                        onNodeToggle={(e, n) => handleNodeToggle(n)}
                        defaultCollapseIcon={
                            <div style={{ display: 'flex' }}>
                                <ArrowDropDown />
                                {/* <Folder /> */}
                            </div>
                        }
                        defaultExpandIcon={
                            <div style={{ display: 'flex' }}>
                                <ArrowRight />
                                {/* <Folder /> */}
                            </div>}
                        defaultEndIcon={<div style={{ display: 'flex', "marginRight": "-2vh" }}>
                            {/* <Folder /> */}
                        </div>}
                        sx={{ height: 'auto', maxHeight: '70vh', flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}
                        selected="0"

                    >
                        {buildTree(treeItems, 'dept_1')}
                        {(onClickEditFolderTreeItem || onClickDeleteFolderTreeItem || onClickAddFolderTreeItem)
                            &&
                            <Menu
                                id="long-menu2"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleCloseMenu}
                                PaperProps={{
                                    style: {
                                        width: '20ch',
                                    },
                                }}
                            >
                                {onClickAddFolderTreeItem && <MenuItem key="menuItemAdd" onClick={handleClickAddFolderItem} >
                                    <ListItemIcon>
                                        <CreateNewFolder fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit" noWrap>
                                        {t('add')}
                                    </Typography>
                                </MenuItem>
                                }
                                {onClickDeleteFolderTreeItem && <MenuItem key="menuItemDelete" onClick={handleClickDeleteFolderItem} >
                                    <ListItemIcon>
                                        <FolderOff fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit" noWrap>
                                        {t('delete')}
                                    </Typography>
                                </MenuItem>}

                                {onClickEditFolderTreeItem && <MenuItem key="menuItemLock" onClick={handleClickLockFolderItem} >
                                    <ListItemIcon>
                                        <DriveFileRenameOutline fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit" noWrap>
                                        {t('edit')}
                                    </Typography>
                                </MenuItem>}

                            </Menu>}
                    </TreeView> : <div>{t('noInfo')}</div>}

            </Grid>

        </Grid>
    )
}

export default PhysicalAddressTreeView