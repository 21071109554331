import { ArrowDropDown, ArrowRight, Folder } from "@mui/icons-material"
import { Chip, Grid, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { IDepartment } from "src/interfaces/IOrg"
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { fuzzySearch_VI } from "src/commons/helpers/search"
import { useTranslation } from "react-i18next"
import Button from "../../components/buttons/Button"
import { ICountByDepartment, IDocumentTreeItem } from "src/interfaces/IDocumentTreeItem"
import { HidenLoading, ShowLoading } from "src/components/loading-page/LoadingPage"
import { countDocumentByDepartment } from "src/apis/apiDocument"
import CustomSelect, { IDataSelect } from "src/components/selects/select"
import { enumToListSelect } from "src/commons/helpers/common"
import { DocumentStatus } from "src/commons/enums/documentStatus"
import InputText from "src/components/inputs/InputText"

interface IProps {
    dataDepartment: IDepartment[];
    dataSelectedItems?: IDocumentTreeItem[];
    onSelectTreeItem?: (org: IDocumentTreeItem) => void;
    dataExpandedNodes?: string[];
    onChangeExpandedNodes?: (nodes: string[]) => void;
    setSelectedTreeItem?: (selectedTreeItem: IDocumentTreeItem) => void;
    onChangeStatus?: (statusValue: number) => void
    onChangeTime?: (timeValue: string) => void
}

const DocumentTreeView: React.FC<IProps> = (
    { dataDepartment, onChangeStatus, onChangeTime, onSelectTreeItem, dataSelectedItems, dataExpandedNodes, onChangeExpandedNodes }
) => {
    const { t } = useTranslation()
    const [treeItems, setTreeItems] = useState<IDocumentTreeItem[]>([]);
    const [selectedTreeItems, setSelectedTreeItems] = useState<IDocumentTreeItem[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const [keyword, setKeyword] = useState('');
    const [expanded, setExpanded] = useState<string[]>([]);
    const [currentStatus, setCurrentStatus] = useState<number>(999);
    const [currentTime, setCurrentTime] = useState<string>("");

    useEffect(() => {
        dataSelectedItems && setSelectedTreeItems(dataSelectedItems);

        ShowLoading()
        countDocumentByDepartment().then(res => {
            if (!res || !res.success) {
                return
            }
debugger
            const data: ICountByDepartment[] = res.data;
            const dataTreeItems = dataDepartment.map(x =>
                departmentToTreeItem(x)).map(x =>
                ({
                    ...x,
                    count: data.find(y =>
                        y.departmentId === x.department?.code)?.count || 0
                }));

            setTreeItems(dataTreeItems);

        }).finally(() => HidenLoading())

        dataExpandedNodes && setExpanded(dataExpandedNodes)
    }, [dataDepartment])


    const onKeyUpKeyWord = (e: any) => {
        setKeyword(e.target.value)
        if ([13].includes(e.keyCode)) {
            e.preventDefault();
            handleClickSearchButton()
        }
    }

    const findParentNodes = (orgsFullMap: IDocumentTreeItem[], results: IDocumentTreeItem[], parentId: string) => {
        const parentNode = orgsFullMap.find(x => x.id === parentId);
        if (parentNode && parentNode.parentId) {
            results.push(parentNode)
            findParentNodes(orgsFullMap, results, parentNode.parentId);
        }
    }

    const handleClickSearchButton = () => {
        const orgsFullMap = dataDepartment.map(x => departmentToTreeItem(x));
        const filters = fuzzySearch_VI(orgsFullMap, 'label', keyword)

        let results: IDocumentTreeItem[] = [];
        for (let index = 0; index < filters.length; index++) {
            const element = filters[index];
            results.push(element)
            if (element.parentId) {
                findParentNodes(orgsFullMap, results, element.parentId)
            }
        }
        const expandedMaps = results.map(x => x.id.toString());
        handleNodeToggle(expandedMaps)

        countDocumentByDepartment(currentStatus === 999 ? null : currentStatus, currentTime).then(res => {
            if (!res || !res.success) {
                return
            }

            const data: ICountByDepartment[] = res.data;
            const dataTreeItems = dataDepartment.map(x =>
                departmentToTreeItem(x)).map(x =>
                ({
                    ...x,
                    count: data.find(y =>
                        y.departmentId === x.department?.code)?.count || 0
                }));

            setTreeItems(dataTreeItems);

        }).finally(() => HidenLoading())
    }


    const handleNodeToggle = (nodes: string[]) => {
        setExpanded(nodes);
        onChangeExpandedNodes && onChangeExpandedNodes(nodes)

    };

    const fillColorSearchDepartmentItem = (node: IDocumentTreeItem, expectedColor: string = 'yellow') => {
        if (!keyword) {
            return '';
        }

        const checkInclude = expanded.findIndex(x => x === node.id.toString()) != -1
        const checkSearchName = fuzzySearch_VI([node], 'label', keyword)?.length > 0;

        return checkInclude && checkSearchName ? expectedColor : ''
    }


    //--------------------------------Select-----------------------------------
    const toggleOrg = (selectedOrgItems: IDocumentTreeItem[], item: IDocumentTreeItem, isExist: boolean) => {
        const isDepartment = item.type === 'department';
        if (isDepartment) {
            const childrenEmployees = item.department?.employees;
            if (childrenEmployees && childrenEmployees?.length > 0) {
                for (let index = 0; index < childrenEmployees.length; index++) {
                    const element = childrenEmployees[index];
                    const indexEmp = selectedOrgItems.findIndex(x => x.id === element.id);
                    indexEmp != -1 && selectedOrgItems.splice(indexEmp, 1);
                }
            }
        }

        const orgIndex = selectedOrgItems.findIndex(x => x.id === item.id);
        const children = treeItems.filter(d => d?.department?.parentId === item.id);
        if (isExist) {
            if (orgIndex != -1) {
                selectedOrgItems.splice(orgIndex, 1);
            }
        } else {
            if (orgIndex === -1) {
                selectedOrgItems.push(item);
            }
        }

        children.forEach(child => toggleOrg(selectedOrgItems, child, isExist));
    }

    const departmentToTreeItem = (item: IDepartment) => {
        const selectedOrg: IDocumentTreeItem = {
            id: item.id,
            label: item.name || ('noName'),
            type: 'department',
            department: item,
            parentId: item?.parentId
        };

        return selectedOrg;
    }


    const handleOnClickSelectDepartment = (event: any, item: IDocumentTreeItem) => {
        event.stopPropagation()
        onSelectTreeItem && onSelectTreeItem(item)
    }


    const buildTree = (items: IDocumentTreeItem[], parentId: number | string | null = null): React.ReactNode => {
        const filteredItems = items.filter((item) => item.parentId === parentId);

        return filteredItems.map((item) => {
            let isExist = selectedTreeItems.findIndex(x => x.id === item?.id) != -1;

            return (
                <div key={item.id} >
                    <TreeItem key={item.id} nodeId={(item.id).toString()}
                        className={isExist ? "Tree-Item Tree-Item-Selected" : "Tree-Item"}
                        label=
                        {
                            <div>
                                <div
                                    key={item.id + 'label'}
                                    onClick={e => handleOnClickSelectDepartment(e, item)}
                                    className='Tree-Item-Label'
                                >
                                    {
                                        <Folder />
                                    }
                                    <label style={{ marginLeft: '1vh', cursor: 'pointer', backgroundColor: fillColorSearchDepartmentItem(item, 'yellow') }}>
                                        {item.label}
                                    </label>
                                </div>
                                {item.count !== undefined && item.count > 0 && <Chip
                                    sx={{ height: '80%', top: '50%', bottom: '50%', transform: 'translate(0%, -50%)', position: 'absolute', right: 2 }}
                                    aria-controls={openMenu ? 'long-menu' : undefined}
                                    aria-expanded={openMenu ? 'true' : undefined}
                                    aria-haspopup="true"
                                    color="default"
                                    label={item.count}
                                />}
                            </div>
                        }
                    >
                        {buildTree(items, item.id)}
                    </TreeItem >
                </div>
            )
        });
    };

    const renderDocumentStatus = () => {
        const allValue: IDataSelect = {
            label: t('all'),
            value: 999
        };

        let dataSelects: IDataSelect[] = [];
        dataSelects.push(allValue);


        return [
            ...dataSelects, ...enumToListSelect(DocumentStatus, t, 'documentStatus')
        ]
    }

    const handleChangeStatus = (e: any) => {
        const { value } = e.currentTarget
        onChangeStatus && onChangeStatus(value)
        setCurrentStatus(value)
    }

    const handleChangeTime = (e: any) => {
        const { value } = e.currentTarget
        setCurrentTime(value)
        onChangeTime && onChangeTime(value)
    }

    return (
        <Grid item xs={12} md={4} className="overflow-auto h-[85svh]">
            <Grid item xs={12} mb={1} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', whiteSpace: 'nowrap', backgroundColor: 'white' }}>
                <CustomSelect label={t('documentStatus')} name="documentStatus" data={renderDocumentStatus()} onChange={handleChangeStatus} />
            </Grid>
            {currentStatus === DocumentStatus.LateProcess && <Grid item xs={12} mb={1} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', whiteSpace: 'nowrap', backgroundColor: 'white' }}>
                <InputText
                    name="lateProcessDate"
                    label={t('lateProcessDate')} type="number"
                    onChange={handleChangeTime}
                    defaultValue={7}
                />
            </Grid>}

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', whiteSpace: 'nowrap', backgroundColor: 'white' }}>
                <TextField
                    defaultValue={keyword}
                    onKeyUp={onKeyUpKeyWord}
                    fullWidth
                    size="small"
                    type="search"
                />
                <Button onClick={handleClickSearchButton} color="primary" classButton="!mt-0 mr-0" label={t('search')}></Button>
            </Grid>

            <Grid item xs={12} sx={{ backgroundColor: 'white', boxShadow: '4px 3px 5px 0px #ac9f9f', borderRadius: '1%' }}>
                {treeItems && treeItems.length > 0 ?
                    <TreeView
                        aria-label="folder"
                        // defaultExpanded={selectedOrgs.filter(x => !(x?.department?.employees?.length || 0 > 0)).map(x => x.id.toString())}
                        expanded={expanded}
                        onNodeToggle={(e, n) => handleNodeToggle(n)}
                        defaultCollapseIcon={
                            <div style={{ display: 'flex' }}>
                                <ArrowDropDown />
                                {/* <Folder /> */}
                            </div>
                        }
                        defaultExpandIcon={
                            <div style={{ display: 'flex' }}>
                                <ArrowRight />
                                {/* <Folder /> */}
                            </div>}
                        defaultEndIcon={<div style={{ display: 'flex', "marginRight": "-2vh" }}>
                            {/* <Folder /> */}
                        </div>}
                        sx={{ height: 'auto', maxHeight: '70vh', flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}
                        selected="0"

                    >
                        {buildTree(treeItems, 1)}
                    </TreeView> : <div>{t('noInfo')}</div>}

            </Grid>
        </Grid>

    )
}

export default DocumentTreeView