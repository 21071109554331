/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPhysicalAddress } from "src/apis/apiDocument";
import { IPhysicalAddress } from "src/interfaces/IPhysicalAddress";

export interface IPhysicalAddressState {
  data: IPhysicalAddress[];
  reload: number;
}

const initialState: IPhysicalAddressState = {
  data: [],
  reload: 0,
};

export const fetchPhysicalAddress = createAsyncThunk(
  "fetchPhysicalAddress",
  async () => {
    const res = await getPhysicalAddress();
    return res.data;
  }
);

export const physicalAddressSlice = createSlice({
  name: "physicalAddressSlice",
  initialState,
  reducers: {
    addPhysicalAddress: (state: any, action: any) => {
      state.data = [action.payload, ...state.data];
      state.reload = state.reload + 1 || 1;
    },
    editPhysicalAddress: (state: any, action: any) => {
      const values = action.payload;
      const index = state.data.findIndex(
        (x: IPhysicalAddress) => x.id === values.id
      );
      if (index > -1) {
        const tempData = [...state.data];
        tempData[index] = { ...values };
        state.data = tempData;
        state.reload = state.reload + 1 || 1;
      }
    },
    removePhysicalAddress: (state: any, action: any) => {
      const index = state.data.findIndex(
        (x: IPhysicalAddress) => x.id === action.payload
      );
      if (index > -1) {
        const tempData = [...state.data];
        tempData.splice(index, 1);
        state.data = tempData;
        state.reload = state.reload + 1 || 1;
      }
    }
  },
  extraReducers: (builder: any) => {
    builder.addCase(fetchPhysicalAddress.fulfilled, (state: any, action: any) => {
      state.data = action.payload;
      state.reload = state.reload + 1 || 1;
    });
  },
});
export const { addPhysicalAddress, editPhysicalAddress, removePhysicalAddress } =
  physicalAddressSlice.actions;

export const selectPhysicalAddressState = (state: any) =>
  state.physicalAddress || initialState;

export default physicalAddressSlice.reducer;
