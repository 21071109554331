import React, { useEffect, useState } from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { ArrowDropDown, ArrowRight, CreateNewFolder, Folder, FolderOff, LockPerson, MoreVert, RuleFolder } from '@mui/icons-material';
import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { FolderModel } from 'src/interfaces/IFolder';
import { t } from 'i18next';



interface TreeViewFolderProps {
    data: FolderModel[];
    handleClickFolderTreeItem: (folder: FolderModel) => void;
    handleClickDeleteFolderTreeItem: (folder: FolderModel) => void;
    handleClickAddFolderTreeItem: (folder: FolderModel) => void;
    handleClickClockFolderTreeItem?: (folder: FolderModel) => void;
}
const ITEM_HEIGHT = 1;

const TreeViewFolder: React.FC<TreeViewFolderProps> = ({ data, handleClickFolderTreeItem, handleClickDeleteFolderTreeItem, handleClickAddFolderTreeItem, handleClickClockFolderTreeItem: handleClickLockFolderTreeItem }) => {

    const [selectedFolder, setSelectedFolder] = useState<FolderModel>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    useEffect(() => {
        data && data.length > 0 && !selectedFolder && setSelectedFolder(data[0]);
    }, [data])


    const handleClickMenu = (event: React.MouseEvent<HTMLElement>, folder: FolderModel) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
        setSelectedFolder(folder);

    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClickDeleteFolderItem = () => {
        if (selectedFolder) {
            handleClickDeleteFolderTreeItem(selectedFolder)
            handleCloseMenu();
        }
    }

    const handleClickAddFolderItem = () => {
        if (selectedFolder) {
            handleClickAddFolderTreeItem(selectedFolder)
            handleCloseMenu();
        }
    }

    const handleClickLockFolderItem = () => {
        if (selectedFolder) {
            handleClickLockFolderTreeItem && handleClickLockFolderTreeItem(selectedFolder)
            handleCloseMenu();
        }
    }
    const buildTree = (items: FolderModel[], parentId: number | null = null): React.ReactNode => {
        const filteredItems = items.filter((item) => item.parentId === parentId);
        return filteredItems.map((item, index) => (
            <div key={item.id + 'div'} >
                <TreeItem key={item.id} nodeId={(item.id).toString()}
                    className={selectedFolder?.id.toString() === item.id.toString() ? "Tree-Item Tree-Item-Selected" : "Tree-Item"}
                    label=
                    {
                        <div className=' group/item'>
                            <div
                                key={item.id + 'label'}
                                onClick={event => {
                                    event.stopPropagation()
                                    setSelectedFolder(item);
                                    handleClickFolderTreeItem(item)
                                }}
                                className='Tree-Item-Label'
                            >
                                {
                                    item.isHasToken ?
                                        <RuleFolder /> :
                                        <Folder />
                                }
                                <label style={{ marginLeft: '1vh', cursor: 'pointer' }}>
                                    {item?.name}
                                </label>


                            </div>
                            <IconButton
                                className='group/edit invisible group-hover/item:visible '
                                aria-label="more"
                                sx={{ width: '1vh', height: '1vh', top: '50%', bottom: '50%', transform: 'translate(0%, -50%)', position: 'absolute', right: 0 }}
                                id="long-button"
                                aria-controls={openMenu ? 'long-menu' : undefined}
                                aria-expanded={openMenu ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(e) => handleClickMenu(e, item)}
                            >
                                <MoreVert />
                            </IconButton>
                        </div>
                    }
                >
                    {buildTree(items, item.id)}
                </TreeItem >
            </div>
        ));
    };

    const rootNode = buildTree(data, 0)



    return data.length > 0 ? (
        <TreeView
            aria-label="folder"
            defaultExpanded={[data[0]?.id.toString()]}
            defaultCollapseIcon={
                <div style={{ display: 'flex' }}>
                    <ArrowDropDown />
                    {/* <Folder /> */}
                </div>
            }
            defaultExpandIcon={
                <div style={{ display: 'flex' }}>
                    <ArrowRight />
                    {/* <Folder /> */}
                </div>}
            defaultEndIcon={<div style={{ display: 'flex', "marginRight": "-2vh" }}>
                {/* <Folder /> */}
            </div>}
            sx={{ height: 'auto', maxHeight: 800, flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}
            selected="0"

        >
            {rootNode}
            <Menu
                id="long-menu2"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                PaperProps={{
                    style: {
                        width: '20ch',
                    },
                }}
            >
                <MenuItem key="menuItemAdd" onClick={handleClickAddFolderItem} >
                    <ListItemIcon>
                        <CreateNewFolder fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        {t('add')}
                    </Typography>
                </MenuItem>

                <MenuItem key="menuItemDelete" onClick={handleClickDeleteFolderItem} >
                    <ListItemIcon>
                        <FolderOff fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        {t('delete')}
                    </Typography>
                </MenuItem>

                {handleClickLockFolderTreeItem && <MenuItem key="menuItemLock" onClick={handleClickLockFolderItem} >
                    <ListItemIcon>
                        <LockPerson fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        {t('Yubikey')}
                    </Typography>
                </MenuItem>}

            </Menu>
        </TreeView>

    ) : <div>Loading...</div>;
};

export default TreeViewFolder;
