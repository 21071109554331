import { selectRootState, setKeyword, setTransparentNavbar } from "src/store/slices/rootSlice";
import Breadcrumb from "src/components/themes/Breadcrumb/Breadcrumb";
import { Menu, MenuOpen, SearchOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useAppDispatch } from "src/store/store";
import Notification from "src/views/notification/Notification";
import ModuleTop from "src/views/module-top/ModuleTop";
import { selectAuthState } from "src/store/slices/authSlice";
import { Link } from "react-router-dom";
import UserAvatar from "src/components/avatars/UserAvatar";
import { useTranslation } from "react-i18next";
import { set } from "lodash";
import OutsideClickHandler from "src/components/outside-click/outside-click";

const TopNav = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authState = useSelector(selectAuthState);
  const rootState = useSelector(selectRootState)
  const { transparentNavbar } = useSelector(selectRootState)
  const [keywordSearch, setKeywordSearch] = useState("")
  const [state, setState] = useState({
    openSildeNav: transparentNavbar,
    openNotification: false,
  })
  
  useEffect(() => {
    setKeywordSearch(rootState.keyword)
  }, [rootState.keyword])

  useEffect(() => {
    setState({ ...state, openSildeNav: transparentNavbar })
  }, [transparentNavbar])

  const handleShowNotification = () => {
    setState({ ...state, openNotification: !state.openNotification })
  }

  const handleOnChangeSearch = (e: any) => {
    e.preventDefault();
    setKeywordSearch(e.target.value)
  }
  const handleOnKeyupSearch = (e: any) => {
    e.preventDefault();
    if ([13].includes(e.keyCode)) {
      dispatch(setKeyword(e.target.value))
    }
  }
  const handleOnClickSearch = () => {
    //@ts-ignore
    const value = document.getElementById("search-on-page")?.value || ''
    dispatch(setKeyword(value))
  }
  return (
    <nav className="relative flex flex-wrap items-center justify-between px-1 mx-6 transition-all shadow-none duration-250 ease-soft-in rounded-2xl lg:flex-nowrap lg:justify-start" >
      <div className="flex items-center justify-between w-full px-4 mx-auto flex-wrap-inherit">
        <Breadcrumb />

        <div className="flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto">
          <div className="flex items-center md:ml-auto md:pr-4">
            <div className="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease-soft">
              <span onClick={handleOnClickSearch} className="text-sm ease-soft leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                <SearchOutlined />
              </span>
              <input type="text" id="search-on-page"
                className="pl-8.75 text-sm focus:shadow-soft-primary-outline ease-soft w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid 
              border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all
               placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none focus:transition-shadow"
                placeholder={t('search')}
                onChange={handleOnChangeSearch}
                value={keywordSearch}
                onKeyUp={handleOnKeyupSearch}
              />
            </div>
          </div>
          <ul className="flex flex-row justify-end pl-0 mb-0 list-none md-max:w-full">

            <li className="flex items-center px-2 xl:hidden">
              <a onClick={() => dispatch(setTransparentNavbar(!transparentNavbar))} className="block p-0 transition-all ease-nav-brand text-sm text-slate-500" >
                <div className="w-4.5 overflow-hidden">
                  {!state.openSildeNav ? <Menu /> : <MenuOpen />}
                </div>
              </a>
            </li>
            <ModuleTop />
            <li className="flex items-center px-2">
              {authState?.isLogin && (
                <Link to="/profile" className="block px-0 py-2 font-semibold transition-all ease-nav-brand text-sm text-slate-500">
                  <UserAvatar {...authState} />
                </Link>
              )}
            </li>
            <li className="relative flex items-center px-2">
              <p className="hidden transform-dropdown-show"></p>
              <a onClick={handleShowNotification} className="block p-0 transition-all text-sm ease-nav-brand text-slate-500" >
                <i className="cursor-pointer fa fa-bell" aria-hidden="true"></i>
              </a>
              <OutsideClickHandler onOutsideClick={()=>setState({ ...state, openNotification: false })} >
                <Notification show={state.openNotification} />
              </OutsideClickHandler>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default TopNav