import { NotificationPayload as INotificationPayload, deleteTokenListener, getMessagingToken, messaging, onBackgroundMessageListener, onMessageListener } from '../../firebase-noti';
import { useAuth } from 'react-oidc-context';
import { getToken, onMessage } from "firebase/messaging";
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Link, RouterProvider } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { createUserToken, getNotifications, updateNotification } from 'src/apis/apiNoti';
import { INotification, INotificationSearchModel } from 'src/interfaces/INoti';
import { HidenLoading, ShowLoading } from 'src/components/loading-page/LoadingPage';
import { useSelector } from 'react-redux';
import { IEmployee } from 'src/interfaces/IOrg';
import { selectEmployeeState } from 'src/store/slices/commonSlice';
import { getDateTimeString } from 'src/commons/helpers/formatDate';
import { ENotiStatus } from 'src/commons/enums/noiType';
import { IDocument } from 'src/interfaces/IDocument';
import { useNavigate } from 'react-router-dom';
import { size } from 'lodash';
import { IPaginationResponse } from 'src/interfaces/ICommon';
import { INotificationState, selectNotificationState } from 'src/store/slices/notiSlice';

interface IProps {
    show: boolean
}
const baseImageURL = `${process.env.REACT_APP_FILE_URL}`
const Notification = (props: IProps) => {
    const loadMoreSize = 5;
    const { t } = useTranslation()
    const notiState = useSelector<INotificationState, INotificationState>(selectNotificationState)
    const navigate = useNavigate();

    const [reload, setReload] = useState(0);
    const [hasNext, setHasNext] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const auth = useAuth();
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const employeeState = useSelector<IEmployee[], IEmployee[]>(selectEmployeeState)
    const [filter, setFilter] = useState<INotificationSearchModel>({
        page: 1,
        size: loadMoreSize,
        isDescSort: true,
        isContainCloudMessage: true
    });


    useEffect(() => {
        const channel = new BroadcastChannel("bg-notifications");
        channel.addEventListener("message", (event) => {
            console.log("Receive background: ", event.data);
            fetchNotis(filter);
        });

        fetchNotis(filter);
    }, []);

    useEffect(() => {

        if (notiState.reload != 0) {
            fetchNotis(filter);
        }
    }, [notiState]);

    useEffect(() => {
        onMessageListener().then((data: any) => {
            console.log("Receive foreground: ", data)
            setReload(reload + 1)
            fetchNotis(filter);
        })
    });

    const fetchNotis = (searchModel: INotificationSearchModel) => {
        setIsLoading(true)
        getNotifications(searchModel).then(res => {
            if (!res.success) {
                return;
            }

            const resData: IPaginationResponse<INotification> = res.data;
            setNotifications(resData.items);
            setHasNext(resData.hasNext);

        }).catch((e) => {
            toast.error('message.Failed');
        }).finally(() => setIsLoading(false));
    }

    const handleClickNoti = (noti: INotification) => {
        if (ENotiStatus.New != noti.status) {
            navigate(`/document?s=${JSON.parse(noti.metaData || '{Serial:0}').Serial}`);
            return;
        }

        ShowLoading()
        updateNotification(noti.id, ENotiStatus.Seen).then(res => {
            if (!res.success) {
                return;
            }

            const updated = notifications;
            const index = updated.findIndex(x => x.id === noti.id);
            updated[index].status = ENotiStatus.Seen;

            setNotifications(updated);
            setReload(reload + 1);

            const metaData: any = JSON.parse(updated[index].metaData || '{}');
            navigate(`/document?s=${metaData.Serial}`);
        }).catch((e) => {
            toast.error('message.Failed');
        }).finally(() => HidenLoading());
    }

    const handleClickLoadMore = () => {
        const updatedFilter = { ...filter, size: filter.size + loadMoreSize };
        setFilter(updatedFilter)
        fetchNotis(updatedFilter)
    }

    //------------------------RENDER--------------------------------///

    const renderNotiCount = () => {
        //recommends use Redis cache
        const newNotis = notifications.filter(x => x.status === ENotiStatus.New);
        if (newNotis.length > 0) {
            return <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 border-1 border-white rounded-full top-2 -end-1 dark:border-gray-900">{newNotis.length}</div>;
        }
        return <></>
    }


    return (
        <>
            {
                renderNotiCount()
            }
            <ul className={`overflow-hidden	 text-sm before:font-awesome before:leading-default before:duration-350 
        before:ease-soft lg:shadow-soft-3xl duration-250 min-w-44 before:sm:right-7.5 before:text-5.5 
        absolute right-0 top-0 z-50 origin-top list-none rounded-lg border-0 border-solid border-transparent 
        bg-white bg-clip-padding px-2 py-4 text-left text-slate-500 transition-all before:absolute 
        before:right-2 before:left-auto before:top-0 before:z-50 before:inline-block before:font-normal 
        before:text-white before:antialiased before:transition-all before:content-['\f0d8'] sm:-mr-6 lg:absolute 
        lg:right-0 lg:left-auto lg:mt-2 lg:block lg:cursor-pointer  ${props.show ? 'before:-top-5 transform-dropdown-show' : 'opacity-0 pointer-events-none transform-dropdown'}`}
                style={{ maxWidth: '32vw' }} key={reload}>
                <>
                    <div className="relative mb-1 overflow-hidden w-98 justify-end flex text-xs">
                        <Link to={'/notification'}>{`${t('viewAll')}`}</Link>
                    </div>
                    {notifications.map(noti =>
                        <li key={noti?.id} className="relative mb-1 overflow-hidden w-98 opacity-80 rounded-lg hover:opacity-100" onClick={() => handleClickNoti(noti)} style={noti.status === ENotiStatus.New ? { backgroundColor: 'palegreen', opacity: 1 } : {}}>
                            <a className="ease-soft py-1.2 clear-both block w-full whitespace-nowrap rounded-lg bg-transparent px-4 duration-300 hover:bg-gray-200 hover:text-slate-700 lg:transition-colors">
                                <div className="flex py-1">
                                    <div className="my-auto">
                                        <img src={`${baseImageURL}/profile/${noti.fromUser}/avatar.png`} onError={(e) => { e.currentTarget.src = "/logo192.png" }} alt='notiImage' className="inline-flex items-center justify-center mr-4 text-white text-sm h-9 w-9 max-w-none rounded-xl" />
                                    </div>
                                    <div className="flex flex-col justify-center">
                                        <h6 className={`mb-1  leading-normal text-sm ${noti.status === ENotiStatus.Seen ? 'font-normal' : 'font-bold'}`} >
                                            <span className="font-semibold">{noti.shortPreview || t('systemNoti')}</span> </h6>
                                        <p className={`mb-0 leading-tight text-xs text-slate-400 ${noti.status === ENotiStatus.Seen ? 'font-normal' : 'font-bold'}`}>
                                            <i className="mr-1 fa fa-user"></i>
                                            {`${employeeState.find(y => y.employeeId === noti.fromUser)?.employeeName || t('system')}`}
                                        </p>
                                        <p className={`mb-0 leading-tight text-xs text-slate-400 ${noti.status === ENotiStatus.Seen ? 'font-normal' : 'font-bold'}`}>
                                            <i className="mr-1 fa fa-clock"></i>
                                            {getDateTimeString(new Date(noti.createDate))}
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </li>
                    )}
                    {isLoading
                        ? <div className="relative mb-1 overflow-hidden w-98 justify-center flex">
                            <i className="fa-solid fa-spinner animate-spin"></i>
                        </div>
                        : (hasNext && <li className="relative mb-1 overflow-hidden w-98 justify-center flex" onClick={() => handleClickLoadMore()}>
                            <label style={{ cursor: "pointer", color: 'blue' }}>
                                {t('filterMore')}
                            </label>
                        </li>)}
                </>

            </ul>
        </>
    )
}
export default Notification