import { combineReducers } from '@reduxjs/toolkit'
import settingSlice from './slices/settingSlice'
import authSlice from './slices/authSlice'
import rootSlice from './slices/rootSlice'
import commonSlice from './slices/commonSlice'
import documentTypeSlice from './slices/documentTypeSlice'
import productSlice from './slices/productSlice'
import brandSlice from './slices/brandSlice'
import kindSlice from './slices/kindSlice'
import spaceUseSlice from './slices/spaceUseSlice'
import suiteSlice from './slices/suiteSlice'
import patternSlice from './slices/patternSlice'
import physicalAddressSlice from './slices/physicalAddressSlice'
import userProfileSlice from './slices/userProfileSlice'
import userAppNotiSlice from './slices/userAppNotiSlice'
import notificationSlice from './slices/notiSlice'

const objReducer = {
  root: rootSlice,
  commons: commonSlice,
  documentType: documentTypeSlice,
  physicalAddress: physicalAddressSlice,
  auth: authSlice,
  setting: settingSlice,
  products: productSlice,
  suites: suiteSlice,
  brands: brandSlice,
  patterns: patternSlice,
  kinds: kindSlice,
  spaceUses: spaceUseSlice,
  userProfile: userProfileSlice,
  userAppNoti: userAppNotiSlice,
  notification: notificationSlice
}

const rootReducer = combineReducers(objReducer)

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
