interface State {
  password: string
  showPassword: boolean
}

const RegisterPage = () => {


  return (
   <div>
    register
   </div>
  )
}


export default RegisterPage
