import { DocumentAction } from "src/commons/enums/documentAction";
import { IDocumentAssign } from "./IDocumentAssign";
import { IDocumentHistory } from "./IDocumentHistory";
import { IFileResponse } from "./IFile";
import { IDocumentFile } from "./IDocumentFile";
import { DocumentPriority } from "src/commons/enums/documentPriority";
import { DocumentStatus } from "src/commons/enums/documentStatus";
import { DocumentAssignType } from "src/commons/enums/documentAssignType";
import { IDocumentManualVersion } from "./IDocumentManualVersion";
import { IDocumentLink } from "./IDocumentLink";

export interface IDocument {
  id: string
  name: string
  serial: string
  description: string
  status: number
  priority: DocumentPriority
  departmentId: string
  departmentName?: string
  documentTypeId: number
  documentTypeName?: string
  fromDate: Date
  toDate: Date
  isPublic?: boolean
  actions: DocumentAction[]
  documentFiles: IDocumentFile[]
  documentAssigns: IDocumentAssign[]
  documentHistories: IDocumentHistory[]
  createdDate: Date
  createdBy: string
  documentLink?:string
  documentLinks?:IDocumentLink[]
  extendField?:string
  isManualVersion?: boolean
  documentManualVersions: IDocumentManualVersion[] | []
}

export interface IDocumentSearchModel {
  status?: DocumentStatus;
  assignType?: DocumentAssignType;
  creationDepartmentId?: string;
  keyword?: string;
  reminderTime?: string;
  page?: number;
  size?: number;
  sortBy?: string;
  isDescSort?: boolean;
}

export const initDocumentSearchModel: IDocumentSearchModel = {
  page: 1,
  size: 10
}