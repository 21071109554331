import { Close, Delete, Info } from "@mui/icons-material"
import { AppBar, Dialog, DialogContent, Grid, IconButton, Toolbar, Tooltip, Typography } from "@mui/material"
import { MaterialReactTable } from "material-react-table"
import { Suspense, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { updateUserDepartments, getUserDepartment } from "src/apis/apiDocument"
import { IPaginationResponse } from "src/interfaces/ICommon"
import { IDepartment, IEmployee } from "src/interfaces/IOrg"
import { IUserDepartment, initUserDepartmentSearchModel } from "src/interfaces/IUserDepartment"
import { selectEmployeeState, selectUserDepartmentState } from "src/store/slices/commonSlice"
import Button from '../../components/buttons/Button'
import CustomAutoComplete, { ICustomAutoComplete } from "src/components/auto-complete/CustomAutoComplete"
import { IActiveId } from "src/commons/enums/activeId"
import getLangReactTable from "src/commons/helpers/getLangReactTable"

const PermissionPage = () => {

    const { t, i18n } = useTranslation()
    const userState = useSelector<IEmployee[], IEmployee[]>(selectEmployeeState)
    const userDepartmentState = useSelector<IDepartment[], IDepartment[]>(selectUserDepartmentState)

    //filter and pagination
    const [selectedUser, setSelectedUser] = useState<IEmployee | null>();
    const [selectedDepartments, setSelectedDepartments] = useState<IUserDepartment[]>([]);
    const [open, setOpen] = useState(false);

    const handleSelectUser = async (emp: IEmployee) => {
        const data = await getUserDepartments(emp);
        setSelectedDepartments(data);

        setSelectedUser(emp);
        setOpen(true)
    }

    const getUserDepartments = async (emp: IEmployee): Promise<IUserDepartment[]> => {
        return getUserDepartment({
            ...initUserDepartmentSearchModel,
            userId: emp.employeeId,
        }).then(res => {
            if (!res.success) {
                toast.warning(t('message.actionError'));
                return [];
            }

            const resDatas: IPaginationResponse<IUserDepartment> = res.data;

            return resDatas.items;
        }).catch(err => {
            console.error(err);
            toast.error(t('message.actionError'));
            return [];
        }).finally(() => { });
    }

    const handleClose = () => {
        setSelectedUser(null);
        setOpen(false);
    }

    const handleSaveAdd = () => {
        selectedUser && selectedUser.employeeId && updateUserDepartments(selectedDepartments.map(x => x.departmentId), selectedUser.employeeId)
        handleClose();
    }

    const handleChangeAdds = (data: ICustomAutoComplete) => {
        if (data?.value) {

            if (selectedUser && selectedUser.employeeId) {
                const dataMap = {
                    userId: selectedUser.employeeId,
                    departmentId: data.value,
                    active: IActiveId.Active
                } as IUserDepartment;

                const dataUpdates = [...selectedDepartments, dataMap];

                setSelectedDepartments(dataUpdates)
            }
        }
    }

    const handleRemove = (index: number) => {
        // setSelectedUser(null);
        const dataUpdates = [...selectedDepartments.filter((_, i) => i != index)];
        setSelectedDepartments(dataUpdates)
    }

    return (
        <div className="overflow-auto h-[87svh]">
            <MaterialReactTable
                localization={getLangReactTable(i18n.language)}
                columns={[
                    {
                        accessorKey: 'employeeName',
                        header: t('name'),
                    },
                    {
                        accessorKey: 'departmentName',
                        header: t('department'),
                        Cell: (data) =>
                            <span>
                                {`${data.row.original.departmentName}`}
                            </span>
                    },
                    {
                        header: t('permission'),
                        Cell: (data) =>
                            <>
                                <Tooltip title={t('permission')}>
                                    <IconButton onClick={() => handleSelectUser(data.row.original)}>
                                        <Info />
                                    </IconButton>
                                </Tooltip>
                            </>
                    },
                ]}
                data={userState || []}
                renderTopToolbarCustomActions={() => (
                    <Typography variant="subtitle1">
                        {selectedUser?.employeeName}
                    </Typography>
                )}
                enableDensityToggle={false}
                initialState={{
                    density: 'compact',
                }}
                enableGrouping
            />


            {selectedUser &&
                <Suspense>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        maxWidth={'md'}
                        fullWidth
                    >
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    {`${t('')} ${selectedUser?.employeeName}`}
                                </Typography>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <Close />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <DialogContent className='relative'>
                            <Grid container spacing={0.5}>
                                <Grid item md={12} sx={{ justifyContent: 'center' }}>
                                    <CustomAutoComplete key={selectedDepartments.length} items={userDepartmentState.filter(x => selectedDepartments.findIndex(y => y.departmentId === x.code) === -1).map(x => ({
                                        label: x.name || t('noInfo'),
                                        value: x.code || t('noInfo')
                                    }))} placeHolder={`${t('pleaseChoose')} ${t('department').toLowerCase()}`} onChange={handleChangeAdds} />
                                </Grid>
                                <Grid item md={12} sx={{ justifyContent: 'center' }}>
                                    <MaterialReactTable
                                        localization={getLangReactTable(i18n.language)}
                                        columns={[
                                            {
                                                accessorKey: 'departmentId',
                                                header: t('department'),
                                                Cell: (data) =>
                                                    <span>
                                                        {`${userDepartmentState.find(x => x.code === data.row.original.departmentId)?.name || t('noInfo')}`}
                                                    </span>
                                            },
                                            {
                                                header: t('action'),
                                                Cell: (data) =>
                                                    <Tooltip title={t('delete')}>
                                                        <IconButton onClick={() => handleRemove(data.row.index)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                            },
                                        ]}
                                        data={selectedDepartments}
                                        renderTopToolbarCustomActions={() => (
                                            <>
                                                {/* <Tooltip title={`${t('add')}`} >
                                                <IconButton onClick={() => handleOpenAdd()}>
                                                    <Add />
                                                </IconButton>
                                            </Tooltip> */}
                                                {
                                                    t('department')
                                                }
                                            </>
                                        )}
                                        enableDensityToggle={false}
                                        initialState={{
                                            density: 'compact',
                                        }}
                                    />
                                </Grid>
                                <Grid item md={12} sx={{ justifyContent: 'end', display: 'flex  ' }}>
                                    <Button onClick={() => handleSaveAdd()} label={t('save')} color='primary'></Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog >
                </Suspense>
            }



        </div>
    )
}
export default PermissionPage