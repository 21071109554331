
type SortOrder = "asc" | "desc";

export function quickSort<T>(arr: T[], field: keyof T, order: SortOrder): T[] {
    if (arr.length <= 1) {
        return arr;
    }

    const left: T[] = [];
    const right: T[] = [];
    const pivot: T = arr[arr.length - 1];

    for (const item of arr.slice(0, arr.length - 1)) {
        if ((order === "asc" && item[field] < pivot[field]) || (order === "desc" && item[field] > pivot[field])) {
            left.push(item);
        } else {
            right.push(item);
        }
    }

    return [...quickSort(left, field, order), pivot, ...quickSort(right, field, order)];
}