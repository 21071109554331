export enum DocumentStatus {
    Deleted = -1,
    Draft = 0,
    New = 1,
    Process = 2,
    Finish = 3,
    Promulgate = 4,
    Cancel = 5,
    Retract = 6,
    LateProcess = 7
}