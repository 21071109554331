import { MaterialReactTable, MRT_PaginationState } from "material-react-table"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import getLangReactTable from "src/commons/helpers/getLangReactTable"
import { useEmployee, useUserDepartment } from "src/store/hook"
import { AppBar, Dialog, DialogContent, Grid, IconButton, Toolbar, Tooltip, Typography } from "@mui/material"
import { Close, Delete, Info, MarkEmailRead, MarkEmailUnread, Remove, RemoveRedEye } from "@mui/icons-material"
import { INotification, INotificationSearchModel } from "src/interfaces/INoti"
import { Link, useNavigate } from "react-router-dom"
import { deleteNotifications, getNotifications, updateNotification } from "src/apis/apiNoti"
import { toast } from "react-toastify"
import { IPaginationResponse } from "src/interfaces/ICommon"
import { useSelector } from "react-redux"
import { IEmployee } from "src/interfaces/IOrg"
import { selectEmployeeState } from "src/store/slices/commonSlice"
import { ENotiStatus } from "src/commons/enums/noiType"
import { HidenLoading, ShowLoading } from "src/components/loading-page/LoadingPage"
import { getDateTimeString } from "src/commons/helpers/formatDate"
import { useAppDispatch } from "src/store/store"
import { reloadNotification } from "src/store/slices/notiSlice"

const NotificationPage = () => {
    const { t, i18n } = useTranslation()
    const employeeState = useSelector<IEmployee[], IEmployee[]>(selectEmployeeState)
    useUserDepartment()
    useEmployee()
    const dispatch = useAppDispatch()

    const navigate = useNavigate();

    const [notifications, setNotifications] = useState<INotification[]>([]);

    const [filter, setFilter] = useState<INotificationSearchModel>({
        page: 1,
        size: 10,
        isDescSort: true,
        isContainCloudMessage: true,
        includes: 'CloudMessages'
    });

    const [isLoad, setIsLoad] = useState(true);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [globalFilter, setGlobalFilter] = useState('');
    const [rowCount, setRowCount] = useState(0);

    const fetchNotis = () => {
        setIsLoad(true)
        getNotifications({
            ...filter,
            keyword: globalFilter,
            page: pagination.pageIndex + 1,
            size: pagination.pageSize
        }).then(res => {
            if (!res.success) {
                toast.warning(t('message.actionError'));
                return;
            }

            const resDatas: IPaginationResponse<INotification> = res.data;
            setNotifications(resDatas.items);
            setRowCount(resDatas.count);

        }).catch(err => {
            console.error(err);
            toast.error(t('message.actionError'));
        }).finally(() => { setIsLoad(false) });
    }

    useEffect(() => {
       fetchNotis();
    }, [])

    useEffect(() => {
        !isLoad && fetchNotis();
    }, [filter])


    useEffect(() => {
        //console.log(pagination);
        //console.log(globalFilter);

        setFilter({
            ...filter,
            keyword: globalFilter,
            page: pagination.pageIndex + 1,
            size: pagination.pageSize
        })
    }, [pagination, globalFilter])


    const handleClickNoti = (noti: INotification) => {
        if (ENotiStatus.New != noti.status) {
            navigate(`/document?s=${JSON.parse(noti.metaData || '{Serial:0}').Serial}`);
            return;
        }

        ShowLoading()
        updateNotification(noti.id, ENotiStatus.Seen).then(res => {
            if (!res.success) {
                return;
            }

            const updated = notifications;
            const index = updated.findIndex(x => x.id === noti.id);
            updated[index].status = ENotiStatus.Seen;

            setNotifications(updated);

            const metaData: any = JSON.parse(updated[index].metaData || '{}');
            dispatch(reloadNotification());
            navigate(`/document?s=${metaData.Serial}`);
        }).catch((e) => {
            toast.error('message.Failed');
        }).finally(() => HidenLoading());
    }

    const handleClickMarkAsRead = (noti: INotification, status: ENotiStatus) => {

        ShowLoading()
        updateNotification(noti.id, status).then(res => {
            if (!res.success) {
                return;
            }

            const updated = [...notifications];
            const index = updated.findIndex(x => x.id === noti.id);
            updated[index].status = status;

            setNotifications(updated);
            dispatch(reloadNotification());
        }).catch((e) => {
            toast.error('message.Failed');
        }).finally(() => HidenLoading());
    }

    const handleClickDelete = (noti: INotification) => {

        ShowLoading()
        deleteNotifications([noti.id]).then(res => {
            if (!res.success) {
                return;
            }

            const updated = notifications.filter(x => x.id != noti.id);
            setNotifications(updated);
        }).catch((e) => {
            toast.error('message.Failed');
        }).finally(() => HidenLoading());
    }

    return (
        <div className="min-h-[85vh]">
            <MaterialReactTable
                localization={getLangReactTable(i18n.language)}
                columns={[
                    { header: t('createdDate'), accessorKey: 'createDate', Cell: (data) => <>{getDateTimeString(new Date(data.row.original.createDate || new Date()))}</> },
                    {
                        accessorKey: 'shortPreview',
                        header: t('name'),
                    },
                    {
                        accessorKey: 'fromUser',
                        header: t('createdBy'),
                        Cell: (data) =>
                            <span>
                                {`${employeeState.find(y => y.employeeId === data.row.original.fromUser)?.employeeName || t('system')}`}
                            </span>
                    },
                    {
                        accessorKey: 'status',
                        header: t('status'),
                        Cell: (data) =>
                            <span>
                                {`${t('noti.status.' + data.row.original.status)}`}
                            </span>
                    },
                    {
                        header: t('action'),
                        Cell: (data) =>
                            <>
                                <div >
                                    <Tooltip title={t('documentDetail')} onClick={() => handleClickNoti(data.row.original)}>
                                        <IconButton >
                                            <Info />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t(data.row.original.status === ENotiStatus.New ? 'markAsRead' : 'markAsUnread')}
                                        onClick={() => handleClickMarkAsRead(data.row.original, data.row.original.status === ENotiStatus.New ? ENotiStatus.Seen : ENotiStatus.New)}>
                                        <IconButton >
                                            {data.row.original.status === ENotiStatus.New ? <MarkEmailRead /> : <MarkEmailUnread />}
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t('delete')} onClick={() => handleClickDelete(data.row.original)}>
                                        <IconButton >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </>
                    },

                ]}
                data={notifications || []}
                renderTopToolbarCustomActions={() => (
                    <Typography variant="subtitle1">
                        {t('notis')}
                    </Typography>
                )}
                enableDensityToggle={false}
                initialState={{
                    density: 'compact',
                    pagination: { pageIndex: pagination.pageIndex, pageSize: pagination.pageSize },

                }}
                manualPagination={true}
                onPaginationChange={setPagination}
                onGlobalFilterChange={setGlobalFilter}
                state={{
                    isLoading: isLoad,
                    pagination,
                    globalFilter,
                }}
                rowCount={rowCount}
                enableSorting={false}
            // enableFilters={false}
            />
        </div>
    )
}
export default NotificationPage