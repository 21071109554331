import removeDiacritics from "./removeDiacritics";

export function fuzzySearch<T>(arr: T[], field: keyof T, searchTerm: string): T[] {
    searchTerm = searchTerm ? searchTerm.trim().toLowerCase() : '';

    return arr.filter(item => {
        const value = String(item[field]).trim().toLowerCase();
        return value.includes(searchTerm);
    });
}

export function fuzzySearch_VI<T>(arr: T[], field: keyof T, searchTerm: string): T[] {
    searchTerm = removeDiacritics(searchTerm ? searchTerm.trim().toLowerCase() : '');

    return arr.filter(item => {
        const value = removeDiacritics(String(item[field]).trim().toLowerCase() || '');
        return value.includes(searchTerm);
    });
}
