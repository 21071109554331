import { Close, CloudUpload, Delete, Download, HideImage, MobileFriendly, MobileOff, RemoveRedEye, Save, Star } from "@mui/icons-material"
import { AppBar, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Toolbar, Tooltip, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { copyFile, countFilesByFolder, deleteFiles, getFilesByFolder, updateFileInfo, upload } from "src/apis/apiFile"
import { IModel } from "src/apis/apiCommon"
import { EFileCategoryId } from "src/commons/enums/fileCategory"
import { convertToFileFormDataWithModifiyFileName, formatFileName, toSlug } from "src/commons/helpers/file"
import { getDateTimeString } from "src/commons/helpers/formatDate"
import { fuzzySearch_VI } from "src/commons/helpers/search"
import CustomAutoComplete from "src/components/auto-complete/CustomAutoComplete"
import ImageCustom from "src/components/img/ImageCustom"
import { HidenLoading, ShowLoading } from "src/components/loading-page/LoadingPage"
import { IFileCountResult, IFileInfo, IUpdateFileInfo } from "src/interfaces/IFile"
import { IProduct, initProduct } from "src/interfaces/IProduct"
import { useBrand, useKind, usePattern, useProduct, useSpaceUse, useSuite } from "src/store/hook"
import { selectBrand } from "src/store/slices/brandSlice"
import { selectPattern } from "src/store/slices/patternSlice"
import { selectProductsState } from "src/store/slices/productSlice"
import { selectSuite } from "src/store/slices/suiteSlice"
import MediaViewer from "src/views/media-viewer/MediaViewer"
import UploadFile, { IFileUploadModel } from "src/views/upload-file/UploadFile"
import Swal from "sweetalert2"
import { MaterialReactTable, MRT_Row } from "material-react-table"
import { quickSort } from "src/commons/helpers/sort"
import getLangReactTable from "src/commons/helpers/getLangReactTable"
import { useTranslation } from "react-i18next"
import { getRandomIntInclusive } from "src/commons/get-initials"

const fileURL = `${process.env.REACT_APP_FILE_URL}/product`
export interface ISearchState {
  reload?: number
  keyword: string | null
  brandIds: string[]
  suiteIds: string[]
  patternIds: string[]
  sortBy: string
  pageSize: number
  pageIndex: number
  sortAscending: boolean
}
const ProductFilePage = () => {
  const { t, i18n } = useTranslation()
  const containerRef = useRef<HTMLDivElement>(null);

  useProduct();
  useSpaceUse();
  useKind();
  usePattern();
  useBrand();
  useSuite();

  const productState = useSelector<IProduct[], IProduct[]>(selectProductsState)
  const brands = useSelector<IModel[], IModel[]>(selectBrand)
  const suites = useSelector<IModel[], IModel[]>(selectSuite)
  const patterns = useSelector<IModel[], IModel[]>(selectPattern)
  const [version, setVersion] = useState('0');

  const [products, setProducts] = useState<IProduct[]>([]);
  const [fileCounts, setFileCounts] = useState<IFileCountResult[]>([]);

  const [isBottom, setIsBottom] = useState(false);

  const [searchState, setSearchState] = useState({
    keyword: "",
    pageSize: 20,
    pageIndex: 1,
    startDate: "2023-06-13T04:06:07.492Z",
    endDate: "2023-06-13T04:06:07.492Z",
    suiteId: "",
    patternId: "",
    brandId: "",
    productIDs: "",
    isSmall: false
  });

  useEffect(() => {
    setSearchState({ ...searchState, pageSize: 20, pageIndex: 1 })
    callCountFiles()
  }, [products])

  useEffect(() => {
    callCountFiles()
  }, [searchState.pageSize])

  useEffect(() => {
    isBottom && setSearchState({ ...searchState, pageSize: searchState.pageSize + 20, pageIndex: searchState.pageIndex + 1 })
  }, [isBottom])

  useEffect(() => {
    productState && setProducts(productState)
    const handleScroll = () => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const isBottom = container.scrollTop + container.clientHeight >= container.scrollHeight;

      setIsBottom(isBottom);
    };


    if (!containerRef.current) return;

    // Bắt sự kiện scroll của phần tử container
    containerRef.current.addEventListener('scroll', handleScroll);

    // Xóa sự kiện scroll khi component bị unmount
    return () => {
      if (!containerRef.current) return;
      containerRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [productState])

  const callCountFiles = () => {
    const prefixMapping = products?.filter((x, i) => i < searchState.pageSize && (i >= (searchState.pageIndex - 1) * 20))?.map(x => x.id).join('-');
    prefixMapping && products && products.length > 0
      && countFilesByFolder('product', '',
        prefixMapping || '')
        .then((res) => {
          if (!res || !res.success) {
            toast.error('message.Failed');
            return;
          }

          const data: IFileCountResult[] = res.data;
          setFileCounts(prev => [...prev, ...data]);
        }).catch((e) => {
          toast.error('message.Failed');
        }).finally(() => HidenLoading());

  }

  const filterProducts = (): IProduct[] => {
    // const queries = searchState.keyword.trim().toLowerCase();

    const filteredProducts =
      productState?.filter(product =>
        (fuzzySearch_VI([product], 'name', searchState.keyword).length > 0 ||
          fuzzySearch_VI([product], 'id', searchState.keyword).length > 0) &&
        fuzzySearch_VI([product], 'suiteId', searchState.suiteId).length > 0 &&
        fuzzySearch_VI([product], 'patternId', searchState.patternId).length > 0 &&
        fuzzySearch_VI([product], 'brandId', searchState.brandId).length > 0
      );

    return filteredProducts;
  }

  const handleClickButtonSearch = () => {
    const filtered = filterProducts()
    setProducts(filtered);
  }


  // ----------------------------Modal Update Files----------------------------//
  const [modalUpdateFiles, setModalUpdateFiles] = useState({
    reload: 0,
    show: false,
  })
  const [currentProductIndex, setCurrentProductIndex] = useState<number>(-1);
  const [currentProduct, setCurrentProduct] = useState<IProduct>(initProduct);
  const [selectedFiles, setSelectedFiles] = useState<IFileInfo[]>([]);

  const sortFilesBySortOrderField = (files: any[]) => {
    return quickSort(files, 'sortOrder', "asc");
  }

  useEffect(() => {
    modalUpdateFiles.reload > 0 && handleOpenModalUpdateFiles()
  }, [currentProduct.id])

  const handleOpenModalUpdateFiles = () => {
    setModalUpdateFiles({
      show: true,
      reload: modalUpdateFiles.reload + 1,
    })
  }

  const handleOnSaveUpdateFiles = () => {
    // handleViewFile(currentFilesViewFiles);
  }

  const handleChangeAvatar = (file: IFileInfo) => {
    // handleViewFile(currentFilesViewFiles);
    ShowLoading()
    copyFile({
      newPath: `${file.folder}/avatar.png`,
      newBucketName: 'product',
      fileId: file.id || 0,
      newCategory: EFileCategoryId.Avatar,
      newParentId: file.id,
      newShowApp: file.showApp,
      newSortOrder: 0,
      isCreateSmallFile: true
    }).then(res => {
      if (!res.success) {
        toast.error('message.Failed');
        return;
      }

      // var data: IFileInfo = res.data;
      // setSelectedFiles(prev => {
      //   const findIndex = prev.findIndex(x => x.fileName === data.fileName);
      //   if (findIndex != -1) {
      //     const updatedLst = prev;
      //     updatedLst[findIndex] = res.data;
      //     return updatedLst;
      //   }

      //   return [...prev, data]
      // });

      handleOnClickProduct(currentProduct)
    }).finally(() => HidenLoading());
  }

  const handleDeleteAvatar = () => {
    // handleViewFile(currentFilesViewFiles);

    getFilesByFolder('product', currentProduct.id, 'avatar.png', false, 1, [EFileCategoryId.Avatar]).then(res => {
      if (!res.success) {
        toast.error('message.Failed');
        return;
      }

      var data: IFileInfo[] = res.data || [];

      const avatarFile = data.find(x => x.fileName === 'avatar.png');
      if (avatarFile) {
        handleDeleteFiles([avatarFile], [EFileCategoryId.Avatar, EFileCategoryId.AvatarSM])
      }
    }).catch(() => {
      Swal.fire(
        `${t('message.errorExcution')}`,
        `${t('fileNotDeleted')}`,
        'error'
      )
    }).finally(() => HidenLoading());
  }

  const handleOnClickDeleteFiles = (files: IFileInfo[]) => {
    handleDeleteFiles(files, [EFileCategoryId.Thumbnail, EFileCategoryId.ThumbnailSM, EFileCategoryId.VideoReview, EFileCategoryId.File3D])
  }

  const handleDeleteFiles = (files: IFileInfo[], categoryFilters: EFileCategoryId[]) => {
    const ids = files?.map(item => item?.id?.toString()) as string[] || [];

    Swal.fire({
      title: `${t('message.DeleteConfirms')}`,
      text: `${t('message.DeleteWarning')}`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: `${t('cancel')}`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${t('confirm')}`
    }).then((result) => {
      if (result.isConfirmed) {
        ShowLoading()
        deleteFiles(ids, 'product', categoryFilters).then((r) => {
          if (r.success) {
            // const updatedData = selectedFiles.filter(item => !ids.includes(item?.id?.toString() || '-1'));
            // setSelectedFiles(updatedData);

            handleOnClickProduct(currentProduct)

            Swal.fire(
              `${t('deleted')}`,
              `${t('fileDeleted')}`,
              'success'
            )
          } else {
            Swal.fire(
              `${t('message.errorExcution')}`,
              `${t('fileNotDeleted')}`,
              'error'
            )
          }
        }).catch(() => {
          Swal.fire(
            `${t('message.errorExcution')}`,
            `${t('fileNotDeleted')}`,
            'error'
          )
        }).finally(() => HidenLoading());
      }
    });
  }

  const handleCloseModalUpdateFiles = () => {
    setCurrentProductIndex(products.findIndex(x => x.id === currentProduct.id))
    setCurrentProduct(initProduct);
    setModalUpdateFiles({ reload: 0, show: false })
  }

  const handleOnClickProduct = (product: IProduct) => {
    setModalUpdateFiles({ ...modalUpdateFiles, reload: modalUpdateFiles.reload + 1 })
    setCurrentProduct(product)
    setCurrentProductIndex(-1)
    setVersion(getRandomIntInclusive(1, 1000) + '')

    getFilesByFolder('product', product.id, '', false, 1, [EFileCategoryId.Thumbnail, EFileCategoryId.VideoReview, EFileCategoryId.Document, EFileCategoryId.File3D]).then(res => {
      if (!res.success) {
        toast.error('message.Failed');
        return;
      }

      var data: IFileInfo[] = res.data;
      setSelectedFiles(sortFilesBySortOrderField(data));
    })
  }

  const getAvatarURL = (files: IFileInfo[]) => {
    if (files && files.length > 0 && (currentProduct?.id && currentProduct?.id != '0')) {
      // const avatar = files.find(x => x.category === EFileCategoryId.Avatar);

      // if (avatar) {
      //   return `${fileURL}/${currentProduct?.id}/avatar.png?v=${avatar.version}`;
      // }
      return `${fileURL}/${currentProduct?.id}/avatar.png?v=${version}`;
    }

    return '/logo1024.png';


  }

  const handleCheckShowApp = (file: IFileInfo) => {

    const req: IUpdateFileInfo = {
      id: file.id || -1,
      showApp: !file.showApp
    }

    updateFileInfo([req]).then((res) => {
      if (!res.success) {
        toast.error('message.Failed');
        return;
      }

      let updatedData = [...selectedFiles];
      const index = updatedData.findIndex(x => x.id === file.id);
      updatedData[index].showApp = !file.showApp;
      setSelectedFiles(updatedData);
    }).catch((e) => {
      toast.error('message.Failed');
    }).finally(() => HidenLoading());
  }

  const handleOnDragEnd = (data: IFileInfo[]) => {
    const lstUpdate = data?.map((x, i) => {
      return {
        id: x.id,
        sortOrder: i + 1
      } as IUpdateFileInfo
    });

    updateFileInfo(lstUpdate).then((res) => {
      if (!res.success) {
        toast.error('message.Failed');
        return;
      }

      setSelectedFiles(data);
    }).catch((e) => {
      toast.error('message.Failed');
    }).finally(() => HidenLoading());
  }

  const handleOnDrag = (draggingFile: IFileInfo, draggingIndex: number, hoveredFile: IFileInfo, hoveredIndex: number) => {

    const reqDraggingFile: IUpdateFileInfo = {
      id: draggingFile.id || -1,
      sortOrder: hoveredIndex
    }

    const reqHoveredFile: IUpdateFileInfo = {
      id: hoveredFile.id || -1,
      sortOrder: draggingIndex
    }

    updateFileInfo([reqDraggingFile, reqHoveredFile]).then((res) => {
      if (!res.success) {
        toast.error('message.Failed');
        return;
      }

      let updatedData = [...selectedFiles];
      const indexDragging = updatedData.findIndex(x => x.id === draggingFile.id);
      const indexHovered = updatedData.findIndex(x => x.id === hoveredFile.id);

      updatedData[indexDragging].sortOrder = reqDraggingFile.sortOrder || 100;
      updatedData[indexHovered].sortOrder = reqHoveredFile.sortOrder || 100;

      setSelectedFiles(updatedData);
    }).catch((e) => {
      toast.error('message.Failed');
    }).finally(() => HidenLoading());
  }

  // ---------------------------- Modal View File----------------------------//
  const [modalViewFileOpen, setModalViewFileOpen] = useState(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState(-1);

  const handleOpenModalViewFile = () => {
    setModalViewFileOpen(true);
  };

  const handleCloseModalViewFile = () => {
    setModalViewFileOpen(false);
    setSelectedFileIndex(- 1);

  };

  const handleViewFile = (rowData: any) => {
    setSelectedFileIndex(rowData.index - 1);
    handleOpenModalViewFile();
  }


  // ---------------------------- Modal Upload----------------------------//
  const [modalUpload, setModalUpload] = useState({
    reload: 1,
    show: false,
  })
  const [currentFilesUploads, setCurrentFilesUploads] = useState<IFileUploadModel[]>([]);
  const fileRef = useRef<any>()

  const handleOpenModalUpload = () => {
    setModalUpload({
      show: true,
      reload: modalUpload.reload + 1,
    })
  }

  const handleOnSaveUpload = () => {
    handleUpload(currentFilesUploads);
  }

  const handleCloseModalUpload = () => {
    setModalUpload({ ...modalUpload, show: false })
  }

  const handleChangeFileUpload = (files: IFileUploadModel[]) => {
    const fileMaps = modalUpdateFiles.show ? files : files.map((x, i) => {
      if (x.file) {
        const id = extractProductIdByFileName(x.file?.name)
        if (productState.findIndex(x => x.id === id) != -1) {
          return x
        }
      }


      return { ...x, isFailed: true }
    });

    setCurrentFilesUploads(fileMaps);
    fileRef.current.setData(fileMaps);

  }

  const findProduct = (id: string) => {
    return productState.find(x => x.id === id) as IProduct;
  }

  const extractProductIdByFileName = (fileName: string | undefined) => {
    return fileName?.split('-')[0] || '-1'
  }

  const generateFileNameTemplate = (productInfo: IProduct, fileName: string) => {
    const format = `${toSlug(`${productInfo?.name}-${productInfo?.suiteName || 'bo'}-${productInfo?.patternName || 'hoa-van'}`)}_${formatFileName(fileName)}`;
    return format;
  }

  const getDefaultCategoryByFileType = (fileType?: string) => {
    if (fileType?.includes('image')) {
      return EFileCategoryId.Thumbnail
    }

    if (fileType?.includes('video')) {
      return EFileCategoryId.VideoReview
    }

    return EFileCategoryId.Document
  }

  const handleUpload = async (files: IFileUploadModel[]) => {
    const fileFilters = files.filter(x => !x.isFailed).map(x => {
      return { ...x, category: getDefaultCategoryByFileType(x.type) }
    });

    if (fileFilters && fileFilters?.length > 0) {

      const count = fileFilters.length;

      //Check avatar => return list exist avatar
      const lstCheckedAvatarExist: string[] = await countFilesByFolder('product', '', fileFilters.map(x => {
        const folderPath = currentProduct.id != initProduct.id ? currentProduct.id : extractProductIdByFileName(x.file?.name);
        return folderPath
      }).join('-') || '', 'avatar.png', 1).then((res) => {
        if (!res || !res.success) {
          toast.error('message.Failed');
          return [];
        }

        const data: IFileCountResult[] = res.data;
        return data.map(x => x.folderPath);
      }).catch((e) => {
        toast.error('message.Failed');
        return []
      }).finally(() => HidenLoading());
      for (let index = 0; index < count; index++) {
        const element = fileFilters[index];
        const folderPath = currentProduct.id != initProduct.id ? currentProduct.id : extractProductIdByFileName(element.file?.name);
        if (!lstCheckedAvatarExist.includes(folderPath)) {

          //Add avatar if not exists
          if (element.file) {
            fileFilters.push({ ...element, name: 'avatar.png', category: EFileCategoryId.Avatar });
          }

          lstCheckedAvatarExist.push(folderPath);
        }
      }
      //Form data Convert
      const formData = convertToFileFormDataWithModifiyFileName({
        files: fileFilters.map(x => x.file as File),
        fileNames: fileFilters.map(x => {
          const currProductInfo = currentProduct.id != initProduct.id
            ? currentProduct
            : findProduct(extractProductIdByFileName(x.file?.name));

          return x.name === 'avatar.png' ? x.name : generateFileNameTemplate(currProductInfo, x.name || x.file?.name as string)
        }),
        bucketName: 'product',
        folders: fileFilters.reduce((accumulator, currentValue, index) => {
          accumulator[`${index}`] = currentProduct.id != initProduct.id ? currentProduct.id : extractProductIdByFileName(currentValue.file?.name);

          return accumulator;
        }, {} as { [key: string]: string }),
        categories: fileFilters.reduce((accumulator, currentValue, index) => {
          accumulator[`${index}`] = currentValue.category;

          return accumulator;
        }, {} as { [key: string]: number }),
      });


      //Start upload
      ShowLoading();
      formData && upload(formData, true).then(res => {
        if (!res || !res.success) {
          toast.error(t('message.ImportedFailed'))
          return;
        }

        toast.success('message.successSendData')

        //handle if upload while opening the Details Modal => add the res to the table
        if (currentProduct) {
          // const data: IFileInfo[] = res.data;
          // setSelectedFiles([...selectedFiles, ...data.map(x => ({ ...x, createdDate: new Date(), updatedDate: new Date() }))])
          handleOnClickProduct(currentProduct)
        }

      }).catch(error => {
        toast.error(`${t('message.errorExcution')}`);
        console.error(error);
      }).finally(() => {
        HidenLoading();
        handleCloseModalUpload();
      });
    }
  }

  //--------------------------RENDER --------------------------//
  const renderBadge = (product: IProduct) => {
    const fileTypes = fileCounts.find(x => x.folderPath === product.id)?.fileCountsByType || [];
    const countImage = fileTypes?.find(x => x.fileType.includes('image'))?.fileCount || 0;
    const countVideo = fileTypes?.find(x => x.fileType.includes('video'))?.fileCount || 0;
    const countOther = fileTypes?.map(x => x.fileCount).reduce((prev, curr) => prev += curr, 0) - countImage - countVideo;

    return (
      <div className="inline-flex bg-red w-2/3 opacity-85">
        {countImage > 0 && <button type="button" className="mr-2 relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
          <i className="fas fa-images"></i>
          <span className="sr-only">image</span>
          <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 ">
            {`${Math.floor(countImage / 2)}`}
          </div>
        </button>}
        {countVideo > 0 && <button type="button" className="mr-2 relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
          <i className="fas fa-video"></i>
          <span className="sr-only">countVideo</span>
          <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 ">
            {`${countVideo}`}
          </div>
        </button>}
        {countOther > 0 && <button type="button" className="mr-2 relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
          <i className="far fa-file"></i>
          <span className="sr-only">countOther</span>
          <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 ">
            {`${countOther}`}
          </div>
        </button>}
      </div>
    )
  }

  const handleOnKeyUpSearch = (e: any) => {
    if ([13].includes(e.keyCode)) {
      e.preventDefault();
      handleClickButtonSearch()
    }
  }

  return (
    <div className="bg-white " style={{ width: '100%' }}>
      <div className="p-4 sm:px-6 ">
        <h2 className="sr-only">Products</h2>
        <div className="mb-2 grid grid-cols-1 gap-3 sm:grid-cols-3 xl:grid-cols-3">
          <CustomAutoComplete items={brands && brands.map(x => ({
            label: x.name, value: x.id
          }))} placeHolder={t('selectBrands')} className="" label={t('brand')}
            onChange={(x) => setSearchState({ ...searchState, brandId: x.value })}
          />

          <CustomAutoComplete items={suites && suites?.map(x => ({
            label: x.name, value: x.id
          }))} placeHolder={t('selectSuites')} className="" label={t('suite')}
            onChange={(x) => setSearchState({ ...searchState, suiteId: x.value })}
          />

          <CustomAutoComplete items={patterns && patterns?.map(x => ({
            label: x.name, value: x.id
          }))} placeHolder={t('selectPatterns')} className="" label={t('pattern')}
            onChange={(x) => setSearchState({ ...searchState, patternId: x.value })}
          />
        </div>
        <div className="grid grid-cols-1 gap-1 my-2 sm:grid-cols-2 xl:grid-cols-10 ">
          <div className="relative w-full col-span-7 ">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <i className="fas fa-file-image"></i>
            </div>
            <input type="text" id="simple-search" className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 "
              placeholder={t('searchByNameCodeURL') + '...'}
              onKeyUp={handleOnKeyUpSearch}
              onChange={(e) => setSearchState({ ...searchState, keyword: e.currentTarget.value })}
            />
          </div>
          <Tooltip title={t('clickToUpload')}>
            <button onClick={handleOpenModalUpload} type="button" className=" py-2.5 px-3 ms-2 text-sm font-medium text-white bg-green-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 ">
              <i className="fas fa-file-upload "></i>
            </button>
          </Tooltip>
          <button onClick={handleClickButtonSearch} type="button"
            className="col-span-2 py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 ">
            <i className="fas fa-search"></i>    {t('search')?.toLocaleUpperCase()}
          </button>
        </div>

        <div ref={containerRef} className="overflow-auto h-[72svh] grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 xl:gap-x-8">
          {products && products?.length > 0 ? products?.map((product, index) => {
            return index < searchState.pageSize && (
              <div key={product.id} onClick={() => handleOnClickProduct(product)} className="group cursor-pointer relative">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">

                  {product.id
                    ? <ImageCustom key={`${product.id}-${currentProductIndex === index}`}
                      src={`${fileURL}/${product.id}/avatar-sm.png?r=${currentProductIndex === index}&v=${Math.floor(Math.random() * 10)}`} width={'100%'} height={'100%'} />
                    : <ImageCustom src={`/logo1024.png`} width={'100%'} height={'100%'} />}
                </div>
                <h3 className="mt-4 text-sm text-gray-700">{`${product.name} (${product.id})`}</h3>
                <div className="absolute right-0 top-0 p-2 w-full">
                  {
                    renderBadge(product)
                  }
                </div>
              </div>
            )
          })
            : (<div className="text-center">{t('canNotFoundProduct')}</div>)}
        </div>

        {/* MODAL UPLOAD */}
        <Dialog
          open={modalUpload.show}
          onClose={handleCloseModalUpload}
          maxWidth={'md'}
          fullWidth
        >
          <AppBar sx={{ position: 'relative' }} className="bg-gradient-to-tl from-purple-700 to-pink-500">
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {t('fileUpload')}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseModalUpload}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container spacing={0.5}>
              <UploadFile ref={fileRef} isHideInputs={true} onChange={handleChangeFileUpload} />
            </Grid>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse' }}>
            <Button onClick={() => handleOnSaveUpload()} ><Save />&nbsp;{t('save')}</Button>
          </DialogActions>
        </Dialog >

        {/* MODAL VIEW FILES */}
        <Dialog
          open={modalUpdateFiles.show}
          onClose={handleCloseModalUpdateFiles}
          maxWidth={'lg'}
          fullWidth
        >
          <AppBar sx={{ position: 'relative' }} className="bg-gradient-to-tl from-purple-700 to-pink-500">
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {currentProduct?.name}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseModalUpdateFiles}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container spacing={0.5}>
              {/* <UploadFile ref={fileRef} isHideInputs={true} onChange={handleChangeFileUploadInModal} /> */}
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                <div style={{ width: '50%' }} key={getAvatarURL(selectedFiles)} >
                  <ImageCustom src={getAvatarURL(selectedFiles)} width={'100%'} height={'100%'} />
                </div>
                <Tooltip title={t('deleteAvatar')}>
                  <IconButton sx={{ position: 'absolute', left: 0, bottom: 0 }} onClick={handleDeleteAvatar}>
                    <HideImage />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('uploadFile')} onClick={handleOpenModalUpload}>
                  <IconButton sx={{ position: 'absolute', right: 0, bottom: 0 }}>
                    <CloudUpload />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <MaterialReactTable
                  localization={getLangReactTable(i18n.language)}
                  getRowId={(row) => `${row.index.toString()}-${currentProduct?.id}` || '-1'}
                  columns={[
                    {
                      header: t('action'), Cell: (data) =>
                        <>
                          <IconButton onClick={() => handleViewFile(data.cell.row.original)}>
                            <RemoveRedEye />
                          </IconButton>
                          <IconButton onClick={() => handleOnClickDeleteFiles([data.row.original])}>
                            <Delete />
                          </IconButton>
                          <IconButton sx={{ display: data.row.original.category != EFileCategoryId.Thumbnail ? 'none' : '' }} onClick={() => handleChangeAvatar(data.row.original)} color={selectedFiles.find(x => x.category === EFileCategoryId.Avatar)?.parentId === data.row.original.id ? 'warning' : 'default'}>
                            <Star />
                          </IconButton>
                          <IconButton >
                            <Download />
                          </IconButton>
                        </>
                    },
                    {
                      header: t('image'), accessorKey: 'url',
                      Cell: (rowData) => {
                        return <ImageCustom key={`${fileURL}/${rowData.row.original.folder}/${rowData.row.original.fileName}?v=${Math.floor(Math.random() * 10)}`} src={`${fileURL}/${rowData.row.original.folder}/${rowData.row.original.fileName}?v=${Math.floor(Math.random() * 10)}`} />
                      }
                    },
                    // { header: t('id'), accessorKey: 'id' },
                    { header: t('name'), accessorKey: 'fileName' },
                    // { header: t('sort'), accessorKey: 'sortOrder' },
                    {
                      header: t('size'),
                      accessorKey: 'size',
                      Cell: (rowData) => {
                        return (rowData.row.original.size ? (rowData.row.original.size / (1024 * 1024)).toFixed(2) : 0) + ' MB';
                      }
                    },
                    { header: t('contentType'), accessorKey: 'contentType' },
                    { header: t('updatedDate'), accessorKey: 'updatedDate', Cell: (data) => <>{getDateTimeString(new Date(data.row.original.updatedDate || new Date()))}</> },
                    {
                      header: t('showApp'), Cell: (data) =>
                        <>
                          <IconButton onClick={() => handleCheckShowApp(data.cell.row.original)}>
                            {data.cell.row.original.showApp ? <MobileFriendly color="success" /> : <MobileOff />}
                          </IconButton>
                        </>
                    },

                  ]}
                  data={(
                    selectedFiles
                      ? (selectedFiles.map((item: IFileInfo, index: number) => ({
                        ...item, index: index + 1, sortOrder: item.sortOrder === 100 ? index + 1 : item.sortOrder
                      })))
                      : [])}
                  muiTableBodyCellProps={({ cell }) => ({
                    onDoubleClick: (event) => {
                      handleViewFile(cell.row.original);
                    },
                  })}
                  initialState={{
                    pagination: {
                      pageIndex: 0,
                      pageSize: 10
                    },
                    showGlobalFilter: true,
                    sorting: [
                      {
                        id: 'sortOrder',
                        desc: false
                      }
                    ]
                  }}

                  enableSorting={false}
                  enableRowOrdering
                  // onDraggingRowChange={setDraggingRow}
                  muiRowDragHandleProps={({ table }) => ({
                    onDragEnd: (props) => {
                      const { draggingRow, hoveredRow } = table.getState();

                      if (hoveredRow && draggingRow) {
                        const data = [...selectedFiles]
                        data.splice((hoveredRow as MRT_Row<any>).index, 0, data.splice(draggingRow.index, 1)[0],);
                        handleOnDragEnd([...data])
                      }
                    }
                  })}
                />
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse' }}>
          </DialogActions>
        </Dialog >
        {/* MODAL VIEW FILE */}
        <div>
          <MediaViewer
            currentIndex={selectedFileIndex}
            files={selectedFiles ? selectedFiles.map((item: IFileInfo, index: number) => ({
              ...item, index: index + 1,
            })) : []}
            open={modalViewFileOpen}
            onClose={handleCloseModalViewFile}
          />
        </div>
      </div>
    </div>
  )
}
export default ProductFilePage