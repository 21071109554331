import { Add, Delete, Edit } from "@mui/icons-material"
import { Box, Button, Checkbox, IconButton, Tooltip } from "@mui/material"
import { MRT_ColumnDef, MRT_Row, MRT_TableOptions, MaterialReactTable, useMaterialReactTable } from "material-react-table"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import getLangReactTable from "src/commons/helpers/getLangReactTable"
import removeDiacritics from "src/commons/helpers/removeDiacritics"
import { validateRequired } from "src/commons/helpers/stringHelper"
import { IHtmlInput } from "src/interfaces/IHtmlInput"


interface IProps {
    data?: IHtmlInput[]
    onChange: (data: IHtmlInput[]) => void
}
const CreateQuestions = (props: IProps) => {
    const { t, i18n } = useTranslation()
    const [validationErrors, setValidationErrors] = useState<
        Record<string, string | undefined>
    >({});
    const [data, setData] = useState<IHtmlInput[]>([])

    useEffect(() => {
        setData(props.data || [])
    }, [props.data])

    const validate = (user: IHtmlInput) => {
        return {
            label: !validateRequired(user.label) ? t('message.required') : '',
        };
    }

    const handleOnSave: MRT_TableOptions<IHtmlInput>['onEditingRowSave'] = ({
        row,
        values,
        table,
    }) => {
        debugger
        const newValidationErrors = validate(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        const id = row.original.id

        const index = data.findIndex((x: IHtmlInput) => x.id === id)
        let newData: IHtmlInput[] = []
        const name = removeDiacritics(values.label)?.replaceAll(' ', '').toLowerCase()
        const obj = { ...values, name: name }
        if (index >= 0) {
            newData = [...data]
            newData[index] = obj
        }
        else {
            newData = [obj, ...data]
        }
        setData(newData)
        props.onChange && props.onChange(newData)
        table.setEditingRow(null)
    }

    const handleOnCreate: MRT_TableOptions<IHtmlInput>['onCreatingRowSave'] = ({
        values,
        table,
    }) => {
        const newValidationErrors = validate(values);
        if (Object.values(newValidationErrors).some((error) => error)) {
            setValidationErrors(newValidationErrors);
            return;
        }
        setValidationErrors({});
        const name = removeDiacritics(values.label)?.replaceAll(' ', '').toLowerCase()
        const newData = [{ ...values, name: name, id: data.length + 1 }, ...data]
        setData(newData)
        props.onChange && props.onChange(newData)
        table.setCreatingRow(null)
    }
    const handleOnDelete = (row: MRT_Row<IHtmlInput>) => {
        const id = row.original.id
        const index = data.findIndex((x: IHtmlInput) => x.id === id)
        const tempData = [...data];
        tempData.splice(index, 1);
        setData(tempData)
    }

    const columns = useMemo<MRT_ColumnDef<IHtmlInput>[]>(
        () => [
            {
                id: 'id',
                header: t('No.'),
                size: 50, 
                Cell: ({ row }) => row.index + 1,
                enableEditing: false,
            },
            {
                accessorKey: 'label',
                header: t('label'),
                width: 100,
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.label,
                    helperText: validationErrors?.label,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            label: undefined,
                        }),
                },
            },
            {
                accessorKey: 'type',
                header: t('type'),
                editVariant: 'select',
                width: 50,
                editSelectOptions: ['text', 'number', 'date', 'datetime', 'color', 'radio', 'checkbox'],
                muiEditTextFieldProps: {
                    select: true,
                    defaultValue: 'text',
                    error: !!validationErrors?.state,
                    helperText: validationErrors?.state,
                },
            },
            {
                accessorKey: 'required',
                header: t('isRequired'),
                width: 50,
                Edit: ({ cell, column, row, table }) => (
                    <Checkbox
                        onChange={(e) => {
                            debugger
                            row._valuesCache["required"] = e.target.checked
                            table.setEditingRow(row);
                        }}
                    />
                ),
                Cell: ({ row }) => (
                    <Checkbox
                        checked={Boolean(row.original.required)} // Đảm bảo kiểu dữ liệu boolean
                        disabled
                    />
                ),
            },
            {
                accessorKey: 'defaultValue',
                width: 50,
                header: t('defaultValue'),
            }
        ],
        [validationErrors]
    );

    const table = useMaterialReactTable({
        localization: getLangReactTable(i18n.language),
        enablePagination: false,
        enableTopToolbar: true,
        enableSorting: false,
        enableBottomToolbar: false,
        enableHiding: false,
        enableRowOrdering: true,
        columns,
        data: data || [],
        createDisplayMode: 'row',
        editDisplayMode: 'row',
        enableEditing: true,
        enableCellActions: true,
        getRowId: (row) => row.id + '',
        onCreatingRowCancel: () => setValidationErrors({}),
        onCreatingRowSave: handleOnCreate,
        onEditingRowCancel: () => setValidationErrors({}),
        onEditingRowSave: handleOnSave,
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => handleOnDelete(row)}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={() => {
                    table.setCreatingRow(true);
                }}
            >
                <Add /> {t('add')}
            </Button>
        ),
        muiTablePaperProps: ({ table }) => ({
            //not sx
            style: {
                zIndex: table.getState().isFullScreen ? 9999 : undefined,
            },
        }),
        muiRowDragHandleProps: ({ table }) => ({
            onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                    data.splice(
                        (hoveredRow as MRT_Row<IHtmlInput>).index,
                        0,
                        data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                    props.onChange && props.onChange(data)
                }
            },
        }),
    });

    return (
        <MaterialReactTable table={table} />
    )
}

export default CreateQuestions