/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createUserAppNoti, getUserAppNoti } from "src/apis/apiNoti";
import { IUserAppNoti } from "src/interfaces/IUserAppNoti";

export interface IUserAppNotiState {
    userAppNoti: IUserAppNoti;
}

const initialState: IUserAppNotiState = {
    userAppNoti: {},
};


export const fetchUserAppNoti = createAsyncThunk("fetchUserAppNoti",
    async () => {
        const res = await getUserAppNoti();
        if (!res?.data) {
            const res2 = await createUserAppNoti({
                byEmail: true,
                byApp: true,
                byPhone: true
            });

            if (res2.success) {
                return res2;
            }
        }

        return res;
    });

export const userAppNotiSlice = createSlice({
    name: "userAppNoti",
    initialState,
    reducers: {
        setUserAppNoti(state, action) {
            state.userAppNoti = action.payload
        },
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchUserAppNoti.fulfilled, (state: any, action: any) => {
                state.userAppNoti = action.payload.data;
            })
    },
});

export const { setUserAppNoti } = userAppNotiSlice.actions

export const selectUserAppNotiState = (state: any) =>
    state.userAppNoti.userAppNoti || {};

export default userAppNotiSlice.reducer;
