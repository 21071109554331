import { useRef, useState } from "react"
import { DocumentStatus } from "src/commons/enums/documentStatus"
import Hitory from "src/components/themes/History/History"
import { useEmployee, usePhysicalAddress, useUserDepartment } from "src/store/hook"
import DetailDocument from "src/views/document/DetailDocument"
import ListDocument from "src/views/document/ListDocument"

const DocumentPage = () => {
    useUserDepartment()
    useEmployee()
    usePhysicalAddress()

    const [state, setState] = useState({
        id: '',
    })
    const listRef = useRef<any>()
    const handleClickDocument = (id: string) => {
        setState({ id: id })
    }
    const handleOnReloadDetail = (data: any) => {
        if (data.active === 0) {
            listRef?.current?.deletedDoc(data.id)
        }
        else
            listRef?.current?.reloadStatus(data)
    }
    return (
        <div className="flex flex-wrap -mx-3">
            <ListDocument ref={listRef} onClick={handleClickDocument} />

            <div className=" max-w-full px-3 w-9/12 md:flex-none">
                <DetailDocument id={state.id} onReload={handleOnReloadDetail} />
            </div>
            {/* action */}

        </div>
    )
}
export default DocumentPage