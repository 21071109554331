//Chart Tiến độ (%) theo từng nhóm
import React from 'react';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Grid } from '@mui/material';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import GaugeChartComponent from './GaugeChart';
import { t } from 'i18next';
import { IProgressBarDataSet } from 'src/interfaces/IChart';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

interface ICustomProgressBarChartProps {
    title: string;
    data: IProgressBarDataSet;
    gaugeValue?: number;
    maxPercentage?: number;
    barColor?: string;
    width?: number;//%
    barThickness?: string | number;//%
    barHeight?: string | number;//%
}

const CustomProgressBarChart: React.FC<ICustomProgressBarChartProps> = ({
    title,
    data, width, barThickness,
    gaugeValue,
    barHeight,
    maxPercentage = 100,
    barColor = 'rgba(75, 192, 192, 0.2)'
}) => {
    const options: any = {
        indexAxis: 'y' as const,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        scales: {
            x: {
                beginAtZero: true,
                max: maxPercentage,
                ticks: {
                    callback: (value: any) => `${value}%`
                },
            },
        },
        barThickness: barThickness,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: title,
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        const dataPoint = data[context.label];

                        if (context.parsed.x !== null && dataPoint?.tooltipValue !== undefined) {
                            label += formatNumber(dataPoint.tooltipValue);
                        } else if (context.parsed.x !== null) {
                            label += `${context.parsed.x}%`;
                        }
                        return label;
                    }
                }
            },
            datalabels: {
                anchor: 'end' as const,
                align: 'end' as const,
                formatter: (value: number, context: any) => {
                    return `${(value?.toFixed(2) || 0)}%`;
                },
                color: 'black',
                font: {
                    weight: 'bold',
                    size: 11
                }
            }
        },
    };

    const chartData = {
        labels: Object.entries(data).map(([key, value]) => {
            return key;
        }),
        datasets: [
            {
                label: t('Progress'),
                data: Object.entries(data).map(([key, value]) => {
                    return value.value;
                }),
                backgroundColor: barColor,
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <Grid container spacing={1} direction="row" style={{ width: `${width}%` }}>
            <Grid item xs={12} style={{ fontWeight: 'bold', display: 'flex' }}>
                {
                    gaugeValue
                    &&
                    <GaugeChartComponent value={gaugeValue} width={20} height={50} />
                }
                {title}
            </Grid>
            <Grid item xs={12} style={{ padding: 2 }}>
                <Bar options={options} data={chartData} height={barHeight} />
            </Grid>
        </Grid>
    );
};

export default CustomProgressBarChart;
