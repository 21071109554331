// ** React Imports
import { useEffect } from 'react'

// ** Next Import
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

// ** MUI Components
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import { useAppDispatch } from 'src/store/store'
import { logout, setAuth } from 'src/store/slices/authSlice'
import { useAuth } from 'react-oidc-context'
import { useSelector } from 'react-redux'
import { setAuthToken } from 'src/commons/helpers/set-auth-token'
import { selectEmployeeState } from 'src/store/slices/commonSlice'
import { setBackUrl } from 'src/store/slices/settingSlice'
// ** Layout Import

// ** Styled Components
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90vw'
  }
}))

const Img = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    height: 450,
    marginTop: theme.spacing(10)
  },
  [theme.breakpoints.down('md')]: {
    height: 400
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(13)
  }
}))

const UnauthorizedPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const auth = useAuth()
  const navigate = useNavigate()
  const employeeState = useSelector(selectEmployeeState)
  const url = searchParams.get('url')
  const dispatch = useAppDispatch()


  useEffect(() => {
    //clearRedux()
    if(url && url !== '')
      dispatch(setBackUrl(url))
    const user: any = auth?.user?.profile
    if (user) {
      const token = auth?.user?.access_token || ''
      setAuthToken(token)
      const curUser = employeeState.find((x: any) => x.employeeId === user.name)
      dispatch(setAuth({ ...user, departmentName: curUser?.departmentName }))
      navigate('/')
    }
    else {
      dispatch(logout())
      if (process.env.REACT_APP_LOGIN_API === 'true') {
        navigate('/login')
        return
      }
      auth.signinRedirect()
    }
    // clear
    //clearRedux()
  }, [])

  const clearRedux = ()=>{
    dispatch(logout())
    dispatch(setBackUrl(''))
    setTimeout(() => {
      navigate('/')
    }, 30000);
  }

  return (
    <Box className='content-center'>
      <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <BoxWrapper>
          <Typography variant='h1'>401</Typography>
          <Typography variant='h5' sx={{ mb: 1, fontSize: '1.5rem !important' }}>
            You are not authorized! 🔐
          </Typography>
          <Typography variant='body2'>You don&prime;t have permission to access this page. Go Home!</Typography>
        </BoxWrapper>
        <Img className='h-[300px]' alt='error-illustration' src='/assets/img/401.png' />
        <Link to='/'>
          <Button component='a' variant='contained' sx={{ px: 5.5 }}>
            Back to Home
          </Button>
        </Link>
      </Box>
    </Box>
  )
}

export default UnauthorizedPage
