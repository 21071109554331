import React, { useEffect, useRef, useState } from 'react';
import { fuzzySearch_VI } from 'src/commons/helpers/search';

export interface ICustomAutoComplete {
    label: string,
    value: string,
}

interface AutocompleteProps {
    items: ICustomAutoComplete[];
    placeHolder: string;
    className?: string
    label?: string,
    name?: string
    required?: boolean
    onChange?: (value: ICustomAutoComplete) => void
    defaultValue?: string
    readOnly?: boolean
}

const CustomAutoComplete: React.FC<AutocompleteProps> = ({ readOnly, defaultValue, onChange, name, label, required, items, placeHolder, className }) => {
    const [query, setQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState<ICustomAutoComplete[]>(items);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        defaultValue && setQuery(defaultValue)
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputQuery = event.target.value.toLowerCase();
        setQuery(inputQuery);
        const filtered = fuzzySearch_VI(items, 'label', inputQuery);

        setFilteredItems(filtered);
        setShowDropdown(true);

        if (!inputQuery) {
            onChange && onChange({ label: 'all', value: '' })
        }
    };

    const handleItemClick = (item: ICustomAutoComplete) => {
        setQuery(item.label);
        setShowDropdown(false);
        onChange && onChange(item)
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowDropdown(false);
        }
    };

    return (
        <div className="autocomplete w-full" ref={dropdownRef}>
            {label && (<label htmlFor={name} className="block text-sm font-bold leading-5 text-gray-700">
                {label} {required === true && (<span className="font-bold text-red-600">*</span>)}
            </label>)}
            <input
                readOnly={readOnly}
                className={`px-2 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 
                shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                focus:ring-2 focus:ring-inset focus:ring-indigo-600 
                sm:text-sm sm:leading-6 undefined ${className}`}
                type="text"
                placeholder={placeHolder}
                value={query}
                onChange={handleInputChange}
                onClick={() => setShowDropdown(true)}

            />
            {showDropdown && !readOnly && (
                <ul className="autocomplete-list text-sm text-gray-900" >
                    {filteredItems.map((item, index) => (
                        <li
                            key={index}
                            className="autocomplete-list-item "
                            onClick={() => handleItemClick(item)}
                            onBlur={() => setShowDropdown(false)}
                        >
                            {item.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomAutoComplete;
