import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import OutsideClickHandler from "src/components/outside-click/outside-click"


const ModuleTop = () => {
    const { t } = useTranslation()
    const [show, setShow] = useState(false)
    const handleOnClick = () => {
        setShow(false)
    }

    return (
        <li className="relative flex items-center px-2">
            <a onClick={() => setShow(!show)} className="block px-0 py-2 font-semibold transition-all ease-nav-brand text-sm text-slate-500">
                <i className="fa fa-th-large sm:mr-1"></i>
            </a>
            <OutsideClickHandler onOutsideClick={handleOnClick}>
                <div
                    className={`text-sm before:font-awesome before:leading-default before:duration-350 
        before:ease-soft lg:shadow-soft-3xl duration-250 min-w-50 before:sm:right-7.5 before:text-5.5 
        absolute right-0 top-0 z-50 origin-top list-none rounded-lg border-0 border-solid border-transparent 
        bg-white bg-clip-padding px-2 py-4 text-center text-slate-500 transition-all before:absolute 
        before:right-2 before:left-auto before:top-0 before:z-50 before:inline-block before:font-normal 
        before:text-white before:antialiased before:transition-all before:content-['\f0d8'] sm:-mr-6 lg:absolute 
        lg:right-0 lg:left-auto lg:mt-2 lg:block lg:cursor-pointer  ${show ? 'before:-top-5 transform-dropdown-show' : 'opacity-0 pointer-events-none transform-dropdown'}`}>
                    <div className="w-[150px]">
                        <Link to="/document" onClick={handleOnClick} className="inline-flex w-full text-gray-800 items-center mb-2 p-2 font-medium rounded-md text-lg border  hover:scale-102 ">
                            <i className="fa fa-book mr-2" aria-hidden="true"></i>
                            <span >{t('breadcrumb.document')}</span>
                        </Link>
                        <Link to="/report" onClick={handleOnClick} className="inline-flex w-full text-gray-800 items-center mb-2 p-2 font-medium rounded-md text-lg border border-solid  hover:scale-102">
                            <i className="fa fa-bar-chart mr-2" aria-hidden="true"></i>
                            <span>{t('breadcrumb.report')}</span>
                        </Link>
                        <Link to="/file" onClick={handleOnClick} className="inline-flex  w-full text-gray-800 items-center mb-2 p-2 font-medium rounded-md text-lg border border-solid  hover:scale-102">
                            <i className="fa-solid fa-icons mr-2" aria-hidden="true"></i>
                            <span>{t('breadcrumb.file')}</span>
                        </Link>
                        <Link to="/permission/group" onClick={handleOnClick} className="inline-flex  w-full text-gray-800 items-center mb-2 p-2 font-medium rounded-md text-lg border border-solid  hover:scale-102">
                            <i className="fa fa-lock mr-2" aria-hidden="true"></i>
                            <span>{t('breadcrumb.permission')}</span>
                        </Link>
                    </div>
                </div>
            </OutsideClickHandler>
        </li>
    )
}
export default ModuleTop