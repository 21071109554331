import MaterialTable, { Column } from "@material-table/core";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { t } from "i18next";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

interface IProps {
  data: any[];
  selects: any[]
  reload: number;
}

export type TablePermissionHandle = {
  get: () => any[];
};

const TablePermission = forwardRef<TablePermissionHandle, IProps>(
  (props, ref) => {
    useImperativeHandle(ref, () => ({
      get() {
        return permissions;
      },
    }));

    useEffect(() => {
      const newData = props.data || [];
      setPermissions(newData);

    }, [props.reload]);

    const [permissions, setPermissions] = useState<any[]>([]);

    const handleOnAddPermission = async (newData: any) => {
      debugger
      if (!newData.id || newData.id === "") {
        toast.warning(t("message.permissionChooseRequired"));
        return;
      }
      setPermissions([newData,...permissions]);
    };

    const handleOnUpdatePermission = async (newData: any, oldData: any) => {
      if (!newData.id || newData.id === "") {
        toast.warning(t("message.permissionChooseRequired"));
        return;
      }
      const currentPer = permissions.find(
        (option: any) => option.id === newData.id
      );
      const dataUpdate = [...permissions];
      const index = oldData.tableData.id;
      dataUpdate[index] = {
        ...newData,
        name: currentPer.moduleName,
      };
      setPermissions([...dataUpdate]);
    };

    const handleOnDeletePermission = async (oldData: any) => {
      const dataDelete = [...permissions];
      const index = dataDelete.findIndex(
        (obj: any) => obj.id === oldData.id
      );
      if (index === -1) {
        toast.warning(t("message.notFoundData"));
        return;
      }
      dataDelete.splice(index, 1);
      setPermissions([...dataDelete]);
    };

    const handleOnChangeCheckboxPer = (e: any, permissionId: string) => {
      const name = e.target.id || e.target.name;
      const value = e.target.checked;
      const dataUpdate = [...permissions];
      const indexPer = dataUpdate.findIndex(
        (option: any) => option.id === permissionId
      );

      dataUpdate[indexPer] = {
        ...dataUpdate[indexPer],
        [name]: value,
      };
      setPermissions([...dataUpdate]);
    };

    const columnPermision: Column<object>[] = [
      {
        title: t("name"),

        field: "id",
        width: 300,
        render: (rowData: any) => <span>{rowData.name}</span>,
        editComponent: (propsy: any) => {
          return (
            <Autocomplete
              options={props.selects}
              onChange={(e, item) => propsy.onChange(item ? item.id : "")}
              getOptionLabel={(obj: any) => obj.name}
              value={
                props.selects.find(
                  (option: any) => option.id === propsy.rowData.id
                ) || null
              }
              renderInput={(params) => (
                <TextField {...params} label={t("permission")} />
              )}
            />
          );
        },
      },
      {
        title: t("group.isInsert"),
        type: "boolean",
        field: "isInsert",
        width: 5,
        render: (rowdata: any) => (
          <Checkbox
            name="isInsert"
            checked={rowdata.isInsert}
            onChange={(e) => handleOnChangeCheckboxPer(e, rowdata.id)}
          />
        ),
      },
      {
        title: t("group.isView"),
        type: "boolean",
        field: "isView",
        width: 5,
        render: (rowdata: any) => (
          <Checkbox
            name="isView"
            checked={rowdata.isView}
            onChange={(e) => handleOnChangeCheckboxPer(e, rowdata.id)}
          />
        ),
      },
      {
        title: t("group.isDelete"),
        type: "boolean",
        field: "isDelete",
        width: 5,
        render: (rowdata: any) => (
          <Checkbox
            name="isDelete"
            checked={rowdata.isDelete}
            onChange={(e) => handleOnChangeCheckboxPer(e, rowdata.id)}
          />
        ),
      },
      {
        title: t("group.isApprove"),
        type: "boolean",
        field: "isApprove",
        width: 5,
        render: (rowdata: any) => (
          <Checkbox
            name="isApprove"
            checked={rowdata.isApprove}
            onChange={(e) => handleOnChangeCheckboxPer(e, rowdata.id)}
          />
        ),
      },
      {
        title: t("group.isUpdate"),
        type: "boolean",
        field: "isUpdate",
        width: 5,
        render: (rowdata: any) => (
          <Checkbox
            name="isUpdate"
            checked={rowdata.isUpdate}
            onChange={(e) => handleOnChangeCheckboxPer(e, rowdata.id)}
          />
        ),
      },
      {
        title: t("group.isPrintPDF"),
        type: "boolean",
        field: "isPrintPDF",
        width: 5,
        render: (rowdata: any) => (
          <Checkbox
            name="isPrintPDF"
            checked={rowdata.isPrintPDF}
            onChange={(e) => handleOnChangeCheckboxPer(e, rowdata.id)}
          />
        ),
      },
      {
        title: t("group.isReport"),
        type: "boolean",
        field: "isReport",
        width: 5,
        render: (rowdata: any) => (
          <Checkbox
            name="isReport"
            checked={rowdata.isReport}
            onChange={(e) => handleOnChangeCheckboxPer(e, rowdata.id)}
          />
        ),
      },
      {
        title: t("group.isExport"),
        type: "boolean",
        field: "isExport",
        width: 5,
        render: (rowdata: any) => (
          <Checkbox
            name="isExport"
            checked={rowdata.isExport}
            onChange={(e) => handleOnChangeCheckboxPer(e, rowdata.id)}
          />
        ),
      },
    ];

    return (
      <MaterialTable
        title={t("group.listPermission")}

        columns={columnPermision}
        data={permissions}
        editable={{
          onRowAdd: handleOnAddPermission,
          onRowUpdate: handleOnUpdatePermission,
          onRowDelete: handleOnDeletePermission,
        }}
        options={{
          headerStyle: {
            fontSize: 12
          },
          addRowPosition: "first",
          paging: true,
          pageSize: 10,
          actionsColumnIndex: 0,
        }}
      />
    );
  }
);

export default TablePermission;
