export enum EFileCategoryId {
    Avatar = 0,
    AvatarSM = 1,
    Thumbnail = 2,
    ThumbnailSM = 3,
    VideoReview = 4,
    File3D = 5,
    Document = 6,
    Undefined = 999

}