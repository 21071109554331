/* eslint-disable */

import ExcelJS from "ts.exceljs/dist/exceljs";
import { getDateTimeString } from "./formatDate";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { IDocument } from "src/interfaces/IDocument";
import { quickSort } from "./sort";


export interface IDocumentQRExport {
  document: IDocument,
  qrData: any
}

const defaultBorder = {
  top: { style: 'thin' },
  left: { style: 'thin' },
  bottom: { style: 'thin' },
  right: { style: 'thin' }
};

export async function fillExcelReportTemplate(
  items: IDocumentQRExport[],
  departmentName: string,
  baseURL: string,
  t: any
) {

  let startRowDocument = 8;
  let startRowHistory = 5;

  // Load the existing template Excel file
  let templateBuffer: ArrayBuffer;

  templateBuffer = await fetch("/report-template.xlsx").then((response) =>
    response.arrayBuffer()
  );

  const templateWorkbook = new ExcelJS.Workbook();
  await templateWorkbook.xlsx.load(templateBuffer);

  // Select the desired worksheet
  const worksheet = templateWorkbook.getWorksheet("Danh mục");
  const worksheet2 = templateWorkbook.getWorksheet("Lịch sử thay đổi");

  worksheet.getCell(`A4`).value = `Phòng ban/Bộ phận: ${departmentName}`;

  for (let i = 0; i < items?.length; i++) {
    //fill data
    worksheet.getCell(`A${startRowDocument + i}`).value = i + 1;
    worksheet.getCell(`B${startRowDocument + i}`).value = items[i]?.document?.name || '';
    worksheet.getCell(`C${startRowDocument + i}`).value = items[i]?.document?.serial || '';
    if (items[i].document.documentManualVersions && items[i].document.documentManualVersions.length > 0 && items[i].document.isManualVersion) {
      worksheet.getCell(`D${startRowDocument + i}`).value = items[i].document.documentManualVersions[0].versionOrder;
      worksheet.getCell(`E${startRowDocument + i}`).value = getDateTimeString(new Date(items[i].document.documentManualVersions[0].promulgateDate)) || '';
    } else {
      // worksheet.getCell(`D${startRowDocument + i}`).value = items[i]?.document?.documentHistories?.length || '1';
      worksheet.getCell(`E${startRowDocument + i}`).value = getDateTimeString(new Date(items[i]?.document?.createdDate)) || '';
    }
    worksheet.getCell(`F${startRowDocument + i}`).value = items[i]?.document?.description || '';



    worksheet.getCell(`A${startRowDocument + i}`).border = defaultBorder;
    worksheet.getCell(`B${startRowDocument + i}`).border = defaultBorder;
    worksheet.getCell(`C${startRowDocument + i}`).border = defaultBorder;
    worksheet.getCell(`D${startRowDocument + i}`).border = defaultBorder;
    worksheet.getCell(`E${startRowDocument + i}`).border = defaultBorder;
    worksheet.getCell(`F${startRowDocument + i}`).border = defaultBorder;
    worksheet.getCell(`G${startRowDocument + i}`).border = defaultBorder;

    const row = worksheet.getRow(startRowDocument + i);
    row.height = 105;

    // add image to workbook by base64
    let imageURL = `${baseURL}/document?s=${items[i].document.serial}`;

    const imageId2 = templateWorkbook.addImage({
      base64: items[i].qrData,
      extension: "png",
    });

    worksheet.addImage(imageId2, {
      tl: { col: 6, row: startRowDocument + i - 1 },
      ext: { width: 120, height: 120 },
      hyperlinks: {
        hyperlink: imageURL,
        tooltip: imageURL,
      },
    });


    //Fill histories
    if (items[i].document.documentManualVersions && items[i].document.documentManualVersions.length > 0 && items[i].document.isManualVersion) {
      const versions = quickSort((items[i].document?.documentManualVersions || []), 'createdDate', 'asc');

      for (let j = 0; j < versions.length; j++) {
        const versionItem = versions[j];

        worksheet2.getCell(`A${startRowHistory + j}`).value = versionItem.versionOrder;
        worksheet2.getCell(`B${startRowHistory + j}`).value = items[i].document.serial || '';
        worksheet2.getCell(`C${startRowHistory + j}`).value = getDateTimeString(new Date(versionItem.createdDate)) || '';
        worksheet2.getCell(`D${startRowHistory + j}`).value = `${versionItem.note || ''}`;

        worksheet2.getCell(`A${startRowHistory + j}`).border = defaultBorder;
        worksheet2.getCell(`B${startRowHistory + j}`).border = defaultBorder;
        worksheet2.getCell(`C${startRowHistory + j}`).border = defaultBorder;
        worksheet2.getCell(`D${startRowHistory + j}`).border = defaultBorder;
      }
      startRowHistory += versions.length + 1;

    } else {
      const versions = items[i].document?.documentHistories.flatMap(x => x.documentVersions || []);
      const histories = quickSort((versions || []), 'versionOrder', 'asc');

      worksheet.getCell(`D${startRowDocument + i}`).value = (histories.length > 0 ? histories[histories.length - 1]?.versionOrder : 1) || '1';

      for (let j = 0; j < histories.length; j++) {
        const historyItem = histories[j];

        worksheet2.getCell(`A${startRowHistory + j}`).value = historyItem.versionOrder;
        worksheet2.getCell(`B${startRowHistory + j}`).value = items[i].document.serial || '';
        worksheet2.getCell(`C${startRowHistory + j}`).value = getDateTimeString(new Date(historyItem.modifiedDate)) || '';
        worksheet2.getCell(`D${startRowHistory + j}`).value = `[${t('documentVersionAction' + historyItem.action)}] ${historyItem.name || ''}`;

        worksheet2.getCell(`A${startRowHistory + j}`).border = defaultBorder;
        worksheet2.getCell(`B${startRowHistory + j}`).border = defaultBorder;
        worksheet2.getCell(`C${startRowHistory + j}`).border = defaultBorder;
        worksheet2.getCell(`D${startRowHistory + j}`).border = defaultBorder;

      }
      startRowHistory += histories.length + 1;
    }
  }

  // Generate the Excel file
  const buffer = await templateWorkbook.xlsx.writeBuffer();

  if (Capacitor.isNativePlatform()) {
    const nameFile = departmentName
      ? departmentName.replaceAll("/", "-") + ".xlsx"
      : `${getDateTimeString(new Date())}.xlsx`;
    Filesystem.writeFile({
      directory: Directory.Documents,
      path: nameFile,
      data: buffer.toString("base64"),
      recursive: true,
    })
      .then((res) => {
        alert("Downloaded Successfully");
      })
      .catch((err) => {
        alert("Write error" + JSON.stringify(err));
      });
  } else {
    // Create a Blob and download the filled Excel file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${departmentName || "no-department-name"}.xlsx`;

    a.click();
    window.URL.revokeObjectURL(url);
  }
};

// interface IImportingProduct {
//   productId: string | any;
//   quantity: number | any;
//   type: string | any;
// }

// export const getItemsFromExcelTemplate = async (
//   event: React.ChangeEvent<HTMLInputElement>,
//   currentCustomer: ICustomer,
//   messageCusCodeNotMatch: string,
//   messageImportedFailed: string
// ): Promise<IImportingProduct[]> => {
//   const file = event.target.files && event.target.files[0];
//   if (file) {
//     // Read file content as ArrayBuffer
//     const fileBuffer = await file.arrayBuffer();
//     const buffer = Buffer.from(fileBuffer);

//     const workbook = new ExcelJS.Workbook();
//     await workbook.xlsx.load(buffer);

//     const worksheet = workbook.getWorksheet(1);

//     const customerId = worksheet.getCell("S17").value;
//     if (
//       currentCustomer === undefined ||
//       currentCustomer.id != customerId?.toString().trim()
//     ) {
//       toast.error(messageCusCodeNotMatch);
//       event.target.value = "";
//       return [];
//     }

//     const lstImportingProducts: IImportingProduct[] = [];

//     let startRow = startRowQuote;
//     let productId = "test";
//     while (productId !== "") {
//       productId = worksheet.getCell(`C${startRow}`).value?.toString() || "";
//       const quantity = worksheet.getCell(`Q${startRow}`).value || 0;
//       const name = worksheet.getCell(`D${startRow}`).value || "";
//       const type = extractLastStringInParentheses(name.toString());

//       if (productId !== "") {
//         const typeData = listQualityCodes.find((x) => x.name === type);
//         if (!type || !typeData) {
//           toast.error(`${messageImportedFailed} ${name} (${productId})`);
//           startRow++;
//           continue;
//         }

//         lstImportingProducts.push({
//           productId: productId,
//           quantity: quantity,
//           type: typeData?.code,
//         });
//       }
//       startRow++;
//     }

//     return lstImportingProducts;
//   }
//   return [];
// };

// export const getValuesFromPackingFile = async (
//   event: React.ChangeEvent<HTMLInputElement>,
//   messagePleaseChooseXLSX: string,
//   messageImportedFailed: string,
//   messageImportSuccess: string
// ): Promise<IPackingLookUpType<IPacking> | null> => {
//   const file = event.target.files && event.target.files[0];

//   if (!file || file?.name.split(".").pop() != "xlsx") {
//     toast.error(messagePleaseChooseXLSX);
//     event.target.value = "";
//     return null;
//   }

//   if (!file) {
//     toast.error(`${messageImportedFailed}`);
//     return null;
//   }

//   if (file) {
//     // Read file content as ArrayBuffer
//     const fileBuffer = await file.arrayBuffer();
//     const buffer = Buffer.from(fileBuffer);

//     const workbook = new ExcelJS.Workbook();
//     await workbook.xlsx.load(buffer);

//     if (!workbook || workbook.worksheets.length === 0) {
//       return null;
//     }

//     const worksheet = workbook.worksheets[0];

//     let startRow = 1;
//     let packings: IPackingLookUpType<IPacking> = {};
//     let count = 0;
//     while (startRow !== -1) {
//       const number = worksheet.getCell(`A${startRow}`).value?.toString() || "";
//       if (!isNaN(parseInt(number, 10))) {
//         if (number !== "") {
//           const productId = worksheet.getCell(`B${startRow}`).value || 0;
//           const pcsCtn = worksheet.getCell(`Q${startRow}`).value || "";
//           const cbm = worksheet.getCell(`U${startRow}`).value || "";
//           const gW = worksheet.getCell(`V${startRow}`).value || "";

//           // console.log(`${number} - ${productId} - ${pcsCtn} - ${cbm instanceof String ? cbm : cbm.result} - ${gW}`);

//           packings[productId] = {
//             productId: productId,
//             pcsCtn: parseFloat(pcsCtn) || 0,
//             cbm: parseFloat(cbm instanceof String ? cbm : cbm.result) || 0,
//             gW: parseFloat(gW) || 0,
//           };
//           count++;
//         }
//       }

//       if (startRow === 100000) {
//         startRow = -1;
//         continue;
//       }
//       startRow++;
//     }

//     toast.success(`${messageImportSuccess} ${count}`);
//     return packings;
//   }

//   return null;
// };

// const extractLastStringInParentheses = (inputString: string) => {
//   // Using a regular expression to find all pairs of parentheses
//   const regex = /\([^)]+\)/g;

//   // Finding all matching results with the regular expression
//   const matches = inputString.match(regex);

//   // Check if there are any matching results
//   if (matches) {
//     // Extracting the last string within parentheses
//     const lastString = matches[matches.length - 1].slice(1, -1);
//     return lastString;
//   } else {
//     // Return null if no string within parentheses is found
//     return null;
//   }
// };
