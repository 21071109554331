import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import CardText from "src/components/themes/Card/CardText"
import CardText1 from "src/components/themes/Card/CardText1"
import Hitory from "src/components/themes/History/History"
import { selectSettingState, setBackUrl } from "src/store/slices/settingSlice"
import { useAppDispatch } from "src/store/store"


const Product = () => {
    // check đăng xuất             ///////////////////
    const [searchParams] = useSearchParams()
    const params = Object.fromEntries([...searchParams])
    const { state } = params
    const settingState = useSelector(selectSettingState)
    const navigate = useNavigate()
    
    if (state) { // đóng popup khi đăng xuất
        window.close()
    }
    // kết thúc kiểm tra đăng xuất ////////////////////

    useEffect(() => {
        const backUrl = settingState.backUrl
        navigate(backUrl && backUrl !== '' ? backUrl : '/document')
    }, [])

    return (
        <div>
            Trang chủ
        </div>
    )
}

export default Product