/* eslint-disable */
import { createBrowserRouter, RouteObject } from "react-router-dom";
import MobileLayout from "src/views/layouts/MobileLayout";
import DashboardLayout from "../views/layouts/DashboardLayout";

const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

import IndexPage from 'src/pages/main/home-page'
import LoginPage from "src/pages/main/login";
import RegisterPage from "src/pages/main/register";
import UnauthorizedPage from "src/pages/main/unauthorized-page";
import CallbackPage from "src/pages/main/call-back";
import ProfilePage from "src/pages/main/profile";
import DocumentPage from "src/pages/document";
import DocumentEdit from "src/pages/document/document-edit";
import DocumentType from "src/pages/document/document-type";
import ProductFilePage from "src/pages/file/product";
import ReportPage from "src/pages/report";
import PrivateFilesPage from "src/pages/file/private";
import VirtualReality from "src/pages/main/virtual-reality";
import DocumentReportDepartment from "src/pages/document/document-report-department";
import PhysicalAddress from "src/pages/document/physical-address";
import CategoryFilePage from "src/pages/file/category";
import DocumentPermissionPage from "src/pages/document/document-permission";

import GroupPage from "src/pages/permission";
import PermisisonPage from "src/pages/permission/permission"
import ApplicationPage from "src/pages/permission/application"
import RolePage from "src/pages/permission/role"
import NotificationPage from "src/pages/notification";
import MenuPage from "src/pages/permission/menu";
import UserPage from "src/pages/permission/user";
import InventorySurveyReport from "src/pages/report/inventory-survey";
import CataloguePage from "src/pages/file/catalogue";
import TestCode from "src/test-code";
const routes: RouteObject[] = [
  {
    path: '/',
    element: isMobileDevice ? <MobileLayout /> : <DashboardLayout />,
    children: [
      { index: true, element: <IndexPage /> },
      { path: '/profile', element: <ProfilePage /> },
      { path: '/virtual-reality', element: <VirtualReality /> },

    ]
  },
  {
    path: '/document',
    element: isMobileDevice ? <MobileLayout /> : <DashboardLayout />,
    children: [
      { index: true, element: <DocumentPage /> },
      { path: 'edit/:id', element: <DocumentEdit /> },
      { path: "type", element: <DocumentType /> },
      { path: "physical-address", element: <PhysicalAddress /> },
      { path: "report-department", element: <DocumentReportDepartment /> },
      { path: 'permission', element: <DocumentPermissionPage /> },

    ]
  },
  {
    path: '/file',
    element: isMobileDevice ? <MobileLayout /> : <DashboardLayout />,
    children: [
      { index: true, element: <ProductFilePage /> },
      { path: 'product', element: <ProductFilePage /> },
      { path: 'catalogue', element: <CataloguePage /> },
      { path: 'private', element: <PrivateFilesPage /> },
      { path: ':type', element: <CategoryFilePage /> },
    ]
  },
  {
    path: '/report',
    element: isMobileDevice ? <MobileLayout /> : <DashboardLayout />,
    children: [
      { index: true, element: <ReportPage /> },
      { path: 'inventory-survey', element: <InventorySurveyReport /> }
    ]
  },

  {
    path: '/permission',
    element: isMobileDevice ? <MobileLayout /> : <DashboardLayout />,
    children: [
      { index: true, element: <GroupPage /> },
      { path: 'group', element: <GroupPage /> },
      { path: 'permission', element: <PermisisonPage /> },
      { path: 'application', element: <ApplicationPage /> },
      { path: 'role', element: <RolePage /> },
      { path: 'menu', element: <MenuPage /> },
      { path: 'user', element: <UserPage /> },
    ]
  },
  {
    path: '/notification',
    element: isMobileDevice ? <MobileLayout /> : <DashboardLayout />,
    children: [
      { index: true, element: <NotificationPage /> },
    ]
  },
  { path: "/callback", element: <CallbackPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/register", element: <RegisterPage /> },
  { path: "/401", element: <UnauthorizedPage /> },
  { path: "/test", element: <TestCode /> },
];

export default createBrowserRouter(routes)