import { IDocument } from "src/interfaces/IDocument";
import api, { ResultObject } from "./base/base";
import { IDocumentType } from "src/interfaces/IDocumentType";
import { IDocumentAssign } from "src/interfaces/IDocumentAssign";
import { IDocumentActionModel } from "src/interfaces/IDocumentActionModel";
import { IPhysicalAddress } from "src/interfaces/IPhysicalAddress";
import { removeUndefinedKeys } from "src/commons/helpers/common";
import { IDocumentFilePhyAddSearchModel } from "src/interfaces/IDocumentPhyAddSearchModel";
import { IUserDepartment, IUserDepartmentSearchModel } from "src/interfaces/IUserDepartment";

const baseURL = `${process.env.REACT_APP_API_DOCUMENT}/api`;

// document Type
export const getDocumentType = async (): Promise<any> => {
  const result = await api
    .get(`${baseURL}/documentType`)
    .then((response: any) => response?.data);
  return result;
};

export const createDocumentType = async (
  documentType: IDocumentType
): Promise<any> => {
  const result = await api.post(`${baseURL}/documentType`, documentType)
    .then((response: any) => response?.data);
  return result;
};

export const updateDocumentType = async (
  documentType: IDocumentType
): Promise<any> => {
  const result = await api.put(`${baseURL}/documentType`, documentType)
    .then((response: any) => response?.data);
  return result;
};

export const deleteDocumentType = async (
  id: number
): Promise<any> => {
  const result = await api.delete(`${baseURL}/documentType/${id}`)
    .then((response: any) => response?.data);
  return result;
};

// physicalAddress
export const getPhysicalAddress = async (): Promise<any> => {
  const result = await api
    .get(`${baseURL}/physicalAddress`)
    .then((response: any) => response?.data);
  return result;
};

export const getPhysicalAddressFiles = async (model: IDocumentFilePhyAddSearchModel): Promise<ResultObject> => {
  const result = await api
    .get(`${baseURL}/physicalAddress/File?${new URLSearchParams(removeUndefinedKeys(model)).toString()}`)
    .then((response: any) => response?.data);
  return result;
};

export const getPhysicalAddressByDepartment = async (departmentId: string): Promise<any> => {
  const result = await api
    .get(`${baseURL}/physicalAddress?departmentId=${departmentId}`)
    .then((response: any) => response?.data);
  return result;
};

export const createPhysicalAddress = async (
  physicalAddress: IPhysicalAddress
): Promise<any> => {
  const result = await api.post(`${baseURL}/physicalAddress`, physicalAddress)
    .then((response: any) => response?.data);
  return result;
};

export const updatePhysicalAddress = async (
  physicalAddress: IPhysicalAddress
): Promise<any> => {
  const result = await api.put(`${baseURL}/physicalAddress`, physicalAddress)
    .then((response: any) => response?.data);
  return result;
};

export const deletePhysicalAddress = async (
  id: number
): Promise<any> => {
  const result = await api.delete(`${baseURL}/physicalAddress/${id}`)
    .then((response: any) => response?.data);
  return result;
};

export const getSerial = async (documentType: string | number, dept?: string): Promise<any> => {
  const result = await api
    .get(`${baseURL}/documentType/serial/${documentType}?dept=${dept}`)
    .then((response: any) => response?.data);
  return result;
};

//User Department
export const getUserDepartment = async (searchModel: IUserDepartmentSearchModel): Promise<any> => {
  const result = await api
    .get(`${baseURL}/UserDepartment?${new URLSearchParams(removeUndefinedKeys(searchModel)).toString()}`)
    .then((response: any) => response?.data);
  return result;
};

export const updateUserDepartments = async (departmentIds: string[], userId: string): Promise<any> => {
  const result = await api.post(`${baseURL}/UserDepartment`, { departmentIds, userId })
    .then((response: any) => response?.data);
  return result;
};


//Document
export const getDocumment = async (model: any): Promise<ResultObject> => {
  const result = await api
    .get(`${baseURL}/document?${new URLSearchParams(removeUndefinedKeys(model)).toString()}`)
    .then((response: any) => response?.data);
  return result;
};

export const getDocummentDetail = async (idOrSerial: string): Promise<ResultObject> => {
  const result = await api
    .get(`${baseURL}/document/detail?id=${idOrSerial}`)
    .then((response: any) => response?.data);
  return result;
};

export const createDocument = async (
  document: IDocument
): Promise<any> => {
  const result = await api.post(`${baseURL}/document`, document)
    .then((response: any) => response?.data);
  return result;
};

export const updateDocument = async (
  document: IDocument
): Promise<any> => {
  const result = await api.put(`${baseURL}/document`, document)
    .then((response: any) => response?.data);
  return result;
};

export const countDocumentByDepartment = async (documentStatus?: number | null, reminderTime?: string | null): Promise<ResultObject> => {
  const req = { status: documentStatus, reminderTime: reminderTime };

  const result = await api
    .get(`${baseURL}/document/count-by-department?${new URLSearchParams(removeUndefinedKeys(req)).toString()}`)
    .then((response: any) => response?.data);
  return result;
};

//Document File
export const updateDocumentFile = async (
  reqData: IDocumentActionModel
): Promise<any> => {
  const result = await api.put(`${baseURL}/document/update-file`, reqData)
    .then((response: any) => response?.data);
  return result;
};


/// ACTION /////////////////////
//giao
export const assignDocument = async (
  data: IDocumentActionModel
): Promise<any> => {
  const result = await api.post(`${baseURL}/document/assign`, data)
    .then((response: any) => response?.data);
  return result;
};

// thu hồi
export const retractDocument = async (
  data: IDocumentActionModel
): Promise<any> => {
  const result = await api.post(`${baseURL}/document/retract`, data)
    .then((response: any) => response?.data);
  return result;
};

// trả lại
export const returnDocument = async (
  data: IDocumentActionModel
): Promise<any> => {
  const result = await api.post(`${baseURL}/document/return`, data)
    .then((response: any) => response?.data);
  return result;
};
// xử lý
export const processDocument = async (
  data: IDocumentActionModel
): Promise<ResultObject> => {
  const result = await api.post(`${baseURL}/document/process`, data)
    .then((response: any) => response?.data);
  return result;
};
// download
export const downloadDocument = async (
  data: IDocumentActionModel
): Promise<any> => {
  const result = await api.post(`${baseURL}/document/download`, data)
    .then((response: any) => response?.data);
  return result;
};

export const promulgateDocument = async (
  data: IDocumentActionModel
): Promise<any> => {
  const result = await api.post(`${baseURL}/document/promulgate`, data)
    .then((response: any) => response?.data);
  return result;
};
export const deleteDocument = async (
  id: string
): Promise<any> => {
  const result = await api.delete(`${baseURL}/document/delete/${id}`)
    .then((response: any) => response?.data);
  return result;
};

export const shareDocument = async (
  data: IDocumentActionModel
): Promise<ResultObject> => {
  const result = await api.post(`${baseURL}/document/share`, data)
    .then((response: any) => response?.data);
  return result;
};
export const privateAccessDocument = async (
  data: IDocumentActionModel
): Promise<ResultObject> => {
  const result = await api.post(`${baseURL}/document/PrivateAccess`, data)
    .then((response: any) => response?.data);
  return result;
};