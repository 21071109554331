import { Delete } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useFormik } from "formik"
import { t } from "i18next"
import moment from "moment"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { useSelector } from "react-redux"
import { DocumentAssignType } from "src/commons/enums/documentAssignType"
import { enumToListSelect } from "src/commons/helpers/common"
import InputText from "src/components/inputs/InputText"
import OrgDialog from "src/components/org/OrgDialog"
import CustomSelect from "src/components/selects/select"
import { IDocumentAssign } from "src/interfaces/IDocumentAssign"
import { IDepartment, IEmployee, IOrgSelectedItem } from "src/interfaces/IOrg"
import { selectEmployeeState, selectUserDepartmentState } from "src/store/slices/commonSlice"


const allowShowFromDate = ['0','1','2']
const allowShowToDate= ['0','1','2','5']
const UserPermission = forwardRef((props: any, ref) => {
    useImperativeHandle(ref, () => ({
        getData() {
            const assigns: IDocumentAssign[] = formik.values && formik?.values?.assigns || []
            const objTemp = {
                //processingDeadline:4,
                fromDate: moment().format(),
                toDate: moment().add(4, 'h').format()
            }
            return selectedOrgs.map((a, index) => {
                const curValue = assigns[index]
                const typeNumber: number = parseInt(curValue?.type + '')
                const type: number = typeNumber === 0 ? 0 : (typeNumber || 4)
                const rs: IDocumentAssign = {
                    type: type,
                    userId: a.type === 'employee' ? curValue.userId : undefined,
                    departmentId: curValue.departmentId,
                    //processingDeadline:curValue?.processingDeadline || objTemp.processingDeadline,
                    fromDate: moment(curValue?.fromDate || objTemp.fromDate).format(),
                    toDate: moment(curValue?.toDate || objTemp.toDate).format(),
                    userNotiId: assigns[index].userNotiId,
                }
                return rs
            });
        },
        setData(data: IDocumentAssign[]) {
            const mapOrgs: IOrgSelectedItem[] = data?.map((ud: IDocumentAssign) => {

                const isDept = !ud.userId
                if (isDept) {
                    const dept = userDepartmentState.find((x: IDepartment) => x.code === ud.departmentId)
                    const item: IOrgSelectedItem = {
                        id: dept.id,
                        label: dept?.name || '',
                        type: 'department',
                        isSelected: true,
                        role: ud.type,
                        department: dept,
                        userNotiId: dept?.managerId || ''
                    }
                    return item
                }
                else {
                    const user = employeeState.find((x: IEmployee) => x.employeeId === ud.userId)
                    const item: IOrgSelectedItem = {
                        id: user.id || '',
                        label: user?.employeeName,
                        type: 'employee',
                        isSelected: true,
                        role: ud.type,
                        employee: user,
                        userNotiId: user.id || ''
                    }
                    return item
                }
            })

            setSelectedOrgs(mapOrgs)
            formik.setValues({ assigns: data || [] })

        }
    }));

    const employeeState = useSelector(selectEmployeeState)
    const userDepartmentState = useSelector(selectUserDepartmentState)

    const [stateOrg, setStateOrg] = useState({
        open: false,
        reload: 0
    })

    const [selectedOrgs, setSelectedOrgs] = useState<IOrgSelectedItem[]>([]);

    useEffect(() => {
        const formDatas: IDocumentAssign[] = formik?.values?.assigns || [];
        const datas = selectedOrgs.map((x) => {
            let data: any = {};
            if (x.type === 'department') {
                data = formDatas.find(y => y.departmentId === x.department?.code)
            } else {
                data = formDatas.find(y => y.userId === x.employee?.employeeId)
            }
            return {
                ...data,
                userId: x?.employee?.employeeId,
                departmentId: x?.department?.code || x?.employee?.departmentId,
                userNotiId: x?.employee?.employeeId || x?.department?.managerId
            }
        }) as IDocumentAssign[];

        formik.setValues({ assigns: datas || [] })


    }, [selectedOrgs])

    const handleClickOpenOrgDialog = () => {
        setStateOrg({ open: true, reload: stateOrg.reload + 1 });
    }

    const onSaveSelectedOrgs = (items: IOrgSelectedItem[]) => {
        setSelectedOrgs(items.map((x => {
            if (!x?.role) {
                x.role = 4
            }
            return x
        })));
    }

    const onCloseSelectedOrgs = () => {
        setStateOrg({ ...stateOrg, open: false });
    }

    const handleClickDeleteAssigns = (index: number) => {
        setSelectedOrgs(prev => prev.filter((v, i) => i != index));
    }

    const formik = useFormik({
        initialValues: {
            assigns: [] as IDocumentAssign[]
        },
        onSubmit: async (values: any) => {
        },
    });
    return (
        <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent 
        border-solid shadow-soft-xl rounded-2xl bg-clip-border ">
            <div className="p-6 pb-0 mb-0 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                <h6 className="font-bold leading-5 text-gray-900">{t('processedEmployee')}</h6>
                <div className="float-right">
                    <button type="button" onClick={handleClickOpenOrgDialog} className="mr-2  px-3 py-3 bg-gradient-to-tl from-purple-700 to-pink-500 font-bold text-center text-white from-purple-700 to-pink-500 uppercase align-middle transition-all ease-in border-0 rounded-lg  shadow-soft-md  leading-pro text-size-xs hover:shadow-soft-2xl hover:scale-102 ">
                        <i className="fas fa-users"></i>    {t('choose')}
                    </button>
                </div>
            </div>
            <div className="flex-auto px-0 pt-0 pb-2">
                <form className="p-0 overflow-x-auto ps relative">
                    <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                        <thead className="align-bottom">
                            <tr>
                                <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{`${t('name')} (${t('department')}/${t('employee')})`}</th>
                                <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('type')}</th>
                                <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('department')}</th>
                                <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('role')}</th>
                                <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('fromDate')}</th>
                                <th className="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{t('toDate')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectedOrgs.map((org: IOrgSelectedItem, index: number) => {
                                    return (
                                        <tr key={org.id + org.type}>
                                            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                <div className="flex px-2 py-1">
                                                    <div className="m-2">
                                                        {org.type != 'department'
                                                            ? <i className="fas fa-user "></i>
                                                            : <i className="fas fa-building"></i>}
                                                    </div>
                                                    <div className="flex flex-col justify-center">
                                                        <h6 className="mb-0 text-sm leading-normal">{org.label}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                <p className="mb-0 text-xs font-semibold leading-tight">{t(org.type)}</p>
                                            </td>
                                            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                <p className="mb-0 text-xs font-semibold leading-tight">{org.type === 'department' ? '' : org?.employee?.departmentName}</p>
                                            </td>
                                            <td className="p-2 text-sm leading-normal text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                <CustomSelect
                                                    data={enumToListSelect(DocumentAssignType, t, 'documentAssignType')}
                                                    name={`assigns[${index}].type`}
                                                    defaultValue={`${org.role}`}
                                                    onChange={formik.handleChange} />
                                            </td>
                                            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                {formik.values.assigns[index]?.type !== undefined && allowShowFromDate.includes(formik.values.assigns[index]?.type+'') && <InputText type="datetime-local"
                                                    name={`assigns[${index}].fromDate`}
                                                    inputField={{ min: moment().format('YYYY-MM-DD HH:mm') }}
                                                    defaultValue={formik.values.assigns[index]?.fromDate ? moment(formik.values.assigns[index].fromDate).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm')}
                                                    onChange={formik.handleChange} />
                                                }
                                            </td>
                                            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                {formik.values.assigns[index]?.type !== undefined && allowShowToDate.includes(formik.values.assigns[index]?.type+'') && <InputText type="datetime-local"
                                                    name={`assigns[${index}].toDate`}
                                                    inputField={{ min: moment().format('YYYY-MM-DD HH:mm') }}
                                                    defaultValue={formik.values.assigns[index]?.toDate ? moment(formik.values.assigns[index].toDate).format('YYYY-MM-DD HH:mm') : moment().add(4, 'h').format('YYYY-MM-DD HH:mm')}
                                                    onChange={formik.handleChange} />
                                                }
                                            </td>
                                            <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                <IconButton onClick={() => handleClickDeleteAssigns(index)}>
                                                    <Delete />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </form>
            </div>
            <OrgDialog
                multiple
                showEmployees
                open={stateOrg.open}
                reload={stateOrg.reload}
                onSave={onSaveSelectedOrgs}
                onClose={onCloseSelectedOrgs}
                data={selectedOrgs} />
        </div>
    )
})
export default UserPermission