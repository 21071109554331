import { Container, FormControlLabel, Grid, Stack } from '@mui/material';
import CustomProgressBarChart from 'src/components/charts/CustomProgressBarChart';
import CustomActualTargetBarChart from 'src/components/charts/CustomActualTargetBarChart';
import CustomGroupBarChart from 'src/components/charts/CustomGroupBarChart';
import { IProgressBarDataSet, IActualTargetBarDataSet, IGroupBarDataSet } from 'src/interfaces/IChart';

const MonthlySalesByGroupChart = () => {
    const salesDataSet: IActualTargetBarDataSet = {
        'Supermarket': {
            actual: 1768351906,
            tatget: 2387022987,
        },
        'Showroom': {
            actual: 13798455048,
            tatget: 15500000000,
        },
        'Branch Sales': {
            actual: 24206860298,
            tatget: 32771532696,
        },
        'B2B': {
            actual: 11527942823,
            tatget: 10797995943,
        },
        'E-Commerce': {
            actual: -21251384,
            tatget: 1084897847,
        },
        'Export': {
            actual: 882753360,
            tatget: 4191173571,
        },
    };

    const labels = ['Siêu Thị', 'Showroom', 'Chi Nhánh Bán Hàng', 'B2B', 'E-Commerce', 'Xuất Khẩu'];

    return <CustomActualTargetBarChart
        actualConfig={{
            label: 'Doanh Số Thực Hiện',
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            type: 'bar',
            order: 2,
        }}
        targetConfig={{
            label: 'CT Tháng',
            backgroundColor: 'rgba(255, 99, 132, 1)',
            type: 'scatter',
            showLine: false,
            pointRadius: 10,
            order: 1,
        }}
        data={salesDataSet}
        title="Doanh Số Tháng Theo Nhóm Khách"
        width={100}
        barHeight={240}
        isAutoShowData={true}
    />
}

const GroupTargetChart: React.FC = () => {
    const progressPercent = 75; // Ví dụ tính toán cho tiến độ tổng thể
    const data: IProgressBarDataSet = {
        'Xuất Khẩu': { value: 63.47 },
        'E-Commerce': { value: 6.39 },
        'B2B': { value: 95.46 },
        'Chi Nhánh Bán Hàng': { value: 90.02 },
        'Showroom': { value: 106.51 },
        'Siêu Thị': { value: 75.63 },
    };

    return (
        <CustomProgressBarChart
            title="Tiến Độ Thực Hiện Mục Tiêu Lũy Kế Theo Nhóm"
            data={data}
            gaugeValue={progressPercent}
            maxPercentage={140}
            width={100}
            barColor='rgba(153, 102, 255, 0.2)'
        />
    );
};

const CumulativeTargetChart: React.FC = () => {
    const progressPercent = (171_876_563_859 / 200_000_000_000) * 100; // Ví dụ tính toán

    const data =
        { '': { value: (171_876_563_859 / 200_000_000_000) * 100, tooltipValue: 171_876_563_859 } } // Giá trị tiến độ
        ;

    return (
        <CustomProgressBarChart
            title="Tiến Độ Thực Hiện Mục Tiêu Lũy Kế"
            data={data}
            gaugeValue={progressPercent}
            width={100}
            barColor='rgba(75, 192, 192, 0.2)'
            barThickness={40}
            barHeight={50}
        />
    );
};

const MonthlyTargetChart: React.FC = () => {
    const progressPercent = (52_163_114_051 / 67_712_623_044) * 100; // Ví dụ tính toán

    const data =
        { '': { value: (52_163_114_051 / 67_712_623_044) * 100, tooltipValue: 52163_114_051 } } // Giá trị tiến độ
        ;

    return (
        <CustomProgressBarChart
            title="Tiến Độ Thực Hiện Mục Tiêu Tháng"
            data={data}
            gaugeValue={progressPercent}
            width={100}
            barColor='rgba(255, 159, 64, 0.2)'
            barThickness={40}
            barHeight={50}
        />
    );
};

const WeeklySalesByGroupChart = () => {
    const data: IGroupBarDataSet[] = [
        { key1: "27", key2: "B2B", actual: 2000000000 },
        { key1: "27", key2: "Chi Nhánh Bán Hàng", actual: 3000000000 },
        { key1: "27", key2: "E-Commerce", actual: 1500000000 },
        { key1: "27", key2: "Showroom", actual: 1000000000 },
        { key1: "27", key2: "Siêu Thị", actual: 500000000 },
        { key1: "27", key2: "Xuất Khẩu", actual: 200000000 },
        { key1: "28", key2: "B2B", actual: 2500000000 },
        { key1: "28", key2: "Chi Nhánh Bán Hàng", actual: 3500000000 },
        { key1: "28", key2: "E-Commerce", actual: 1000000000 },
        { key1: "28", key2: "Showroom", actual: 1500000000 },
        { key1: "28", key2: "Siêu Thị", actual: 700000000 },
        { key1: "28", key2: "Xuất Khẩu", actual: 300000000 },
        { key1: "29", key2: "B2B", actual: 1500000000 },
        { key1: "29", key2: "Chi Nhánh Bán Hàng", actual: 2500000000 },
        { key1: "29", key2: "E-Commerce", actual: 2000000000 },
        { key1: "29", key2: "Showroom", actual: 1000000000 },
        { key1: "29", key2: "Siêu Thị", actual: 600000000 },
        { key1: "29", key2: "Xuất Khẩu", actual: 250000000 },
        { key1: "30", key2: "B2B", actual: 3000000000 },
        { key1: "30", key2: "Chi Nhánh Bán Hàng", actual: 4000000000 },
        { key1: "30", key2: "E-Commerce", actual: 2500000000 },
        { key1: "30", key2: "Showroom", actual: 2000000000 },
        { key1: "30", key2: "Siêu Thị", actual: 800000000 },
        { key1: "30", key2: "Xuất Khẩu", actual: 400000000 },
        { key1: "31", key2: "B2B", actual: 2000000000 },
        { key1: "31", key2: "Chi Nhánh Bán Hàng", actual: 3000000000 },
        { key1: "31", key2: "E-Commerce", actual: 1500000000 },
        { key1: "31", key2: "Showroom", actual: 1000000000 },
        { key1: "31", key2: "Siêu Thị", actual: 500000000 },
        { key1: "31", key2: "Xuất Khẩu", actual: 200000000 }
    ]

    return <CustomGroupBarChart
        data={data} title={'Doanh Số Tháng Theo Tuần / Nhóm Khách'}
        groupBy='key2'
        isAutoShowData={false}
    />
}

const ReportPage = () => {
    return (
        <Container className="flex flex-wrap mx-3 overflow-auto h-[85svh]">
            <Grid container spacing={3}>
                <Grid container item xs={5} spacing={1}>
                    <Grid item xs={12}>
                        <MonthlyTargetChart />
                    </Grid>
                    <Grid item xs={12}>
                        <CumulativeTargetChart />
                    </Grid>
                    <Grid item xs={12}>
                        <GroupTargetChart />
                    </Grid>
                </Grid>
                <Grid container item xs={7} spacing={3}>
                    <Grid item xs={12}>
                        <MonthlySalesByGroupChart />
                    </Grid>
                    <Grid item xs={12}>
                        <WeeklySalesByGroupChart />
                    </Grid>
                </Grid>
            </Grid>
        </Container>

    )
}
export default ReportPage