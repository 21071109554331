import MaterialTable from "@material-table/core";
import { Autocomplete, Button, Chip, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Edit, PersonAddAlt, GroupAdd } from "@mui/icons-material";
import { toast } from "react-toastify";
import SaveUser from "src/views/permission/SaveUser";
import { ResultObject } from "src/apis/base/base";
import { fetchUserPermission, resetPassword, updatedUserPermission } from "src/apis/permission/apiUser";
import { DropdownOption } from "material-react-table";
import { fetchApplication } from "src/apis/permission/apiApplication";

let indexReload = 1;

const UserPage = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const [applications, setApplications] = useState<any[]>([])
  const [state, setState] = useState({
    showEdit: false,
    reLoadEdit: 1,
    reLoadAdd: 1,
    keyword: "",
    idExtend: "",
    detail: {},
    users: [] as any[],
  });


  useEffect(() => {
    loadApplication()
    loadData()
  }, []);

  const loadApplication = () => {
    fetchApplication().then(rs => {
      if (rs.success) {
        setApplications(rs.data)
      }
    })
  }

  const loadData = async () => {
    try {
      let data: ResultObject = await fetchUserPermission({
        id: state.idExtend,
        departmentId: state.keyword,
        pageIndex: 1,
        pageSize: 10000,
      });
      if (data.success) {
        setData(data.data);
      } else {
        alert(data.message);
      }
    } catch (error: any) {
      alert(error.message);
    }
  };

  //handle
  const handleOnSearch = () => {
    loadData();
  };

  const handleOnEdit = (event: any, rowData: any) => {
    indexReload = indexReload + 1;
    setState({
      ...state,
      showEdit: true,
      reLoadEdit: indexReload,
      detail: rowData,
    });
  };

  const handleOnAddUser = (event: any) => {
    indexReload = indexReload + 1;
    setState({
      ...state,
      showEdit: true,
      reLoadEdit: indexReload,
      detail: { userId: 'new' },
    });
  }

  const handleOnAdd = (event: any, rowData: any) => {
    const users = data.map((u: any) => u.userId);
    setState({
      ...state,
      reLoadAdd: state.reLoadAdd + 1,
      users: users,
    });
  };
  const handleOnSave = async (data: any) => {
    if (!data.permissions || data.permissions.length === 0) {
      setState({
        ...state,
        reLoadEdit: state.reLoadEdit + 1,
        showEdit: false,
      });
      return
    }

    let rs = await updatedUserPermission(data);
    if (rs.success) {
      if (rs.data === "1") {
        toast.success(t("common.actionSuccess"));
        setState({
          ...state,
          reLoadEdit: state.reLoadEdit + 1,
          showEdit: false,
        });
      }
      else toast.warning(t('common.actionError'))

    } else {
      toast.error(rs.message);
    }
  };

  const handleOnchange = (value: any, name: string) => {
    setState({ ...state, [name]: value });
  }

  const handleResetPassword = async (users: any) => {
    if (window.confirm(`Bạn có chắc muốn reset password cho [${+users.userId} - ${users.fullName}] không?`)) {
      try {
        let sendData = { ...users };
        sendData.siteType = 'sso';
        const data: ResultObject = await resetPassword(sendData);
        if (data.success) {
          // call save 
          toast.success("Reset mật khẩu thành công!");
        } else {
          toast.error(data.message);
        }
      } catch (error: any) {
        alert(error.message);
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={4}>
        <Grid item md={5} xs={6}>
          <TextField
            fullWidth
            onChange={(e) =>
              handleOnchange(e.target.value, "keyword")
            }
            label={t("common.idOrName")}
            placeholder=""
          />
        </Grid>
        <Grid item md={5} xs={6}>
          <Autocomplete
            options={applications}
            onChange={(e, value) => handleOnchange(value.id, "idExtend")}
            getOptionLabel={(obj: any) => obj.name}
            renderInput={(params) => (
              <TextField {...params} label={t("application")} />
            )}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Button
            size="large"
            fullWidth
            type="submit"
            sx={{ mr: 2, mt: 2, p: 2 }}
            variant="contained"
            onClick={handleOnSearch}
          >
            {t("common.search")}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          title={t("application.listUser")}
          columns={[
            { title: t("common.id"), field: "userId" },
            { title: t("common.fullName"), field: "fullName" },
            { title: t("common.departmentId"), field: "departmentId" },
            { title: t("common.application"), field: "applications" },
            { title: t("menu.permissionGroup"), field: "permissions" },
            {
              title: t("common.inUse"),
              field: "inUse",
              render: (rowData: any) =>
                rowData.inUse ? (
                  <Chip label={t("common.statusInUse")} color="success" />
                ) : (
                  <Chip label={t("common.statusNoUse")} color="default" />
                ),
            },
            {
              title: t("common.resetPassword"),
              field: "inUse",
              render: (rowData: any) => {
                return <Button onClick={() => handleResetPassword(rowData)} style={{ backgroundColor: '#00bbff' }} color="primary">
                  Reset password
                </Button>
              }

            },
          ]}
          data={data}
          actions={[
            {
              icon: () => <GroupAdd />,
              tooltip: t("common.addUserInApp") || "",
              isFreeAction: true,
              onClick: handleOnAddUser,
            },
            {
              icon: () => <Edit />,
              tooltip: t("common.edit") || "",
              onClick: handleOnEdit,
            },
          ]}
          options={{
            paging: true,
            pageSize: 10,
            actionsColumnIndex: 0,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SaveUser
          applications={applications}
          show={state.showEdit}
          reload={state.reLoadEdit}
          data={state.detail}
          onSave={handleOnSave}
        />
      </Grid>
    </Grid>
  );
};
export default UserPage
