import './LoadingPage.css'

interface ILoadingPageProps {
    showLoading?: boolean
}
const LoadingPage = (props: ILoadingPageProps) => {
    const { showLoading } = props
    return (
        <div id="loading-page-component" className={`loading-page ${showLoading ? '' : 'hidden'}`}>
            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export const ShowLoading = () => document.getElementById("loading-page-component")?.classList.remove("hidden")
export const HidenLoading = () => document.getElementById("loading-page-component")?.classList.add("hidden")

export default LoadingPage