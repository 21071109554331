import { Skeleton, Box } from "@mui/material"
import { t } from "i18next"

const DetailDocumentSkeleton = () => {
    return (
        <div className=" max-w-full px-3 w-9/12 md:flex-none">
            < div className="relative flex flex-col h-full min-w-0 mb-6 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border" >
                <div className="flex flex-wrap">
                    <Skeleton height={60} width={70} className="font-bold text-center  px-4 py-3 mx-3 mt-3 mb-2 text-gray ">
                    </Skeleton>
                    <Skeleton width={70} className="font-bold text-center  px-4 py-3 mx-3 mt-3 mb-2 text-gray ">
                    </Skeleton>
                </div>
                <hr className="h-px my-1 bg-gray-300 border-0"></hr>
                <div className="overflow-auto h-[78svh]">
                    <div className="flex flex-wrap px-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl ">
                        <div className="w-full px-3 md:w-9/12">
                            <h6 className="flex items-center w-full justify-between ">
                                <span className="whitespace-nowrap overflow-hidden text-ellipsis w-10/12 py-2 ">
                                    <Skeleton className={`font-bold text-center align-middle transition-all 
                                        bg-transparent border-0 rounded-lg px-3 py-1 mr-2  leading-pro
                                        text-xs ease-soft-in tracking-tight-soft shadow-soft-md `}>
                                    </Skeleton >
                                </span>
                            </h6>

                            <div className="mb-0 flex items-center w-full">
                                <div className="flex items-center">
                                    <Skeleton width={100} className="mb-0 mr-4" style={{ fontSize: '14px' }}><i className="fas fa-clock"></i> {``}</Skeleton >
                                    <Skeleton width={100} className="mb-0  mr-4" style={{ fontSize: '14px' }}><i className="fas fa-user"></i> {``}</Skeleton >
                                    <Skeleton width={100} className="mb-0 " style={{ fontSize: '14px' }}><i className="fa fa-file-text-o"></i> {``}</Skeleton >
                                </div>
                            </div>
                            <Skeleton width={100} className="relative w-full mt-4">
                                <h6 className="mb-2 font-bold leading-tight uppercase text-xs text-slate-500">{`${t('info')} ${t('description')}`}</h6>
                                <ul className="flex flex-col pl-0 mb-0 rounded-lg">
                                </ul>
                            </Skeleton>
                        </div>
                        <div className="block md:w-3/12 w-full mt-3">

                            <Skeleton className="mb-0 mr-4" style={{ fontSize: '14px' }}>
                            </Skeleton>
                            <Skeleton width={100} height={100} className="p-1 hover:shadow-black hover:shadow-sm duration-1000" >
                            </Skeleton>
                        </div>
                    </div>
                    <div className="flex-auto p-4 pt-6">
                        <Box sx={{ bgcolor: 'background.paper' }}>
                            <Skeleton sx={{ width: '100%', height: '80px' }}>
                            </Skeleton>
                            <div style={{ padding: '2%', paddingTop: '-2%' }}>
                                <Skeleton sx={{ width: '100%', height: '100px' }}>
                                </Skeleton>
                                <Skeleton sx={{ width: '100%', height: '100px' }}>
                                </Skeleton>
                                <Skeleton sx={{ width: '100%', height: '100px' }}>
                                </Skeleton>
                                <Skeleton sx={{ width: '100%', height: '100px' }}>
                                </Skeleton>
                            </div>
                        </Box>
                    </div>
                </div>
            </div >
        </div>)
}


export default DetailDocumentSkeleton