import { IApplication } from "src/interfaces/permission/IApplication";
import api, { ResultObject } from "../base/base";

const baseUrl = `${process.env.REACT_APP_API_PERMISSION}/api/application`;

export const fetchApplication = async (
  showAll: boolean = false
): Promise<ResultObject> => {
  const result = await api
    .get(baseUrl + "?showAll=" + showAll)
    .then((response: any) => response?.data);
  return result;
};

export const createApplication = async (obj: IApplication): Promise<any> => {
  const result = await api
    .post(`${baseUrl}`, obj)
    .then((response: any) => response?.data);
  return result;
};

export const updateApplication = async (obj: IApplication): Promise<any> => {
  const result = await api
    .put(`${baseUrl}`, obj)
    .then((response: any) => response?.data);
  return result;
};

export const deleteApplication = async (id: string): Promise<any> => {
  const result = await api
    .delete(`${baseUrl}/${id}`)
    .then((response: any) => response?.data);
  return result;
};

// user application
export const fetchDetail = async (
  applicationId: string = ""
): Promise<ResultObject> => {
  const result = await api
    .get<ResultObject>(baseUrl + "/detail/" + applicationId)
    .then((response: any) => response.data);
  return result;
};
