export enum DocumentAction {
    CreatedDocument=0,
    UpdateDocument=1,
    UploadFile=2,
    UpdateFile=3,
    Assign=4,
    Download=5,
    Return=6,
    Retract=7,
    Promulgate=8,
    Share=9,
    PrivateAccess=10,
    Finish=11,
    Deleted=12
}


